<template>
  <div class="ru:badge" :style="styles">
    <slot />
  </div>
</template>

<script>
import { hexToRgb } from '@/utilities';
export default {
  name: 'ComponentsAtomsBadge',
  props: {
    background: {
      default: null,
      type: String,
    },
    solid: {
      default: false,
      type: Boolean,
    },
    color: {
      default: null,
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return `var(--lighter)`;
    },
    colorStyle() {
      if (this.color) {
        if (this.color.includes('#')) {
          return hexToRgb(this.color);
        }
        return `var(--${this.color})`;
      }
      return `var(--darker)`;
    },
    dimensionStyle() {
      if (this.size === 'huge') {
        return 2.375;
      } else if (this.size === 'large') {
        return 1.2;
      } else if (this.size === 'small') {
        return 0.5;
      }
      return null;
    },
    styles() {
      return {
        '--background': this.backgroundStyle,
        '--color': this.colorStyle,
        '--dimensions': this.dimensionStyle,
      };
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &badge {
    --dimensions: 1;
    flex-shrink: 0;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background: --rgba(background);
    color: --rgba(color);
    font-size: #{calc(1.125rem * var(--dimensions))};
    width: #{calc(2rem * var(--dimensions))};
    height: #{calc(2rem * var(--dimensions))};
    transition: --in();
    @include mq('min-md') {
      font-size: #{calc(1.25rem * var(--dimensions))};
      width: #{calc(2.5rem * var(--dimensions))};
      height: #{calc(2.5rem * var(--dimensions))};
    }
  }
}
</style>
