<template>
  <ModuleStep :index="5" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{
            $t('pages.poa.medical.substitutes.headline', {
              substitute: canHaveMultipleSubstitutes
                ? $t('texts.sentences.substituteDecisionMakers')
                : $t('texts.sentences.aSubstituteDecisionMaker'),
            })
          }}
        </Headline>
        <p class="mb-4">
          {{ $t('pages.poa.medical.substitutes.stateDescription')[userState] }}
        </p>
        <LargeRadioButtons
          id="has_substitute"
          v-model="poaMedicalMeta.has_substitute"
          layout="column"
          :options="[
            {
              label: $t('pages.poa.medical.substitutes.options.yes', {
                substitute: canHaveMultipleSubstitutes
                  ? $t('texts.sentences.substituteDecisionMakers')
                  : $t('texts.sentences.aSubstituteDecisionMaker'),
              }),
              value: true,
            },
            {
              label: $t('pages.poa.medical.substitutes.options.no', {
                substitute: canHaveMultipleSubstitutes
                  ? $t('texts.sentences.anySubstituteDecisionMakers')
                  : $t('texts.sentences.aSubstituteDecisionMaker'),
              }),
              value: false,
            },
          ]"
          @input="onSubmit"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalSubstitutes',
  components: {
    AdiForm,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaMedicalMeta: {
      ...metaQueries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIDs: [],
      poaMedicalMeta: {
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIDs.length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        poaMedicalMeta: this.poaMedicalMeta,
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIDs.length,
      });
    },
    canHaveMultipleSubstitutes() {
      return ['act'].includes(this.userState);
    },
    primaryAttorneyPeopleIDs() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'PRIMARY')
        ?.map((attorney) => attorney.person.id);
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaMedicalMeta &&
        (this.poaMedicalMeta.has_substitute === true ||
          this.poaMedicalMeta.has_substitute === false)
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length) {
        this.selectedPrimaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        metaMutations.updatePoaMeta(this.poaId, {
          key: 'has_substitute',
          value: this.poaMedicalMeta.has_substitute ? 'true' : 'false',
          category: 'MEDICAL',
        })
      );
      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
