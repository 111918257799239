<template>
  <BaseButton
    class="w-12 h-10 mx-1"
    :class="classes"
    :disabled="disabled"
    focusable
    @click="$emit('click')"
  >
    <div class="flex justify-center items-center">
      <slot />
    </div>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  name: 'ComponentsAdminDataTableButton',
  components: {
    BaseButton,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        'text-teal-200': !this.disabled,
        'text-grey-300': this.disabled,
        'cursor-wait': this.loading,
      };
    },
  },
};
</script>
