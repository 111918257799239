<template>
  <div class="flex">
    <Tooltip
      class="flex-grow md:flex-grow-0"
      :content="tooltip"
      :disabled="!disabled"
    >
      <BaseButton
        class="btn btn-xl btn-slate btn-focus w-full md:w-auto text-white"
        :class="classes"
        :disabled="disabled"
        @click="downloadWill"
      >
        {{ buttonText }}
      </BaseButton>
    </Tooltip>
    <div class="relative ml-2">
      <Dropdown :direction="direction">
        <template #toggle>
          <div v-if="!disabled" class="btn btn-xl bg-slate-100 text-white">
            <div class="flex justify-center">
              <BaseGlyph :id="glyph" />
            </div>
          </div>
        </template>
        <template #items>
          <MenuItem
            :text="$t('components.willDownloadButton.viewSigningInstructions')"
            vertical
            @click="viewSigningInstructions"
          />
          <MenuItem
            :icon="hasExpiredSubscription ? 'lock' : null"
            :text="$t('components.willDownloadButton.updateWill')"
            vertical
            @click="unlockWill"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseButton from '@/components/BaseButton';
import BaseGlyph from '@/components/BaseGlyph';
import Dropdown from '@/components/Dropdown';
import MenuItem from '@/components/MenuItem';
import Tooltip from '@/components/Tooltip';

import { subscription, user, will } from '@/mixins/apollo';
import files from '@/mixins/files';

export default {
  name: 'ComponentsWillDownloadComboButton',
  components: {
    BaseButton,
    BaseGlyph,
    Dropdown,
    MenuItem,
    Tooltip,
  },
  mixins: [files, subscription, user, will],
  computed: {
    ...mapGetters(['token', 'willId', 'willStatus', 'verified']),
    buttonText() {
      return ['base', 'sm'].includes(this.$mq)
        ? this.$t('components.willDownloadButton.downloadWill')
        : this.$t('components.willDownloadButton.downloadYourWill');
    },
    classes() {
      return {
        'btn-disabled text-grey-500': this.disabled,
      };
    },
    direction() {
      return ['base', 'sm'].includes(this.$mq) ? 'up' : 'down';
    },
    disabled() {
      return (
        this.will &&
        (this.will.status === 'AWAITING_APPROVAL' ||
          (this.will.status === 'APPROVED' && !this.verified) ||
          !this.will.willFileId)
      );
    },
    glyph() {
      return ['base', 'sm'].includes(this.$mq) ? 'angle-up' : 'angle-down';
    },
    pdfFilename() {
      return `safewill-${this.will.hashId}_${this.willMeta.name_last},${this.willMeta.name_first}`.toLowerCase();
    },
    tooltip() {
      return this.will && this.will.status === 'AWAITING_APPROVAL'
        ? this.$t('components.willDownloadButton.tooltip.willAwaitingApproval')
        : this.$t('components.willDownloadButton.tooltip.emailNotVerified');
    },
  },
  methods: {
    ...mapActions('ui', [
      'setShowSubscriptionExpiredModal',
      'setShowUnlockWillModal',
    ]),
    async downloadWill() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '📥 Download Will',
      });
      await this.downloadFile(this.will.willFileId);
    },
    unlockWill() {
      if (this.hasValidSubscription) {
        this.setShowUnlockWillModal(true);
      } else {
        this.setShowSubscriptionExpiredModal(true);
      }
    },
    viewSigningInstructions() {
      window.open(
        'https://safewill.com/blog/posts/how-do-i-sign-my-will',
        '_blank'
      );
    },
  },
};
</script>
