<template>
  <div class="ru:orders">
    <div class="grid ru:orders__header">
      <div class="cell cell__2/12">{{ $t('components.orders.date') }}</div>
      <div class="cell cell__7/12">{{ $t('components.orders.order') }}</div>
      <div class="cell cell__3/12">{{ $t('components.orders.cost') }}</div>
    </div>
    <div
      v-for="(order, orderIndex) in orders"
      :key="`order${orderIndex}`"
      class="ru:orders__order"
    >
      <button
        :ref="`expandOrder${orderIndex}`"
        class="ru:orders__expander"
        :class="{ '--active': expandedOrders[orderIndex] }"
        @click="expandOrder(orderIndex)"
      >
        <div
          :key="`orderSummary${orderIndex}`"
          class="grid grid--flush grid--middle"
        >
          <div class="cell cell__2/12">
            {{ $formatDate(order.createdAt, 'dd mmm yyyy') }}
          </div>
          <div class="cell cell__7/12">
            {{ $t('components.orders.order') }}: {{ order.id.split('-')[0] }}
          </div>
          <div class="cell cell__2/12">
            {{ money(order.valueInCents) }}
          </div>
          <div class="cell cell__1/12">
            <Icon id="chevron-down" size="small" />
          </div>
        </div>
      </button>
      <template v-if="expandedOrders[orderIndex]">
        <div
          :key="`orderColumns${orderIndex}`"
          class="grid grid--flush ru:orders__header"
        >
          <div class="cell cell__2/12">{{ $t('components.orders.qty') }}</div>
          <div class="cell cell__5/12">{{ $t('components.orders.item') }}</div>
          <div class="cell cell__2/12">
            {{ $t('components.orders.unitPrice') }}
          </div>
          <div class="cell cell__3/12">{{ $t('components.orders.total') }}</div>
        </div>
        <div
          v-for="(item, itemIndex) in collatedItems(order.orderItems)"
          :key="`orderItems${orderIndex}-${itemIndex}`"
          class="grid ru:orders__line-item"
        >
          <div class="cell cell__2/12">{{ item.count }}</div>
          <div class="cell cell__5/12">
            {{ productToDisplayNameMapping[item.product] }}
          </div>
          <div class="cell cell__2/12">
            {{ money(item.valueInCents) }}
          </div>
          <div class="cell cell__3/12">
            {{ money(item.valueInCents * item.count) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import { productToDisplayNameMapping, money } from '@/utilities';

import orders from '@/mixins/apollo/orders';

export default {
  name: 'ComponentsOrganismsOrders',
  components: {
    Icon,
  },
  mixins: [orders],
  data() {
    return {
      productToDisplayNameMapping,
      expandedOrders: [],
    };
  },
  methods: {
    money(valueInCents) {
      return money(valueInCents / 100);
    },
    expandOrder(orderIndex) {
      this.$set(
        this.expandedOrders,
        orderIndex,
        !this.expandedOrders[orderIndex]
      );
      this.$refs[`expandOrder${orderIndex}`][0].blur();
    },
    collatedItems(items) {
      const products = [];
      const collatedItems = [];
      items.forEach((item) => {
        const product = item.product;
        const productIndex = products.indexOf(product);
        if (productIndex > -1) {
          collatedItems[productIndex].count += 1;
        } else {
          products.push(product);
          const lineItem = {
            product,
            count: 1,
            valueInCents: item.valueInCents,
          };
          collatedItems.push(lineItem);
        }
      });
      return collatedItems;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &orders {
    &__header {
      font-weight: 500;
      margin-bottom: var(--base-margin-small);
      border-bottom: 1px solid --rgba(iron);
    }

    &__expander {
      display: block;
      text-align: left;
      padding: var(--base-margin-small) 0.5rem;
      width: #{calc(100% + 1rem)};
      margin: 0 -0.5rem;
      border-radius: var(--border-radius);
      transition: --out();

      #{$ru}icon {
        transform-origin: center;
        transition: --out();
      }

      &:hover,
      &:is(#{$mf}active) {
        transition: --in();
        background: --rgba(lighter);
        margin-bottom: var(--base-margin-small);
      }

      &:is(#{$mf}active) #{$ru}icon {
        transform: rotate(-180deg);
        transition: --in();
      }
    }

    &__order {
      margin-bottom: var(--base-margin-small);
    }

    &__order &__header,
    &__line-item {
      color: --rgba(rock);
    }
  }
}
</style>
