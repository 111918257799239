var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":5,"prev-step":_vm.previous}},[_c('AdiForm',{attrs:{"hide-submit":!_vm.isComplete,"submit-icon":"arrow-right"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.substitutes.headline', {
            substitute: _vm.canHaveMultipleSubstitutes
              ? _vm.$t('texts.sentences.substituteDecisionMakers')
              : _vm.$t('texts.sentences.aSubstituteDecisionMaker'),
          }))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.substitutes.stateDescription')[_vm.userState])+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_substitute","layout":"column","options":[
          {
            label: _vm.$t('pages.poa.medical.substitutes.options.yes', {
              substitute: _vm.canHaveMultipleSubstitutes
                ? _vm.$t('texts.sentences.substituteDecisionMakers')
                : _vm.$t('texts.sentences.aSubstituteDecisionMaker'),
            }),
            value: true,
          },
          {
            label: _vm.$t('pages.poa.medical.substitutes.options.no', {
              substitute: _vm.canHaveMultipleSubstitutes
                ? _vm.$t('texts.sentences.anySubstituteDecisionMakers')
                : _vm.$t('texts.sentences.aSubstituteDecisionMaker'),
            }),
            value: false,
          },
        ]},on:{"input":_vm.onSubmit},model:{value:(_vm.poaMedicalMeta.has_substitute),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "has_substitute", $$v)},expression:"poaMedicalMeta.has_substitute"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }