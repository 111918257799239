var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":2,"prev-step":"/will/guardians/your-children"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_primary_guardian,"submit-icon":"arrow-right"},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',{staticClass:"step-1"},[_c('Headline',{attrs:{"level":1}},[_c('i18n',{attrs:{"path":"pages.will.guardians.appointGuardianPrompt"}},[_c('GlossaryTooltip',{attrs:{"term":"guardian"}},[_vm._v("\n            "+_vm._s(_vm.$t('texts.words.guardian'))+"\n          ")])],1)],1),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.childGuardianExplanation'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.survivingParentGuardian'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPrimaryGuardians","options":[
          {
            label: _vm.$t('forms.labels.yes'),
            value: true,
          },
          {
            label: _vm.$t('forms.labels.no'),
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_primary_guardian),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_primary_guardian", $$v)},expression:"willMeta.has_primary_guardian"}})],1),_vm._v(" "),(_vm.willMeta.has_primary_guardian)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":_vm.$t('pages.will.peopleSelectionInstructions')}},[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.primaryGuardianPrompt'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.primaryGuardianExplanation'))+"\n      ")]),_vm._v(" "),_c('Tip',[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.guardians.dontAppointOtherParent'))+"\n        ")]),_vm._v(" "),_c('Buttons',{attrs:{"layout":"start"}},[_c('RuButton',{attrs:{"theme":"secondary","size":"small","href":"https://safewill.com/blog/posts/how-do-i-choose-a-guardian"}},[_c('Icon',{attrs:{"id":"info"}}),_vm._v("\n            "+_vm._s(_vm.$t('pages.will.guardians.howChooseGuardian'))+"\n          ")],1)],1)],1),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":1,"people":_vm.filteredPeople,"type":"guardian"},model:{value:(_vm.selectedPrimaryGuardian),callback:function ($$v) {_vm.selectedPrimaryGuardian=$$v},expression:"selectedPrimaryGuardian"}})],1):_vm._e()]}}])}),_vm._v(" "),_c('GlossaryPanel')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }