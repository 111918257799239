var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.element,{tag:"component",staticClass:"ru:button",class:[
    _vm.level ? `--${_vm.level}` : '',
    _vm.theme ? `--${_vm.theme}` : '',
    _vm.align ? `--${_vm.align}` : '',
    _vm.size ? `--${_vm.size}` : '',
    _vm.active ? `--active` : '',
  ],style:(_vm.styles),attrs:{"id":_vm.id,"href":_vm.href,"to":_vm.href,"type":_vm.type,"aria-label":_vm.ariaLabel,"disabled":_vm.disabled,"rel":_vm.rel,"target":_vm.target},on:{"click":_vm.click}},[_c('span',{staticClass:"ru:button__slot"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }