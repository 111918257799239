<template>
  <div>
    <svg
      class="absolute w-full h-full"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" stroke-width="2" stroke-linecap="round">
        <circle
          class="text-grey-400 stroke-current"
          stroke-dasharray="2,4"
          vector-effect="non-scaling-stroke"
          cx="24"
          cy="24"
          r="23"
        />
        <circle
          ref="progress"
          class="text-teal-200 stroke-current"
          :class="{ invisible: !progress }"
          transform="translate(0, 48) rotate(-90)"
          vector-effect="non-scaling-stroke"
          cx="24"
          cy="24"
          r="23"
        />
      </g>
    </svg>
    <div
      v-if="complete"
      class="absolute flex justify-center items-center w-12 h-12 rounded-full bg-teal-200 text-white"
    >
      <BaseIcon id="tick" />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

import BaseIcon from '@/components/BaseIcon';

export default {
  name: 'ComponentsProgressRing',
  components: {
    BaseIcon,
  },
  props: {
    progress: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    complete() {
      return this.progress === 1;
    },
    dashOffset() {
      return anime.setDashoffset(this.$refs.progress) * (1 - this.progress);
    },
  },
  mounted() {
    anime.set(this.$refs.progress, {
      strokeDashoffset: anime.setDashoffset,
    });

    anime({
      targets: this.$refs.progress,
      strokeDashoffset: this.dashOffset,
      duration: 500,
      easing: 'easeInOutQuad',
      begin: () => {
        this.ready = true;
      },
    });
  },
};
</script>
