<template>
  <div class="ru:container ru:container--tight">
    <Headline :level="1"> {{ $t('texts.sentences.thankYou') }} </Headline>
    <p>
      {{ $t('pages.checkout.paymentSuccessful') }}
    </p>
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliateCheckoutGenericThanks',
  components: {
    Headline,
  },
  layout: 'ruach',
};
</script>
