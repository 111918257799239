<template>
  <table class="ru:admin-simple-table">
    <caption v-if="description">
      {{
        description
      }}
    </caption>
    <tbody>
      <tr class="d-none">
        <th scope="col">{{ $t('components.adminSimpleTable.item') }}</th>
        <th scope="col">{{ $t('components.adminSimpleTable.value') }}</th>
      </tr>
      <tr v-for="(row, index) in rows" :key="index">
        <td>{{ row[0] }}</td>
        <td v-if="row[2]">
          <a :href="row[2]" class="link link-dark whitespace-no-wrap">
            {{ row[1] }}
          </a>
        </td>
        <td v-else>
          {{ row[1] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ComponentsAdminSimpleTable',
  props: {
    rows: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-simple-table {
    --sand: 242, 242, 242;

    @at-root #{$ru}box & {
      td,
      th {
        padding: var(--base-margin-small) var(--base-margin);
      }
    }

    tr {
      &:nth-child(2n) {
        background: --rgba(sand);
      }
    }

    td:first-child {
      color: --rgba(dust);
      width: 40%;
    }
  }
}
</style>
