<template>
  <ProductGateway
    :title="$t('components.willGateway.title')"
    icon="feather"
    :primary-cta-list="primaryCtaList"
    :items-cta="primaryCtaList[0]"
    :items="items"
    :progress="willProgress"
    :items-description="itemsDescription"
    :completed="willProgress >= 100 && willStatus === 'APPROVED'"
  >
    <RuImage :src="require('@/assets/svg/gateway__will.svg')" alt="" />
    <template v-if="willStatus === 'IN_PROGRESS'">
      <p v-if="completedWillStepsAsPercentage < 100">
        {{ $t('components.willGateway.description.notStarted') }}
      </p>
      <p v-else>
        {{ $t('components.willGateway.description.finishedNotSubmitted') }}
      </p>
    </template>
    <p v-if="willStatus === 'AWAITING_APPROVAL'">
      {{ $t('components.willGateway.description.awaitingApproval') }}
    </p>
    <p v-if="willStatus === 'FLAGGED'">
      {{ $t('components.willGateway.description.flagged') }}
    </p>
    <template v-if="willStatus === 'APPROVED'">
      <p>
        {{ $t('components.willGateway.description.approved') }}
        <a
          class="ru:product-gateway__link"
          href="https://safewill.com/blog/posts/how-do-i-sign-my-will"
          target="_blank"
          >{{ $t('components.willGateway.howToSignYourWill') }}
        </a>
      </p>
    </template>
  </ProductGateway>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductGateway from '@/components/molecules/ProductGateway';
import RuImage from '@/components/atoms/Image';

import willProgress from '@/mixins/will-progress';
import { will, purchasedProducts } from '@/mixins/apollo';

export default {
  name: 'ComponentsTemplatesWillGateway',
  components: {
    ProductGateway,
    RuImage,
  },
  mixins: [will, purchasedProducts, willProgress],
  computed: {
    ...mapGetters(['willStatus']),
    ...mapGetters('modules', ['modules']),
    items() {
      return [
        {
          title: this.$t('components.willGateway.modules.aboutYourself'),
          status: this.getModuleStatus('About Yourself'),
          cta: '/will/about-yourself/name',
          disabled: this.willStatus === 'APPROVED',
        },
        {
          title: this.$t('components.willGateway.modules.family'),
          status: this.getModuleStatus('Guardians'),
          cta: '/will/guardians/your-children',
          disabled: this.willStatus === 'APPROVED',
        },
        {
          title: this.$t('components.willGateway.modules.executors'),
          status: this.getModuleStatus('Executors'),
          cta: '/will/executors',
          disabled: this.willStatus === 'APPROVED',
        },
        {
          title: this.$t('components.willGateway.modules.estate'),
          status: this.getModuleStatus('Estate'),
          cta: '/will/estate',
          disabled: this.willStatus === 'APPROVED',
        },
        {
          title: this.$t('components.willGateway.modules.gifts'),
          status: this.getModuleStatus('Gifts'),
          cta: '/will/gifts',
          disabled: this.willStatus === 'APPROVED',
        },
        {
          title: this.$t('components.willGateway.modules.assets'),
          status: this.getModuleStatus('Assets'),
          cta: '/will/assets',
          disabled: this.willStatus === 'APPROVED',
        },
        {
          title: this.$t('components.willGateway.modules.funeral'),
          status: this.getModuleStatus('Funeral'),
          cta: '/will/funeral',
          disabled: this.willStatus === 'APPROVED',
        },
      ];
    },
    ctaText() {
      if (this.isWillPurchased && this.willStatus === 'IN_PROGRESS') {
        return this.$t('components.willGateway.inProgress.cta');
      }
      if (this.willStatus === 'APPROVED') {
        return this.$t('components.willGateway.approved.cta');
      }
      if (this.willStatus === 'AWAITING_APPROVAL') {
        return this.$t('components.willGateway.awaitingApproval.cta');
      }
      if (this.willMeta.name_last) {
        return 'Complete my Will';
      }
      return this.$t('components.willGateway.notStarted.cta');
    },
    primaryCtaList() {
      if (
        this.willStatus === 'IN_PROGRESS' &&
        this.completedWillStepsAsPercentage >= 100
      ) {
        return [
          {
            text: this.$t('components.willGateway.submitYourWill'),
            link: '/will',
            icon: 'arrow-right',
          },
        ];
      } else if (this.willStatus === 'IN_PROGRESS') {
        return [
          {
            text: this.$t('components.willGateway.completeMyWill'),
            link: '/will',
            icon: 'arrow-right',
          },
        ];
      } else if (this.willStatus === 'AWAITING_APPROVAL') {
        return [
          {
            text: this.$t('components.willGateway.downloadMyWill'),
            link: '/will',
            icon: 'download',
            disabled: true,
          },
        ];
      } else if (this.willStatus === 'FLAGGED') {
        return [
          {
            text: this.$t('components.willGateway.editMyWill'),
            link: '/will',
            icon: 'pencil',
          },
        ];
      } else if (this.willStatus === 'APPROVED') {
        return [
          {
            text: this.$t('components.willGateway.downloadMyWill'),
            link: '/will',
            icon: 'download',
          },
          {
            text: this.$t('components.willGateway.updateMyWill'),
            link: '/will',
            icon: 'pencil',
            level: 'plain',
          },
        ];
      }
      return [
        {
          link: '/will',
          text: this.$t('components.willGateway.editMyWill'),
        },
      ];
    },
    willProgress() {
      let willProgress = this.completedWillStepsAsPercentage * 0.9;
      if (this.isWillPurchased) {
        willProgress += 10;
      }
      return willProgress;
    },
    itemsDescription() {
      const hasCompleted = this.modules.reduce(
        (hasCompleted, current) =>
          ['completed', 'awaiting-approval', 'approved'].includes(
            this.getModuleStatus(current.name)
          )
            ? hasCompleted + 1
            : hasCompleted,
        0
      );

      return `${hasCompleted} ${this.$t('components.poaGateway.outOf')} ${
        this.modules.length
      } ${this.$t('components.poaGateway.areComplete')}`;
    },
  },
  methods: {
    getModuleStatus(moduleName) {
      if (this.willStatus === 'APPROVED') {
        return 'approved';
      }
      if (this.willStatus === 'FLAGGED' && this.willIssues[moduleName]) {
        return 'flagged';
      }
      if (this.willStatus === 'AWAITING_APPROVAL') {
        return 'awaiting-approval';
      }
      const totalModuleSteps = this.modules.find(
        (module) => module.name === moduleName
      ).totalSteps;
      if (this.completedSteps[moduleName] >= totalModuleSteps) {
        return 'completed';
      }
      if (this.completedSteps[moduleName] > 0) {
        return 'in-progress';
      }
      return 'not-started';
    },
  },
};
</script>
