<template>
  <nuxt-child />
</template>

<script>
export default {
  name: 'PagesAffiliateWillGifts',
  layout: 'ruach',
  middleware: ['authenticated'],
};
</script>
