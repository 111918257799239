<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.relationshipToDeceased') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.checkAboutYourselfDetails') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('initial')">
      <Headline :level="3">{{ $t('texts.sentences.otherDetails') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantRelationship"
            v-model="formData.informantRelationship"
            type="select"
            :text="$t('forms.labels.relationshipToDeceased')"
            :options="RELATIVE_OPTIONS"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestHasWill"
            v-model="formData.guestHasWill"
            type="select"
            :text="$t('forms.labels.didDeceasedHaveWill')"
            :options="YES_NO_OPTIONS"
          />
        </div>
        <div
          v-if="showInformantIsWillExecutor"
          class="cell cell__12/12 cell__4/12--lg"
        >
          <FormControl
            id="informantIsWillExecutor"
            v-model="formData.informantIsWillExecutor"
            type="select"
            :text="$t('forms.labels.areYouExecutor')"
            :options="YES_NO_OPTIONS"
          />
        </div>
        <div
          v-if="showInformantIsNextOfKin"
          class="cell cell__12/12 cell__4/12--lg"
        >
          <FormControl
            id="informantIsNextOfKin"
            v-model="formData.informantIsNextOfKin"
            type="select"
            :text="$t('forms.labels.areYouTheNextOfKin')"
            :options="YES_NO_OPTIONS"
          />
        </div>
      </div>
      <div v-if="showExecutorOrKin">
        <Headline :level="3">
          {{ executorOrKin }}
          {{ $t('pages.cremations.contactDetails') }}
        </Headline>
        <div v-if="!formData.guestHasWill">
          <p>
            {{ $t('pages.cremations.cremationArrangementsNoWill') }}
          </p>
          <p>
            {{ $t('pages.cremations.authorityToActNextOfKin') }}
          </p>
        </div>
        <div v-else>
          <p>
            {{ $t('pages.cremations.cremationArrangementsExecutor') }}
          </p>
          <p>
            {{ $t('pages.cremations.authorityToActExecutor') }}
          </p>
        </div>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="firstName"
              v-model="formData.executorOrKinFirstName"
              type="text"
              :text="$t('forms.labels.firstName')"
              :pattern="FIRST_NAME_PATTERN.regex"
              :title="$t(FIRST_NAME_PATTERN.description)"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="middleName"
              v-model="formData.executorOrKinOtherGivenNames"
              type="text"
              :text="$t('forms.labels.otherGivenNames')"
              :required="false"
              :optional="true"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="lastName"
              v-model="formData.executorOrKinLastName"
              type="text"
              :text="$t('forms.labels.lastName')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="executorRelationship"
              v-model="formData.executorOrKinRelationship"
              type="select"
              :text="$t('forms.labels.relationshipToDeceased')"
              :options="RELATIVE_OPTIONS"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="primaryPhone"
              v-model="formData.executorOrKinPhone"
              type="text"
              text="Primary Phone Number"
              :required="false"
              :optional="true"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="email"
              v-model="formData.executorOrKinEmail"
              type="text"
              :text="$t('forms.labels.emailAddress')"
              :required="false"
              :optional="true"
            />
          </div>
        </div>
        <Headline :level="3">
          {{ executorOrKin }} {{ $upperFirst($t('texts.words.address')) }}
        </Headline>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="executorOrKinAddressLine1"
              v-model="formData.executorOrKinAddressLine1"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.addressLine1')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="executorOrKinAddressLine2"
              v-model="formData.executorOrKinAddressLine2"
              :required="false"
              :optional="true"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.addressLine2')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="executorOrKinAddressSuburb"
              v-model="formData.executorOrKinAddressSuburb"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.suburb')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              v-if="showExecutorOrKinRegion"
              id="executorOrKinAddressState"
              v-model="formData.executorOrKinAddressState"
              :disabled="loading"
              type="text"
              text="Region"
            />
            <FormControl
              v-else
              id="executorOrKinAddressState"
              v-model="formData.executorOrKinAddressState"
              :disabled="loading"
              type="select"
              :text="$t('forms.labels.australianState')"
              :options="australianStatesOptions"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="executorOrKinAddressPostcode"
              v-model="formData.executorOrKinAddressPostcode"
              :disabled="loading"
              type="text"
              :text="
                showExecutorOrKinRegion
                  ? $t('forms.labels.postCode')
                  : $t('forms.labels.4digitPostcode')
              "
              inputmode="decimal"
              min="4"
              max="4"
              :pattern="showExecutorOrKinRegion ? null : `[0-9]{4}`"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="executorOrKinAddressCountry"
              v-model="formData.executorOrKinAddressCountry"
              type="select"
              :text="$t('forms.labels.country')"
              :disabled="loading"
              :options="countries"
            />
          </div>
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="goNext">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="goNext">
          {{ $t('forms.labels.ok') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedInstructionsId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      formData: {
        informantRelationship: null,
        guestHasWill: null,
        informantIsWillExecutor: null,
        informantIsNextOfKin: null,
        executorOrKinFirstName: null,
        executorOrKinOtherGivenNames: null,
        executorOrKinLastName: null,
        executorOrKinRelationship: null,
        executorOrKinPhone: null,
        executorOrKinEmail: null,
        executorOrKinAddressLine1: null,
        executorOrKinAddressLine2: null,
        executorOrKinAddressSuburb: null,
        executorOrKinAddressState: null,
        executorOrKinAddressPostcode: null,
        executorOrKinAddressCountry: null,
      },
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/informant/${this.cremationRequestId}`
      );
    },
    executorOrKin() {
      if (
        this.formData.guestHasWill &&
        !this.formData.informantIsWillExecutor
      ) {
        return 'Executor';
      }
      return 'Next of Kin';
    },
  },
};
</script>
