var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":2,"prev-step":"/will/executors"}},[_c('AdiForm',{attrs:{"hide-submit":""},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.executors.options.whoExecutor'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.executors.options.tip'))+"\n      ")]),_vm._v(" "),_c('div',_vm._l((_vm.executorOptions),function(option,index){return _c('ExpandingCard',{key:index,staticClass:"mb-5",attrs:{"open":option.value === _vm.willMeta.executors_option},on:{"click":function($event){_vm.willMeta.executors_option = option.value}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(option.label)+"\n          ")]},proxy:true}],null,true)},[_vm._v(" "),_c('div',{staticClass:"flex items-center my-6"},[_c('BaseIcon',{attrs:{"id":"info"}}),_vm._v(" "),_c('span',{staticClass:"ml-3 font-bold text-sm tracking-wide uppercase text-slate-100"},[_vm._v("\n              "+_vm._s(_vm.$t('pages.will.executors.options.youShouldKnow'))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"text-lg"},[(option.value === 'friendsFamily')?[_c('p',{staticClass:"mb-8"},[_vm._v("\n                "+_vm._s(_vm.$t('pages.will.executors.options.friendsFamily.content'))+"\n              ")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'pages.will.executors.options.friendsFamily.trustedByYou'
                    ))+"\n                ")]),_vm._v(" "),_c('li',[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'pages.will.executors.options.friendsFamily.familiarWithLegal'
                    ))+"\n                ")]),_vm._v(" "),_c('li',[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'pages.will.executors.options.friendsFamily.influencedByFamily'
                    ))+"\n                ")]),_vm._v(" "),_c('li',[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'pages.will.executors.options.friendsFamily.willingAndAvailableToHelp'
                    ))+"\n                ")])])]:(option.value === 'professional')?[_c('p',{staticClass:"mb-8"},[_vm._v("\n                "+_vm._s(_vm.$t('pages.will.executors.options.professional.content'))+"\n              ")]),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.$t('pages.will.executors.options.professional.content2'))+"\n              ")])]:(option.value === 'friendsFamilyAndProfessional')?[_c('p',{staticClass:"mb-8"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'pages.will.executors.options.friendsFamilyAndProfessional.content'
                  ))+"\n              ")]),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.$t(
                    'pages.will.executors.options.friendsFamilyAndProfessional.content2'
                  ))+"\n              ")])]:_vm._e()],2),_vm._v(" "),(option.value !== 'friendsFamily')?_c('ReviewsSummaryCard',{staticClass:"mt-8"}):_vm._e(),_vm._v(" "),_c('BaseButton',{staticClass:"btn-xl btn-slate w-full mt-5",attrs:{"loading":_vm.isLoading},on:{"click":_vm.onSubmit}},[_c('div',{staticClass:"flex justify-between items-center"},[_c('span',{staticClass:"inline-block text-white"},[_vm._v("\n                "+_vm._s(_vm.$t('forms.labels.select'))+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"ml-4"},[_c('BaseIcon',{attrs:{"id":"arrow-right"}})],1)])])],1)}),1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }