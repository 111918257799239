<template>
  <div class="ru:referrals">
    <Box
      v-if="!invitedByPartner && !hasPurchasedPartnerWill"
      background="white"
    >
      <Chip background="darker">
        {{ $t('components.invite.invitePartner.discount') }}
      </Chip>
      <Headline :level="2">
        {{ $t('components.invite.invitePartner.title') }}
      </Headline>
      <p>{{ $t('components.invite.invitePartner.description') }}</p>
      <SentInvite
        v-for="invite in partnerInvites"
        :key="invite.id"
        :invite="invite"
      />
      <SendInvite
        v-if="!hasInvitedPartner"
        :invites="invites"
        :products="['WILL']"
        :prefill="partnerEmail"
        type="PARTNER"
      />
    </Box>
    <Box background="white">
      <Chip background="darker">
        {{ $t('components.invite.inviteFriend.discount') }}
      </Chip>
      <Headline :level="2">
        {{ $t('components.invite.inviteFriend.title') }}
      </Headline>
      <p>
        {{ $t('components.invite.inviteFriend.description') }}
      </p>
      <Headline v-if="friends.length" :level="3">
        {{
          $t('components.invite.inviteFriend.invitePeopleInYourWillVieEmail')
        }}
      </Headline>
      <SendInvite
        v-for="(friend, i) in friends"
        :key="i"
        :invites="invites"
        :products="['WILL']"
        :prefill="friend"
      />
      <Headline :level="3">
        {{ $t('components.invite.inviteFriend.inviteViaEmail') }}
      </Headline>
      <SendInvite :products="['WILL']" />
      <template v-if="defaultInvites.length">
        <Headline :level="3">
          {{ $t('components.invite.inviteFriend.sentInvites') }}
        </Headline>
        <SentInvite
          v-for="invite in defaultInvites"
          :key="invite.id"
          :invite="invite"
        />
      </template>
    </Box>
    <Box v-if="publicInviteCode" background="white">
      <Chip background="darker">
        {{ $t('components.invite.supportTheCause') }}
      </Chip>
      <Headline :level="2"> {{ $t('components.invite.shareLink') }} </Headline>
      <p>
        {{ $t('components.invite.weMakingWillsEasier') }}
      </p>
      <InviteLink :link="inviteLink" />
    </Box>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Box from '@/components/atoms/Box';
import Chip from '@/components/atoms/Chip';
import Headline from '@/components/atoms/Headline';
import InviteLink from '@/components/organisms/InviteLink';
import SentInvite from '@/components/organisms/SentInvite';
import SendInvite from '@/components/organisms/SendInvite';

import { will, invites, people } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'ComponentsOrganismsInvite',
  components: {
    Box,
    Chip,
    Headline,
    InviteLink,
    SentInvite,
    SendInvite,
  },
  mixins: [will, invites, people],
  data() {
    return {
      partnerInviteSending: false,
      friendInvites: [],
    };
  },
  computed: {
    ...mapGetters('checkout', ['invitedByPartner']),
    ...mapGetters('invites', ['publicInviteCode']),
    hasPurchasedPartnerWill() {
      return (this.purchasedProducts || []).includes('PARTNER_WILL');
    },
    hasInvitedPartner() {
      return !!this.invites.find((i) => i.type === 'PARTNER');
    },
    partnerEmail() {
      const meta = this.partners[0] && metaArrayToObject(this.partners[0].meta);
      return (meta && meta.email) || '';
    },
    partnerInvites() {
      return this.invites.filter((invite) => invite.type === 'PARTNER');
    },
    defaultInvites() {
      return this.invites
        .filter((invite) => invite.type === 'DEFAULT')
        .reverse();
    },
    friends() {
      return this.people
        .map((person) => {
          const isPartner = person.category === 'partner';
          const isMinor = this.minors.includes(person);
          if (isPartner || isMinor) {
            return false;
          }

          const meta = metaArrayToObject(person.meta);
          const hasEmail = !!meta && !!meta.email;
          if (!hasEmail) {
            return false;
          }

          const alreadyInvited = this.invites.find(
            (invite) => invite.email === meta.email
          );
          if (alreadyInvited) {
            return false;
          }

          return meta.email;
        })
        .filter((val) => !!val);
    },
    inviteLink() {
      return `https://safewill.com/i/${this.publicInviteCode}`;
    },
  },
  mounted() {
    this.getPublicInviteCode(!!this.willMeta.name_first);
  },
  methods: {
    ...mapActions('invites', ['getPublicInviteCode']),
  },
};
</script>
