<template>
  <div class="ru:thank-you">
    <Tabs :tabs="tabsToDisplay" variant="underline">
      <template #SUBSCRIPTION>
        <Box loose background="white">
          <div class="grid grid--stretch grid--flush">
            <div class="cell cell__fill">
              <BigNumber number="01" />
              <Headline :level="4">{{
                $t('components.thankYou.will.step1.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.will.step1.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="02" />
              <Headline :level="4">{{
                $t('components.thankYou.will.step2.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.will.step2.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="03" />
              <Headline :level="4">{{
                $t('components.thankYou.will.step3.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.will.step3.description') }}
              </p>
            </div>
          </div>
          <Buttons layout="center">
            <RuButton :href="localePath('/end-of-life')" level="primary">
              {{ $t('components.thankYou.startEOLPlan') }}
            </RuButton>
          </Buttons>
        </Box>
      </template>
      <template #POA>
        <Box loose background="white">
          <div class="grid grid--stretch">
            <div class="cell cell__fill">
              <BigNumber number="01" />
              <Headline :level="4">{{
                $t('components.thankYou.poa.step1.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.poa.step1.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="02" />
              <Headline :level="4">{{
                $t('components.thankYou.poa.step2.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.poa.step2.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="03" />
              <Headline :level="4">{{
                $t('components.thankYou.poa.step3.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.poa.step3.description') }}
              </p>
            </div>
          </div>
          <Buttons layout="center">
            <RuButton :href="localePath('/poa')" level="primary">
              {{ $t('components.thankYou.completePOA') }}
            </RuButton>
          </Buttons>
        </Box>
      </template>
      <template #NO_POA>
        <Box loose background="white">
          <div class="grid grid--stretch">
            <div class="cell cell__fill">
              <BigNumber number="01" />
              <Headline :level="4">{{
                $t('components.thankYou.noPoa.step1.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.noPoa.step1.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="02" />
              <Headline :level="4">{{
                $t('components.thankYou.noPoa.step2.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.noPoa.step2.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="03" />
              <Headline :level="4">{{
                $t('components.thankYou.noPoa.step3.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.noPoa.step3.description') }}
              </p>
            </div>
          </div>
          <Buttons layout="center">
            <RuButton :href="localePath('/poa')" level="primary">
              {{ $t('components.thankYou.purchasePOA') }}
            </RuButton>
          </Buttons>
        </Box>
      </template>
      <template #GIFT>
        <Box loose background="white">
          <div class="grid grid--stretch">
            <div class="cell cell__fill">
              <BigNumber number="01" />
              <Headline :level="4">{{
                $t('components.thankYou.gift.step1.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.gift.step1.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="02" />
              <Headline :level="4">{{
                $t('components.thankYou.gift.step2.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.gift.step2.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="03" />
              <Headline :level="4">{{
                $t('components.thankYou.gift.step3.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.gift.step3.description') }}
              </p>
            </div>
          </div>
          <Buttons layout="center">
            <RuButton :href="localePath('/dashboard/invites')" level="primary">
              {{ $t('components.thankYou.sendYourGift') }}
            </RuButton>
          </Buttons>
        </Box>
      </template>
      <template #NO_GIFT>
        <Box loose background="white">
          <div class="grid grid--stretch">
            <div class="cell cell__fill">
              <BigNumber number="01" />
              <Headline :level="4">{{
                $t('components.thankYou.noGift.step1.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.noGift.step1.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="02" />
              <Headline :level="4">{{
                $t('components.thankYou.noGift.step2.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.noGift.step2.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="03" />
              <Headline :level="4">{{
                $t('components.thankYou.noGift.step3.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.noGift.step3.description') }}
              </p>
            </div>
          </div>
          <Buttons layout="center">
            <RuButton :href="localePath('/checkout/gifts')" level="primary">
              {{ $t('components.thankYou.purchaseAGift') }}
            </RuButton>
          </Buttons>
        </Box>
      </template>
      <template #LEGAL_ADVICE>
        <Box loose background="white">
          <div class="grid grid--stretch">
            <div class="cell cell__fill">
              <BigNumber number="01" />
              <Headline :level="4">{{
                $t('components.thankYou.legalAdvice.step1.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.legalAdvice.step1.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="02" />
              <Headline :level="4">{{
                $t('components.thankYou.legalAdvice.step2.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.legalAdvice.step2.description') }}
              </p>
            </div>
            <div class="cell cell__12/12 cell__2/12--md">
              <Arrow />
            </div>
            <div class="cell cell__fill">
              <BigNumber number="03" />
              <Headline :level="4">{{
                $t('components.thankYou.legalAdvice.step3.title')
              }}</Headline>
              <p>
                {{ $t('components.thankYou.legalAdvice.step3.description') }}
              </p>
            </div>
          </div>
        </Box>
      </template>
      <template #WILL>
        <Box loose background="white">
          <Headline :level="3">{{
            $t('components.thankYou.notifyYourExecutors')
          }}</Headline>
          <p>{{ $t('components.thankYou.letYourExecutorsKnow') }}</p>
          <div class="grid">
            <div class="cell cell__12/12 cell__9/12--md">
              <SendNotification />
            </div>
          </div>
          <Buttons layout="center">
            <RuButton :href="localePath('/')" level="primary">{{
              $t('components.thankYou.goToDashboard')
            }}</RuButton>
          </Buttons>
        </Box>
      </template>
    </Tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Headline from '@/components/atoms/Headline';
import Tabs from '@/components/molecules/Tabs';
import BigNumber from '@/components/atoms/BigNumber';
import Arrow from '@/components/atoms/Arrow';
import SendNotification from '@/components/organisms/SendNotification';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Box from '@/components/atoms/Box';

import { subscription, user, will, invites, people } from '@/mixins/apollo';

import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

export default {
  name: 'ComponentsTemplatesThankYou',
  components: {
    Headline,
    Tabs,
    BigNumber,
    Arrow,
    SendNotification,
    Buttons,
    RuButton,
    Box,
  },
  mixins: [subscription, user, will, invites, people],
  middleware: ['hasPurchasedWill'],
  props: {
    primaryTabName: {
      default: null,
      type: String,
    },
  },
  apollo: {
    purchasedProducts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update(data) {
        return data.getPurchasedProducts.products || [];
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    ...mapGetters('checkout', ['invitedByPartner']),
    ...mapGetters(['willId', 'willStatus']),
    hasPurchasedLegalAdvice() {
      return !(this.purchasedProducts || []).includes('LEGAL_ADVICE');
    },
    hasPurchasedWill() {
      return !(this.purchasedProducts || []).includes('WILL');
    },
    hasPurchasedPOA() {
      return (this.purchasedProducts || []).includes('POA');
    },
    uniqueProducts() {
      return Array.from(
        new Set(
          this.purchasedProducts?.map((product) =>
            product.includes('_GIFT') ? 'GIFT' : product
          )
        )
      );
    },
    tabs() {
      return {
        SUBSCRIPTION: {
          name: 'SUBSCRIPTION',
          title: this.$t('components.thankYou.tabsTitle.subscription'),
        },
        POA: {
          name: 'POA',
          title: this.$t('components.thankYou.tabsTitle.poa'),
        },
        NO_POA: {
          name: 'NO_POA',
          title: this.$t('components.thankYou.tabsTitle.noPoa'),
        },
        GIFT: {
          name: 'GIFT',
          title: this.$t('components.thankYou.tabsTitle.gift'),
        },
        NO_GIFT: {
          name: 'NO_GIFT',
          title: this.$t('components.thankYou.tabsTitle.noGift'),
        },
        LEGAL_ADVICE: {
          name: 'LEGAL_ADVICE',
          title: this.$t('components.thankYou.tabsTitle.legalAdvice'),
        },
        WILL: {
          name: 'WILL',
          title: this.$t('components.thankYou.tabsTitle.will'),
        },
      };
    },
    tabsToDisplay() {
      const tabsToDisplay = [this.tabs.SUBSCRIPTION];
      ['POA', 'GIFT'].forEach((tab) => {
        if (this.uniqueProducts.includes(tab)) {
          tabsToDisplay.push(this.tabs[tab]);
        } else {
          tabsToDisplay.push(this.tabs[`NO_${tab}`]);
        }
      });
      ['LEGAL_ADVICE', 'WILL'].forEach((tab) => {
        if (this.uniqueProducts.includes(tab)) {
          tabsToDisplay.push(this.tabs[tab]);
        }
      });
      if (this.primaryTabName) {
        tabsToDisplay.forEach((tab, index) => {
          if (tab.name === this.primaryTabName) {
            tabsToDisplay.splice(index, 1);
            tabsToDisplay.unshift(tab);
          }
        });
      }
      return tabsToDisplay;
    },
  },
};
</script>
