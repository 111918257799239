<template>
  <div class="ru:container">
    <Headline :level="1">
      {{ headline.prefix }}
      <DecoratedText decoration="underline">
        {{ headline.suffix }}
      </DecoratedText>
    </Headline>
    <p v-if="completeData">
      {{ $t('pages.cremations.customerCareFinaliseArrangements') }}
    </p>
    <p v-else>
      {{ $t('texts.sentences.somethingWentWrong') }}
    </p>
  </div>
</template>

<script>
import { showChat } from '@/modules/chat';
import Headline from '@/components/atoms/Headline';
import DecoratedText from '@/components/atoms/DecoratedText';

export default {
  name: 'PagesAffiliateCremationsCompleteQuoteArrangePayment',
  components: {
    Headline,
    DecoratedText,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        primaryPhone: '',
        addressLine1: '',
        addressLine2: '',
        australianState: '',
        postcode: '',
        secondaryPhone: '',
        dateOfBirth: '',
        placeOfBirth: '',
        agreePDS: false,
        agreeContract: false,
        agreeSign: false,
        transportFee: '',
        professionalFee: '',
        totalQuote: '',
      },
      optionalOnLoad: ['addressLine2', 'secondaryPhone'],
      completeData: true,
    };
  },
  computed: {
    headline() {
      return this.completeData
        ? {
            prefix: this.$upperFirst(this.$t('texts.words.thank')),
            suffix: this.$t('texts.words.you'),
          }
        : {
            prefix: this.$upperFirst(this.$t('texts.words.oh')),
            suffix: this.$t('texts.sentences.dear'),
          };
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        path: this.localePath('/cremations/complete-quote'),
        query: this.$route.query,
      });
    },
    showChat() {
      showChat();
    },
  },
};
</script>
