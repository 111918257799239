<template>
  <ModuleStep :index="4" :prev-step="prevStepUrl">
    <AdiForm
      v-if="currentBeneficiary"
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="
        isComplete && currentBeneficiary.meta.estate_backup_split !== 'custom'
      "
      submit-icon="arrow-right"
      @done="done"
    >
      <FormSection class="step-1">
        <Headline :level="1">
          {{
            $t('pages.will.estate.inheritShareQuestion', {
              name: currentBeneficiary.person.meta.full_name,
            })
          }}
        </Headline>
        <Tip>
          {{ $t('pages.will.estate.beneficiaryPassesAway') }}
          <br />
          {{ $t('pages.will.estate.backupBeneficiaryDies') }}
        </Tip>
        <EstateBackupSelector
          v-model="currentBeneficiary.meta.estate_backup_split"
        />
      </FormSection>
      <FormSection
        v-if="currentBeneficiary.meta.estate_backup_split === 'custom'"
        class="step-2"
      >
        <Headline :level="2">
          {{
            $t('pages.will.estate.backupBeneficiaryQuestion', {
              name: currentBeneficiary.person.meta.full_name,
            })
          }}
        </Headline>
        <p class="mb-8">
          {{ $t('pages.will.estate.selectBackupBeneficiariesNote') }}
        </p>
        <PersonSelector
          v-model="selectedBeneficiaryIDs"
          label
          :loading="loading"
          :people="validPeople"
          type="beneficiary"
        />
        <Headline :level="3">
          {{ $t('pages.will.estate.charitiesAndNotForProfit') }}
        </Headline>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="selectedSuggestedCharities"
          :loading="loading"
        />
        <CharitySelector v-model="selectedBeneficiaryIDs" :loading="loading" />
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addCharity">
            + {{ $t('forms.labels.addCharity') }}
          </RuButton>
        </Buttons>
        <CharityPanel />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { metaArrayToObject } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';

import CharitySelector from '@/components/CharitySelector';
import CharitySuggester from '@/components/CharitySuggester';
import EstateBackupSelector from '@/components/EstateBackupSelector';
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import CharityPanel from '@/components/CharityPanel';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { beneficiaries, charities, people, user, will } from '@/mixins/apollo';

export default {
  key(route) {
    return route.fullPath;
  },
  components: {
    CharitySelector,
    CharitySuggester,
    EstateBackupSelector,
    AdiForm,
    FormSection,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
    CharityPanel,
    Buttons,
    RuButton,
  },
  mixins: [beneficiaries, charities, people, user, will],
  data() {
    return {
      selectedBeneficiaryIDs: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    currentBeneficiary() {
      return this.beneficiaries.find((b) => b.id === this.$route.params.id);
    },
    currentIndex() {
      return this.backupQueue.findIndex((b) => b.id === this.$route.params.id);
    },
    prevStepUrl() {
      const beneficiary = this.backupQueue[this.currentIndex - 1];
      return beneficiary
        ? `/will/estate/backup-beneficiaries/${beneficiary.id}`
        : '/will/estate/beneficiaries';
    },
    isComplete() {
      return !!(
        this.currentBeneficiary.meta.estate_backup_split &&
        (this.currentBeneficiary.meta.estate_backup_split !== 'custom' ||
          this.selectedBeneficiaryIDs.length ||
          this.selectedSuggestedCharities.length)
      );
    },
    validPeople() {
      return this.people.filter(
        (person) =>
          person.id !==
          (this.currentBeneficiary && this.currentBeneficiary.person.id)
      );
    },
    possibleBeneficiaries() {
      return [this.beneficiaries, this.people, this.charities];
    },
  },
  watch: {
    possibleBeneficiaries() {
      if (!this.currentBeneficiary) return;

      if (
        this.currentBeneficiary.meta.estate_backup_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaryIDs.length
      ) {
        const peopleIds = this.people.map((person) => person.id);
        const charityIds = this.charities.map((charity) => charity.id);
        const selectedPersonIds = [];
        const selectedCharityIds = [];

        this.currentBeneficiary.backup.forEach(({ person, charity }) => {
          if (
            person &&
            person.id !== this.currentBeneficiary.person.id &&
            peopleIds.includes(person.id)
          ) {
            selectedPersonIds.push(person.id);
          }
          if (charity && charityIds.includes(charity.id)) {
            selectedCharityIds.push(charity.id);
          }
        });

        this.selectedBeneficiaryIDs =
          selectedPersonIds.concat(selectedCharityIds);
      }
    },
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    metaArrayToObject,
    async done() {
      if (this.selectedSuggestedCharities.length) {
        await Promise.all(
          this.selectedSuggestedCharities.map((charity) => {
            return this.addPartnerCharity(charity);
          })
        );
        await this.refetchCharities();
        this.charities.forEach((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          const exists = this.selectedSuggestedCharities.find(
            (selectedSuggestedCharity) => {
              return isSameCharity(selectedSuggestedCharity, charityMeta);
            }
          );
          if (exists) {
            this.selectedBeneficiaryIDs.push(charity.id);
          }
        });
      }

      // Save estate backup split meta data.
      await this.updateBeneficiaryMeta(this.currentBeneficiary);

      switch (this.currentBeneficiary.meta.estate_backup_split) {
        case 'remaining':
          await this.removeAllBackupBeneficiaries(this.currentBeneficiary);
          break;
        case 'children':
          for (const charity of this.charities) {
            await this.removeBackupBeneficiaryByCharity(
              this.currentBeneficiary,
              charity
            );
          }

          for (const person of this.people) {
            if (!this.isChild(person) && !this.isPartner(person)) {
              await this.removeBackupBeneficiaryByPerson(
                this.currentBeneficiary,
                person
              );
            }
          }

          break;
        case 'custom': {
          for (const person of this.people) {
            const beneficiary = this.getBackupBeneficiaryByPerson(
              this.currentBeneficiary,
              person
            );
            const isSelected = this.selectedBeneficiaryIDs.includes(person.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  person,
                  null
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          for (const charity of this.charities) {
            const beneficiary = this.getBackupBeneficiaryByCharity(
              this.currentBeneficiary,
              charity
            );
            const isSelected = this.selectedBeneficiaryIDs.includes(charity.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  charity,
                  null
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          break;
        }
        default:
          break;
      }

      this.willMeta.charity_in_estate = this.charities.length
        ? 'true'
        : 'false';

      await this.updateWillMeta();

      if (this.currentBeneficiary.meta.estate_backup_split === 'custom') {
        await this.$router.push({
          path: this.localePath(
            `/will/estate/backup-distribution/${
              this.backupQueue[this.currentIndex].id
            }`
          ),
        });
        this.currentBeneficiary.meta.estate_backup_split = null;
      } else if (this.currentIndex < this.backupQueue.length - 1) {
        // Update current beneficiary, set isBackupEstateSplitEvenly to false
        await this.updateBeneficiaryIsBackupEstateSplitEvenly(
          this.currentBeneficiary,
          false
        );

        await this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${
              this.backupQueue[this.currentIndex + 1].id
            }`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });
        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage ? '/will/charity-consent' : '/will'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>
