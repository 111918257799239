<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.authorisation') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.checkAboutYourselfDetails') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('final')">
      <div v-if="showGpDetails">
        <Headline :level="3">
          {{ $t('pages.cremations.generalPractitionerDetails') }}
        </Headline>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="gpFirstName"
              v-model="formData.gpFirstName"
              type="text"
              :text="$t('forms.labels.firstName')"
              :pattern="FIRST_NAME_PATTERN.regex"
              :title="$t(FIRST_NAME_PATTERN.description)"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="gpLastName"
              v-model="formData.gpLastName"
              type="text"
              :text="$t('forms.labels.lastName')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="gpPhone"
              v-model="formData.gpPhone"
              :required="false"
              :optional="true"
              type="text"
              :text="$t('forms.labels.phoneNumber')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--lg">
            <FormControl
              id="gpAddressLine1"
              v-model="formData.gpAddressLine1"
              type="text"
              :text="$t('forms.labels.addressLine1')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--lg">
            <FormControl
              id="gpAddressLine2"
              v-model="formData.gpAddressLine2"
              :required="false"
              :optional="true"
              type="text"
              :text="$t('forms.labels.addressLine2')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="gpAddressSuburb"
              v-model="formData.gpAddressSuburb"
              type="text"
              :text="$t('forms.labels.suburb')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="gpAddressState"
              v-model="formData.gpAddressState"
              type="select"
              :text="$t('forms.labels.australianState')"
              :options="australianStatesOptions"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="gpAddressPostcode"
              v-model="formData.gpAddressPostcode"
              type="text"
              :text="$t('forms.labels.4digitPostcode')"
              inputmode="decimal"
              min="4"
              max="4"
              pattern="[0-9]{4}"
            />
          </div>
        </div>
      </div>
      <Headline :level="3">
        {{ $t('texts.sentences.occupationDetails') }}
      </Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestOccupation"
            v-model="formData.guestOccupation"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.occupationDuringWorkingLife')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestIsRetiredAtDeath"
            v-model="formData.guestIsRetiredAtDeath"
            type="select"
            text="Retired"
            :options="YES_NO_OPTIONS"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestIsPensionerAtDeath"
            v-model="formData.guestIsPensionerAtDeath"
            type="select"
            :text="$t('forms.labels.pension')"
            :options="YES_NO_OPTIONS"
          />
        </div>
        <div
          v-if="showGuestPensionerType"
          class="cell cell__12/12 cell__4/12--lg"
        >
          <FormControl
            id="guestIsPensionerAtDeath"
            v-model="formData.guestPensionerType"
            type="select"
            :text="$t('forms.labels.pensionType')"
            :options="PENSIONER_TYPE_OPTIONS"
          />
        </div>
      </div>
      <Headline :level="3">Marital status</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="guestMaritalStatusAtDeath"
            v-model="formData.guestMaritalStatusAtDeath"
            :disabled="loading"
            type="select"
            :text="$t('forms.labels.maritalStatusAtDeath')"
            :options="FINAL_MARITAL_STATUS_OPTIONS"
          />
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="goNext">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="goNext">
          {{ $t('forms.labels.ok') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedAuthorisationId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      formData: {
        isReportableToCoroner: null,
        gpFirstName: null,
        gpLastName: null,
        gpAddressLine1: null,
        gpAddressLine2: null,
        gpAddressSuburb: null,
        gpAddressState: null,
        gpAddressPostcode: null,
        gpPhone: null,
        guestOccupation: null,
        guestIsRetiredAtDeath: null,
        guestIsPensionerAtDeath: null,
        guestPensionerType: null,
        guestMaritalStatusAtDeath: null,
        placeOfDeathAddressState: null,
      },
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
    prevPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
  },
};
</script>
