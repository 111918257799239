<template>
  <Portal to="stack">
    <transition appear mode="out-in" name="ru:wiggle-fade-squish">
      <div class="ru:modal">
        <div
          ref="card"
          v-on-dismiss="{
            callback: close,
            watch: isDismissable,
          }"
          class="ru:modal__content"
        >
          <div class="ru:modal__body">
            <div class="relative">
              <slot />
              <div v-if="isDismissable" class="absolute top-0 right-0">
                <BaseButton class="text-black" @click="close">
                  <BaseGlyph id="close" />
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Portal>
</template>

<script>
import VDismiss from 'vue-dismiss';
import { Portal } from 'portal-vue';

import BaseButton from '@/components/BaseButton';
import BaseGlyph from '@/components/BaseGlyph';

export default {
  name: 'ComponentsBaseModal',
  components: {
    BaseButton,
    BaseGlyph,
    Portal,
  },
  mixins: [VDismiss],
  props: {
    title: {
      default: null,
      type: String,
    },
    isDismissable: {
      default: true,
      type: Boolean,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>
