<template>
  <nuxt-child />
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'PagesAffiliateCheckout',
  layout: 'ruach',
  middleware: ['authenticated'],
  mounted() {
    this.setNavigationVariant('none');
  },
  beforeDestroy() {
    this.resetUI();
  },
  methods: {
    ...mapActions('ruach', ['setNavigationVariant', 'resetUI']),
  },
};
</script>
