<template>
  <div class="ru:navigation" :class="[modifiers]">
    <div
      class="ru:__underlay ru:navigation__mobile-menu-underlay"
      @click="toggleMobileMenu"
    />
    <div class="ru:navigation__links" @click="toggleMobileMenuIfOpen">
      <User />
      <div class="ru:navigation__links--site">
        <NavigationItem
          v-if="hasCremationRequest"
          to="/cremations/at-need"
          icon="urn"
          :text="$t('components.navigation.cremations')"
        />
        <NavigationItem
          v-if="features.dashboardEnabled"
          to="/"
          icon="dashboard"
          :text="$t('components.navigation.endOfLifePlan')"
          class="ru:navigation__dropdown-toggle"
        >
          <Icon
            :id="dashboardDropdownIsOpen ? 'chevron-up' : 'chevron-down'"
            :class="[dashboardDropdownIsOpen ? '--active' : '']"
            theme="light"
          />
        </NavigationItem>
        <transition
          appear
          mode="out-in"
          :duration="250"
          name="ru:wiggle-fade-down"
        >
          <div
            v-if="dashboardDropdownIsOpen"
            class="ru:navigation__links--dropdown --primary"
          >
            <NavigationItem
              v-if="features.willEnabled"
              to="/will"
              :text="$t('components.navigation.yourWill')"
            />
            <NavigationItem
              v-if="features.powerOfAttorneyEnabled"
              :to="poaId ? '/poa' : `/onboarding/poa`"
              :text="$t('components.navigation.powerOfAttorney')"
            />
            <NavigationItem
              v-if="features.endOfLifeEnabled"
              :text="$t('components.navigation.digitalVault')"
              class="ru:navigation__dropdown-toggle"
              :icon="endOfLifeDropdownIsOpen ? 'caret-up' : 'caret-down'"
              :to="
                !hasEndOfLifeItems || !endOfLifeCategoryTree.length
                  ? '/onboarding/end-of-life'
                  : `/end-of-life`
              "
            />
            <transition
              appear
              mode="out-in"
              :duration="250"
              name="ru:wiggle-fade-down"
            >
              <div
                v-if="endOfLifeDropdownIsOpen"
                class="ru:navigation__links--dropdown --secondary"
              >
                <NavigationItem
                  v-for="category of endOfLifeCategoryTree"
                  :key="category.slug"
                  :to="`/end-of-life/${category.slug}`"
                  :text="category.name"
                />
              </div>
            </transition>
          </div>
        </transition>
        <NavigationItem
          v-if="features.giftEnabled"
          to="/checkout/gifts"
          icon="gift"
          :text="$t('components.navigation.gifts')"
        />
      </div>
      <div class="ru:navigation__links--account">
        <NavigationItem
          to="/dashboard/account-settings"
          :text="$t('components.navigation.account')"
          icon="user"
        >
          <WarningBadge
            v-if="willStatus === 'APPROVED' && hasExpiredSubscription"
          />
        </NavigationItem>
        <client-only>
          <NavigationItem to="/dashboard/invites" icon="invite" text="Invite" />
          <NavigationItem
            v-if="willStatus === 'APPROVED'"
            to="/dashboard/files"
            icon="upload"
            :text="$t('components.navigation.fileUpload')"
          />
        </client-only>
        <NavigationItem
          to="/log-out"
          icon="logout"
          :text="$t('components.navigation.logout')"
          icon-theme="error"
        />
      </div>
      <div class="ru:navigation__explainer">
        <Box background="lighter" tight>
          <Headline :level="3">
            <Icon id="security" />
            {{ $t('components.navigation.safety.title') }}
          </Headline>
          <p>
            {{ $t('components.navigation.safety.description') }}
          </p>
          <p>
            <i18n path="components.navigation.safety.moreInfo">
              <RuButton
                level="default"
                theme="plain"
                href="https://safewill.com/security"
                small
              >
                {{ $t('components.navigation.safety.securityCTA') }}
              </RuButton>
              <RuButton
                level="default"
                theme="plain"
                href="https://safewill.com/privacy"
                small
              >
                {{ $t('components.navigation.safety.privacyCTA') }}
              </RuButton>
            </i18n>
            .
          </p>
        </Box>
      </div>
    </div>
    <button class="ru:navigation__mobile-toggle" @click="toggleMobileMenu">
      <transition-group
        tag="div"
        class="ru:navigation__mobile-toggle-icon"
        mode="out-in"
      >
        <Icon v-if="mobileNavigationIsOpen" id="close" key="close" />
        <Icon v-else id="menu" key="menu" />
      </transition-group>
    </button>
    <button
      ref="toggleDesktopMenuButton"
      class="ru:navigation__desktop-toggle"
      @click="toggleDesktopMenu"
    >
      <Icon :id="desktopMenuIsCollapsed ? 'chevron-right' : 'chevron-left'" />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Box from '@/components/atoms/Box';
import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';
import RuButton from '@/components/atoms/Button';
import User from '@/components/molecules/User';
import NavigationItem from '@/components/molecules/NavigationItem';
import WarningBadge from '@/components/WarningBadge';

import { will, subscription } from '@/mixins/apollo';

import GET_AT_NEED_CREMATION_REQUESTS_BY_INFORMANT_USER_ID_QUERY from '@/graphql/queries/GetAtNeedCremationRequestsByInformantUserId';

export default {
  name: 'ComponentsOrganismsNavigation',
  components: {
    Box,
    Icon,
    Headline,
    RuButton,
    User,
    NavigationItem,
    WarningBadge,
  },
  mixins: [will, subscription],
  props: {
    variant: {
      default: 'full',
      type: String,
    },
  },
  apollo: {
    cremationsRequested: {
      query: GET_AT_NEED_CREMATION_REQUESTS_BY_INFORMANT_USER_ID_QUERY,
      variables() {
        return {
          informantUserId: this.userId,
        };
      },
      update: (data) => data.getAtNeedCremationRequestsByInformantUserId,
      skip() {
        return !this.userId;
      },
    },
  },
  data() {
    return {
      mobileNavigationIsOpen: false,
      desktopMenuIsCollapsed: false,
      dashboardMenuIsCollapsed: false,
    };
  },
  computed: {
    ...mapGetters('affiliate', ['features', 'slug']),
    ...mapGetters([
      'verified',
      'willStatus',
      'willEnabled',
      'powerOfAttorneyEnabled',
      'userId',
    ]),
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('end-of-life-categories', [
      'endOfLifeCategoryTree',
      'endOfLifeCategoryItemCounts',
    ]),
    hasEndOfLifeItems() {
      return this.endOfLifeCategoryItemCounts &&
        Object.keys(this.endOfLifeCategoryItemCounts)
        ? Object.keys(this.endOfLifeCategoryItemCounts).some(
            (category) => this.endOfLifeCategoryItemCounts[category] > 0
          )
        : false;
    },
    hasCremationRequest() {
      return this.cremationsRequested?.length;
    },
    modifiers() {
      return {
        '--mobile-menu-open': this.mobileNavigationIsOpen,
      };
    },
    endOfLifeDropdownIsOpen() {
      return this.$route.path.includes('/end-of-life');
    },
    dashboardDropdownIsOpen() {
      return (
        this.localePath(`/${this.slug}`) === this.$route.path ||
        /\/(?:poa|end-of-life|will)/.test(this.$route.path)
      );
    },
  },
  watch: {
    mobileNavigationIsOpen(mobileNavigationIsOpen) {
      document.body.classList.toggle(
        '--mobile-navigation-is-open',
        mobileNavigationIsOpen
      );
    },
    desktopMenuIsCollapsed(desktopMenuIsCollapsed) {
      document.body.classList.toggle(
        '--desktop-menu-is-collapsed',
        desktopMenuIsCollapsed
      );
    },
  },
  created() {
    if (this.userId) {
      this.getEndOfLifeCategories();
      this.getEndOfLifeCategoryItemCounts();
    }
  },
  methods: {
    ...mapActions('end-of-life-categories', [
      'getEndOfLifeCategories',
      'getEndOfLifeCategoryItemCounts',
    ]),
    toggleMobileMenuIfOpen() {
      if (this.mobileNavigationIsOpen) {
        this.toggleMobileMenu();
      }
    },
    toggleMobileMenu() {
      this.mobileNavigationIsOpen = !this.mobileNavigationIsOpen;
    },
    toggleDesktopMenu() {
      this.$refs.toggleDesktopMenuButton.blur();
      this.desktopMenuIsCollapsed = !this.desktopMenuIsCollapsed;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &navigation {
    --links-padding-y: var(--app-height);
    --border-radius: var(border-radius-large);
    position: relative;
    z-index: $z-navigation;
    @include mq('min-lg') {
      --links-padding-y: 2.5rem;
    }

    &__links {
      background: --rgba(white);
      position: fixed;
      left: 100%;
      top: 0;
      bottom: 0;
      width: var(--navigation-width);
      transition: --out();
      padding: var(--links-padding-y) 1rem;
      overflow: auto;

      &,
      &--site,
      &--account,
      &--dropdown {
        display: flex;
        flex-direction: column;
      }

      &--site,
      &--account,
      &--dropdown {
        gap: var(--base-margin-small);
        position: relative;
      }

      &--dropdown {
        --border-radius-large: var(--border-radius);
        --link-padding: 0.5rem;

        &.--primary {
          --navigation-text-indent: 2rem;
        }

        &.--secondary {
          --navigation-text-indent: 1rem;
          padding-left: 2rem;
        }

        @at-root #{$mf}desktop-menu-is-collapsed & {
          display: none;
        }
      }

      &--account {
        padding-top: var(--base-margin-big);

        &::before {
          display: block;
          content: '';
          height: 1px;
          background: --rgba(iron);
          margin-bottom: var(--base-margin);
        }
      }
      @include mq('min-lg') {
        background: transparent;
        left: 0;
        top: var(--app-height);

        &::before {
          display: block;
          content: '';
          background: --rgba(white);
          position: fixed;
          left: inherit;
          top: inherit;
          bottom: inherit;
          width: var(--navigation-width);
          transition: --out();
        }

        &--account {
          margin-bottom: 0;
        }
      }
    }
    @include mq('max-lg') {
      &:is(#{$mf}mobile-menu-open) &__links {
        transition: --in();
        transform: translateX(-100%);
      }
    }
    @include mq('min-lg') {
      @at-root #{$mf}desktop-menu-is-collapsed &__links {
        width: var(--navigation-width-collapsed);

        &::before {
          transition: --in();
          transform: translateX(
            #{calc(
                -1 * (var(--navigation-width) -
                      var(--navigation-width-collapsed))
              )}
          );
        }
      }
    }

    &__explainer {
      --base-margin-big: var(--base-margin-small);
      margin-top: auto;
      padding-top: var(--base-margin);
      transition: --out();

      p,
      h3 {
        --base-margin: var(--base-margin-small);
      }

      p,
      a {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
      }

      a {
        --base-margin-small: 0;
        text-decoration: underline !important;
      }
    }
    @include mq('max-lg') {
      &:is(#{$mf}mobile-menu-open) &__explainer {
        display: none;
      }
    }
    @include mq('min-lg') {
      @at-root #{$mf}desktop-menu-is-collapsed &__explainer {
        display: none;
      }
    }

    &__mobile-toggle {
      position: fixed;
      height: var(--app-logo-height);
      width: var(--app-logo-height);
      top: var(--app-padding-y);
      right: var(--app-padding-x);
      @include mq('min-lg') {
        display: none;
      }

      #{$ru}icon {
        transform-origin: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -12px;
      }
    }

    &__mobile-menu-underlay {
      position: fixed;
      inset: 0;
      transition: --out();
      pointer-events: none;
      background: --rgba(
        black,
        #{calc(25% * var(--is-mobile-navigation-open))}
      );
      @include mq('min-lg') {
        display: none;
      }

      @at-root #{$mf}mobile-navigation-is-open & {
        pointer-events: all;
      }
    }

    &__desktop-toggle {
      display: none;
      position: fixed;
      height: 2rem;
      width: 2rem;
      top: #{calc(var(--app-height) + var(--links-padding-y) - 2rem)};
      left: #{calc(var(--navigation-width) - 1rem)};
      color: --rgba(basalt);
      transform: translateX(
        #{calc(
            var(--is-desktop-menu-collapsed) *
              (var(--navigation-width-collapsed) - var(--navigation-width))
          )}
      );
      overflow: hidden;
      transition: --out();
      background: --rgba(lighter);
      border-radius: 99px;

      @include mq('min-lg') {
        display: block;
      }

      @at-root #{$mf}desktop-menu-is-collapsed & {
        transition: --in();
      }

      #{$ru}icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        transition: --out();
        &:is(#{$mf}active) {
          transform: translate(-50%, -50%) rotate(-180deg);
          transition: --in();
        }
      }
    }
    #{$ru}user {
      margin: 0 0 var(--base-margin-small);
      padding-bottom: var(--base-margin);
      border-bottom: 1px solid --rgba(iron);
      color: #000;

      @include mq('min-lg') {
        display: none;
      }
    }
  }
}
</style>
