<template>
  <div>
    <div class="flex items-center justify-between h-12 mb-8">
      <Headline flush :level="1"> Institutions </Headline>
      <div class="flex">
        <input
          id="search"
          class="input input-compact mr-8"
          :v-model="searchQuery"
          placeholder="Search..."
          :compact="true"
          @keydown="search"
        />
        <BaseButton
          class="btn btn-slate px-4 py-3"
          @click="openNewInstitutionModal"
        >
          <div class="flex items-center">
            <span class="text-white whitespace-no-wrap">
              + Add Institution
            </span>
          </div>
        </BaseButton>
      </div>
    </div>

    <AdminCard tight>
      <AdminDataTable
        v-if="institutions"
        :columns="columns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="institutions.length"
        :sortable-columns="sortableColumns"
        :current-sorting-column="currentSortingColumn"
        @sortColumnClicked="changeSortColumnAndResetPageIndex"
      >
        <template #rows>
          <AdminDataTableRow
            v-for="(institution, index) in institutions"
            :key="index"
            :to="localePath(`/admin/institutions/${institution.id}`)"
            class="group"
          >
            <AdminDataTableCell>
              {{ institution.name }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ institution.type }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(institution.createdAt) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(institution.updatedAt) }}
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="institutionsPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevInstitutionsPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="institutions.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextInstitutionsPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>

    <AdminNewInstitutionModal
      v-if="newInstitutionModalIsVisible"
      @institutionAdded="reloadInstitutions"
      @close="closeNewInstitutionModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
import BaseButton from '@/components/BaseButton';
import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import BaseIcon from '@/components/BaseIcon';
import Headline from '@/components/atoms/Headline';

import AdminNewInstitutionModal from '@/components/admin/NewInstitutionModal';

import institutionsAdmin from '@/mixins/institutions-admin';
import sorting from '@/mixins/sorting';

export default {
  name: 'PagesAffiliateAdminInstitutionsIndex',
  components: {
    BaseButton,
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminDataTableButton,
    BaseIcon,
    AdminNewInstitutionModal,
    Headline,
  },
  mixins: [institutionsAdmin, sorting],
  data() {
    return {
      newInstitutionModalIsVisible: false,
      columns: ['Name', 'Type', 'Created At', 'Updated At'],
      searchQuery: '',
      sortableFields: {
        name: {
          field: 'name',
          columnName: 'Name',
          defaultDirection: 'ASC',
        },
        type: {
          field: 'type',
          columnName: 'Type',
          defaultDirection: 'ASC',
        },
        createdAt: {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
        updatedAt: {
          field: 'updatedAt',
          columnName: 'Updated At',
          defaultDirection: 'DESC',
        },
      },
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters('admin', ['institutionsPageIndex']),
  },
  methods: {
    ...mapActions('admin', [
      'nextInstitutionsPage',
      'prevInstitutionsPage',
      'resetInstitutionsPageIndex',
      'setInstitutionsSearchQuery',
    ]),
    openNewInstitutionModal() {
      this.newInstitutionModalIsVisible = true;
    },
    closeNewInstitutionModal() {
      this.newInstitutionModalIsVisible = false;
    },
    async reloadInstitutions(createdInstitution) {
      await this.refetchInstitutions();
      await this.$router.push(
        this.localePath(`/admin/institutions/${createdInstitution.id}`)
      );
    },
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextUsersPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevUsersPage();
      }
    },
    search: debounce(300, function (event) {
      this.resetInstitutionsPageIndex();
      this.setInstitutionsSearchQuery(event.target.value.trim());
    }),
    changeSortColumnAndResetPageIndex(columnName) {
      this.changeSortColumn(columnName);
      this.resetInstitutionsPageIndex();
    },
  },
};
</script>
