<template>
  <div>
    <div class="flex items-center justify-between h-12 mb-8">
      <Headline flush :level="1"> Submissions </Headline>
      <div class="flex">
        <input
          id="search"
          class="input input-compact"
          :v-model="submissionsSearch"
          placeholder="Search..."
          :compact="true"
          @keydown="search"
        />
      </div>
    </div>
    <AdminCard tight>
      <AdminDataTable
        v-if="wills"
        :columns="columns"
        :current-sorting-column="currentSortingColumn"
        :sortable-columns="sortableColumns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="wills.length"
        @sortColumnClicked="changeSortColumn"
      >
        <template #filters>
          <AdminDataTableFilter
            v-for="(filter, index) in filters"
            :key="index"
            :active="filter.value === submissionsStatusFilter"
            :label="filter.label"
            @click="setSubmissionsStatusFilter(filter.value)"
          />
        </template>
        <template #rows>
          <AdminDataTableRow
            v-for="(will, index) in wills"
            :key="index"
            :to="localePath(`/admin/submissions/${will.id}`)"
          >
            <AdminDataTableCell>
              <span
                class="admin-id px-2 py-1 border rounded font-mono font-medium text-sm leading-none"
              >
                {{ will.hashId.toUpperCase() }}
              </span>
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ will.name }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ will.email }}
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              <AdminStatusChip :status="will.status" />
            </AdminDataTableCell>
            <AdminDataTableCell truncate>
              {{ will.awaitingOn }}
            </AdminDataTableCell>
            <AdminDataTableCell full>
              <div class="flex items-center">
                {{ $formatDate(will.updatedAt) }}
                <nuxt-link
                  :to="localePath(`/admin/submissions/${will.id}`)"
                  class="inline-flex justify-center items-center w-4 h-4 rounded-full ml-4 bg-slate-100 opacity-0 group-hover:opacity-100 text-white fill-current transition-opacity transition-100"
                >
                  <svg width="6" height="8" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 7.4L.6 6l2-2-2-2L2 .6 5.4 4z" />
                  </svg>
                </nuxt-link>
              </div>
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="submissionsPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="wills.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_WILLS_BY_STATUS_QUERY from '@/graphql/queries/GetWillsByStatus';

import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import AdminDataTableFilter from '@/components/admin/DataTableFilter';
import AdminStatusChip from '@/components/admin/StatusChip';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import BaseIcon from '@/components/BaseIcon';
import Headline from '@/components/atoms/Headline';

import sorting from '@/mixins/sorting';

export default {
  name: 'PagesAffiliateAdminSubmissionsIndex',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableButton,
    AdminDataTableFilter,
    AdminStatusChip,
    AdminDataTableRow,
    AdminDataTableCell,
    BaseIcon,
    Headline,
  },
  mixins: [sorting],
  layout: 'admin',
  apollo: {
    wills: {
      query() {
        return GET_WILLS_BY_STATUS_QUERY;
      },
      update: (data) => {
        return data.getWillsByStatus.wills;
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.submissionsPageIndex * this.pageSize,
          status: this.submissionsStatusFilter,
          searchQuery: this.submissionsSearch,
          customOrder: this.customOrder,
        };
      },
    },
  },
  data() {
    return {
      GET_WILLS_BY_STATUS_QUERY,
      columns: [
        'ID',
        'Customer Name',
        'Customer Email',
        'Status',
        'Awaiting On',
        'Updated At',
      ],
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'In Progress',
          value: 'IN_PROGRESS',
        },
        {
          label: 'Awaiting Review',
          value: 'AWAITING_APPROVAL',
        },
        {
          label: 'Flagged',
          value: 'FLAGGED',
        },
        {
          label: 'Approved',
          value: 'APPROVED',
        },
        {
          label: 'Archived',
          value: 'ARCHIVED',
        },
      ],
      pageSize: 10,
      wills: [],
      sortableFields: [
        {
          field: 'hashId',
          columnName: 'ID',
          defaultDirection: 'ASC',
        },
        {
          field: 'status',
          columnName: 'Status',
          defaultDirection: 'ASC',
        },
        {
          field: 'awaitingOn',
          columnName: 'Awaiting On',
          defaultDirection: 'ASC',
        },
        {
          field: 'updatedAt',
          columnName: 'Updated At',
          defaultDirection: 'ASC',
        },
      ],
      currentSortingColumn: ['Updated At', 'ASC'],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'submissionsStatusFilter',
      'submissionsPageIndex',
      'submissionsSearch',
    ]),
  },
  watch: {
    submissionsStatusFilter() {
      this.resetSubmissionsPageIndex();
    },
  },
  mounted() {
    this.setSubmissionsSearch('');
  },
  methods: {
    ...mapActions('admin', [
      'nextSubmissionsPage',
      'prevSubmissionsPage',
      'resetSubmissionsPageIndex',
      'setSubmissionsStatusFilter',
      'setSubmissionsSearch',
      'setSortOrder',
    ]),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextSubmissionsPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevSubmissionsPage();
      }
    },
    search: debounce(300, function (event) {
      this.resetSubmissionsPageIndex();
      this.setSubmissionsSearch(event.target.value.trim());
    }),
  },
};
</script>
