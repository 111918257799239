var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showCouponModal)?_c('BaseModal',{on:{"close":_vm.close}},[_c('div',{ref:"container"},[_c('div',[_c('h2',{staticClass:"mb-4 text-4xl leading-tight"},[_vm._v("\n        "+_vm._s(_vm.$t('components.couponModal.title'))+"\n      ")]),_vm._v(" "),_c('AdiForm',{attrs:{"auto-focus":"","mutation":_vm.ADD_COUPON_MUTATION,"variables":_vm.variables,"submit-wide":"","submit-label":_vm.$t('components.couponModal.addCoupon')},on:{"update":_vm.update,"done":_vm.close}},[_c('FormSection',[_c('FormRow',{attrs:{"half-width":""}},[_c('TextInput',{attrs:{"id":"code","disabled":_vm.loading,"label":_vm.$t('components.couponModal.codeInput.label'),"classes":"uppercase","placeholder":_vm.$t('components.couponModal.codeInput.placeholder'),"rules":"required"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_vm._v(" "),_c('FormRow',[_c('TextInput',{attrs:{"id":"description","disabled":_vm.loading,"label":_vm.$t('components.couponModal.descriptionInput.label'),"placeholder":_vm.$t('components.couponModal.descriptionInput.placeholder'),"rules":"required"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_vm._v(" "),_c('FormRow',[_c('BaseRadio',{attrs:{"id":"coupon_type","label":_vm.$t('components.couponModal.typeInput.label'),"options":[
                {
                  text: _vm.$t(
                    'components.couponModal.typeInput.options.percentage'
                  ),
                  value: 'PERCENTAGE',
                },
                {
                  text: _vm.$t('components.couponModal.typeInput.options.fixed'),
                  value: 'FIXED',
                },
              ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_vm._v(" "),_c('FormRow',{attrs:{"half-width":""}},[(_vm.type === 'FIXED')?_c('MoneyInput',{attrs:{"id":"factor","disabled":_vm.loading,"label":_vm.$t('components.couponModal.value'),"placeholder":"00","rules":`required`},model:{value:(_vm.factor),callback:function ($$v) {_vm.factor=$$v},expression:"factor"}}):_vm._e(),_vm._v(" "),(_vm.type === 'PERCENTAGE')?_c('PercentageInput',{attrs:{"id":"factor","disabled":_vm.loading,"label":_vm.$t('components.couponModal.value'),"placeholder":"00","rules":`required|min_value:0|max_value:100|integer`},model:{value:(_vm.factor),callback:function ($$v) {_vm.factor=$$v},expression:"factor"}}):_vm._e()],1),_vm._v(" "),_c('label',{staticClass:"input-label"},[_vm._v("\n            "+_vm._s(_vm.$t('components.couponModal.products'))+"\n          ")]),_vm._v(" "),_c('FormRow',_vm._l((_vm.checkboxProductsMap),function(value,key){return _c('CheckboxInput',{key:key,staticClass:"mt-4 md:mt-6",attrs:{"id":key,"value":value},on:{"input":(checked) => _vm.handleChangeCheckboxProducts(key, checked)}},[_vm._v("\n              "+_vm._s(key)+"\n            ")])}),1)],1)],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }