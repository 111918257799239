<template>
  <Modal v-if="visible" not-dismissable>
    <template #header>{{ title }}</template>
    <template #body>
      <p>{{ message }}</p>
      <Buttons layout="stretch">
        <RuButton level="secondary" @click="close(false)"> Cancel </RuButton>
        <RuButton level="primary" @click="close(true)"> Confirm </RuButton>
      </Buttons>
    </template>
  </Modal>
</template>

<script>
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Modal from '@/components/molecules/Modal';

export default {
  name: 'ComponentsBaseMessageBox',
  components: {
    Buttons,
    RuButton,
    Modal,
  },
  data() {
    return {
      title: '',
      message: '',
      callback: null,
      visible: false,
    };
  },
  methods: {
    close(action) {
      this.visible = false;
      this.callback(action);
    },
  },
};
</script>
