<template>
  <div class="ru:get-started flex min-h-screen">
    <div class="w-full md:w-3/6 md:self-center px-4">
      <div class="relative max-w-3xl mx-auto">
        <div class="ru:get-started__logos">
          <Logo />
          <AffiliateLogo v-if="hasAffiliateLogo" />
        </div>
        <template v-if="screen !== 'callback'">
          <Headline big :level="1">
            {{ $t('pages.getStarted.getting') }}
            <DecoratedText decoration="circle">
              {{ $t('pages.getStarted.started') }}
            </DecoratedText>
          </Headline>
          <p class="--big">
            {{ $t('pages.getStarted.weWillStart') }}
          </p>
          <div class="grid grid--big">
            <div class="cell">
              <Badge color="basalt" background="lighter" size="large">
                <Icon id="chat" />
              </Badge>
            </div>
            <div class="cell cell__fill">
              <Headline :level="2" flush>{{
                $t('pages.getStarted.liveSupport')
              }}</Headline>
              <p>
                {{ $t('pages.getStarted.liveSupportText') }}
              </p>
            </div>
          </div>
          <div class="grid grid--big">
            <div class="cell">
              <Badge color="basalt" background="lighter" size="large">
                <Icon id="glasses" />
              </Badge>
            </div>
            <div class="cell cell__fill">
              <Headline :level="2" flush>{{
                $t('pages.getStarted.willReview')
              }}</Headline>
              <p>
                {{ $t('pages.getStarted.willReviewText') }}
              </p>
            </div>
          </div>
          <div v-if="!isFreeWillWeek" class="grid grid--big">
            <div class="cell">
              <Badge color="basalt" background="lighter" size="large">
                <Icon id="card" />
              </Badge>
            </div>
            <div class="cell cell__fill">
              <Headline :level="2" flush>{{
                $t('pages.getStarted.payWhenReady')
              }}</Headline>
              <p>
                {{ $t('pages.getStarted.payWhenReadyText') }}
              </p>
            </div>
          </div>
          <Buttons layout="column">
            <RuButton
              id="next"
              :level="hoverCallback ? 'secondary' : 'primary'"
              size="large"
              @click="next"
            >
              Next
              <Icon id="arrow-right" />
            </RuButton>
            <RuButton
              :level="hoverCallback ? 'primary' : 'secondary'"
              size="large"
              @click="callback"
              @mouseover.native="hoverCallback = true"
              @mouseleave.native="hoverCallback = false"
            >
              {{ $t('pages.getStarted.speakToTeam') }}
              <Icon id="arrow-right" />
            </RuButton>
          </Buttons>
        </template>
        <template v-else>
          <RuButton theme="light" level="tertiary" icon @click="back">
            <Icon id="arrow-left" /> {{ $t('forms.labels.back') }}
          </RuButton>
          <Headline big :level="1">
            <i18n path="pages.getStarted.writeYourWill">
              <DecoratedText decoration="circle">
                {{ $t('texts.words.phone') }}
              </DecoratedText>
            </i18n>
          </Headline>
          <p>
            {{ $t('pages.getStarted.helpWritingWill') }}
          </p>
          <p>
            <i18n path="pages.getStarted.weAreOpenForCall">
              <a
                href="tel:1800103310"
                class="link link-dark whitespace-no-wrap"
              >
                1800 10 33 10
              </a>
            </i18n>
            <i18n path="pages.getStarted.orChatNow">
              <button
                type="button"
                class="link link-dark whitespace-no-wrap"
                @click="showChat"
              >
                {{ $t('pages.getStarted.startChat') }}
              </button>
            </i18n>
          </p>
        </template>
      </div>
    </div>
    <div
      class="hidden md:flex bg-cover md:w-3/6 bg-center"
      :style="background ? `backgroundImage: url(${background})` : null"
    >
      <div class="text-lg pl-14 pb-12 flex overlay-gradient w-full">
        <div class="flex justify-end flex-col">
          <div class="flex items-center">
            <span class="flex relative h-3 w-3">
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-200 opacity-75"
              ></span>
              <span
                class="relative inline-flex rounded-full h-3 w-3 bg-green-200"
              ></span>
            </span>
            <span class="text-xl text-green-100 font-medium mt-2 ml-3 mb-3">
              {{ $t('pages.getStarted.weAreOnline') }}
            </span>
          </div>
          <span class="w-8/12 text-left text-white">
            {{ $t('pages.getStarted.weAreOnlineText') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import cookies from 'js-cookie';

import { showChat } from '@/modules/chat';
import { charityBackgrounds } from '@/modules/charityData';

import Icon from '@/components/atoms/Icon';
import Logo from '@/components/atoms/Logo';
import AffiliateLogo from '@/components/atoms/AffiliateLogo';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';
import DecoratedText from '@/components/atoms/DecoratedText';
import Badge from '@/components/atoms/Badge';

import tracking from '@/mixins/tracking';

export default {
  name: 'PagesAffiliateGetStartedIndex',
  components: {
    Icon,
    Logo,
    AffiliateLogo,
    Buttons,
    RuButton,
    Headline,
    DecoratedText,
    Badge,
  },
  mixins: [tracking],
  data() {
    return {
      background: null,
      screen: 'start',
      hoverCallback: false,
    };
  },
  computed: {
    ...mapFields('signup', ['meta.has_partner']),
    ...mapGetters('affiliate', ['branding']),
    ...mapGetters(['isFreeWillWeek']),
    hasAffiliateLogo() {
      return this.branding.logo;
    },
  },
  created() {
    this.$root.setLayout('minimal');
  },
  mounted() {
    this.setBackground();
  },
  methods: {
    back() {
      this.screen = 'start';
    },
    callback() {
      this.screen = 'callback';
    },
    next() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '🏁 Get Started',
      });
      this.$auth.loginWith('auth0', { params: { screen_hint: 'signup' } });
    },
    scheduleCallback() {
      this.$router.push({
        path: this.localePath('/get-started/callback'),
      });
    },
    setBackground() {
      if (this.branding.landingImage) {
        this.background = this.branding.landingImage;
        return;
      }

      const referral = cookies.get('referral_charity')
        ? cookies.get('referral_charity').toLowerCase()
        : null;
      const charityBackground = charityBackgrounds[referral];

      if (charityBackground) {
        this.background = charityBackground;
      } else {
        this.background = require('assets/img/pilot-kid.png');
      }
    },
    showChat() {
      showChat();
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &get-started {
    &__logos {
      --app-logo-height: 3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: var(--base-margin-big);
    }

    p {
      margin-bottom: var(--base-margin-small);
    }

    .grid + #{$ru}buttons {
      padding-top: var(--base-margin);
    }
  }
}
</style>
