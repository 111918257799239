<template>
  <div class="ru:container">
    <div class="grid grid--top grid--between">
      <div class="cell cell__12/12 cell__7/12--md">
        <Buttons theme="default" layout="start">
          <RuButton level="plain" :disabled="!prevStep" @click="goBack">
            <Icon id="arrow-left" />
          </RuButton>
        </Buttons>
        <slot />
      </div>
      <div class="d-none d-block--md cell cell__4/12--md sticky">
        <Icon id="chat" size="huge" shadow />
        <Headline big :level="4">
          {{ $t('components.moduleStep.weHereToHelp') }}
        </Headline>
        <p>
          {{ $t('components.moduleStep.bestWayToContactIs') }}
          <button type="button" @click="showChat">
            {{ $t('components.moduleStep.viaChat') }}
          </button>
          , {{ $t('components.moduleStep.orCallUsOn') }}
          <a href="tel:1800103310">1800 10 33 10</a>
          - {{ $t('components.moduleStep.open7days') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { showChat } from '@/modules/chat';
import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'ComponentsTemplatesModuleStep',
  components: {
    Icon,
    Buttons,
    RuButton,
    Headline,
  },
  props: {
    prevStep: {
      default: null,
      type: String,
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        path: this.localePath(this.prevStep),
        query: this.$route.query,
      });
    },
    showChat() {
      showChat();
    },
  },
};
</script>
