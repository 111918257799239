<template>
  <AdiForm
    v-slot="{ loading }"
    auto-focus
    :mutation="mutation"
    :variables="variables"
    submit-label="Save Charity"
    @update="update"
    @done="close"
  >
    <FormSection>
      <FormRow>
        <TextInput
          id="charity_name"
          v-model="name"
          :disabled="loading"
          label="Charity's Name"
          placeholder="e.g. Oxfam Australia"
          rules="required"
        />
      </FormRow>
      <FormRow>
        <TextInput
          id="charity_abn"
          v-model="abn"
          :disabled="loading"
          label="Charity's ABN (optional)"
          placeholder="e.g. 18 055 208 636"
        />
      </FormRow>
      <Tip>
        {{ $t('components.addCustomCharity.tip1') }}
      </Tip>
      <FormRow>
        <TextInput
          id="charity_address"
          v-model="address"
          :disabled="loading"
          label="Charity's Address"
          placeholder="e.g. 123 City Rd, Sydney, NSW 2000"
          rules="required"
        />
      </FormRow>
      <Tip>
        {{ $t('components.addCustomCharity.tip2') }}
      </Tip>
    </FormSection>
  </AdiForm>
</template>

<script>
import { metaArrayToObject } from '../utilities';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import TextInput from '@/components/TextInput';
import Tip from '@/components/molecules/Tip';

import { charities, user } from '@/mixins/apollo';
import { objectToMetaArray } from '@/utilities';

export default {
  name: 'ComponentsAddCustomCharity',
  components: {
    AdiForm,
    FormRow,
    FormSection,
    TextInput,
    Tip,
  },
  mixins: [charities, user],
  data() {
    return {
      name: '',
      abn: '',
      address: '',
    };
  },
  computed: {
    mutation() {
      return this.ADD_CHARITY_MUTATION;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray({
          name: this.name,
          abn: this.abn.trim(),
          address: this.address,
        }),
      };
      variables.willId = this.willId;
      return variables;
    },
  },
  methods: {
    close() {
      this.$emit('successfullyAddedCharity');
    },
    update(store, { data: { addCharity } }) {
      if (addCharity) {
        const addedCharity = metaArrayToObject(addCharity?.charity?.meta);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'charity_added',
          props: {
            charity_name: addedCharity.name,
          },
        });
        const data = store.readQuery(this.getCharitiesQuery);
        data.getCharities.charities.push(addCharity.charity);
        store.writeQuery({
          ...this.getCharitiesQuery,
          data,
        });
        this.$nuxt.$emit('addCharity', addCharity.charity);
      }
    },
  },
};
</script>
