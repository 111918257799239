<template>
  <ModuleStep :index="6" prev-step="/will/guardians/pet-guardians">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-icon="arrow-right"
      :submit-label="submitLabel"
      @done="onDone"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.guardians.leavePetCareFund') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.guardians.petCareFundExplanation') }}
        </Tip>
        <MoneyInput
          id="pet_care_fund"
          v-model="willMeta.pet_care_fund"
          :disabled="loading"
          rules="max_value:999999999"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import MoneyInput from '@/components/MoneyInput';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGuardiansPetCareFund',
  components: {
    MoneyInput,
    AdiForm,
    FormSection,
    ModuleStep,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.pet_care_fund
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 2 (Families)',
      });
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
