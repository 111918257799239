<template>
  <div class="ru:container">{{ $t('titles.endOfLife') }}</div>
</template>

<script>
export default {
  name: 'PagesAffiliateOnboardingEndOfLifeCategory',
  layout: 'ruach',
};
</script>
