<template>
  <div class="flex items-center md:text-lg" :class="classes">
    <div v-if="glyph || percentage" class="mr-1">
      <BaseGlyph v-if="glyph" :id="glyph" />
      <span v-if="percentage" class="text-grey-500">{{ percentage }} </span>
    </div>
    <div :class="truncate && 'truncate'">{{ text }}</div>
  </div>
</template>

<script>
import BaseGlyph from '@/components/BaseGlyph';

export default {
  name: 'ComponentsModuleCardText',
  components: {
    BaseGlyph,
  },
  props: {
    glyph: {
      default: null,
      type: String,
    },
    light: {
      default: false,
      type: Boolean,
    },
    percentage: {
      default: null,
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    truncate: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        'mt-2 first-child:mt-0 text-grey-500': this.light,
        'text-charcoal-100': !this.light,
      };
    },
  },
};
</script>
