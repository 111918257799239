<template>
  <div class="fixed top-0 right-0 z-10">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="toast-item flex align-center m-2 p-4 rounded bg-white text-white"
      :class="notification.type"
      @mouseenter="clearTimer(notification)"
      @mouseout="restartTimer(notification)"
    >
      <div>
        {{ notification.message }}
      </div>
      <button
        type="button"
        class="ml-4 bg-white px-4 text-black rounded-full"
        @click="removeNotification(notification)"
      >
        {{ $t('components.toast.clear') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsToast',
  data() {
    return {
      notifications: [],
      timers: {},
    };
  },
  mounted() {
    this.$nuxt.$on('toast', ({ type, message }) => {
      const newNotification = {
        id: new Date().getTime(),
        type,
        message,
      };
      this.notifications.push(newNotification);

      // By default, the notification auto close after 10 seconds
      this.timers[newNotification.id] = setTimeout(() => {
        this.removeNotification(newNotification);
      }, 10000);
    });
  },
  methods: {
    removeNotification(notification) {
      this.clearTimer(notification);
      this.notifications = this.notifications.filter((notif) => {
        return notif.id !== notification.id;
      });
    },
    clearTimer(notification) {
      window.clearTimeout(this.timers[notification.id]);
    },
    restartTimer(notification) {
      this.timers[notification.id] = setTimeout(() => {
        this.removeNotification(notification);
      }, 10000);
    },
  },
};
</script>

<style lang="scss">
.toast-item {
  &.success {
    background: --rgba(theme);
  }

  &.error {
    background: --rgba(error);
  }
}
</style>
