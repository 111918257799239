<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.finalDocumentation') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.finaliseTheCremationService') }}
    </p>
    <FormWrapper v-if="!loading" @submit="saveUpdatedDetails">
      <Box stretch background="lilac">
        <div class="grid grid--around">
          <!-- Authority To Post -->
          <div
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isAuthorityToPostSigned"
              :cover="require('assets/img/cover__AuthorityToPost.png')"
              :title="$t('pages.cremations.authorityToPost')"
              @click="toggleDocumentReader('AuthorityToPost')"
            />
            <RuButton
              size="small"
              rounded
              :background="isSignedButtonClass(isAuthorityToPostSigned)"
              @click="
                isSignedButtonEvent(isAuthorityToPostSigned, 'AuthorityToPost')
              "
            >
              <BaseIcon
                :id="isSignedButtonIcon(isAuthorityToPostSigned)"
                size="small"
                classes="mr-2"
              />
              {{ isSignedButtonText(isAuthorityToPostSigned) }}
            </RuButton>
            <DocumentReader
              v-if="documents.AuthorityToPost.visible"
              v-model="formData.authorityToPostSignature"
              :name="informantName"
              :images="documents.AuthorityToPost.images"
              :signable="
                !isSigned(cremationRequestData.authorityToPostSignature)
              "
              @close="toggleDocumentReader('AuthorityToPost')"
            />
          </div>

          <!-- Death Registration Statement -->
          <div
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isDeathRegistrationSigned"
              :cover="
                require('assets/img/cover__NSWDeathRegistrationStatement.png')
              "
              title="Death Registration Statement"
              @click="toggleDocumentReader('NSWDeathRegistrationStatement')"
            />
            <RuButton
              size="small"
              rounded
              :background="deathRegistrationButtonClass"
              @click="
                isSignedButtonEvent(
                  isDeathRegistrationSigned,
                  'NSWDeathRegistrationStatement'
                )
              "
            >
              <BaseIcon
                :id="deathRegistrationButtonIcon"
                size="small"
                classes="mr-2"
              />
              {{ deathRegistrationButtonText }}
            </RuButton>
            <DocumentReader
              v-if="documents.NSWDeathRegistrationStatement.visible"
              v-model="formData.deathRegistrationSignature"
              :name="informantName"
              :images="documents.NSWDeathRegistrationStatement.images"
              :signable="isSignableDeathRegistration"
              @close="toggleDocumentReader('NSWDeathRegistrationStatement')"
            >
              <template v-if="!isSignableDeathRegistration" #bottom>
                <Alert level="major" theme="warning" icon="warning">
                  {{ $t('pages.cremations.incompleteDeathRegistration') }}
                </Alert>
              </template>
            </DocumentReader>
          </div>

          <!-- Release of Ashes -->
          <div
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isAuthorityToReleaseSigned"
              :cover="require('assets/img/cover__ReleaseOfAshes.png')"
              title="Release of Ashes"
              @click="toggleDocumentReader('ReleaseOfAshes')"
            />
            <RuButton
              size="small"
              rounded
              :background="isSignedButtonClass(isAuthorityToReleaseSigned)"
              @click="
                isSignedButtonEvent(
                  isAuthorityToReleaseSigned,
                  'ReleaseOfAshes'
                )
              "
            >
              <BaseIcon
                :id="isSignedButtonIcon(isAuthorityToReleaseSigned)"
                size="small"
                classes="mr-2"
              />
              {{ isSignedButtonText(isAuthorityToReleaseSigned) }}
            </RuButton>
            <DocumentReader
              v-if="documents.ReleaseOfAshes.visible"
              v-model="formData.authorityToReleaseSignature"
              :name="informantName"
              :images="documents.ReleaseOfAshes.images"
              :signable="
                !isSigned(cremationRequestData.authorityToReleaseSignature)
              "
              @close="toggleDocumentReader('ReleaseOfAshes')"
            />
          </div>
        </div>
      </Box>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseIcon from '@/components/BaseIcon';
import Alert from '@/components/molecules/Alert';
import Box from '@/components/atoms/Box';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import DocumentReader from '@/components/organisms/DocumentReader';
import DocumentCover from '@/components/atoms/DocumentCover';

export default {
  name: 'PagesAffiliateCremationsAtNeedDocumentationFinalId',
  components: {
    Alert,
    BaseIcon,
    Box,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    DocumentReader,
    DocumentCover,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      formData: {
        informantFirstName: null,
        informantLastName: null,
        authorityToPostSignature: null,
        deathRegistrationSignature: null,
        authorityToReleaseSignature: null,
      },
      documents: {
        AuthorityToPost: {
          images: [],
          visible: false,
        },
        NSWDeathRegistrationStatement: {
          images: [],
          visible: false,
        },
        ReleaseOfAshes: {
          images: [],
          visible: false,
        },
      },
    };
  },
  computed: {
    informantName() {
      return `${this.formData.informantFirstName} ${this.formData.informantLastName}`;
    },
    nextPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/address/${this.cremationRequestId}`
      );
    },
    deathRegistrationButtonClass() {
      return this.isSignableDeathRegistration
        ? this.isSignedButtonClass(this.deathRegistrationSignature)
        : 'apricot';
    },
    deathRegistrationButtonText() {
      return this.isSignableDeathRegistration
        ? this.isSignedButtonText(this.deathRegistrationSignature)
        : 'Missing Information';
    },
    deathRegistrationButtonIcon() {
      return this.isSignableDeathRegistration
        ? this.isSignedButtonIcon(this.deathRegistrationSignature)
        : 'info-alt';
    },
    isAuthorityToPostSigned() {
      return this.isSigned(this.formData.authorityToPostSignature);
    },
    isDeathRegistrationSigned() {
      return this.isSigned(this.formData.deathRegistrationSignature);
    },
    isAuthorityToReleaseSigned() {
      return this.isSigned(this.formData.authorityToReleaseSignature);
    },
  },
  methods: {
    async toggleDocumentReader(documentType) {
      this.documents[documentType].visible =
        !this.documents[documentType].visible;
      if (
        this.documents[documentType].visible &&
        !this.documents[documentType].images.length
      ) {
        this.documents[documentType].images = await this.requestImageUrls(
          documentType
        );
      }
    },
  },
};
</script>
