<template>
  <div class="ru:radio-options">
    <Buttons :layout="layout">
      <RuButton
        v-for="(option, index) in options"
        :key="index"
        :level="option.value === value ? 'primary' : option.level"
        :theme="option.theme ? option.theme : 'plain'"
        round
        @click="$emit('change', option.value)"
      >
        {{ option.label }}
      </RuButton>
    </Buttons>
  </div>
</template>

<script>
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsMoleculesRadioOptions',
  components: {
    Buttons,
    RuButton,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
    layout: {
      default: 'end',
      type: String,
    },
    theme: {
      default: null,
      type: String,
    },
  },
};
</script>
