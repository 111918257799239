<template>
  <Box shadow background="white">
    <div class="ru:cremations-gateway__intro">
      <div class="grid">
        <div class="cell cell__5/12--lg">
          <Headline big :level="2">
            <small>{{
              $t('components.cremationGateway.cremationServiceFor')
            }}</small>
            {{ guestIdentifier }}
          </Headline>
          <p>
            {{ $t('components.cremationGateway.fillInformation') }}
          </p>
          <Buttons layout="start">
            <RuButton size="large" level="primary" :href="href">
              {{ $t('components.cremationGateway.start') }}
            </RuButton>
          </Buttons>
        </div>
        <div class="cell cell__6/12--lg">
          <div class="ru:cremations-gateway__steps">
            <Steps :steps="steps" />
          </div>
        </div>
        <div class="cell cell__1/12--lg">
          <img :src="require('~/assets/svg/gateway__cremations.svg')" alt="" />
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';
import Steps from '@/components/molecules/Steps';
import Box from '@/components/atoms/Box';

export default {
  name: 'ComponentsTemplatesCremationsGateway',
  components: { Buttons, RuButton, Headline, Steps, Box },
  props: {
    app: {
      required: true,
      type: String,
    },
    href: {
      required: true,
      type: String,
    },
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      steps: [
        {
          progress: 15,
          text: this.$t('components.cremationGateway.aboutYouAndYourLove'),
        },
        {
          progress: 40,
          text: this.$t('components.cremationGateway.reviewAndSign'),
        },
        {
          progress: 65,
          text: this.$t('components.cremationGateway.finaliseInformation'),
        },
        {
          progress: 90,
          text: this.$t('components.cremationGateway.submit'),
        },
      ],
    };
  },
  computed: {
    guestIdentifier() {
      if (this.data.guestFirstName && this.data.guestLastName) {
        return `${this.data.guestFirstName} ${this.data.guestLastName}`;
      }
      return this.$t('components.cremationGateway.yourLovedOne');
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &cremations-gateway {
    &__image {
      @include mq('max-md') {
        display: none;
      }
    }
  }
}
</style>
