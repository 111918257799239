<template>
  <div
    class="ru:product-gateway"
    :class="{
      '--open': isOpen,
    }"
  >
    <div class="ru:product-gateway__overview">
      <div class="ru:product-gateway__overview-header">
        <div class="ru:product-gateway__progress">
          <ProgressCircle
            class="ru:product-gateway__progress-circle"
            :progress="progress"
          />
          <Icon
            v-if="completed"
            id="check"
            class="ru:product-gateway__step"
            theme="turquoise"
          />
          <span v-else class="ru:product-gateway__step">{{ step }}</span>
        </div>
        <Headline :level="2" flush>
          {{ title }}
        </Headline>
      </div>
      <transition
        appear
        mode="out-in"
        :duration="250"
        name="ru:wiggle-fade-down"
      >
        <div v-if="isOpen" class="ru:product-gateway__overview-content">
          <slot />
          <Buttons layout="column" class="ru:product-gateway__cta">
            <RuButton
              v-for="cta in primaryCtaList"
              :key="cta.text"
              data-test="primary-cta"
              :href="cta.link"
              :click="cta.click"
              :level="cta.level || 'primary'"
              :disabled="cta.disabled"
            >
              {{ cta.text }}
              <Icon
                v-if="cta.icon !== null"
                :id="cta.icon || `arrow-right`"
                data-test="cta-icon"
              />
            </RuButton>
          </Buttons>
        </div>
      </transition>
    </div>
    <div class="ru:product-gateway__divide" />
    <div class="ru:product-gateway__details">
      <div class="ru:product-gateway__details-header">
        <Buttons layout="between">
          <RuButton
            level="plain"
            :href="itemsCta.link"
            :disabled="itemsCta.disabled"
          >
            <Headline :level="3" flush>
              {{ itemsCta.text }}
            </Headline>
            <Icon id="arrow-right-2" size="small" />
          </RuButton>
          <RuButton data-test="toggle-button" level="plain" @click="toggle">
            <transition name="ru:wiggle-fade-squish" mode="out-in">
              <Icon v-if="isOpen" id="chevron-up" key="close" />
              <Icon v-else id="chevron-down" key="menu" />
            </transition>
          </RuButton>
        </Buttons>
      </div>
      <transition appear mode="out-in" name="ru:wiggle-fade">
        <Tooltip
          :content="$t('components.willGateway.disabledTooltip')"
          :disabled="!isDisabled"
        >
          <div
            v-if="isOpen"
            class="ru:product-gateway__details-content"
            :class="{ '--disabled': isDisabled }"
          >
            <p v-if="itemsDescription">
              {{ itemsDescription }}
            </p>
            <div class="ru:product-gateway__items">
              <button
                v-for="item in items"
                :key="item.title"
                :class="[
                  'ru:product-gateway__item',
                  `--${item.disabled ? 'disabled' : item.status}`,
                ]"
                data-test="item"
                @click="openPage(item.cta)"
              >
                <div
                  data-test="item-icon"
                  class="ru:product-gateway__item-icon"
                  :style="{
                    opacity: ['not-started', 'in-progress'].includes(
                      item.status
                    )
                      ? '0.2'
                      : null,
                  }"
                >
                  <Icon :id="itemStatusIcons[item.status] || 'circle'" />
                </div>
                <div
                  data-test="item-title"
                  class="ru:product-gateway__item-title"
                >
                  {{ item.title }}
                </div>
                <div
                  data-test="items-cta"
                  class="ru:product-gateway__item-action"
                >
                  <Icon :id="itemActionIcons[item.status]" />
                </div>
              </button>
            </div>
          </div>
        </Tooltip>
      </transition>
    </div>
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import ProgressCircle from '@/components/atoms/ProgressCircle';
import Tooltip from '@/components/Tooltip';

export default {
  name: 'ComponentsMoleculesProductGateway',
  components: {
    Headline,
    Icon,
    Buttons,
    RuButton,
    ProgressCircle,
    Tooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    primaryCtaList: {
      type: Array,
      default: () => [],
    },
    itemsCta: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Number,
      default: 0,
    },
    itemsDescription: {
      type: String,
      default: null,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    isDisabled() {
      return this.items.every((item) => item.status === 'awaiting-approval');
    },
  },
  created() {
    this.itemStatusIcons = {
      'in-progress': 'incomplete-circle',
      completed: 'check',
      'awaiting-approval': 'check',
      flagged: 'warning',
      approved: 'check',
    };
    this.itemActionIcons = {
      'not-started': 'arrow-right',
      'in-progress': 'pencil',
      completed: 'pencil',
      'awaiting-approval': null,
      approved: null,
      flagged: 'pencil',
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    disabled() {
      return this.status !== 'awaiting-approval';
    },
    openPage(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
$header-text: '.ru\\:product-gateway__details-header .ru\\:buttons > :first-child';
#{$ru} {
  &product-gateway {
    background-color: --rgba(white);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--base-margin-big);
    box-shadow: 4px 4px 50px --rgba(black, 0.1);
    border-radius: var(--border-radius);
    position: relative;
    padding: var(--base-margin-big);
    transition: var(--transition);
    overflow: hidden;

    @include mq('min-md') {
      flex-direction: row;
    }

    #{$ru}image img {
      width: 50%;
      @include mq('min-md') {
        width: 40%;
      }
    }

    &__divide {
      border-top: 1px solid --rgba(iron);
      align-self: stretch;
      margin-bottom: var(--base-margin-big);
      @include mq('min-md') {
        border-left: 1px solid --rgba(iron);
        border-top: none;
        align-self: stretch;
        margin: 0 var(--base-margin);
      }
    }

    &__overview {
      display: flex;
      flex-direction: column;
      flex: 1;

      &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--base-margin);
      }

      &-content {
        margin: var(--base-margin-big) 0;
        @include mq('min-md') {
          margin: var(--base-margin-big);
          padding-bottom: 0;
        }

        #{$ru}button {
          &#{$mf}plain {
            color: --rgba(slate) !important;

            &:disabled {
              color: --rgba(silver) !important;
            }

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__link {
      color: --rgba(cornflower);
      text-decoration: underline;
    }

    & #{$header-text} {
      transition: var(--transition);
      opacity: 1;
    }

    &#{$mf}open #{$header-text} {
      opacity: 0;
    }

    &__details {
      flex: 0.8;

      &-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2.5rem;
        gap: var(--base-margin);

        #{$ru}button {
          &#{$mf}plain {
            color: --rgba(slate) !important;

            &[disabled] {
              color: --rgba(silver) !important;
            }
          }
        }
      }

      &-content {
        margin: var(--base-margin-big) 0;
        &#{$mf}disabled {
          pointer-events: none;
        }
      }
    }

    &__progress {
      position: relative;
    }

    &__step {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.25rem;
    }

    &__progress-circle {
      width: 2.5rem;
    }

    &__items {
      display: flex;
      flex-direction: column;
      gap: var(--base-margin);
      align-items: stretch;
    }

    &__item {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;

      &-icon {
        width: 2rem;
      }

      &-title {
        font-weight: 500;
        font-size: 1rem !important;
        transition: --out();
      }

      &-action {
        margin-left: auto;
        color: --rgba(basalt);
        transition: --out();
      }

      &:hover,
      &:hover &-action,
      &#{$mf}completed,
      &#{$mf}approved {
        transition: --in();
        color: --rgba(theme);
      }

      &#{$mf}flagged {
        color: --rgba(bourbon);
      }

      &#{$mf}disabled,
      &#{$mf}disabled &-action {
        color: --rgba(silver);
        pointer-events: none;
      }
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0.25rem;
      height: 100%;
      background-color: --rgba(slate);
      transform-origin: top left;
      transform: scaleY(0);
      transition: --out();
    }

    &#{$mf}open::before {
      transition: --in();
      transform: scaleY(1);
    }

    &#{$mf}open &__toggle #{$ru}icon {
      transform: rotate(180deg);
    }
  }
}
</style>
