<template>
  <div class="ru:chip" :style="styles">
    <div class="ru:chip__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { hexToRgb } from '@/utilities';
export default {
  name: 'ComponentsAtomsChip',
  props: {
    background: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return null;
    },
    styles() {
      return {
        '--background': this.backgroundStyle,
      };
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &chip {
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.5rem;
    margin-bottom: var(--base-margin-small);
    display: flex;

    &__content {
      display: block;
      background-color: --rgba(background);
      color: #fff;
      padding: 0 0.75rem;
      border-radius: 999px;
      font-weight: 500;
    }
  }
}
</style>
