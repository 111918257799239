<template>
  <div class="ru:coupon-input">
    <Buttons layout="end">
      <RuButton level="plain" @click="toggleFormVisibility">
        {{ $t('components.couponInput.haveACode') }}
        <Icon :id="formIsVisible ? 'chevron-up' : 'chevron-down'" />
      </RuButton>
    </Buttons>

    <slot />

    <div v-if="formIsVisible" class="grid">
      <div class="cell cell__12/12 cell__8/12--md">
        <FormControl
          id="couponInput"
          v-model="modelValue"
          :required="false"
          type="text"
          placeholder="Your code"
        />
      </div>
      <div class="cell cell__12/12 cell__4/12--md">
        <Buttons layout="stretch">
          <RuButton level="secondary" @click="applyCoupon(modelValue)">
            {{ $t('components.couponInput.apply') }}
          </RuButton>
        </Buttons>
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'ComponentsMoleculesCouponInput',
  components: {
    Buttons,
    RuButton,
    Icon,
    FormControl,
  },
  props: {
    value: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      formIsVisible: false,
      modelValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  methods: {
    toggleFormVisibility() {
      this.formIsVisible = !this.formIsVisible;
    },
    applyCoupon(value) {
      this.$emit('applyCoupon', value);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &coupon-input {
    margin-bottom: var(--base-margin);

    &__toggle {
      color: --rgba(theme);
      text-decoration: underline;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
      margin-bottom: var(--base-margin);

      svg {
        margin-left: 0.5rem;
      }
    }

    &__form {
      position: relative;
      margin-bottom: var(--base-margin);

      & input,
      & button {
        text-transform: uppercase;
      }

      &-button {
        position: absolute;
        top: 50%;
        right: 0.375rem;
        transform: translateY(-50%);
        #{$ru}button {
          border-width: 0;
        }
      }
    }
  }
}
</style>
