<template>
  <Portal to="stack">
    <transition
      appear
      mode="out-in"
      :duration="250"
      name="ru:wiggle-fade-squish"
      @after-leave="onAfterLeave"
    >
      <div class="ru:modal">
        <div
          v-on-dismiss="{
            watch: isDismissable,
            callback: closeModal,
          }"
          class="ru:modal__content"
        >
          <div class="ru:modal__close">
            <Buttons theme="default" layout="end">
              <RuButton v-if="isDismissable" level="plain" @click="closeModal">
                <Icon id="close" />
              </RuButton>
            </Buttons>
          </div>
          <div v-if="showHeader" class="ru:modal__header">
            <slot name="header" />
          </div>
          <div class="ru:modal__body">
            <slot name="body" />
          </div>
        </div>
      </div>
    </transition>
  </Portal>
</template>

<script>
import VDismiss from 'vue-dismiss';
import { Portal } from 'portal-vue';

import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsMoleculesModal',
  components: {
    Icon,
    Buttons,
    RuButton,
    Portal,
  },
  mixins: [VDismiss],
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    notDismissable: {
      type: Boolean,
      default: false,
    },
    watch: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isDismissable() {
      return !this.notDismissable;
    },
    showHeader() {
      return !this.hideHeader;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    onAfterLeave() {
      this.$emit('modalHasClosed');
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &modal {
    position: fixed;
    inset: 0;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--base-margin);

    &__close {
      min-height: var(--app-height);
      display: flex;
      position: absolute;
      align-items: center;
      top: 0;
      right: var(--base-margin);
    }

    &__header {
      background: --rgba(white);
      min-height: var(--app-height);
      font-weight: 500;
      padding: var(--base-margin) #{calc(var(--base-margin) * 4)} var(
          --base-margin
        ) var(--base-margin);
      display: flex;
      align-items: center;
    }

    &__content {
      border-radius: var(--border-radius);
      max-height: #{calc(
          100vh - (var(--app-logo-height) + var(--app-padding-y) * 2)
        )};
      width: min(100%, 30rem);
      background: --rgba(body-background);
      box-shadow: var(--base-box-shadow);
      position: relative;
      overflow-y: auto;
    }

    &__body {
      padding: var(--base-margin);

      > :is(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
