<template>
  <div class="ru: ru:minimal">
    <nuxt />
  </div>
</template>

<script>
export default {
  name: 'MinimalLayout',
};
</script>

<style lang="scss">
#{$ru} {
  &minimal &container {
    width: 100%;
  }
}
</style>
