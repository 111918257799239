var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ru:container"},[_c('Headline',{attrs:{"level":1}},[_c('small',[_vm._v(_vm._s(_vm.headlineSmallText))]),_vm._v("\n    "+_vm._s(_vm.headlineBigText)+"\n  ")]),_vm._v(" "),(!_vm.showThanks)?_c('Slider',[_c('SliderItem',[_c('GenericModuleCard',{attrs:{"title":_vm.$t('texts.sentences.aboutYourself'),"description":_vm.$t('texts.sentences.confirmAboutYourself'),"duration":[3, 5],"number":1,"loading":false,"progress":_vm.aboutYourselfComplete ? 1 : 0,"ready":!_vm.aboutYourselfComplete,"editable":true,"to":_vm.localePath(`/cremations/at-need/informant/${_vm.cremationRequestId}`)}})],1),_vm._v(" "),_c('SliderItem',[_c('GenericModuleCard',{attrs:{"title":_vm.$t('texts.sentences.yourLovedOne'),"description":_vm.$t('pages.cremations.confirmDetailsAboutLovedOne'),"duration":[3, 5],"number":2,"loading":false,"progress":_vm.yourLovedOneComplete ? 1 : 0,"ready":_vm.aboutYourselfComplete && !_vm.yourLovedOneComplete,"editable":true,"to":_vm.localePath(`/cremations/at-need/deceased/${_vm.cremationRequestId}`),"locked":!_vm.aboutYourselfComplete,"locked-message":_vm.$t('pages.cremations.confirmDetailsAboutYourselfFirst')}})],1),_vm._v(" "),_c('SliderItem',[_c('GenericModuleCard',{attrs:{"title":_vm.$upperFirst(_vm.$t('texts.words.documents')),"description":_vm.$t('pages.cremations.reviewAndSignDocuments'),"duration":[8, 10],"number":3,"loading":false,"progress":_vm.documentsComplete ? 1 : 0,"ready":_vm.aboutYourselfComplete && _vm.yourLovedOneComplete && !_vm.documentsComplete,"editable":true,"to":_vm.localePath(
            `/cremations/at-need/documentation/initial/${_vm.cremationRequestId}`
          ),"locked":!_vm.aboutYourselfComplete || !_vm.yourLovedOneComplete,"locked-message":_vm.$t('pages.cremations.confirmDetailsAboutLovedOneFirst')}})],1),_vm._v(" "),_c('SliderItem',[_c('GenericModuleCard',{attrs:{"title":_vm.$t('texts.sentences.authorisation'),"description":_vm.$t('pages.cremations.completeDetailsToAuthoriseCremation'),"duration":[3, 5],"number":4,"loading":false,"progress":_vm.authorisationComplete ? 1 : 0,"ready":_vm.aboutYourselfComplete &&
          _vm.yourLovedOneComplete &&
          _vm.documentsComplete &&
          !_vm.authorisationComplete,"editable":true,"to":_vm.localePath(
            `/cremations/at-need/authorisation/${_vm.cremationRequestId}`
          ),"locked":!_vm.aboutYourselfComplete ||
          !_vm.yourLovedOneComplete ||
          !_vm.documentsComplete,"locked-message":_vm.$t('pages.cremations.reviewAndSignDocumentsFirst')}})],1),_vm._v(" "),_c('SliderItem',[_c('GenericModuleCard',{attrs:{"title":_vm.$upperFirst(_vm.$t('texts.words.finalise')),"description":_vm.$t('pages.cremations.completeRequiredInfoForDeathCertificate'),"duration":[10],"number":5,"loading":false,"progress":_vm.finaliseComplete ? 1 : 0,"ready":_vm.aboutYourselfComplete &&
          _vm.yourLovedOneComplete &&
          _vm.documentsComplete &&
          _vm.authorisationComplete &&
          !_vm.finaliseComplete,"editable":true,"to":_vm.localePath(`/cremations/at-need/life/${_vm.cremationRequestId}`),"locked":!_vm.aboutYourselfComplete ||
          !_vm.yourLovedOneComplete ||
          !_vm.documentsComplete ||
          !_vm.authorisationComplete,"locked-message":_vm.$t('pages.cremations.completeDetailsToAuthoriseCremationFirst')}})],1)],1):_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell cell__12/12 cell__6/12--md"},[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('pages.cremations.thankYouForCompletingRequiredDocuments'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell cell__10/12 cell__6/12--md cell__5/12--lg"},[_c('RuButton',{attrs:{"aria-label":_vm.$t('texts.sentences.reviewDetails'),"button-type":"submit","color":"wine","border":"wine","background":"white","wide":""},on:{"click":_vm.toggleReviewDetails}},[_c('span',[_vm._v(_vm._s(_vm.$t('texts.sentences.reviewDetails')))])])],1),_vm._v(" "),_c('div',{staticClass:"cell cell__10/12 cell__6/12--md cell__5/12--lg"},[_c('RuButton',{attrs:{"href":_vm.localePath('/cremations/at-need'),"aria-label":_vm.$t('texts.sentences.goToDashboard'),"button-type":"submit","background":"wine","color":"white","wide":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('texts.sentences.goToDashboard')))])])],1)])]),_vm._v(" "),_c('div',{staticClass:"cell cell__12/12 cell__6/12--md"},[_c('img',{staticStyle:{"max-height":"448px","margin":"0 auto"},attrs:{"src":require('assets/svg/gateway__cremations.svg'),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }