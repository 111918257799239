<template>
  <div v-if="comments" class="pt-2">
    <div
      v-for="comment in comments"
      :key="comment.id"
      class="card bg-grey-100 text-charcoal-200 border-grey-200 border-2 w-full my-2 p-2 rounded relative"
    >
      <span class="text-grey-500 absolute right-0 px-2">
        {{ $formatDate(comment.updatedAt) }}
      </span>
      <strong>{{ comment.user.email }}</strong>
      <p>{{ comment.text }}</p>
    </div>
    <form
      class="card bg-grey-100 border-grey-200 border-2 w-full my-2 p-2 rounded relative text-charcoal-200"
      @submit.prevent="submit"
    >
      <input
        v-model="text"
        type="text"
        :disabled="loading"
        class="bg-grey-100 w-full outline-none"
        placeholder="Add a comment..."
        rows="2"
      />
    </form>
  </div>
</template>

<script>
import GET_COMMENTS from '@/graphql/queries/GetComments';
import ADD_COMMENT from '@/graphql/mutations/AddComment';

export default {
  name: 'ComponentsAdminComments',
  props: {
    willId: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: '',
      loading: false,
    };
  },
  computed: {
    getCommentsQuery() {
      return {
        query: GET_COMMENTS,
        variables: {
          willId: this.willId,
          key: this.moduleName,
        },
      };
    },
  },
  apollo: {
    comments: {
      query: GET_COMMENTS,
      variables() {
        return {
          willId: this.willId,
          key: this.moduleName,
        };
      },
      update: (data) => data.getComments && data.getComments.comments,
    },
  },
  mounted() {
    this.$root.$on('updateFlags', () =>
      this.$apollo.queries.comments.refetch()
    );
  },
  methods: {
    async submit() {
      try {
        await this.$apollo.mutate({
          mutation: ADD_COMMENT,
          variables: {
            willId: this.willId,
            key: this.moduleName,
            text: this.text,
          },
          update: (store, { data: { addComment } }) => {
            const data = store.readQuery(this.getCommentsQuery);
            data.getComments.comments.push(addComment.comment);
            store.writeQuery({
              ...this.getCommentsQuery,
              data,
            });

            this.text = '';
          },
        });
      } catch (e) {
        this.error = e.message;
      }
    },
  },
};
</script>
