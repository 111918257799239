<template>
  <BaseModal
    v-if="showUnlockWillModal"
    :is-dismissable="!loading"
    @close="close"
  >
    <div
      class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-teal-300 text-white"
    >
      <BaseGlyph id="edit" />
    </div>
    <h4 class="max-w-xs mb-4 text-3xl text-teal-300">
      {{ $t('components.unlockWillModal.title') }}
    </h4>
    <div class="mb-4 text-xl">
      <p v-if="willStatus === 'FLAGGED'">
        {{ $t('components.unlockWillModal.willFlaggedMessage') }}
      </p>
      <p v-else>
        {{ $t('components.unlockWillModal.message') }}
      </p>
    </div>
    <AdiForm
      :mutation="VERSION_WILL_MUTATION"
      :variables="variables"
      submit-icon="arrow-right"
      submit-label="Start updating"
      @requestStarted="setLoading(true)"
      @done="done"
    />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import VERSION_WILL_MUTATION from '@/graphql/mutations/VersionWill';

import BaseGlyph from '@/components/BaseGlyph';
import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'ComponentsUnlockWillModal',
  components: {
    BaseGlyph,
    BaseModal,
    AdiForm,
  },
  mixins: [user, will],
  data() {
    return {
      VERSION_WILL_MUTATION,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['willStatus']),
    ...mapGetters('ui', ['showUnlockWillModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions(['setWillId', 'setWillStatus']),
    ...mapActions('ui', ['setShowUnlockWillModal']),
    setLoading(value) {
      this.loading = value;
    },
    close() {
      this.setShowUnlockWillModal(false);
    },
    done({ data }) {
      if (data.versionWill.success) {
        this.setWillId(data.versionWill.will.id);
        this.setWillStatus(data.versionWill.will.status);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '📝 Edit Will',
        });
      }
      this.setLoading(false);
      this.close();
    },
  },
};
</script>
