<template>
  <ModuleStep :index="3" :prev-step="prevStep">
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="
        !(
          willMeta.professional_executor_terms_check &&
          willMeta.professional_executor_payment_check &&
          willMeta.professional_executor_court_check
        )
      "
      submit-icon="arrow-right"
      @submit="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.executors.professionalTerms.headline') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.executors.professionalTerms.tip') }}
        </Tip>
        <div
          ref="terms"
          class="h-84 px-8 py-6 border-2 border-grey-200 rounded overflow-y-scroll bg-white professional-terms"
          @scroll="onScroll"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-charcoal-400"
            v-html="
              $t('pages.will.executors.professionalTerms.termsConditions')
            "
          />
          <!--eslint-enable-->
        </div>
        <FormRow>
          <Tooltip
            :disabled="hasScrolledTerms"
            content="You'll need to read the terms before you tick this box."
          >
            <CheckboxInput
              id="professional_executor_terms"
              v-model="willMeta.professional_executor_terms_check"
              :disabled="!hasScrolledTerms"
              class="mt-4 md:mt-6"
              required
            >
              {{ $t('pages.will.executors.professionalTerms.label.terms') }}
            </CheckboxInput>
          </Tooltip>
        </FormRow>
        <FormRow>
          <CheckboxInput
            id="professional_executor_payment"
            v-model="willMeta.professional_executor_payment_check"
            class="mt-4 md:mt-6"
            required
          >
            {{ $t('pages.will.executors.professionalTerms.label.payment') }}
          </CheckboxInput>
        </FormRow>
        <FormRow>
          <CheckboxInput
            id="professional_executor_court"
            v-model="willMeta.professional_executor_court_check"
            class="mt-4 md:mt-6"
            required
          >
            {{ $t('pages.will.executors.professionalTerms.label.court') }}
          </CheckboxInput>
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import CheckboxInput from '@/components/CheckboxInput';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Tooltip from '@/components/Tooltip';
import Headline from '@/components/atoms/Headline';

import { executors, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillExecutorsProfessionalTerms',
  components: {
    CheckboxInput,
    AdiForm,
    FormRow,
    FormSection,
    ModuleStep,
    Tip,
    Tooltip,
    Headline,
  },
  mixins: [executors, user, will],
  data() {
    return {
      hasScrolledTerms: false,
      isLoading: false,
    };
  },
  computed: {
    prevStep() {
      return this.willMeta.executors_option === 'professional'
        ? '/will/executors/options'
        : '/will/executors/primary-contact';
    },
  },
  methods: {
    onScroll() {
      if (!this.hasScrolledTerms) {
        this.hasScrolledTerms =
          this.$refs.terms.scrollHeight -
            this.$refs.terms.scrollTop -
            this.$refs.terms.clientHeight <
          10;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      const professionalSelected =
        this.willMeta.executors_option === 'professional';
      if (professionalSelected) {
        this.willMeta.has_backup_executor = true;
      }
      await this.updateWillMeta();
      const path = this.localePath(
        professionalSelected ? '/will/executors/backup' : '/will'
      );
      this.$router.push({
        path,
      });
    },
  },
};
</script>
