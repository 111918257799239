<template>
  <ModuleStep :index="1" prev-step="/poa">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.poa.confirmDetails') }}
      </Headline>
      <FormSection class="step-1">
        <Headline :level="1">{{ $t('pages.poa.questions.fullName') }}</Headline>
        <FormRow>
          <TextInput
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourFirstName')"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourMiddleName')"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourLastName')"
            rules="required"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import TextInput from '@/components/TextInput';
import Headline from '@/components/atoms/Headline';

import { will } from '@/mixins/apollo';
import { getUserFullName } from '@/utilities';

export default {
  name: 'PagesAffiliatePoaAboutYourselfIndex',
  components: {
    AdiForm,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
    Headline,
  },
  mixins: [will],
  computed: {
    ...mapGetters('ui', ['australianStates']),
    isComplete() {
      return !!(this.willMeta.name_first && this.willMeta.name_last);
    },
  },
  methods: {
    onDone() {
      const willMeta = this.willMeta;

      this.$nuxt.$emit('sendTrackingAttributes', {
        name: getUserFullName(willMeta),
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/address'),
      });
    },
  },
};
</script>
