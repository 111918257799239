var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":3,"prev-step":"/will/guardians/primary"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-icon":"arrow-right"},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',{staticClass:"step-1"},[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.appointBackupGuardian'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.backupGuardianExplanation'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.addExtraProtection'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasBackupGuardians","options":[
          {
            label: _vm.$t('forms.labels.yes'),
            value: true,
          },
          {
            label: _vm.$t('forms.labels.no'),
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_backup_guardian),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_backup_guardian", $$v)},expression:"willMeta.has_backup_guardian"}})],1),_vm._v(" "),(_vm.willMeta.has_backup_guardian)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":_vm.$t('pages.will.peopleSelectionInstructions')}},[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.appointBackupGuardianPrompt'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.backupGuardianRequirements'))+"\n      ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":1,"people":_vm.filteredPeople,"type":"guardian"},model:{value:(_vm.selectedBackupGuardian),callback:function ($$v) {_vm.selectedBackupGuardian=$$v},expression:"selectedBackupGuardian"}})],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }