<template>
  <div>
    <AdminBackLink to="/admin/coupons">Coupons</AdminBackLink>
    <AdminMainSideLayout v-if="coupon">
      <template #main>
        <AdminCard :title="`Coupon: ${coupon.code}`" tight>
          <AdminSimpleTable description="Coupons" :rows="couponRows" />
        </AdminCard>
        <AdminCard title="Update details">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateCouponData"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="description"
            v-model="coupon.description"
            type="text"
            label="Description"
            :disabled="loading"
          />
          <AdminCardRow
            id="partnership"
            v-model="coupon.partnershipId"
            type="select"
            label="Partnership"
            :options="partnershipsOptions"
            :disabled="loading"
          />
          <AdminCardRow
            v-for="(value, key, i) in products"
            :id="key"
            :key="`products${i}`"
            :label="key"
            :value="value"
            type="switch"
            @input="(checked) => updateProductSelection(key, checked)"
          />
        </AdminCard>
      </template>
      <template #side>
        <AdminCard title="Status">
          <AdminCardSection>
            <label class="flex items-center">
              <BaseSwitch
                v-model="coupon.active"
                @change="setCouponActive(coupon)"
              />
              <p class="ml-4">
                {{ coupon.active ? 'Active' : 'Inactive' }}
              </p>
            </label>
          </AdminCardSection>
        </AdminCard>
        <AdminCard title="Actions">
          <AdminCardSection>
            <AdminActionLink
              glyph="remove"
              :disabled="coupon.archived"
              class="m-0 p-0 border-none bg-transparent text-red-300 underline"
              @click="showArchiveCouponModal"
            >
              Archive
            </AdminActionLink>
            <p v-if="coupon.archived" class="mt-2">Coupon already archived</p>
          </AdminCardSection>
        </AdminCard>
        <AdminCard title="History">
          <AdminCardSection>
            <div class="text-sm">
              Created at:
              {{ $formatDate(coupon.createdAt) }}
            </div>
          </AdminCardSection>
        </AdminCard>
      </template>
    </AdminMainSideLayout>
    <Toast />
  </div>
</template>

<script>
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships.gql';
import dialog from '@/mixins/message-box';
import AdminBackLink from '@/components/admin/BackLink';
import AdminCard from '@/components/admin/Card';
import AdminMainSideLayout from '@/components/admin/MainSideLayout';
import AdminCardSection from '@/components/admin/CardSection';
import AdminActionLink from '@/components/admin/ActionLink';
import AdminSimpleTable from '@/components/admin/SimpleTable';
import AdminCardRow from '@/components/admin/CardRow';
import BaseSwitch from '@/components/BaseSwitch';
import BaseButton from '@/components/BaseButton';
import BaseIcon from '@/components/BaseIcon';
import Toast from '@/components/Toast';

import { coupon } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateAdminCouponsId',
  components: {
    AdminBackLink,
    AdminMainSideLayout,
    AdminCard,
    AdminCardSection,
    AdminSimpleTable,
    AdminActionLink,
    AdminCardRow,
    BaseSwitch,
    BaseButton,
    BaseIcon,
    Toast,
  },
  mixins: [coupon, dialog],
  layout: 'admin',
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data && data.getPartnerships,
    },
  },
  data() {
    return {
      loading: false,
      products: {
        WILL: false,
        POA: false,
        PARTNER_WILL: false,
      },
    };
  },
  computed: {
    partnershipsOptions() {
      return (this.partnerships || []).map((partnership) => {
        return {
          text: partnership.name,
          value: partnership.id,
        };
      });
    },
    couponId() {
      return this.$route.params.id;
    },
    couponRows() {
      return [
        ['ID', this.coupon?.id],
        ['Code', this.coupon?.code],
        ['Factor', this.coupon?.factor],
        ['Type', this.coupon?.type],
      ];
    },
  },
  watch: {
    coupon(coupon) {
      coupon.products.forEach((product) => (this.products[product] = true));
    },
  },
  methods: {
    setCouponActive(coupon) {
      this.$confirm(
        {
          title: `${
            coupon.active ? 'Activate' : 'Deactivate'
          } ${coupon.code.toUpperCase()}?`,
          message: `Are you sure you want to ${
            coupon.active ? 'activate' : 'deactivate'
          } this coupon?`,
        },
        (action) => {
          if (action) {
            this.updateCoupon(coupon);
          } else {
            coupon.active = !coupon.active;
          }
        }
      );
    },
    showArchiveCouponModal() {
      this.$confirm(
        {
          title: `Archive ${this.coupon.code.toUpperCase()}?`,
          message: 'Are you sure you want to archive this coupon?',
        },
        (action) => {
          if (action) {
            this.archiveCoupon(this.coupon);
          }
        }
      );
    },
    updateProductSelection(key, value) {
      this.products[key] = value;
      const productEntries = Object.entries(this.products);
      this.coupon.products = productEntries
        .filter((el) => el[1])
        .map((el) => el[0]);
    },
    async updateCouponData() {
      this.loading = true;
      await this.updateCoupon(this.coupon);
      this.loading = false;
    },
  },
};
</script>
