<template>
  <ModuleStep :index="3" prev-step="/poa/about-yourself/address">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="onDone"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.poa.questions.dateOfBirth') }}
        </Headline>
        <Tip>
          {{ $t('pages.poa.tips.above18') }}
        </Tip>
        <FormRow>
          <DateInput
            id="date_of_birth"
            v-model="willMeta.date_of_birth"
            :disabled="loading"
            rules="required|date|over18"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import DateInput from '@/components/DateInput';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';
import { age } from '@/utilities';

export default {
  name: 'PagesAffiliatePoaAboutYourselfDateOfBirth',
  components: {
    AdiForm,
    FormRow,
    FormSection,
    ModuleStep,
    DateInput,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    isComplete() {
      return this.willMeta && this.willMeta.date_of_birth;
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        date_of_birth: `${this.willMeta.date_of_birth}`,
        age: age(this.willMeta.date_of_birth),
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/phone'),
      });
    },
  },
};
</script>
