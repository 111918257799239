<template>
  <div class="signature">
    <div v-if="label" class="signature__label">{{ label }}</div>
    <div :style="styles" class="signature__box" @click="click">
      <span>{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsSignature',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    signed: {
      default: false,
      type: Boolean,
    },
    label: {
      default: null,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    sizeStyle() {
      if (this.small) {
        return '0.66';
      }
      return null;
    },
    cursorStyle() {
      if (this.disabled) {
        return 'not-allowed';
      }
      return null;
    },
    styles() {
      return {
        '--cursor': this.cursorStyle,
        '--size-modifier': this.sizeStyle,
        '--is-signed': this.signed ? 1 : 0,
        '--is-disabled': this.disabled ? 1 : 0,
      };
    },
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit('input', !this.signed);
      }
    },
  },
};
</script>
<style lang="scss">
.signature {
  &__label {
    margin-bottom: 0.5rem;
  }

  &__box {
    --size-modifier: 1;
    border: 2px dashed --rgba(rock);
    font-family: 'Mr De Haviland', serif;
    background: --rgba(white);
    color: --rgba(navy, var(--is-signed));
    display: flex;
    align-items: center;
    height: #{calc(8.5rem * var(--size-modifier))};
    font-size: #{calc(4.25rem * var(--size-modifier))};
    cursor: var(--cursor, pointer);
    transition: color calc(var(--transition-duration-n) * 2ms)
      var(--transition-timing-function);
    position: relative;
    white-space: nowrap;
    overflow: hidden;

    span {
      transform: scaleX(0.9);
      margin-left: #{calc(2rem * var(--size-modifier))};
    }

    &::before {
      transition: color calc(var(--transition-duration-n) * 2ms)
        var(--transition-timing-function);
      position: absolute;
      content: '';
      inset: 0;
      opacity: var(--is-disabled);
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 10px,
        --rgba(iron) 10px,
        --rgba(iron) 20px,
        transparent 20px
      );
    }
  }
}
</style>
