<template>
  <div class="ru:container ru:dashboard">
    <div class="ru:dashboard__overview">
      <div class="grid grid--flush">
        <div class="cell cell__12/12 cell__8/12--md">
          <Headline :level="1" flush>
            {{ $t('pages.dashboard.title', { name: fullName }) }}
          </Headline>
          <p class="ru:dashboard__description">
            {{ $t('pages.dashboard.description') }}
          </p>
          <RuProgress />
        </div>
        <div class="cell cell__12/12 cell__4/12--md">
          <div class="ru:dashboard__action">
            <RecommendedAction />
          </div>
        </div>
      </div>
    </div>
    <WillGateway v-if="features.willEnabled" />
    <PoaGateway v-if="features.powerOfAttorneyEnabled" />
    <VaultGateway v-if="features.endOfLifeEnabled" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Headline from '@/components/atoms/Headline';

import WillGateway from '@/components/templates/WillGateway';
import PoaGateway from '@/components/templates/PoaGateway';
import VaultGateway from '@/components/templates/VaultGateway';
import RecommendedAction from '@/components/organisms/RecommendedAction';
import RuProgress from '@/components/organisms/Progress';

import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateIndex',
  components: {
    Headline,
    WillGateway,
    PoaGateway,
    VaultGateway,
    RecommendedAction,
    RuProgress,
  },
  mixins: [will],
  layout: 'ruach',
  middleware: ['authenticated'],
  computed: {
    ...mapGetters('affiliate', ['features']),
    fullName() {
      if ('name_first' in this.willMeta && this.willMeta.name_first.length) {
        let name = this.willMeta.name_first.trim();
        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }
        name = ` ${name} ${this.willMeta.name_last.trim()}`;
        return name;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.ru\: {
  &dashboard {
    &__overview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid --rgba(iron);
      padding-bottom: var(--base-margin);
      margin-bottom: var(--base-margin);
      @include mq('min-md') {
        padding-bottom: var(--base-margin-big);
        margin-bottom: var(--base-margin-big);
      }
    }

    &__description {
      display: block;
      font-size: 0.875rem;
      color: --rgba(rock);
      padding-top: var(--base-margin-small);
      padding-bottom: var(--base-margin);
    }

    &__action {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }
  }
}
</style>
