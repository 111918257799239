<template>
  <ModuleStep :index="4" :prev-step="prevStep">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_pets"
      submit-icon="arrow-right"
      @done="submit"
    >
      <FormSection class="step-1">
        <Headline :level="1">
          {{ $t('pages.will.guardians.doYouHaveAnyPets') }}
        </Headline>
        <LargeRadioButtons
          id="hasPets"
          v-model="willMeta.has_pets"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_pets" class="step-2">
        <Headline :level="2">
          {{ $t('pages.will.guardians.myPetsAre') }}
        </Headline>
        <MetaSlot
          v-for="(pet, index) in pets"
          :key="index"
          v-slot="{ meta }"
          :meta="pet.meta"
        >
          <Box background="white" border="white">
            <div class="grid grid--flush grid--middle grid--small">
              <div class="cell cell__9/12">
                <Headline big :level="3">
                  {{ meta.name }}
                </Headline>
                <p class="--big flush">{{ meta.type }}</p>
              </div>
              <div class="cell cell__3/12">
                <Buttons layout="column">
                  <RuButton level="secondary" @click="removePet(pet)">
                    {{ $t('forms.labels.remove') }}
                  </RuButton>
                  <RuButton level="secondary" @click="editPet(pet)">
                    {{ $t('forms.labels.edit') }}
                  </RuButton>
                </Buttons>
              </div>
            </div>
          </Box>
        </MetaSlot>
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addPet">
            + {{ $t('forms.labels.addAPet') }}
          </RuButton>
        </Buttons>
      </FormSection>
      <PetPanel />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import MetaSlot from '@/components/MetaSlot';
import ModuleStep from '@/components/templates/ModuleStep';
import PetPanel from '@/components/PetPanel';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Box from '@/components/atoms/Box';

import { pets, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGuardiansYourPets',
  components: {
    AdiForm,
    FormSection,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    PetPanel,
    Headline,
    Buttons,
    RuButton,
    Box,
  },
  mixins: [pets, user, will],
  computed: {
    isComplete() {
      return !!(this.willMeta.has_pets === false || this.pets.length);
    },
    nextStep() {
      return this.willMeta.has_pets ? '/will/guardians/pet-guardians' : '/will';
    },
    prevStep() {
      let to = '/will/guardians/your-children';

      if (this.willMeta.has_children) {
        if (this.willMeta.has_backup_guardian !== null) {
          to = '/will/guardians/backup';
        } else if (this.willMeta.has_primary_guardian !== null) {
          to = '/will/guardians/primary';
        }
      }

      return to;
    },
  },
  methods: {
    async submit() {
      if (this.willMeta.has_pets === false && this.pets.length) {
        await this.removeAllPets();

        this.willMeta.has_pet_guardians = null;
        this.willMeta.pet_care_fund = '';
        await this.updateWillMeta();
      }

      if (!this.willMeta.has_pets) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 2 (Families)',
        });
      }
      const path = this.localePath(
        this.willMeta.has_pets ? '/will/guardians/pet-guardians' : '/will'
      );
      this.$router.push({
        path,
      });
    },
  },
};
</script>
