<template>
  <ValidationProvider
    v-slot="{ errors }"
    ref="provider"
    :vid="id"
    :disabled="!rules"
    :mode="customInteraction"
    :rules="rules"
    tag="div"
  >
    <input
      v-if="type !== 'textarea'"
      :id="id"
      v-model="modelValue"
      class="input"
      :class="[states, { 'input-error': errors.length }, classes]"
      :disabled="disabled"
      :max="max"
      :min="min"
      :maxlength="maxlength"
      :name="name"
      :placeholder="placeholder"
      :step="step"
      :inputmode="inputmode"
      :type="type"
      @input="input"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <textarea
      v-else
      :id="id"
      v-model="modelValue"
      class="input"
      :class="[states, { 'input-error': errors.length }, classes]"
      :disabled="disabled"
      :name="name"
      :inputmode="inputmode"
      @input="input"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <div
      v-if="errors.length && showError"
      class="errors mt-1 text-base text-red-300"
    >
      {{ getErrorMessage(errors) }}
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'ComponentsBaseInput',
  components: {
    ValidationProvider,
  },
  props: {
    classes: {
      default: null,
      type: String,
    },
    compact: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    ignoreDisabled: {
      default: false,
      type: Boolean,
    },
    error: {
      default: false,
      type: Boolean,
    },
    showError: {
      default: true,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    max: {
      default: null,
      type: Number,
    },
    min: {
      default: null,
      type: Number,
    },
    maxlength: {
      default: null,
      type: Number,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      default: '',
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
    step: {
      default: null,
      type: Number,
    },
    type: {
      default: 'text',
      type: String,
    },
    inputmode: {
      default: null,
      type: String,
    },
    value: {
      default: '',
      type: [String, Number],
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    states() {
      return {
        'input-compact': this.compact,
        'input-disabled': this.disabled && !this.ignoreDisabled,
        'input-filled': this.filled,
      };
    },
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  mounted() {
    this.$nuxt.$on(
      'resetInputs',
      (id) => id === this.id && (this.modelValue = '')
    );
  },
  methods: {
    customInteraction({ errors }) {
      if (errors.length) {
        return {
          on: ['input'],
        };
      }
      return {
        on: ['blur', 'input'],
      };
    },
    input() {
      if (this.inputmode === 'decimal') {
        this.modelValue = this.modelValue.replace(/[^\d.]/g, '');
      }
      this.$emit('input', this.modelValue);
    },
    getErrorMessage(errors) {
      const lastErrorKey = errors[errors.length - 1];
      if (lastErrorKey.split(':').length > 1) {
        const [key, args] = lastErrorKey.split(':');
        return this.$t(key, { args });
      } else {
        return this.$t(lastErrorKey);
      }
    },
  },
};
</script>
