<template>
  <div>
    <div class="flex items-center justify-between h-12 mb-8">
      <Headline flush :level="1"> End-of-Life Categories </Headline>
      <BaseButton
        class="btn btn-slate px-4 py-3"
        @click="setShowNewEndOfLifeCategoryModal(true)"
      >
        <div class="flex items-center">
          <span class="text-white"> + Add End-of-Life Category </span>
        </div>
      </BaseButton>
    </div>

    <AdminCard tight>
      <AdminDataTable
        v-if="endOfLifeCategories"
        :columns="columns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="pageOfEndOfLifeCategoryTree.length"
      >
        <template #rows>
          <AdminDataTableRow
            v-for="(endOfLifeCategory, index) in pageOfEndOfLifeCategoryTree"
            :key="index"
            :to="
              localePath(
                `/admin/end-of-life-categories/${endOfLifeCategory.id}`
              )
            "
            class="group"
          >
            <AdminDataTableCell>
              {{ endOfLifeCategory.name }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{
                endOfLifeCategories[endOfLifeCategory.parentCategoryId]?.name
              }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(endOfLifeCategory.createdAt, 'dd mmm yyyy') }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(endOfLifeCategory.updatedAt, 'dd mmm yyyy') }}
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="endOfLifeCategoriesPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevEndOfLifeCategoriesPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="pageOfEndOfLifeCategoryTree.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextEndOfLifeCategoriesPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>

    <AdminNewEndOfLifeCategoryModal
      v-if="endOfLifeCategoryModalIsVisible"
      @close="setShowNewEndOfLifeCategoryModal(false)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import endOfLife from '@/mixins/end-of-life';

import BaseButton from '@/components/BaseButton';
import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminNewEndOfLifeCategoryModal from '@/components/admin/NewEndOfLifeCategoryModal';
import BaseIcon from '@/components/BaseIcon';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliateAdminEndOfLifeCategoriesIndex',
  components: {
    BaseButton,
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminNewEndOfLifeCategoryModal,
    BaseIcon,
    AdminDataTableButton,
    Headline,
  },
  mixins: [endOfLife],
  layout: 'admin',
  data() {
    return {
      columns: ['Name', 'Parent Category', 'Created at', 'Updated at'],
      pageSize: 10,
      endOfLifeCategoryModalIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('admin', ['endOfLifeCategoriesPageIndex']),
    ...mapGetters('end-of-life-categories', [
      'endOfLifeCategories',
      'endOfLifeCategorySlugsToIds',
    ]),
    pageOfEndOfLifeCategoryTree() {
      const offset = this.endOfLifeCategoriesPageIndex * this.pageSize;

      const flattenTree = (tree) => {
        const flatten = (category) => {
          const { subcategories, ...rest } = category;
          return [
            rest,
            ...(subcategories ? subcategories.flatMap(flatten) : []),
          ];
        };
        return tree.flatMap(flatten);
      };

      const endOfLifeCategoryTreeFlattened = flattenTree(
        this.endOfLifeCategoryTree
      );

      return endOfLifeCategoryTreeFlattened.length
        ? endOfLifeCategoryTreeFlattened.slice(offset, offset + this.pageSize)
        : [];
    },
  },
  created() {
    this.getEndOfLifeCategories();
  },
  methods: {
    ...mapActions('end-of-life-categories', ['getEndOfLifeCategories']),
    ...mapActions('admin', [
      'nextEndOfLifeCategoriesPage',
      'prevEndOfLifeCategoriesPage',
    ]),
    setShowNewEndOfLifeCategoryModal(isVisible) {
      this.endOfLifeCategoryModalIsVisible = isVisible;
    },
    getParentCategoryNameFromId(id) {
      const parentCategory = this.endOfLifeCategories.find(
        (category) => category.id === id
      );
      return parentCategory ? parentCategory.name : null;
    },
  },
};
</script>
