<template>
  <div class="ru:data-table">
    <table :style="styles" class="ru:data-table__table">
      <caption v-if="description">
        {{
          description
        }}
      </caption>
      <thead v-if="columns.length">
        <tr>
          <th
            v-if="deleteButton || editButton"
            class="ru:data-table__controls"
          />
          <th v-for="(column, columnIndex) in columns" :key="columnIndex">
            {{ column.text }}
          </th>
        </tr>
      </thead>
      <tbody v-if="rows.length">
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
          <td v-if="deleteButton || editButton" class="ru:data-table__controls">
            <RuButton
              v-if="editButton"
              :background="theme"
              color="white"
              size="small"
              round
              @click="$emit('edit', rowIndex)"
            >
              <BaseIcon
                id="pencil"
                size="small"
                classes="ru:data-table__controls-icon"
              />
            </RuButton>
            <RuButton
              v-if="deleteButton"
              background="orange"
              color="white"
              size="small"
              round
              @click="$emit('delete', rowIndex)"
            >
              <BaseIcon
                id="trash"
                size="small"
                classes="ru:data-table__controls-icon"
              />
            </RuButton>
          </td>
          <td v-for="(column, columnIndex) in columns" :key="columnIndex">
            {{ tranformCellData(row[column.key]) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { hexToRgb } from '@/utilities';
import BaseIcon from '@/components/BaseIcon';
import RuButton from '@/components/atoms/Button';
import {
  UNKNOWN_DATE,
  UNKNOWN_NUMBER,
} from '@/utilities/cremations/form-constants';

export default {
  name: 'ComponentsAtomsDataTable',
  components: {
    BaseIcon,
    RuButton,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    accent: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accentStyle() {
      if (this.accent) {
        if (this.accent.includes('#')) {
          return hexToRgb(this.accent);
        }
        return `var(--${this.accent})`;
      }
      return null;
    },
    themeStyle() {
      if (this.theme) {
        if (this.theme.includes('#')) {
          return hexToRgb(this.theme);
        }
        return `var(--${this.theme})`;
      }
      return null;
    },
    styles() {
      return {
        '--accent': this.accentStyle,
        '--theme': this.themeStyle,
      };
    },
  },
  methods: {
    tranformCellData(data) {
      if (data === UNKNOWN_DATE || data === UNKNOWN_NUMBER) {
        return this.$t('components.dataTable.unknown');
      }
      return data;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &data-table {
    overflow-x: scroll;
    margin-bottom: var(--base-margin);
    border: 1px solid --rgba(concrete);
    border-radius: 0.375rem;

    &__table {
      background: --rgba(white);
      min-width: 100%;
      font-size: 0.875rem;
      margin-bottom: var(--base-margin);

      caption {
        height: 0;
        overflow: hidden;
      }

      th {
        text-align: left;
        background: --rgba(accent);
      }

      td,
      th {
        padding: 1rem;
        border-bottom: 1px solid --rgba(concrete);
        line-height: 1;
        white-space: nowrap;

        &:not(:last-child) {
          border-right: 1px solid --rgba(concrete);
        }
      }

      tr:last-child td {
        padding-bottom: 1.5rem;
        border-bottom: 0;
      }
    }

    &__controls {
      &:is(th, td) {
        width: 0;
      }

      #{$ru}button {
        position: relative;
        margin: 0;

        &:first-child {
          margin-right: 0.25rem;
        }
      }

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
