<template>
  <nuxt-child />
</template>

<script>
export default {
  name: 'PagesAffiliateAdminUsers',
  layout: 'admin',
  middleware: ['adminAuthenticated'],
};
</script>
