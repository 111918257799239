<template>
  <transition
    appear
    mode="out-in"
    :duration="250"
    name="ru:wiggle-fade-squish"
    @after-enter="onAfterEnter"
  >
    <div class="ru:snackbar" :style="styles">
      <svg width="24" height="24" class="ru:snackbar__icon">
        <use
          v-bind="{
            'xlink:href': `${require('~/assets/svg/ru-icons.svg')}#${type}`,
          }"
        />
      </svg>
      {{ text }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ComponentsMoleculesSnackbar',
  props: {
    type: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundStyle() {
      switch (this.type) {
        case 'success':
          return 'grass';
        case 'warning':
          return 'apricot';
        case 'error':
          return 'red';
        default:
          return 'azure';
      }
    },
    styles() {
      return {
        '--background': `var(--${this.backgroundStyle})`,
      };
    },
  },
  methods: {
    onAfterEnter() {
      setTimeout(() => {
        this.$emit('closeSnackbar');
      }, 4000);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &snackbar {
    --width: 17.5rem;
    padding: 0.5rem 1rem;
    position: fixed;
    z-index: $z-stack;
    bottom: 1rem;
    width: var(--width);
    left: #{calc(50% - var(--width) / 2)};
    background: --rgba(background);
    border-radius: var(--border-radius);
    color: --rgba(white);
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 0.5rem;
    }
  }
}
</style>
