<template>
  <svg
    :width="dimensions"
    :height="dimensions"
    class="stroke-current"
    :class="classes"
  >
    <use
      v-bind="{
        'xlink:href': `${require('~/assets/svg/icons.svg')}#${id}`,
      }"
    />
  </svg>
</template>

<script>
export default {
  name: 'ComponentsBaseIcon',
  props: {
    id: {
      type: String,
      required: true,
    },
    classes: {
      default: null,
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
  },
  computed: {
    dimensions() {
      switch (this.size) {
        case 'large':
          return 30;
        case 'small':
          return 16;
        case 'tiny':
          return 12;
        default:
          return 24;
      }
    },
  },
};
</script>
