<template>
  <button
    class="ru:tab-title"
    :class="[isSelectedClass, `--${variant}`]"
    @click="selectTab"
  >
    {{ title }}
    <span v-if="subtitle" class="ru:tab-title__subtitle">
      {{ subtitle }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'ComponentsAtomsTabTitle',
  props: {
    isSelected: {
      required: true,
      type: Boolean,
    },
    variant: {
      default: 'pill',
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      default: null,
      type: [String, Number],
    },
  },
  computed: {
    isSelectedClass() {
      return {
        '--selected': this.isSelected,
      };
    },
  },
  methods: {
    selectTab({ target }) {
      target.blur();
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &tab-title {
    transition: --out();
    &:is(#{$mf}selected) {
      transition: --in();
    }

    &:is(#{$mf}pill) {
      padding: 0.5rem 1rem;
      border-radius: var(--border-radius);
      color: --rgba(rock);
      transition: --out();

      &:is(#{$mf}selected) {
        transition: --in();
        color: --rgba(basalt);
        background: --rgba(lighter);
      }
    }

    &:is(#{$mf}underline) {
      padding-bottom: var(--base-margin-small);
      border-bottom: 4px solid transparent;
      color: --rgba(rock);
      @include mq('min-md') {
        margin-right: 1rem;
      }
      @include mq('min-lg') {
        margin-right: 2.5rem;
      }

      &:is(#{$mf}selected) {
        border-color: --rgba(theme);
        color: --rgba(basalt);
      }
    }
  }
}
</style>
