<template>
  <td class="h-16 first-child:pl-8 last-child:pr-8">
    <div class="text-slate-100" :class="classes">
      <slot />
    </div>
  </td>
</template>

<script>
export default {
  name: 'ComponentsAdminDataTableCell',
  props: {
    full: {
      default: false,
      type: Boolean,
    },
    truncate: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        'max-w-2xs': !this.full,
        truncate: this.truncate,
      };
    },
  },
};
</script>
