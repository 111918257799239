<template>
  <div
    class="relative md:flex mb-2 last-child:mb-0 text-base"
    :class="{
      'w-full': !halfWidth && !inline,
      'w-1/2': halfWidth,
      'mb-6': loose,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ComponentsFormRow',
  props: {
    halfWidth: {
      default: false,
      type: Boolean,
    },
    inline: {
      deafult: false,
      type: Boolean,
    },
    loose: {
      deafult: false,
      type: Boolean,
    },
  },
};
</script>
