var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ru:container ru:container--snug ru:invites"},[_c('Headline',{attrs:{"level":1}},[_vm._v(_vm._s(_vm.$t('pages.invites.headline')))]),_vm._v(" "),_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('pages.invites.description'))+"\n  ")]),_vm._v(" "),_c('Tip',[_vm._v("\n    "+_vm._s(_vm.$t('pages.invites.tipText'))+"\n  ")]),_vm._v(" "),_c('Tabs',{attrs:{"tabs":[
      {
        name: 'gifts',
        title: _vm.$t('pages.invites.giftsTab'),
      },
      {
        name: 'sent',
        title: _vm.$t('pages.invites.sentTab'),
      },
      {
        name: 'history',
        title: _vm.$t('pages.invites.historyTab'),
      },
      {
        name: 'referrals',
        title: _vm.$t('pages.invites.referralsTab'),
      },
    ],"variant":"pill"},scopedSlots:_vm._u([{key:"gifts",fn:function(){return [_c('SendGifts')]},proxy:true},{key:"sent",fn:function(){return [_c('SentGifts')]},proxy:true},{key:"history",fn:function(){return [_c('Box',{attrs:{"background":"white"}},[_c('Headline',{attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('pages.invites.orderHistoryHeadline')))]),_vm._v(" "),_c('Orders')],1)]},proxy:true},{key:"referrals",fn:function(){return [_c('Box',{attrs:{"background":"white"}},[_c('Headline',{attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('pages.invites.referralsHeadline')))]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.invites.referralsText'))+"\n        ")])],1),_vm._v(" "),_c('Invite')]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }