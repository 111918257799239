<template>
  <span
    v-tooltip.bottom-start="{
      classes,
      content,
      hideOnTargetClick: false,
      popperOptions,
    }"
    class="relative inline-block leading-none cursor-help"
    @click="setShowGlossaryPanel(true)"
  >
    <slot />
    <span
      class="absolute top-100 left-0 right-0 z-under inline-block overflow-hidden text-teal-200"
    >
      <svg
        class="w-full"
        height="6"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMin"
      >
        <path
          class="stroke-current"
          d="M0 3h9999"
          fill="none"
          stroke-width="2"
          stroke-dasharray="3,3"
        />
      </svg>
    </span>
  </span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ComponentsGlossaryTooltip',
  props: {
    term: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      return [
        'max-w-lg',
        'px-4',
        'py-3',
        'rounded',
        'bg-white',
        'shadow',
        'text-left',
        'text-charoal-300',
        'no-arrow',
      ];
    },
    content() {
      return `
        ${this.$t('glossary')[this.term].desc}
        <br>
        <div class="mt-2 font-bold text-sm text-teal-300 uppercase">
          ${this.$t('texts.sentences.clickToOpenGlossary')}
        <div>`;
    },
    popperOptions() {
      return {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      };
    },
  },
  methods: {
    ...mapActions('glossary', ['setShowGlossaryPanel']),
  },
};
</script>
