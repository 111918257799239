<template>
  <label class="ru:file-control">
    <slot />
    <input
      ref="fileControl"
      type="file"
      :accept="accept"
      :multiple="multiple"
      @change="filesChange"
    />
  </label>
</template>

<script>
export default {
  name: 'ComponentsAtomsFileControl',
  props: {
    accept: {
      type: String,
      default: null,
    },
    filesType: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    filesChange() {
      this.$emit('filesChange', this.$refs.fileControl.files, this.filesType);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &file-control {
    display: block;
    position: relative;
    overflow: hidden;
    text-align: center;
    cursor: pointer;

    #{$ru}icon {
      margin: 0 auto;
    }

    [type='file'] {
      position: absolute;
      transform: translateY(-100%);
      top: 0;
      left: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
