<template>
  <ModuleStep :index="4" prev-step="/will/about-yourself/date-of-birth">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="done"
    >
      <FormSection>
        <Headline :level="1">
          {{
            $t('pages.will.aboutYourSelf.questions.currentResidentialAddress')
          }}
        </Headline>
        <Tip>
          {{ $t('pages.will.aboutYourSelf.australianAddressTip') }}
        </Tip>
        <FormRow>
          <TextInput
            id="address_street"
            v-model="willMeta.address_street"
            :disabled="loading"
            :placeholder="$t('forms.labels.streetAddress')"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="address_suburb"
            v-model="willMeta.address_suburb"
            :disabled="loading"
            :placeholder="$t('forms.labels.suburb')"
          />
        </FormRow>
        <FormRow>
          <SelectInput
            id="address_state"
            v-model="willMeta.address_state"
            class="w-full mb-2 md:w-1/2 md:mr-2 md:mb-0"
            :disabled="loading"
            :placeholder="$t('forms.labels.state')"
            :options="australianStates"
            searchable
          />
          <TextInput
            id="address_postcode"
            v-model="willMeta.address_postcode"
            class="w-full md:w-1/2"
            :disabled="loading"
            :placeholder="$t('forms.labels.postCode')"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import SelectInput from '@/components/SelectInput';
import TextInput from '@/components/TextInput';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { getUserFullAddress } from '@/utilities';
import { beneficiaries, user, will, charities } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfAddress',
  components: {
    AdiForm,
    FormRow,
    FormSection,
    ModuleStep,
    SelectInput,
    TextInput,
    Tip,
    Headline,
  },
  mixins: [beneficiaries, user, will, charities],
  computed: {
    ...mapGetters('ui', ['australianStates']),
    ...mapFields('signup', ['meta', 'meta.referral_charity']),
    isComplete() {
      return !!(
        this.willMeta.address_street &&
        this.willMeta.address_suburb &&
        this.willMeta.address_state &&
        this.willMeta.address_postcode
      );
    },
  },
  methods: {
    async done() {
      if (!this.hasBeneficiaries) {
        await this.replaceNationalCharitiesWithRegionalCharities();
      }

      const willMeta = this.willMeta;
      this.$nuxt.$emit('sendTrackingAttributes', {
        address: getUserFullAddress(willMeta),
      });

      // Some feature flags depend on the user's address, so we should refetch them when this changes.
      this.$ff.refreshFeatureFlags();

      this.$router.push({
        path: this.localePath('/will/about-yourself/partner'),
      });
    },
  },
};
</script>
