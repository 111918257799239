<template>
  <div>
    <span v-if="label" class="input-label" :class="spanClasses">
      {{ label }}
    </span>
    <div class="flex w-full">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="flex justify-start"
      >
        <label
          class="inline-flex items-center mr-6 cursor-pointer"
          :class="labelClasses[index]"
          :for="ids[index]"
        >
          <input
            :id="ids[index]"
            v-model="modelValue"
            :name="id"
            type="radio"
            class="hidden"
            :value="option.value"
            aria-hidden="true"
            @change="onChange"
          />
          <svg
            class="svg cursor-pointer"
            preserveAspectRatio="xMidYMid meet"
            :height="svgSize"
            :width="svgSize"
            viewBox="0 0 30 30"
          >
            <circle
              class="outline"
              cx="15"
              cy="15"
              r="13"
              fill="#EDF0EF"
              stroke="#3E4542"
              stroke-width="2"
            />
            <circle
              class="core"
              cx="15"
              cy="15"
              r="8"
              :fill="checkedColor[index]"
            />
          </svg>
          <span class="ml-2 text-charcoal-100"> {{ option.text }}</span>
        </label>
      </div>
    </div>

    <div v-if="error" class="absolute top-100 mt-2 text-red-300">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsBaseRadio',
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      // [{ label, value }, ...]
      type: Array,
      required: true,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
    label: {
      default: '',
      type: String,
    },
    error: {
      default: '',
      type: String,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    labelClasses() {
      return this.options.map((option) => {
        const selected = option.value === this.modelValue;
        return {
          'group-hover:btn-white-hover': !selected,
          'text-lg': !this.compact,
        };
      });
    },
    spanClasses() {
      return this.compact ? 'text-base mt-0 mb-3' : 'mb-6';
    },
    svgSize() {
      return this.compact ? '20px' : '30px';
    },
    checkedColor() {
      return this.options.map((option) => {
        const selected = option.value === this.modelValue;
        return selected ? (this.error ? '#E65B4A' : '#00DABF') : '#EDF0EF';
      });
    },
    ids() {
      return this.options.map((option) => `${this.id}_${option.value}`);
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.modelValue, this);
    },
  },
};
</script>
