<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="dimensions"
    :height="dimensions"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M10.854 21.564V24C4.764 23.397 0 18.26 0 12S4.764.603 10.854 0v2.436A9.634 9.634 0 0 0 2.412 12a9.634 9.634 0 0 0 8.442 9.564Z"
      style="transform-origin: center center"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 0 0"
        to="360 0 0"
        dur="0.9s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>

<script>
export default {
  name: 'ComponentsLoadingRing',
  computed: {
    dimensions() {
      switch (this.size) {
        case 'huge':
          return 40;
        case 'large':
          return 32;
        case 'small':
          return 20;
        case 'tiny':
          return 12;
        default:
          return 24;
      }
    },
  },
};
</script>
