<template>
  <div class="ru:container ru:container--snug ru:account-settings">
    <Headline :level="1">{{ $t('pages.account.headline') }}</Headline>
    <p>{{ $t('pages.account.manageAccountDetails') }}</p>
    <Tip>
      {{ $t('pages.account.startLiveChat') }}
    </Tip>
    <Headline :level="2">
      {{ $t('forms.labels.yourEmail') }}
    </Headline>
    <Box background="white">
      <AdiForm
        v-slot="{ loading }"
        submit-icon="check"
        submit-classes="w-full max-w-sm"
        :submit-label="$t('forms.labels.updateEmail')"
        @submit="setShowConfirmPasswordModal(true)"
      >
        <FormSection>
          <FormRow>
            <div class="w-full">
              <TextInput
                id="email"
                v-model="email"
                :disabled="loading"
                :label="$t('forms.labels.email')"
                rules="required|email"
                type="email"
              />
              <div class="flex items-center mt-4 leading-none">
                <div
                  class="flex justify-center items-center w-5 h-5 rounded-full mr-2 bg-teal-200 text-white"
                  :class="verified ? 'bg-teal-200' : 'bg-orange-200'"
                >
                  <BaseGlyph :id="verified ? 'tick-small' : 'question-mark'" />
                </div>
                <span class="opacity-75 text-slate-100">
                  {{
                    verified
                      ? $upperFirst($t('texts.words.verified'))
                      : $upperFirst($t('texts.words.unverified'))
                  }}
                </span>
                <BaseButton
                  v-if="!verified"
                  class="ml-2 text-teal-300 underline"
                  @click="resendWelcomeEmail"
                >
                  {{
                    resendTimeout
                      ? $t('texts.sentences.sent')
                      : $t('texts.sentences.resendVerificationEmail')
                  }}
                </BaseButton>
                <div v-if="resendLoading" class="ml-2">
                  <LoadingRing />
                </div>
              </div>
              <p
                v-if="welcomeEmailSent"
                class="mt-4 font-medium text-orange-400"
              >
                {{ $t('pages.account.checkSpamFolder') }}
              </p>
            </div>
          </FormRow>
        </FormSection>
      </AdiForm>
    </Box>
    <Headline :level="2"> {{ $t('pages.account.yourDetails') }} </Headline>
    <Box background="white">
      <AdiForm
        v-slot="{ loading }"
        submit-icon="check"
        :mutation="UPDATE_WILL_MUTATION"
        :variables="UPDATE_WILL_VARS"
        submit-classes="w-full max-w-sm"
        :submit-label="$t('forms.labels.saveChanges')"
        @done="updateDetails"
      >
        <FormSection>
          <FormRow>
            <TextInput
              id="name_first"
              v-model="willMeta.name_first"
              class="w-full"
              :disabled="loading"
              :label="$t('forms.labels.firstName')"
              rules="required"
            />
            <TextInput
              id="name_middle"
              v-model="willMeta.name_middle"
              class="flex-shrink md:ml-4"
              :disabled="loading"
              :label="$t('forms.labels.middleName')"
              placeholder="None"
            />
          </FormRow>
          <FormRow>
            <TextInput
              id="name_last"
              v-model="willMeta.name_last"
              class="md:w-1/2"
              :disabled="loading"
              :label="$t('forms.labels.lastName')"
              rules="required"
            />
          </FormRow>
          <FormRow>
            <TextInput
              id="phone"
              v-model="willMeta.phone_number"
              class="md:w-1/2"
              :disabled="loading"
              :label="$t('forms.labels.phoneNumber')"
              type="tel"
              rules="phone"
            />
          </FormRow>
          <FormRow>
            <TextInput
              id="address_street"
              v-model="willMeta.address_street"
              class="md:w-1/2"
              :disabled="loading"
              :label="$t('forms.labels.streetAddress')"
              rules="required"
            />
          </FormRow>
          <FormRow>
            <TextInput
              id="address_suburb"
              v-model="willMeta.address_suburb"
              class="md:w-1/2"
              :disabled="loading"
              :label="$t('forms.labels.suburb')"
              rules="required"
            />
            <div class="flex-shrink-0 md:w-56 md:ml-4">
              <SelectInput
                id="address_state"
                v-model="willMeta.address_state"
                :options="australianStates"
                :disabled="loading"
                :label="$t('forms.labels.state')"
                searchable
              />
            </div>
            <div class="flex-shrink-0 md:w-32 md:ml-4">
              <TextInput
                id="address_postcode"
                v-model="willMeta.address_postcode"
                :disabled="loading"
                :label="$t('forms.labels.postCode')"
                rules="required"
              />
            </div>
          </FormRow>
        </FormSection>
      </AdiForm>
    </Box>
    <Headline :level="2">{{ $t('pages.account.changePassword') }}</Headline>
    <Box background="white">
      <ChangePassword />
    </Box>
    <Headline :level="2">{{ $t('pages.account.updateBilling') }}</Headline>
    <Box background="white">
      <SubscriptionStatusCard primary-text="Unlimited Will Updates" />
    </Box>
    <template v-if="showNotifyCharities">
      <Headline :level="2">{{
        $t('pages.account.charityContactConsent')
      }}</Headline>
      <Box background="white">
        <AdiForm
          v-slot="{ loading }"
          submit-icon="check"
          :mutation="UPDATE_WILL_NOTIFY_CHARITY_MUTATION"
          :variables="UPDATE_WILL_NOTIFY_CHARITY_VARS"
          submit-classes="w-full max-w-sm"
          submit-label="Save changes"
          @done="updateWills"
        >
          <FormSection class="max-w-3xl">
            <Tip>
              {{ $t('pages.account.tipShareDetails') }}
            </Tip>
            <LargeRadioButtons
              id="notifyCharities"
              v-model="willMeta.notify_charities"
              :options="[
                {
                  label: $t('forms.labels.yes'),
                  value: true,
                },
                {
                  label: $t('forms.labels.no'),
                  value: false,
                },
              ]"
            />
          </FormSection>
        </AdiForm>
      </Box>
    </template>
    <template v-if="showExecutorTermsAndConditions">
      <Headline :level="2">{{
        $t('pages.account.executorTermsAndConditions')
      }}</Headline>
      <Box background="white">
        <div class="ru:account-settings__executor-terms">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-charcoal-400"
            v-html="
              $t('pages.will.executors.professionalTerms.termsConditions')
            "
          />
          <!--eslint-enable-->
        </div>
      </Box>
    </template>
    <BaseModal v-if="showConfirmPasswordModal" @close="closeModal">
      <div
        class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-teal-300 text-white"
      >
        <BaseGlyph id="lock" />
      </div>
      <p class="max-w-xs mb-4 text-3xl text-teal-300">
        {{ $t('pages.account.modalConfirmPassword') }}
      </p>
      <AdiForm
        v-slot="{ loading }"
        submit-icon="check"
        auto-focus
        :mutation="UPDATE_EMAIL_MUTATION"
        :variables="updateEmailVariables"
        :submit-label="$t('forms.labels.confirmPassword')"
        @done="updateToken"
      >
        <FormSection>
          <PasswordInput
            id="confirmPassword"
            v-model="confirmPassword"
            :disabled="loading"
            :label="$t('forms.labels.password')"
            :placeholder="$t('forms.placeholders.enterPassword')"
          />
        </FormSection>
      </AdiForm>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RESEND_WELCOME_EMAIL from '@/graphql/mutations/ResendWelcomeEmail';
import UPDATE_EMAIL_MUTATION from '@/graphql/mutations/UpdateEmail';
import UPDATE_PASSWORD_MUTATION from '@/graphql/mutations/UpdatePassword';
import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '@/graphql/mutations/UpdateNotifyCharityOfWill';

import BaseButton from '@/components/BaseButton';
import BaseGlyph from '@/components/BaseGlyph';
import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import LoadingRing from '@/components/LoadingRing';
import PasswordInput from '@/components/PasswordInput';
import SelectInput from '@/components/SelectInput';
import SubscriptionStatusCard from '@/components/SubscriptionStatusCard';
import TextInput from '@/components/TextInput';
import Tip from '@/components/molecules/Tip';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';
import Box from '@/components/atoms/Box';
import ChangePassword from '@/components/organisms/ChangePassword';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateDashboardAccountSettings',
  components: {
    BaseButton,
    BaseGlyph,
    BaseModal,
    AdiForm,
    FormRow,
    FormSection,
    LoadingRing,
    PasswordInput,
    SelectInput,
    SubscriptionStatusCard,
    TextInput,
    Tip,
    LargeRadioButtons,
    Headline,
    Box,
    ChangePassword,
  },
  mixins: [user, will],
  data() {
    return {
      UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
      UPDATE_EMAIL_MUTATION,
      UPDATE_PASSWORD_MUTATION,
      confirmPassword: '',
      email: this.$store.state.email,
      resendEmailText: 'Resend verification email',
      resendLoading: false,
      resendTimeout: null,
      validPassword: false,
      welcomeEmailSent: false,
      hasChangedPassword: false,
    };
  },
  computed: {
    ...mapGetters(['verified']),
    ...mapGetters('ui', ['australianStates', 'showConfirmPasswordModal']),
    showNotifyCharities() {
      return this.willMeta.notify_charities !== null;
    },
    showExecutorTermsAndConditions() {
      return (
        ['professional', 'friendsFamilyAndProfessional'].includes(
          this.willMeta.executors_option
        ) &&
        this.willMeta.professional_executor_payment_check &&
        this.willMeta.professional_executor_court_check &&
        this.willMeta.professional_executor_terms_check
      );
    },
    updateEmailVariables() {
      return {
        currentPassword: this.confirmPassword,
        newEmail: this.email,
      };
    },
    UPDATE_WILL_NOTIFY_CHARITY_VARS() {
      return {
        userId: this.userId,
        value: this.willMeta.notify_charities,
      };
    },
  },
  methods: {
    ...mapActions(['setEmail', 'setToken']),
    ...mapActions('ui', ['setShowConfirmPasswordModal']),
    closeModal() {
      this.setShowConfirmPasswordModal(false);
      this.confirmPassword = '';
      this.$nuxt.$emit('unlockForm');
    },
    async resendWelcomeEmail() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✉️ Resend Verification',
      });

      this.resendLoading = true;

      await this.$apollo
        .mutate({
          mutation: RESEND_WELCOME_EMAIL,
          variables: {
            email: this.email,
          },
        })
        .then(({ data }) => {
          this.resendLoading = false;

          if (data.resendWelcomeEmail && data.resendWelcomeEmail.success) {
            this.welcomeEmailSent = true;

            this.resendTimeout = window.setTimeout(() => {
              this.resendTimeout = null;
            }, 3000);
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    updateWills() {
      this.refetchWill();
      this.$nuxt.$emit('unlockForm');
      this.$nuxt.$emit('clearErrors');
    },
    updateDetails() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        name: `${this.willMeta.name_first} ${this.willMeta.name_last}`,
      });

      this.$ff.refreshFeatureFlags();

      this.$nuxt.$emit('unlockForm');
    },
    async updateToken({
      data: {
        updateEmail: { token, user },
      },
    }) {
      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setEmail(user.email);
          this.setToken(token);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &account-settings {
    &__executor-terms {
      height: 21rem;
      overflow-y: scroll;
      padding-right: var(--base-margin);
    }
  }
}
</style>
