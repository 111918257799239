<template>
  <div class="ru:tabs" :class="overFlowClass">
    <div
      ref="tabs"
      class="ru:tabs__titles"
      :class="`--${variant}`"
      @scroll="onScroll"
    >
      <button
        v-if="showLeft"
        class="ru:tabs__scroll-indicator --left"
        @click="scrollToLeft"
      >
        <Icon id="chevron-left" size="small" />
      </button>
      <TabTitle
        v-for="(tab, index) in tabs"
        :key="index"
        :variant="variant"
        :is-selected="isSelected(index)"
        :title="tab.title"
        :subtitle="tab.subtitle ? '' + tab.subtitle : ''"
        @click="selectTab(index)"
      />
    </div>
    <button class="ru:tabs__scroll-indicator --right" @click="scrollToRight">
      <Icon id="chevron-right" size="small" />
    </button>
    <transition :name="`ru:wiggle-horizontal-${transitionDirection}`">
      <template v-for="(tab, index) in tabs">
        <TabContent v-if="isSelected(index)" :key="index">
          <slot :name="tab.name" />
        </TabContent>
      </template>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'throttle-debounce';

import TabContent from '@/components/atoms/TabContent';
import TabTitle from '@/components/atoms/TabTitle';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ComponentsMoleculesTabs',
  components: { TabTitle, TabContent, Icon },
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    value: {
      default: null,
      type: Number,
    },
    variant: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      selectedTab: 0,
      transitionDirection: 'right',
      childrenWidth: -1,
      parentWidth: 0,
      scrollLeft: 0,
    };
  },
  computed: {
    overFlowClass() {
      return { '--overflow': this.childrenWidth > this.parentWidth };
    },
    showLeft() {
      return this.scrollLeft > 0;
    },
  },
  watch: {
    value(value) {
      this.selectTab(value, true);
    },
  },
  mounted() {
    if (this.value > 0) {
      this.selectedTab = this.value;
    }
    this.measureAndAdjust();
    window.addEventListener('resize', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    isSelected(tabIndex) {
      return this.selectedTab === tabIndex;
    },
    selectTab(tabIndex, externalInput) {
      if (this.selectedTab > tabIndex) {
        this.transitionDirection = 'right';
      } else {
        this.transitionDirection = 'left';
      }
      this.selectedTab = tabIndex;
      if (!externalInput) this.$emit('tabSelected', tabIndex);
    },
    measureAndAdjust: debounce(100, function () {
      this.childrenWidth = this.$refs.tabs.scrollWidth;
      this.parentWidth = this.$refs.tabs.offsetWidth;
    }),
    scrollToRight() {
      this.$refs.tabs.scrollBy({
        top: 0,
        left: +100,
        behavior: 'smooth',
      });
    },
    scrollToLeft() {
      this.$refs.tabs.scrollBy({
        top: 0,
        left: -100,
        behavior: 'smooth',
      });
    },
    onScroll: debounce(100, function () {
      this.scrollLeft = this.$refs.tabs.scrollLeft;
    }),
  },
};
</script>

<style lang="scss">
#{$ru} {
  &tabs {
    position: relative;
    flex-wrap: nowrap;

    &__scroll-indicator {
      display: none;
    }

    &:is(#{$mf}overflow) &__scroll-indicator {
      display: block;
      background: --rgba(body-background, 0.75);
      position: absolute;

      &.--left {
        top: 0.5rem;
        left: 0;
        z-index: 1;
      }

      &.--right {
        top: 0.5rem;
        right: 0;
        z-index: 1;
      }
    }

    &:is(#{$mf}overflow) &__titles {
      padding-bottom: var(--base-margin);
      overflow: auto;
    }

    &__titles {
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: var(--base-margin);
      padding-right: 1.5rem;
      white-space: nowrap;

      &:is(#{$mf}underline) {
        border-bottom: 1px solid --rgba(iron);
      }
    }
  }
}
</style>
