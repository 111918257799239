<template>
  <component
    :is="element"
    :id="id"
    :href="href"
    :to="href"
    :type="type"
    :style="styles"
    :aria-label="ariaLabel"
    :disabled="disabled"
    :rel="rel"
    :target="target"
    class="ru:button"
    :class="[
      level ? `--${level}` : '',
      theme ? `--${theme}` : '',
      align ? `--${align}` : '',
      size ? `--${size}` : '',
      active ? `--active` : '',
    ]"
    @click="click"
  >
    <span class="ru:button__slot">
      <slot />
    </span>
  </component>
</template>

<script>
import { hexToRgb } from '@/utilities';
export default {
  name: 'ComponentsAtomsButton',
  props: {
    id: {
      default: null,
      type: String,
    },
    align: {
      default: null,
      type: String,
    },
    level: {
      default: null,
      type: String,
    },
    ariaLabel: {
      default: null,
      type: String,
    },
    href: {
      default: null,
      type: String,
    },
    background: {
      default: null,
      type: String,
    },
    color: {
      default: null,
      type: String,
    },
    border: {
      default: null,
      type: String,
    },
    round: {
      default: false,
      type: Boolean,
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    size: {
      default: null,
      type: String,
      validator(value) {
        return ['small', 'large'].includes(value);
      },
    },
    wide: {
      default: false,
      type: Boolean,
    },
    buttonType: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    width: {
      default: null,
      type: String,
    },
    theme: {
      default: null,
      type: String,
    },
    active: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return null;
    },
    colorStyle() {
      if (this.color) {
        if (this.color.includes('#')) {
          return hexToRgb(this.color);
        }
        return `var(--${this.color})`;
      }
      return null;
    },
    borderColor() {
      if (this.border) {
        if (this.border.includes('#')) {
          return hexToRgb(this.border);
        }
        return `var(--${this.border})`;
      }
      return this.backgroundStyle;
    },
    radiusStyle() {
      if (this.rounded || this.round) {
        return '999px';
      } else if (this.small) {
        return 'var(--button-border-radius-small)';
      }
      return null;
    },
    widthStyle() {
      if (this.width) {
        return this.width;
      } else if (this.wide) {
        return '100%';
      } else if (this.small) {
        return 'auto';
      }
      return null;
    },
    textAlignmentStyle() {
      if (this.width) {
        return 'center';
      }
      return null;
    },
    paddingStyle() {
      if (this.icon) {
        return '0';
      } else if (this.small && !this.round) {
        return 'var(--padding-small)';
      }
      return null;
    },
    styles() {
      return {
        '--button-background': this.backgroundStyle,
        '--button-color': this.colorStyle,
        '--border-color': this.borderColor,
        '--button-border-radius': this.radiusStyle,
        '--width': this.widthStyle,
        '--text-alignment': this.textAlignmentStyle,
        '--padding': this.paddingStyle,
      };
    },
    element() {
      if (!this.isAnchor) {
        return 'button';
      }
      return this.href.startsWith('/') ? 'nuxt-link' : 'a';
    },
    isAnchor() {
      return !!this.href;
    },
    isExternal() {
      return this.isAnchor && !this.href.startsWith('/');
    },
    rel() {
      return this.isExternal ? 'noopener' : null;
    },
    target() {
      return this.isExternal ? '_blank' : null;
    },
    type() {
      return this.isAnchor ? null : this.buttonType || 'button';
    },
  },
  methods: {
    click({ target }) {
      target.blur();
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &button {
    --button-padding-y: 1rem;
    --button-padding-x: 1.5rem;
    --padding: var(--button-padding-y) var(--button-padding-x);
    padding: var(--padding);
    font-size: var(--base-font-size);

    --button-background: var(--theme);
    --button-border-radius: 0.25rem;
    --button-border-radius-small: 0.25rem;
    --button-color: var(--white);
    --button-icon-margin: 0.625rem;
    --text-alignment: left;
    --width: auto;
    text-align: var(--text-alignment);
    background: --rgba(button-background);
    border-radius: var(--button-border-radius);
    color: --rgba(button-color);
    border-width: 2px;
    border-style: solid;
    border-color: --rgba(border-color);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    font-weight: 500;
    gap: var(--button-icon-margin);
    max-width: 100%;
    line-height: 1.125rem;
    flex-basis: var(--width);

    &:active &__slot {
      transform: translate(1px, 1px);
    }

    &__slot {
      display: flex;
      gap: 1rem;
      align-items: center;

      .grid {
        width: 100%;
      }
    }

    &#{$mf}small {
      --button-padding-y: 0.75rem;
      --button-padding-x: 1.125rem;
      font-size: #{calc(var(--base-font-size) * 0.875)};
    }

    &#{$mf}large {
      --button-padding-y: 1.75rem;
      --button-padding-x: 2.5rem;
      font-size: #{calc(var(--base-font-size) * 1.25)};
    }

    &#{$mf}large &__slot {
      line-height: 1.5rem;
    }

    &#{$mf}between {
      justify-content: space-between;
    }

    &::after {
      background: --rgba(button-color);
      border-radius: var(--button-border-radius);
      transition: opacity calc(var(--transition-duration-n) * 1ms)
        var(--transition-timing-function);
      opacity: 0;
      position: absolute;
      inset: 0;
      content: '';
      box-shadow: 0 4px 4px --rgba(white, 0.25);
      z-index: -1;
      filter: invert(1);
    }

    &:hover {
      background: --rgba(button-background, 0.66);
      border-color: --rgba(border-color, 0.66);

      &::after {
        opacity: 1;
      }
    }

    &:is([disabled], #{$mf}disabled) {
      --button-color: var(--white) !important;
      --border-color: var(--silver) !important;
      --button-background: var(--silver) !important;
      pointer-events: none;
    }

    .icon {
      width: 1em;
    }

    @at-root #{$ru}buttons & {
      transition: --out();

      &:hover,
      &:active,
      &:focus {
        transition: --in();
      }

      &:is([disabled], #{$mf}disabled) {
        --theme: var(--silver);
        --darker: var(--silver);
        --lighter: var(--silver);
        --primary: var(--silver);
      }
    }

    &:is(
        #{$mf}primary,
        #{$mf}secondary,
        #{$mf}tertiary,
        #{$mf}neutral,
        #{$mf}plain
      ) {
      outline-offset: 0.125rem;

      &::after {
        display: none;
      }
    }

    &:is(#{$mf}primary, #{$mf}secondary) {
      &:hover {
        box-shadow: 0 4px 4px --rgba(black, 0.25);
      }
    }

    &#{$mf}primary {
      --border-width: 1px;
      background: --rgba(primary);
      color: --rgba(white);
      border: var(--border-width) solid --rgba(primary);
      padding-bottom: #{calc(var(--button-padding-y) - var(--border-width))};
      padding-top: #{calc(var(--button-padding-y) - var(--border-width))};

      &:hover,
      &:active,
      &:focus {
        background: --rgba(secondary);
        border-color: --rgba(secondary);
        box-shadow: inset 0 4px 8px --rgba(primary, 0.125);
      }

      &#{$mf}active {
        &,
        &:hover,
        &:active,
        &:focus {
          background: --rgba(primary);
          border-color: --rgba(primary);
        }
      }
    }

    &#{$mf}secondary {
      --border-width: 1px;
      background: --rgba(white);
      color: --rgba(theme);
      border: 1px solid --rgba(theme);
      padding-bottom: #{calc(var(--button-padding-y) - var(--border-width))};
      padding-top: #{calc(var(--button-padding-y) - var(--border-width))};

      &:hover,
      &:active,
      &:focus {
        background: --rgba(lighter);
        color: --rgba(darker);
      }

      &:hover {
        box-shadow: inset 0.25rem 0.25rem 0.5rem --rgba(white, 0.25),
          0.25rem 0.25rem 0.5rem --rgba(white, 0.25),
          0.25rem 0.25rem 0.25rem --rgba(black, 0.125);
      }

      &:active,
      &:focus {
        box-shadow: inset 0.125rem 0.125rem 0.125rem --rgba(black, 0.125);
      }

      &#{$mf}active {
        &,
        &:hover,
        &:active,
        &:focus {
          background: --rgba(theme);
          color: --rgba(white);
        }
      }
    }

    &#{$mf}tertiary {
      background: transparent;
      color: --rgba(theme);
      border-style: none;

      &:hover,
      &:active,
      &:focus {
        color: --rgba(darker);
      }
    }

    &#{$mf}neutral {
      --border-width: 1px;
      background: --rgba(white);
      color: --rgba(theme);
      border: var(--border-width) solid --rgba(white);
      padding-bottom: #{calc(var(--button-padding-y) - var(--border-width))};
      padding-top: #{calc(var(--button-padding-y) - var(--border-width))};

      &:hover,
      &:active,
      &:focus {
        color: --rgba(darker);
        border: 1px solid --rgba(theme);
        box-shadow: inset 0 4px 8px --rgba(black, 0.25);
      }
    }

    &#{$mf}plain {
      --button-padding-y: 0;
      --button-padding-x: 0;
      --width: auto;
      background: transparent;
      color: --rgba(theme);
      border-style: none;
      margin: 0 var(--base-margin-small);
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: --rgba(darker);
        text-decoration: underline;
      }
    }

    #{$ru}loading {
      fill: currentcolor;
    }
  }
}
</style>
