<template>
  <div class="ru:container ru:container--snug">
    <Headline :level="1">{{ $t('pages.files.yourFiles') }}</Headline>
    <p>
      {{ $t('pages.files.uploadSignedWillDescription') }}
    </p>
    <Box background="white">
      <Headline :level="2"> {{ $t('pages.files.uploadSignedWill') }} </Headline>
      <Tip>
        {{ $t('pages.files.uploadSignedWillTip') }}
      </Tip>
      <Alert
        v-if="uploadedFiles.SIGNED_WILL.alert.message"
        :theme="uploadedFiles.SIGNED_WILL.alert.type"
        level="medium"
      >
        {{ uploadedFiles.SIGNED_WILL.alert.message }}
      </Alert>
      <template v-if="uploadedFiles.SIGNED_WILL.loading">
        <p class="flex items-center flush">
          <LoadingRing :size="24" class="mr-4" />
          {{ $t('pages.files.loadingSignedWillMessage') }}
        </p>
      </template>
      <FilesList
        v-else-if="uploadedFiles.SIGNED_WILL.fileId"
        :files="[uploadedFiles.SIGNED_WILL]"
        @downloadFile="downloadSignedWill"
        @unlinkFile="unlinkSignedWill"
      />
      <FileControl
        v-else
        multiple
        accept="application/pdf, image/*"
        files-type="SIGNED_WILL"
        @filesChange="uploadFiles"
      >
        <Box tight background="sand">
          <Icon id="upload" size="huge" />
          {{ $t('pages.files.clickHereToUploadSignedWill') }}
        </Box>
      </FileControl>
    </Box>
    <Box background="white">
      <Headline :level="2">{{
        $t('pages.files.notifyExecutorHeadline')
      }}</Headline>
      <p>
        {{ $t('pages.files.notifyExecutorDescription') }}
      </p>
      <SendNotification />
    </Box>
    <Box background="white">
      <div class="grid">
        <div class="cell cell__fill">
          <Headline :level="2">{{ $t('pages.files.yourFiles') }}</Headline>
        </div>
        <div class="cell d-none">
          <Buttons layout="end">
            <RuButton level="plain" @click="openUploadFileModal">
              <Icon id="upload" />
              {{ $t('forms.labels.uploadAFile') }}
            </RuButton>
          </Buttons>
          <UploadFileModal
            v-if="uploadFileModalIsOpen"
            files-type="any"
            multiple
            @filesChange="uploadFiles"
            @closeModal="closeUploadFileModal"
          />
        </div>
      </div>
      <Alert
        v-if="uploadedFiles.all.alert.message"
        :theme="uploadedFiles.all.alert.type"
        level="medium"
      >
        {{ uploadedFiles.all.alert.message }}
      </Alert>
      <template v-if="uploadedFiles.all.loading">
        <p class="flex items-center">
          <LoadingRing :size="24" class="mr-4" />
          {{ $t('pages.files.loadingFilesMessage') }}
        </p>
      </template>
      <template v-else>
        <FilesList
          v-if="uploadedFiles.all.files.length"
          :files="uploadedFiles.all.files"
          @downloadFile="downloadFile"
          @deleteFile="openDeleteFileModal"
        />
        <template v-else>
          <RuImage
            :src="require('~/assets/img/empty.png')"
            :width="225"
            :height="150"
          />
          <p class="text-center">{{ $t('pages.files.emptyMessage') }}</p>
        </template>
      </template>
    </Box>
    <Snackbar
      v-if="snackbarIsVisible"
      :type="snackbarType"
      :text="snackbarText"
      @closeSnackbar="closeSnackbar"
    />
    <Modal v-if="deleteFileModalIsVisible" @closeModal="closeDeleteFileModal">
      <template #header>{{ $t('pages.files.deleteFile') }}</template>
      <template #body>
        <p>
          {{ $t('pages.files.deleteFileMessage') }}
        </p>
        <Buttons layout="stretch">
          <RuButton
            level="secondary"
            theme="light"
            :disabled="uploadedFiles.all.loading"
            @click="closeDeleteFileModal"
          >
            <Loading v-if="uploadedFiles.all.loading" />
            {{ $t('forms.labels.cancel') }}
          </RuButton>
          <RuButton
            level="primary"
            theme="error"
            :disabled="uploadedFiles.all.loading"
            @click="deleteFileFromList"
          >
            <Loading v-if="uploadedFiles.all.loading" />
            <Icon v-else id="delete" size="small" />
            {{ $t('forms.labels.delete') }}
          </RuButton>
        </Buttons>
      </template>
    </Modal>
  </div>
</template>

<script>
import Box from '@/components/atoms/Box';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import UploadFileModal from '@/components/templates/UploadFileModal';
import RuImage from '@/components/atoms/Image';
import FileControl from '@/components/atoms/FileControl';
import FilesList from '@/components/molecules/FilesList';
import LoadingRing from '@/components/LoadingRing';
import Alert from '@/components/molecules/Alert';
import Snackbar from '@/components/molecules/Snackbar';
import SendNotification from '@/components/organisms/SendNotification';
import Modal from '@/components/molecules/Modal';

import files from '@/mixins/files';
import { user } from '@/mixins/apollo';

import GET_SIGNED_WILL from '@/graphql/queries/GetSignedWill';
import UPDATE_SIGNED_WILL from '@/graphql/mutations/UpdateSignedWill';

export default {
  name: 'PagesAffiliateDashboardFiles',
  components: {
    Headline,
    Buttons,
    RuButton,
    Icon,
    UploadFileModal,
    Tip,
    Box,
    RuImage,
    FileControl,
    FilesList,
    LoadingRing,
    Alert,
    Snackbar,
    SendNotification,
    Modal,
  },
  mixins: [user, files],
  data() {
    return {
      GET_SIGNED_WILL,
      UPDATE_SIGNED_WILL,
      snackbarIsVisible: false,
      snackbarType: null,
      snackbarText: null,
      uploadFileModalIsOpen: false,
      uploadedFiles: {
        SIGNED_WILL: {
          fileId: null,
          fileName: null,
          fileStatus: null,
          executorHasPermission: null,
          loading: null,
          alert: {
            type: null,
            message: null,
          },
          unlinkable: true,
        },
        all: {
          files: [],
          loading: null,
          alert: {
            message: null,
          },
        },
      },
      deleteFileModalIsVisible: false,
      selectedDeleteFileId: null,
    };
  },
  mounted() {
    this.getAllUploadedFiles();
    this.getSignedWill();
  },
  methods: {
    async getAllUploadedFiles() {
      this.uploadedFiles.all.loading = true;
      this.uploadedFiles.all.alert.message = null;
      try {
        this.uploadedFiles.all.files = [
          ...(await this.getFileList()),
        ].reverse();
      } catch (error) {
        this.uploadedFiles.all.alert.message = this.$t(
          'texts.sentences.uploadedFilesFetchFailed'
        );
      } finally {
        this.uploadedFiles.all.loading = false;
      }
    },
    async getSignedWill() {
      this.uploadedFiles.SIGNED_WILL.loading = true;
      this.uploadedFiles.SIGNED_WILL.errorMessage = null;
      this.uploadedFiles.SIGNED_WILL.fileId = null;
      this.uploadedFiles.SIGNED_WILL.fileName = null;
      this.uploadedFiles.SIGNED_WILL.fileStatus = null;
      try {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_SIGNED_WILL,
          variables: {
            id: this.willId,
          },
        });
        if (data.getSignedWill?.signedWill) {
          const signedWill = data.getSignedWill.signedWill;
          this.uploadedFiles.SIGNED_WILL.fileId = signedWill.fileId;
          this.uploadedFiles.SIGNED_WILL.fileName = signedWill.fileName;
          this.uploadedFiles.SIGNED_WILL.fileStatus = signedWill.fileStatus;
        }
      } catch (error) {
        this.uploadedFiles.SIGNED_WILL.alert.type = 'warning';
        this.uploadedFiles.SIGNED_WILL.alert.message =
          this.uploadedFiles.all.alert.message = this.$t(
            'texts.sentences.somethingWentWrong'
          );
      } finally {
        this.uploadedFiles.SIGNED_WILL.loading = false;
      }
    },
    openUploadFileModal() {
      this.uploadFileModalIsOpen = true;
    },
    closeUploadFileModal() {
      this.uploadFileModalIsOpen = false;
    },
    openDeleteFileModal(fileId) {
      this.selectedDeleteFileId = fileId;
      this.deleteFileModalIsVisible = true;
    },
    closeDeleteFileModal() {
      this.deleteFileModalIsVisible = false;
    },
    async uploadFiles(files, fileType) {
      let uploadedFiles = this.uploadedFiles[fileType];
      if (!uploadedFiles) {
        uploadedFiles = this.uploadedFiles.all;
      }
      uploadedFiles.alert.type = 'info';
      uploadedFiles.alert.message = this.$t('pages.files.processingYourUpload');
      const { success, message } = await this.processFileUpload(
        files,
        fileType
      );
      if (success) {
        uploadedFiles.alert.message = null;
        this.snackbarText = this.$t('texts.sentences.successfullyUploaded');
        this.openSnackbar('success');
        this.getAllUploadedFiles();
        this.getSignedWill();
      } else {
        uploadedFiles.alert.type = 'error';
        uploadedFiles.alert.message = message;
      }
    },
    async unlinkSignedWill() {
      this.uploadedFiles.SIGNED_WILL.alert.type = 'info';
      this.uploadedFiles.SIGNED_WILL.alert.message = this.$t(
        'pages.files.unlinkYourSingedWill'
      );
      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_SIGNED_WILL,
        variables: {
          id: this.willId,
          data: null,
        },
      });
      if (data.updateSignedWill.success) {
        this.uploadedFiles.SIGNED_WILL.alert.message = null;
        this.snackbarText = this.$t('texts.sentences.successfullyUnlinked');
        this.openSnackbar('success');
        this.getAllUploadedFiles();
        this.getSignedWill();
      } else {
        this.uploadedFiles.SIGNED_WILL.alert.type = 'error';
        this.uploadedFiles.SIGNED_WILL.alert.message =
          data.updateSignedWill.message;
      }
    },
    async deleteFileFromList() {
      this.uploadedFiles.all.loading = true;
      try {
        await this.deleteFile(this.selectedDeleteFileId);
        this.snackbarText = this.$t('texts.sentences.successfullyDeleted');
        this.openSnackbar('success');
        this.getAllUploadedFiles();
        this.getSignedWill();
      } catch (error) {
        this.uploadedFiles.all.alert.message =
          this.uploadedFiles.all.alert.message = this.$t(
            'texts.sentences.somethingWentWrong'
          );
      } finally {
        this.uploadedFiles.all.loading = false;
        this.selectedDeleteFileId = null;
        this.closeDeleteFileModal();
      }
    },
    async downloadSignedWill() {
      await this.downloadFile(this.uploadedFiles.SIGNED_WILL.fileId);
    },
    openSnackbar(type) {
      this.snackbarType = type;
      this.snackbarIsVisible = true;
    },
    closeSnackbar() {
      this.snackbarIsVisible = false;
    },
  },
};
</script>
