<template>
  <div class="mb-4">
    <div v-if="$slots.heading" class="flex items-center mb-8">
      <Headline :level="2">
        <Tooltip v-if="tip" class="ml-4" :content="tip">
          <div
            class="flex justify-center items-center w-5 h-5 rounded-full bg-grey-500 text-grey-200"
          >
            <BaseGlyph id="question-mark" />
          </div>
        </Tooltip>
        <slot name="heading" />
      </Headline>
    </div>
    <slot />
  </div>
</template>

<script>
import BaseGlyph from '@/components/BaseGlyph';
import Tooltip from '@/components/Tooltip';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'ComponentsFormSection',
  components: {
    BaseGlyph,
    Tooltip,
    Headline,
  },
  props: {
    tip: {
      default: null,
      type: String,
    },
  },
};
</script>
