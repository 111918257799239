<template>
  <ModuleStep :index="4" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.poa.financial.spending.title') }}
        </Headline>
        <Tip v-if="['nsw', 'wa', 'sa'].includes(userState)">
          {{ $t('pages.poa.financial.spending.tipNswWaSa') }}
        </Tip>
        <Tip v-else>
          {{ $t('pages.poa.financial.spending.tipOtherStates') }}
        </Tip>
        <LargeRadioButtons
          id="has_inclusions"
          v-model="poaFinancialMeta.has_inclusions"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
          @input="onChange"
        />
      </FormSection>
      <FormSection v-if="poaFinancialMeta.has_inclusions">
        <Headline :level="2">
          {{ $upperFirst($t('texts.words.people')) }}
        </Headline>
        <PersonSelector
          v-model="selectedInclusionPeopleIDs"
          :loading="loading"
          :people="people"
          :will-beneficiary-selector="false"
          :min="1"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import {
  queries as inclusionsQueries,
  mutations as inclusionsMutations,
} from '@/modules/apollo-queries/poa-inclusions';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import * as peopleQueries from '@/modules/apollo-queries/people';
import Tip from '@/components/molecules/Tip';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import PersonSelector from '@/components/PersonSelector';
import Headline from '@/components/atoms/Headline';

import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialSpending',
  components: {
    Tip,
    AdiForm,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    PersonSelector,
    Headline,
  },
  mixins: [will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'FINANCIAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaFinancialMeta: {
      ...metaQueries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    people: {
      ...peopleQueries.queries.getPeopleOfAccount(),
      variables() {
        return {
          userId: this.userId,
        };
      },
    },
    inclusionPeople: {
      ...inclusionsQueries.getInclusionPeople({
        power: 'FINANCIAL',
      }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      inclusionPeople: [],
      selectedInclusionPeopleIDs: [],
      poaFinancialMeta: {
        has_inclusions: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),

    previous() {
      return prevStep({
        addressState: this.userState,
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta.has_inclusions === false ||
        (this.poaFinancialMeta.has_inclusions === true &&
          this.selectedInclusionPeopleIDs.length > 0)
      );
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
  },
  watch: {
    inclusionPeople(inclusionPeople) {
      if (inclusionPeople.length && !this.selectedInclusionPeopleIDs.length) {
        this.selectedInclusionPeopleIDs = inclusionPeople.map(
          (people) => people.id
        );
      }
    },
  },
  methods: {
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await Promise.all([
        this.$apollo.mutate(
          metaMutations.updatePoaMeta(this.poaId, {
            key: 'has_inclusions',
            value: this.poaFinancialMeta.has_inclusions ? 'true' : 'false',
            category: 'FINANCIAL',
          })
        ),
        this.$apollo.mutate(
          inclusionsMutations.setInclusionPeople({
            peopleIds: this.selectedInclusionPeopleIDs,
            power: 'FINANCIAL',
            poaId: this.poaId,
          })
        ),
      ]);

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
