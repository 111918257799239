<template>
  <ModuleStep :index="4" :prev-step="prevStep">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="done"
    >
      <FormSection class="step-1">
        <template v-if="willMeta.executors_option === 'professional'">
          <Headline :level="1">
            {{ $t('pages.will.executors.backup.appointBackup.professional') }}
          </Headline>
          <Tip>
            {{ $t('pages.will.executors.backup.tip.professional') }}
          </Tip>
        </template>
        <template v-else>
          <Headline :level="1">
            {{ $t('pages.will.executors.backup.appointBackup.other') }}
          </Headline>
          <Tip>
            {{ $t('pages.will.executors.backup.tip.other') }}
          </Tip>
          <LargeRadioButtons
            id="hasBackupExecutor"
            v-model="willMeta.has_backup_executor"
            :options="[
              {
                label: $t('forms.labels.yes'),
                value: true,
              },
              {
                label: $t('forms.labels.no'),
                value: false,
              },
            ]"
          />
        </template>
      </FormSection>
      <FormSection
        v-if="willMeta.has_backup_executor"
        class="step-2"
        :tip="$t('pages.will.peopleSelectionInstructions')"
      >
        <Headline
          v-if="willMeta.executors_option !== 'professional'"
          :level="2"
        >
          {{ $t('pages.will.executors.backup.whoBackupExecutor') }}
        </Headline>
        <PersonSelector
          v-model="selectedBackupExecutors"
          :loading="loading"
          :max="2"
          :people="filteredPeople"
          type="executor"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { executors, people, user, will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillExecutorsBackup',
  components: {
    AdiForm,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
  },
  mixins: [executors, people, user, will],
  data() {
    return {
      selectedBackupExecutors: [],
    };
  },
  computed: {
    prevStep() {
      return this.willMeta.executors_option === 'professional'
        ? '/will/executors/professional-terms'
        : '/will/executors/primary';
    },
    isComplete() {
      return (
        this.willMeta.has_backup_executor === false ||
        !!this.selectedBackupExecutors.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedBackupExecutors.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 &&
            meta.address_country === 'Australia' &&
            !this.isPrimaryExecutor(person))
        );
      });
    },
  },
  watch: {
    backupExecutors(executors) {
      if (executors && !this.selectedBackupExecutors.length) {
        this.selectedBackupExecutors = this.people
          .filter((person) => {
            return executors.find(
              (executor) => person.id === executor.person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    async done() {
      await Promise.all(
        this.people.map((person) => {
          const isBackupExecutor = this.isBackupExecutor(person);
          const isSelected = this.selectedBackupExecutors.includes(person.id);
          if (this.willMeta.has_backup_executor && isSelected) {
            if (!isBackupExecutor) {
              return this.addExecutor(person, 'backup');
            }
          } else if (isBackupExecutor) {
            return this.removeExecutor(person);
          }
          return Promise.resolve();
        })
      );
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 3 (Executors)',
      });
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
