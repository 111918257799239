<template>
  <nuxt-child />
</template>

<script>
export default {
  name: 'PagesAffiliatePoa',
  layout: 'ruach',
  middleware: ['authenticated', 'hasPurchasedPoa'],
};
</script>
