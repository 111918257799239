import { render, staticRenderFns } from "./TransferWillModal.vue?vue&type=template&id=15f24948&"
import script from "./TransferWillModal.vue?vue&type=script&lang=js&"
export * from "./TransferWillModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports