<template>
  <div :style="styles" class="ru:progress-bar">
    <div class="ru:progress-bar__bar"></div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    styles() {
      return {
        '--progress': this.progress / 100,
      };
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &progress-bar {
    background-color: --rgba(iron);
    width: 100%;
    height: 0.25rem;
    overflow: hidden;
    border-radius: var(--border-radius-small);

    &__bar {
      background-color: --rgba(theme);
      height: 100%;
      transform: scaleX(var(--progress));
      transform-origin: top left;
      transition: --in();
    }
  }
}
</style>
