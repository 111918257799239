<template>
  <div ref="wrapper" class="ru:admin-module-summary">
    <div ref="header" class="flex justify-between items-center mb-2">
      <div @click="toggle">
        <Headline flush row :level="4">
          <Icon :id="expanded ? 'chevron-up' : 'chevron-down'" />
          {{ title }}
        </Headline>
      </div>
      <AdminFlagCheckbox
        v-model="flagged"
        :will-id="willId"
        :module-name="moduleName"
        :disabled="disabled"
        @change="$emit('change', flagged)"
      />
    </div>
    <div v-show="expanded">
      <slot />
    </div>
  </div>
</template>

<script>
import AdminFlagCheckbox from '@/components/admin/FlagCheckbox';
import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'ComponentsAdminModuleSummary',
  components: {
    AdminFlagCheckbox,
    Icon,
    Headline,
  },
  model: {
    event: 'change',
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      default: '',
      type: [String, Boolean],
    },
    moduleName: {
      required: true,
      type: String,
    },
    willId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      done: true,
      expanded: true,
      flagged: this.value,
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) this.flagged = false;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-module-summary {
    position: relative;
    padding-bottom: var(--base-margin);
    margin-bottom: var(--base-margin);

    &:not(:last-child) {
      border-bottom: 1px solid --rgba(iron);
    }
  }
}
</style>
