<template>
  <div :class="`ru:accordion-panel ${expandedClass}`">
    <button type="button" class="ru:accordion-panel__toggle" @click="toggle">
      <Headline flush row :level="3">
        <slot name="title" />
        <transition-group
          tag="div"
          class="ru:accordion-panel__toggle-icons"
          name="ru:wiggle-fade-squish"
          mode="out-in"
        >
          <Icon v-if="expanded" id="minus" key="menu" />
          <Icon v-else id="add" key="add" />
        </transition-group>
      </Headline>
    </button>
    <div :class="`ru:accordion-panel__content`">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'ComponentsMoleculesAccordionPanel',
  components: {
    Icon,
    Headline,
  },
  data() {
    return {
      expanded: false,
      icon: 'plus',
    };
  },
  computed: {
    expandedClass() {
      return this.expanded ? '--expanded' : '';
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      this.icon = this.expanded ? 'plus' : 'minus';
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &accordion-panel {
    border-bottom: 2px solid --rgba(theme);
    padding-bottom: var(--base-margin);
    margin-bottom: var(--base-margin);
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    &__content {
      height: 0;
      overflow: hidden;
    }

    &:is(#{$mf}expanded) &__content {
      padding-top: var(--base-margin);
      height: auto;
    }

    &__toggle {
      display: block;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    &__toggle-icons {
      position: absolute;
      right: 0;
      width: 1.5rem;
      height: 1.5rem;
      #{$ru}icon {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
