<template>
  <div class="ru:upload-file-modal">
    <Modal @closeModal="closeModal">
      <template #header>
        {{ $t('components.uploadFileModal.fileUpload') }}
      </template>
      <template #body>
        <transition-group
          ref="wiggleParent"
          tag="div"
          class="ru:wiggle__parent"
          name="ru:wiggle-horizontal"
          mode="out-in"
          :style="`height: ${stageHeight}`"
          @before-leave="onBeforeLeave"
          @enter="onEnter"
          @after-enter="onAfterEnter"
        >
          <div
            v-if="currentScene === 0"
            key="scene0"
            class="ru:upload-file-modal__stage"
          >
            <FileControl
              accept="application/pdf, image/*"
              :multiple="multiple"
              :files-type="filesType"
              @filesChange="selectFiles"
            >
              <Box tight background="sand">
                <Icon id="upload" size="huge" />
                {{ $t('components.uploadFileModal.clickHereToAddFiles') }}
              </Box>
            </FileControl>
            <Buttons layout="stretch">
              <RuButton level="secondary" @click="closeModal">
                {{ $t('components.uploadFileModal.cancel') }}
              </RuButton>
              <RuButton level="primary" @click="nextStep">
                {{ $t('components.uploadFileModal.next') }}
              </RuButton>
            </Buttons>
          </div>
          <div
            v-if="currentScene === 1"
            key="scene1"
            class="ru:upload-file-modal__stage"
          >
            <FormControl
              id="file_type"
              v-model="fileType"
              type="select"
              text="Please select a category for this file"
              :options="fileTypes"
            />
            <Buttons layout="stretch">
              <RuButton level="secondary" @click="previousStep">
                {{ $t('components.uploadFileModal.back') }}
              </RuButton>
              <RuButton :disabled="!fileType" level="primary" @click="nextStep">
                {{ $t('components.uploadFileModal.next') }}
              </RuButton>
            </Buttons>
          </div>
          <div
            v-if="currentScene === 2"
            key="scene3"
            class="ru:upload-file-modal__stage"
          >
            <FormControl
              id="file_name"
              v-model="fileName"
              type="text"
              text="Please enter a name for this file"
            />
            <Buttons layout="stretch">
              <RuButton level="secondary" @click="previousStep">
                {{ $t('components.uploadFileModal.back') }}
              </RuButton>
              <RuButton :disabled="!fileType" level="primary" @click="nextStep">
                {{ $t('components.uploadFileModal.save') }}
              </RuButton>
            </Buttons>
          </div>
        </transition-group>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/molecules/Modal';
import FileControl from '@/components/atoms/FileControl';
import Box from '@/components/atoms/Box';
import Icon from '@/components/atoms/Icon';
import FormControl from '@/components/molecules/FormControl';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsTemplatesUploadFileModal',
  components: {
    Modal,
    FileControl,
    Box,
    Icon,
    FormControl,
    Buttons,
    RuButton,
  },
  props: {
    filesType: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fileTypes: {
      type: Array,
      default: () => [
        {
          value: 'SIGNED_WILL',
          text: 'Signed Will',
        },
        {
          value: 'POA_MEDICAL',
          text: 'Medical POA',
        },
        {
          value: 'POA_FINANCIAL',
          text: 'Financial POA',
        },
      ],
    },
    renameable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stageHeight: 'auto',
      currentScene: 0,
      files: [],
      fileType: null,
      fileName: null,
    };
  },
  created() {
    this.hasMultipleFileTypesAvailable =
      this.fileTypes && this.fileTypes.length > 1;
    this.fileType = this.hasMultipleFileTypesAvailable
      ? null
      : this.fileTypes[0].value;
  },
  methods: {
    removeExtensionFromFileName(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    selectFiles(files) {
      this.files = files;
      this.fileName = this.removeExtensionFromFileName(files[0].name);
      this.nextStep();
    },
    submitFiles() {
      this.$emit('filesChange', this.files, this.fileType, this.fileName);
      this.closeModal();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    setCurrentScene(scene) {
      this.currentScene = scene;
    },
    onBeforeLeave(el) {
      this.$refs.wiggleParent.$el.classList.add('--active');
      this.stageHeight = `${el.offsetHeight}px`;
    },
    onEnter(el) {
      this.stageHeight = `${el.offsetHeight}px`;
    },
    onAfterEnter() {
      this.$refs.wiggleParent.$el.classList.remove('--active');
    },
    nextStep() {
      switch (this.currentScene) {
        case 0:
          if (this.hasMultipleFileTypesAvailable) {
            this.setCurrentScene(1);
          } else if (this.renameable) {
            this.setCurrentScene(2);
          } else {
            this.submitFiles();
          }
          break;
        case 1:
          if (this.renameable) {
            this.setCurrentScene(2);
          } else {
            this.submitFiles();
          }
          break;
        case 2:
          this.submitFiles();
          break;
        default:
          break;
      }
    },
    previousStep() {
      switch (this.currentScene) {
        case 2:
          if (this.hasMultipleFileTypesAvailable) {
            this.setCurrentScene(1);
          } else {
            this.setCurrentScene(0);
          }
          break;
        default:
          this.setCurrentScene(0);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &upload-file-modal {
    &__stage {
      > * {
        margin-bottom: var(--base-margin);
      }
    }
  }
}
</style>
