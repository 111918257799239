<template>
  <ModuleStep :index="5" :prev-step="prevStepUrl">
    <AdiForm
      v-if="validBeneficiaries"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit="submit"
    >
      <FormSection v-if="currentBeneficiary">
        <Headline :level="1">
          {{
            $t('pages.will.estate.shareOfEstateQuestion', {
              name: currentBeneficiary.person.meta.full_name,
            })
          }}
        </Headline>
        <Tip>{{ $t('pages.will.estate.beneficiaryProportionsNote') }}</Tip>
        <CheckboxInput
          id="isBackupEstateSplitEvenly"
          v-model="isBackupEstateSplitEvenly"
          class="w-full mb-8"
        >
          {{ $t('pages.will.estate.splitEvenlyCheckbox') }}
        </CheckboxInput>
        <BeneficiaryInputs
          v-model="distributions"
          :beneficiaries="validBeneficiaries"
          :is-split-evenly="isBackupEstateSplitEvenly"
          @valid="checkValid"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import BeneficiaryInputs from '@/components/BeneficiaryInputs';
import Tip from '@/components/molecules/Tip';
import CheckboxInput from '@/components/CheckboxInput';
import Headline from '@/components/atoms/Headline';

import { beneficiaries, user, will } from '@/mixins/apollo';

export default {
  key(route) {
    return route.fullPath;
  },
  components: {
    AdiForm,
    FormSection,
    ModuleStep,
    BeneficiaryInputs,
    Tip,
    CheckboxInput,
    Headline,
  },
  mixins: [beneficiaries, user, will],
  data() {
    return {
      distributions: [],
      valid: false,
      isBackupEstateSplitEvenly: false,
    };
  },
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    currentBeneficiary() {
      return this.beneficiaries.find((b) => b.id === this.$route.params.id);
    },
    currentIndex() {
      return this.backupQueue.findIndex((b) => b.id === this.$route.params.id);
    },
    prevStepUrl() {
      const beneficiary = this.backupQueue[this.currentIndex];
      return beneficiary
        ? `/will/estate/backup-beneficiaries/${beneficiary.id}`
        : '/will/estate/beneficiaries';
    },
    isComplete() {
      return !!this.valid;
    },
    validBeneficiaries() {
      return (
        this.currentBeneficiary &&
        this.currentBeneficiary.backup.filter((beneficiary) => {
          if (beneficiary.person && this.currentBeneficiary) {
            return beneficiary.person.id !== this.currentBeneficiary.person.id;
          }
          return true;
        })
      );
    },
  },
  watch: {
    beneficiaries(newBeneficiariesValue) {
      const currentBeneficiary = newBeneficiariesValue.find(
        (b) => b.id === this.$route.params.id
      );
      this.isBackupEstateSplitEvenly =
        currentBeneficiary.isBackupEstateSplitEvenly;
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await this.updateBeneficiaryIsBackupEstateSplitEvenly(
        this.currentBeneficiary,
        this.isBackupEstateSplitEvenly
      );
      await Promise.all(
        this.currentBeneficiary.backup.map((beneficiary, i) => {
          return this.updateBeneficiaryDistribution(
            beneficiary,
            this.isBackupEstateSplitEvenly ? null : this.distributions[i]
          );
        })
      );
      if (this.currentIndex < this.backupQueue.length - 1) {
        this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${
              this.backupQueue[this.currentIndex + 1].id
            }`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });
        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage ? '/will/charity-consent' : '/will'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>
