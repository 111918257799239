<template>
  <AdminCard tight>
    <AdminDataTable
      :columns="columns"
      :loading="$apollo.loading"
      :min-rows="partnershipsReportData.length"
      :row-count="partnershipsReportData.length"
    >
      <template #rows>
        <AdminDataTableRow
          v-for="(reportElement, index) in partnershipsReportData"
          :key="index"
          class="group"
        >
          <AdminDataTableCell full="full">
            {{ toTitleCase(reportElement.metric) }}
          </AdminDataTableCell>
          <AdminDataTableCell>
            {{ reportElement.count }}
          </AdminDataTableCell>
        </AdminDataTableRow>
      </template>
    </AdminDataTable>
  </AdminCard>
</template>

<script>
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import GET_PARTNERSHIPS_REPORT_QUERY from '@/graphql/queries/GetPartnershipsReportData';

import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';

export default {
  name: 'ComponentsPartnershipsReportTable',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
  },
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  props: {
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    partnershipsReportData: {
      query: GET_PARTNERSHIPS_REPORT_QUERY,
      variables() {
        return {
          startDate: this.dateFrom,
          endDate: this.dateTo,
        };
      },
      update: ({ getPartnershipsReportData }) => getPartnershipsReportData,
    },
  },
  data() {
    return {
      columns: ['Metric', 'Count'],
      pageSize: 10,
      partnershipsReportData: [],
    };
  },
  methods: {
    toTitleCase(str) {
      return startCase(toLower(str));
    },
  },
};
</script>
