<template>
  <div class="ru:end-of-life-items">
    <button
      v-for="(item, i) in items"
      :key="`item${i}`"
      class="ru:end-of-life-items__item"
      @click="$emit('editItem', item.id)"
    >
      <div class="ru:end-of-life-items__item-text">
        {{ itemHeadline(item) }}
        <small v-if="itemIntro(item)">
          {{ itemIntro(item) }}
        </small>
      </div>
      <div class="ru:end-of-life-items__item-actions">
        <Icon id="pencil" />
      </div>
    </button>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';

import institutions from '@/mixins/institutions';

export default {
  name: 'ComponentsTemplatesEndOfLifeItemList',
  components: {
    Icon,
  },
  mixins: [institutions],
  props: {
    layout: {
      type: String,
      default: 'DEFAULT',
    },
    institutionType: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      institutionsByType: [],
    };
  },
  async mounted() {
    if (!this.institutionType) {
      return [];
    }

    this.institutionsByType = await this.getInstitutionsByType(
      this.institutionType
    );
  },
  methods: {
    itemHeadline(item) {
      return item.data.name;
    },
    getInstitutionNameById(institutionId) {
      const institution = this.institutionsByType.find(
        ({ id }) => id === institutionId
      );
      return institution ? institution.name : '';
    },
    itemIntro(item) {
      if (item.id.includes('fq_id')) {
        return item.data.hint;
      }
      const layoutToItemIntroTextMapping = {
        BIRTH_CERTIFICATE: item.data.registrationNumber,
        RESIDENCE: item.data.country,
        GOVERNMENT_CONCESSION: item.data.type,
        LICENCE: item.data.type,
        MEDICARE: item.data.cardNumber,
        PASSPORT: item.data.passportNumber,
        RELATIONSHIP: item.data.relationshipType,
        TAXATION: item.data.tfn,
        GUARDIANSHIP: item.data.notes,
        POWER_OF_ATTORNEY: item.data.type,
        WILL: item.data.provider,
        BANK_ACCOUNT: item.data.type,
        BUSINESS: item.data.type,
        FINANCIAL_ASSETS: item.data.type,
        INSURANCE: item.data.type,
        PROPERTY: item.data.type,
        SUPERANNUATION:
          this.getInstitutionNameById(item.data.institutionId) ||
          item.data.otherInstitutionName,
        TRUST: item.data.trustName,
        FINANCIAL_VALUABLE: item.data.item,
        VEHICLE: item.data.makeOrModel,
        EMPLOYMENT: item.data.employer,
        GOVERNMENT_BENEFIT: item.data.type,
        CREDIT_CARD: item.data.accountName,
        LOAN: item.data.type,
        DONATION:
          this.getInstitutionNameById(item.data.institutionId) ||
          item.data.otherInstitutionName,
        SUBSCRIPTION:
          this.getInstitutionNameById(item.data.institutionId) ||
          item.data.otherInstitutionName,
        UTILITY: item.data.type,
        MEDICAL_DEVICE: item.data.notes,
        ORGAN_DONATION: item.data.notes,
        FUNERAL_PREFERENCE: item.data.value || 'Not specified',
        FUNERAL_PREPAID_POLICY: item.data.value || 'Not specified',
        EMOTIONAL_MESSAGE: item.data.recipientName,
        EMOTIONAL_STORY: item.data.story,
        CONTACT_LAYOUT: item.data.contact,
      };

      return layoutToItemIntroTextMapping[this.layout] || '';
    },
    truncateText(text, maxLength) {
      let newText = text.substring(0, maxLength);
      if (text.length > maxLength) {
        newText = newText.substring(0, text.lastIndexOf(' ')) + '…';
      }
      return newText;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &end-of-life-items {
    margin-bottom: var(--base-margin);

    &__item {
      background: --rgba(white);
      padding: 0.5rem 1rem;
      border-radius: var(--border-radius);
      border: 1px solid --rgba(iron);
      margin-bottom: var(--base-margin);
      transition: var(--transition);
      display: flex;
      width: 100%;
      align-items: stretch;

      &-text {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;

        small {
          line-height: 1.25rem;
          margin-top: 0.25rem;
          display: block;
          font-weight: normal;
          font-size: 0.875rem;
          color: --rgba(rock);
        }
      }

      &-actions {
        display: flex;
        margin-left: auto;
        align-items: center;
        color: --rgba(rhino);
      }
    }
  }
}
</style>
