<template>
  <ModuleStep :index="5" prev-step="/will/guardians/your-pets">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="submit"
    >
      <FormSection class="step-1">
        <Headline :level="1">
          {{ $t('pages.will.guardians.appointPetGuardian') }}
        </Headline>
        <LargeRadioButtons
          id="hasPetGuardians"
          v-model="willMeta.has_pet_guardians"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
        />
      </FormSection>
      <MetaSlot
        v-for="(pet, index) in pets"
        :key="index"
        v-slot="{ meta }"
        :meta="pet.meta"
      >
        <FormSection
          v-if="showForm(index)"
          class="step-2"
          :tip="$t('pages.will.peopleSelectionInstructions')"
        >
          <Headline :level="2">
            {{
              $t('pages.will.guardians.appointAGuardianFor', {
                name: meta.name,
              })
            }}
          </Headline>
          <PersonSelector
            :id="pet.id"
            v-model="selectedPetGuardians[index]"
            :loading="loading"
            :max="1"
            :people="filteredPeople[index]"
            type="guardian"
          />
        </FormSection>
      </MetaSlot>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import MetaSlot from '@/components/MetaSlot';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Headline from '@/components/atoms/Headline';

import { pets, people, user, will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillGuardiansPetGuardians',
  components: {
    AdiForm,
    FormSection,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    PersonSelector,
    Headline,
  },
  mixins: [pets, people, user, will],
  data() {
    return {
      selectedPetGuardians: [],
    };
  },
  computed: {
    isComplete() {
      const assignedPets = this.selectedPetGuardians.filter((arr) => {
        return arr.length;
      });

      return !!(
        this.willMeta.has_pet_guardians === false ||
        assignedPets.length === this.pets.length
      );
    },
    filteredPeople() {
      return this.pets.map((pet, index) => {
        return this.people.filter((person) => {
          const meta = metaArrayToObject(person.meta);
          const personIsAlreadySelected = this.selectedPetGuardians[
            index
          ].includes(person.id);
          return personIsAlreadySelected || meta.is_over_18;
        });
      });
    },
  },
  watch: {
    pets() {
      this.updateSelectedPetGuardians();
    },
    people() {
      this.updateSelectedPetGuardians();
    },
  },
  methods: {
    updateSelectedPetGuardians() {
      this.selectedPetGuardians = this.pets.map((pet) => {
        const person = this.people.find(
          (person) => pet.person && pet.person.id === person.id
        );

        return person ? [person.id] : [];
      });
    },
    showForm(index) {
      return (
        this.willMeta.has_pet_guardians &&
        this.pets.length &&
        this.selectedPetGuardians[index]
      );
    },
    async submit() {
      await Promise.all(
        this.pets.map((pet, i) => {
          const selectedGuardian = this.selectedPetGuardians[i][0];
          if (this.willMeta.has_pet_guardians) {
            if (!pet.person || pet.person.id !== selectedGuardian) {
              return this.addPetGuardian(pet, selectedGuardian);
            }
          } else if (pet.person) {
            return this.removePetGuardian(pet);
          }
          return Promise.resolve();
        })
      );

      this.$router.push({
        path: this.localePath('/will/guardians/pet-care-fund'),
      });
    },
  },
};
</script>
