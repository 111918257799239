<template>
  <div>
    <AuthPanel>
      <template #form>
        <div class="relative">
          <AdiForm
            :class="classes"
            :mutation="RESEND_WELCOME_EMAIL"
            :variables="variables"
            submit-arrow
            :submit-label="$t('forms.labels.resendEmail')"
            submit-wide
            @done="$router.push({ path: localePath('/') })"
          >
            <p class="text-xl text-center">
              {{ $t('pages.verify.expiredTokenMessage') }}
            </p>
          </AdiForm>
          <div
            v-if="!failed"
            class="absolute inset-0 flex justify-center items-center text-teal-200"
          >
            <LoadingDots />
          </div>
        </div>
      </template>
    </AuthPanel>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import RESEND_WELCOME_EMAIL from '@/graphql/mutations/ResendWelcomeEmail';
import VERIFY_MUTATION from '@/graphql/mutations/Verify';

import AuthPanel from '@/components/AuthPanel';
import AdiForm from '@/components/Form';
import LoadingDots from '@/components/LoadingDots';

export default {
  name: 'PagesAffiliateVerify',
  components: {
    AuthPanel,
    AdiForm,
    LoadingDots,
  },
  data() {
    return {
      RESEND_WELCOME_EMAIL,
      failed: false,
    };
  },
  fetch({ redirect, route }) {
    if (!route.query.e && !route.query.t) redirect('/');
  },
  computed: {
    classes() {
      return {
        invisible: !this.failed,
      };
    },
    variables() {
      return {
        email: this.$route.query.e,
      };
    },
  },
  mounted() {
    const { e, t } = this.$route.query;

    this.$apollo
      .mutate({
        mutation: VERIFY_MUTATION,
        variables: {
          email: e,
          token: t,
        },
      })
      .then(({ data }) => {
        if (data.verify && data.verify.success) {
          this.setVerified(true);

          this.$router.push({
            path: this.localePath('/'),
          });
        } else {
          this.failed = true;
        }
      })
      .catch(() => {
        this.failed = true;
      });
  },
  methods: {
    ...mapActions(['setVerified']),
  },
};
</script>
