<template>
  <div class="ru:container ru:container--snug">
    <Headline :level="1">
      <small>{{ fullName }}</small>
      {{ $t('pages.onboarding.poa.appointPoaTitle') }}
    </Headline>
    <p>
      {{ $t('pages.onboarding.poa.appointPoaDesc') }}
    </p>
    <Buttons layout="start">
      <RuButton level="primary" :href="localePath('/checkout/poa')">
        {{ $t('pages.onboarding.poa.purchaseNow', { cost: POA_COST }) }}
      </RuButton>
    </Buttons>
    <Box loose background="white">
      <div class="ru:onboarding-poa__shield-container">
        <BaseIcon id="shield-bg" class="ru:onboarding-poa__shield" />
      </div>
      <div class="ru:onboarding-poa__shield-sibling">
        <Headline :level="2">
          {{ $t('pages.onboarding.poa.theEasiestWay') }}
        </Headline>
        <div class="grid grid--big">
          <div
            v-for="(item, index) in wishes"
            :key="`wish-${index}`"
            class="cell cell__12/12 cell__5/12--md"
          >
            <BaseIcon :id="item.iconId" />
            <Headline flush :level="3">
              {{ item.title }}
            </Headline>
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
        <i18n
          v-if="userState"
          path="pages.onboarding.poa.userStateText"
          tag="p"
        >
          <template #poa>
            <strong>{{
              $t('pages.onboarding.poa.enduringPowerOfAttorney')
            }}</strong>
          </template>
          <template #medical>
            <strong>{{
              $t('pages.onboarding.poa.poaMedical')[userState]
            }}</strong>
          </template>
          <template #state>
            <strong>{{ $t('userState')[userState] }}</strong>
          </template>
        </i18n>
      </div>
    </Box>
    <Headline :level="2"> FAQ </Headline>

    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.enduringPoa.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.enduringPoa.desc') }}
        </p>
        <ul>
          <i18n
            path="pages.onboarding.poa.enduringPoa.financialPoaDesc"
            tag="li"
          >
            <strong>{{ $t('titles.financialPoa') }}</strong>
          </i18n>
          <i18n path="pages.onboarding.poa.enduringPoa.medicalPoaDesc" tag="li">
            <strong>{{ $t('titles.medicalPoa') }}</strong>
          </i18n>
        </ul>
        <p>
          {{ $t('pages.onboarding.poa.enduringPoa.appointeeObligations') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.differenceGeneralEnduringPOA.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.differenceGeneralEnduringPOA.desc') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.whenWillPOATakeEffect.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.whenWillPOATakeEffect.desc1') }}
        </p>
        <p>
          {{ $t('pages.onboarding.poa.whenWillPOATakeEffect.desc2') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.differenceWithWill.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.differenceWithWill.desc1') }}
        </p>
        <i18n path="pages.onboarding.poa.differenceWithWill.desc2" tag="p">
          <strong>{{
            $t(
              'pages.onboarding.poa.differenceWithWill.powerOfAttorneyEffectEnd'
            )
          }}</strong>
        </i18n>
        <i18n path="pages.onboarding.poa.differenceWithWill.desc3" tag="p">
          <strong>{{
            $t('pages.onboarding.poa.differenceWithWill.effectOnceYouPassAway')
          }}</strong>
        </i18n>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.needEnduringPOA.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.needEnduringPOA.desc1') }}
        </p>
        <p>
          {{ $t('pages.onboarding.poa.needEnduringPOA.desc2') }}
        </p>
        <p>
          {{ $t('pages.onboarding.poa.needEnduringPOA.desc3') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.withoutEnduringPOA.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.withoutEnduringPOA.desc1') }}
        </p>
        <p>
          {{ $t('pages.onboarding.poa.withoutEnduringPOA.desc2') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.getStarted.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.getStarted.desc') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.cost.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.cost.desc', { cost: POA_COST }) }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.time.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.time.desc') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.powers.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.powers.desc') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.limitations.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.limitations.desc') }}
        </p>
      </template>
    </AccordionPanel>
    <AccordionPanel>
      <template #title>
        {{ $t('pages.onboarding.poa.previousPOA.title') }}
      </template>
      <template #content>
        <p>
          {{ $t('pages.onboarding.poa.previousPOA.desc') }}
        </p>
      </template>
    </AccordionPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { stateCodeToStateName } from '@/modules/stateCodeToStateName';

import AccordionPanel from '@/components/molecules/AccordionPanel';
import BaseIcon from '@/components/BaseIcon';
import Headline from '@/components/atoms/Headline';
import Box from '@/components/atoms/Box';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { user, will } from '@/mixins/apollo';
import { PRICE_DEFAULTS } from '@/store/checkout';

export default {
  name: 'PagesAffiliateOnboardingPoaIndex',
  components: {
    AccordionPanel,
    BaseIcon,
    Headline,
    Box,
    Buttons,
    RuButton,
  },
  mixins: [user, will],
  layout: 'ruach',
  data() {
    return {
      wishes: [
        {
          iconId: 'wallet-bg',
          title: this.$t('pages.onboarding.poa.wishes.financialDecisions'),
          description: this.$t(
            'pages.onboarding.poa.wishes.financialDecisionsDesc'
          ),
        },
        {
          iconId: 'pen-bg',
          title: this.$t('pages.onboarding.poa.wishes.legalAffairs'),
          description: this.$t('pages.onboarding.poa.wishes.legalAffairsDesc'),
        },
        {
          iconId: 'aidkit-bg',
          title: this.$t('pages.onboarding.poa.wishes.medicalTreatment'),
          description: this.$t(
            'pages.onboarding.poa.wishes.medicalTreatmentDesc'
          ),
        },
        {
          iconId: 'house-bg',
          title: this.$t('pages.onboarding.poa.wishes.lifestyleDecisions'),
          description: this.$t(
            'pages.onboarding.poa.wishes.lifestyleDecisionsDesc'
          ),
        },
      ],
      POA_COST: PRICE_DEFAULTS.DEFAULT_POA_PRICE,
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    userStateName() {
      return this.userState ? stateCodeToStateName(this.userState) : null;
    },
    fullName() {
      let name = '';
      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();
        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }
        name = `${name} ${this.willMeta.name_last.trim()}`;
      }
      return name;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &onboarding-poa {
    &__shield {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(40%);
      width: 80%;
      height: 80%;
      z-index: 0;

      &-container {
        position: absolute;
        inset: 0;
        border-radius: inherit;
        overflow: hidden;
      }

      &-sibling {
        position: relative;
      }
    }
  }
}
</style>
