<template>
  <div class="ru:app">
    <nuxt-link :to="localePath('/')" class="ru:app__logos">
      <Logo :variant="logoVariant" />
      <AffiliateLogo v-if="hasAffiliateLogo" />
    </nuxt-link>
    <User />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Logo from '@/components/atoms/Logo';
import AffiliateLogo from '@/components/atoms/AffiliateLogo';
import User from '@/components/molecules/User';

export default {
  name: 'ComponentsOrganismsApp',
  components: {
    Logo,
    User,
    AffiliateLogo,
  },
  computed: {
    ...mapGetters('ruach', ['logoVariant']),
    ...mapState('affiliate', ['branding']),
    hasAffiliateLogo() {
      return this.branding.logo;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &app {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--app-padding-y) var(--app-padding-x);
    background: --rgba(white);
    box-shadow: 0 15px 40px 0 --rgba(glacier, 0.125);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-app;

    a,
    button {
      pointer-events: all;
    }

    #{$ru}user {
      @include mq('max-lg') {
        display: none;
      }
    }

    &__logos {
      display: flex;
      align-items: center;
    }

    #{$ru}icon {
      @include mq('min-md') {
        display: none;
      }
    }
  }
}
</style>
