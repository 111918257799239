<template>
  <BaseModal @close="close">
    <h2 class="mb-4 text-4xl leading-tight">
      {{ $t('components.newInstitutionModal.title') }}
    </h2>
    <AdiForm
      auto-focus
      :mutation="CREATE_INSTITUTION_MUTATION"
      :variables="variables"
      submit-wide
      submit-label="Add Institution"
      @update="update"
      @done="close"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="name"
            v-model="formData.name"
            :label="$t('components.newInstitutionModal.name')"
            rules="required"
            compact
          />
        </FormRow>
        <FormRow>
          <SelectInput
            id="type"
            v-model="formData.type"
            :options="types"
            :label="$t('components.newInstitutionModal.type')"
            required
            compact
          />
        </FormRow>
        <FormRow v-if="typeSelectedIsOther">
          <TextInput
            id="typeOther"
            v-model="formData.typeOther"
            :label="$t('components.newInstitutionModal.otherType')"
            rules="required"
            compact
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </BaseModal>
</template>

<script>
import CREATE_INSTITUTION_MUTATION from '@/graphql/mutations/CreateInstitution.gql';
import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import TextInput from '@/components/TextInput';
import SelectInput from '@/components/SelectInput';

import institutionsAdmin from '@/mixins/institutions-admin';

export default {
  name: 'ComponentsAdminNewInstitutionModal',
  components: {
    BaseModal,
    AdiForm,
    FormRow,
    FormSection,
    TextInput,
    SelectInput,
  },
  mixins: [institutionsAdmin],
  data: () => ({
    CREATE_INSTITUTION_MUTATION,
    formData: {
      name: null,
      type: null,
    },
  }),
  computed: {
    variables() {
      return {
        name: this.formData.name,
        type: this.type,
        archived: false,
      };
    },
    typeSelectedIsOther() {
      return this.formData.type === 'Other';
    },
    type() {
      return this.typeSelectedIsOther
        ? this.formData.typeOther
        : this.formData.type;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    update(store, res) {
      const createdInstitution = res.data.createInstitution;
      this.$emit('institutionAdded', createdInstitution);
    },
  },
};
</script>
