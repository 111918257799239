<template>
  <div class="w-full relative">
    <label v-if="label" :for="id" class="input-label" :class="labelClass">{{
      label
    }}</label>
    <BaseInput
      :id="id"
      v-model="modelValue"
      :compact="compact"
      :disabled="disabled"
      :name="id"
      :placeholder="placeholder"
      :rules="rules"
      :type="type"
      :value="value"
      :classes="classes"
      :mask="mask"
      @blur="blur"
      @focus="focus"
      @input="onChange"
    />
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';

export default {
  name: 'ComponentsTextInput',
  components: {
    BaseInput,
  },
  props: {
    compact: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
    type: {
      default: 'text',
      type: String,
    },
    mask: {
      default: false,
      type: [Boolean, String],
    },
    value: {
      default: '',
      type: [String, Number],
    },
    classes: {
      default: '',
      type: String,
    },
    fancy: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modelValue: this.value,
      focused: false,
    };
  },
  computed: {
    started() {
      return !!this.modelValue;
    },
    labelClass() {
      return {
        'fancy-label': this.fancy,
        'fancy-label--active': this.fancy && (this.focused || this.started),
      };
    },
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur($event) {
      this.focused = false;
      this.$emit('blur', $event);
    },
    onChange() {
      this.$emit('input', this.modelValue, this);
    },
  },
};
</script>
