var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":3,"prev-step":"/will"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_liabilities,"submit-icon":"arrow-right"},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.liabilities.question'))+"\n      ")]),_vm._v(" "),_c('p',{class:{ 'mb-10': _vm.isFreeWillWeek }},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.liabilities.desc'))+"\n      ")]),_vm._v(" "),(!_vm.isFreeWillWeek || _vm.willMeta.asset_note)?_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.liabilities.youCanAddNotes'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasLiabilities","options":[
          {
            label: _vm.$t('forms.labels.yes'),
            value: true,
          },
          {
            label: _vm.$t('forms.labels.no'),
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_liabilities),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_liabilities", $$v)},expression:"willMeta.has_liabilities"}})],1),_vm._v(" "),(_vm.willMeta.has_liabilities)?_c('FormSection',[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('texts.sentences.yourLiabilities'))+"\n      ")]),_vm._v(" "),(!_vm.selectedLiabilities.length)?_c('EmptyState',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.liabilities.noLiabilities'))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.selectedLiabilities),function(liability,index){return _c('LiabilityInputs',{key:index,attrs:{"id":`liability_${liability.id || index}`,"disabled":loading,"description":liability.meta.description,"type":liability.meta.type},on:{"description":function($event){liability.meta.description = $event},"type":function($event){liability.meta.type = $event},"add":_vm.addLiabilityInput,"remove":function($event){return _vm.removeLiabilityInput(liability)}}})}),_vm._v(" "),_c('Buttons',{attrs:{"layout":"end"}},[_c('RuButton',{attrs:{"size":"large","level":"secondary"},on:{"click":_vm.addLiabilityInput}},[_vm._v("\n          + "+_vm._s(_vm.$t('forms.labels.add'))+"\n        ")])],1)],2):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }