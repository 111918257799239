<template>
  <div class="ru:container ru:container--tight">
    <Checkout
      ref="checkoutComponent"
      is-generic
      :checkout-items="checkoutItems"
      :products-with-custom-prices-token="
        checkoutData.productsWithCustomPricesToken
      "
      @orderCreated="ordersCreationSuccessfulHandler"
    >
      <template #PAYMENT>
        <Headline :level="1">
          {{ $t('pages.checkout.secureCheckout') }}
        </Headline>
      </template>
    </Checkout>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { decodePayloadFromUrl, signJwt } from '@/utilities';

import Headline from '@/components/atoms/Headline';
import Checkout from '@/components/templates/Checkout';

export default {
  name: 'PagesAffiliateCheckoutGenericIndex',
  components: {
    Headline,
    Checkout,
  },
  middleware: ['authenticated'],
  data() {
    return {
      checkoutData: {
        customProducts: [],
        productsWithCustomPricesToken: null,
      },
      checkoutItems: [],
    };
  },
  created() {
    this.checkoutData = decodePayloadFromUrl(this.$route.query.checkoutData);
    this.validateProducts(this.checkoutData);
    this.checkoutData.productsWithCustomPricesToken = signJwt({
      id: uuid(),
      productPricePairs: this.checkoutData.customProducts,
    });
    this.checkoutItems = this.checkoutData.customProducts.map(
      ({ name, price }) => ({
        product: name,
        finalPrice: price,
        type: 'DEFAULT',
      })
    );
  },
  methods: {
    validateProducts({ customProducts }) {
      if (Array.isArray(customProducts) && customProducts.length > 0) {
        return;
      }
      throw new Error('Invalid products passed');
    },
    ordersCreationSuccessfulHandler() {
      this.$router.push({ path: this.localePath('/checkout/generic/thanks') });
    },
  },
};
</script>
