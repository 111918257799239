<template functional>
  <div class="ru:image">
    <img :src="props.src" alt="" :width="props.width" :height="props.height" />
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsImage',
};
</script>

<style lang="scss">
#{$ru} {
  &image {
    display: flex;
    justify-content: space-around;
    margin-bottom: var(--base-margin);

    img {
      max-width: 100%;
    }
  }
}
</style>
