<template>
  <div class="ru:admin-card__section">
    <Headline v-if="title" :level="4">{{ title }}</Headline>
    <slot />
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';
export default {
  name: 'ComponentsAdminCardSection',
  components: {
    Headline,
  },
  props: {
    title: {
      default: null,
      type: String,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-card {
    & &__section {
      margin-bottom: var(--base-margin-small);
    }
  }
}
</style>
