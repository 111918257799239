<template>
  <div class="ru:sent-invite">
    <div v-if="sentProducts.length" class="ru:sent-invite__sent-products">
      {{ $t('components.sentInvite.product') }}
      {{ sentProducts.length > 1 ? 's' : '' }}:
      <template v-for="(product, index) in sentProducts">
        <div :key="`product${index}`" class="ru:sent-invite__sent-product">
          <Badge color="white" background="theme" size="small">
            <Icon id="check" />
          </Badge>
          {{ productToDisplayNameMapping[product] }}
        </div>
      </template>
    </div>
    <div class="ru:sent-invite__detail">
      <p>
        {{ $t('components.sentInvite.to') }} <strong>{{ invite.email }}</strong>
      </p>
      <Buttons layout="end">
        <RuButton
          v-if="!recentlyInvited"
          level="plain"
          :disabled="loading"
          @click="sendReminder(invite.id)"
        >
          {{ $t('components.sentInvite.resend') }}
        </RuButton>
        <RuButton level="plain" :disabled="loading" @click="openEditEmailModal">
          {{ $t('components.sentInvite.editEmail') }}
        </RuButton>
        <RuButton
          level="plain"
          theme="error"
          :disabled="loading"
          @click="openCancelGiftModal"
        >
          {{ $t('components.sentInvite.cancel') }}
        </RuButton>
      </Buttons>
    </div>
    <Loading v-if="loading" />
    <div v-else class="ru:sent-invite__status">
      <Icon id="completed" theme="success" /> {{ inviteStatus }}
    </div>
    <Alert v-if="error" theme="error" level="medium">
      {{ error }}
    </Alert>
    <Alert v-else-if="success" theme="success" level="medium">
      {{ success }}
    </Alert>
    <Modal v-if="editEmailModalIsVisible" @closeModal="closeEditEmailModal">
      <template #header> {{ $t('components.sentInvite.editEmail') }} </template>
      <template #body>
        <FormWrapper @submit="updateInviteEmail">
          <FormControl
            id="inviteEmail"
            v-model="newEmail"
            type="text"
            placeholder="New email"
          />
          <Buttons layout="end">
            <RuButton type="submit">{{
              $t('components.sentInvite.update')
            }}</RuButton>
          </Buttons>
        </FormWrapper>
      </template>
    </Modal>
    <Modal v-if="cancelGiftModalIsVisible" @closeModal="closeCancelGiftModal">
      <template #header>
        {{ $t('components.sentInvite.cancelGift') }}
      </template>
      <template #body>
        <p>
          {{ $t('components.sentInvite.aboutToCancel') }}
          {{ invite.products.length > 1 ? 'gifts' : 'gift' }}:
        </p>
        <p class="ru:sent-invite__cancel-gifts">
          <strong> {{ invite.products.join(', ') }} </strong>
          <br />
          {{ $t('components.sentInvite.sentTo') }}<br />
          <strong>{{ invite.email }}</strong>
        </p>
        <Buttons layout="stretch">
          <RuButton
            level="secondary"
            theme="default"
            :disabled="loading"
            @click="closeCancelGiftModal"
          >
            <Loading v-if="loading" />
            {{ $t('components.sentInvite.cancel') }}
          </RuButton>
          <RuButton
            level="primary"
            theme="error"
            :disabled="loading"
            @click="archiveInvite"
          >
            <Loading v-if="loading" />
            {{ $t('components.sentInvite.confirm') }}
          </RuButton>
        </Buttons>
      </template>
    </Modal>
  </div>
</template>

<script>
import GET_INVITES_QUERY from '@/graphql/queries/GetInvites';
import SEND_INVITE_REMINDER from '@/graphql/mutations/SendInviteReminder';
import ARCHIVE_INVITE_MUTATION from '@/graphql/mutations/ArchiveInvite';
import UPDATE_INVITE_MUTATION from '@/graphql/mutations/UpdateInvite';

import Loading from '@/components/atoms/Loading';
import Badge from '@/components/atoms/Badge';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';

import FormControl from '@/components/molecules/FormControl';
import Modal from '@/components/molecules/Modal';
import Alert from '@/components/molecules/Alert';

import FormWrapper from '@/components/organisms/Form';

import dialog from '@/mixins/message-box';
import { will, user } from '@/mixins/apollo';
import {
  formatError,
  productToDisplayNameMapping,
  is24HoursFromNow,
} from '@/utilities';

export default {
  name: 'ComponentsOrganismsSentInvite',
  components: {
    Alert,
    Buttons,
    RuButton,
    Icon,
    Badge,
    Modal,
    FormControl,
    Loading,
    FormWrapper,
  },
  mixins: [will, user, dialog],
  props: {
    invite: {
      type: Object,
      required: true,
    },
    productsToShow: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: '',
      success: '',
      loading: false,
      done: false,
      showAlert: null,
      alertTheme: null,
      alertMessage: null,
      editEmailModalIsVisible: false,
      cancelGiftModalIsVisible: false,
      newEmail: '',
      productToDisplayNameMapping,
    };
  },
  computed: {
    inviteStatus() {
      if (this.isRedeemed) {
        return 'Gift redeemed';
      }
      return 'Gift sent';
    },
    isRedeemed() {
      return this.invite.type !== 'PUBLIC' && this.invite.type.purchased;
    },
    recentlyInvited() {
      return is24HoursFromNow(this.invite.remindedAt);
    },
    sentProducts() {
      return this.productsToShow.filter((product) => {
        return this.invite.products.includes(product);
      });
    },
    variables() {
      return {
        type: this.type,
        email: this.email,
      };
    },
    getInvitesQuery() {
      return {
        query: GET_INVITES_QUERY,
        variables: {
          userId: this.userId,
        },
        skip() {
          return !this.userId;
        },
      };
    },
  },
  methods: {
    async sendReminder(id) {
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: SEND_INVITE_REMINDER,
          variables: { id },
          update: (store, { data: { sendInviteReminder } }) => {
            const data = store.readQuery(this.getInvitesQuery);
            const index = data.getInvites.invites.findIndex(
              (invite) => invite.id === id
            );
            if (index !== -1) {
              data.getInvites.invites.splice(
                index,
                1,
                sendInviteReminder.invite
              );
              store.writeQuery({
                ...this.getInvitesQuery,
                data,
              });
            }
          },
        });
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'invite_sent',
          props: {
            reminder: true,
            inviteId: id,
          },
        });
        this.alertTheme = 'success';
        this.alertMessage = data.sendInviteReminder.message;
      } catch (error) {
        this.alertTheme = 'error';
        this.alertMessage = formatError(error.message);
      }
      this.loading = false;
    },
    openCancelGiftModal() {
      this.cancelGiftModalIsVisible = true;
    },
    closeCancelGiftModal() {
      this.cancelGiftModalIsVisible = false;
    },
    openEditEmailModal() {
      this.editEmailModalIsVisible = true;
    },
    closeEditEmailModal() {
      this.editEmailModalIsVisible = false;
    },
    async updateInviteEmail() {
      this.closeEditEmailModal();

      this.error = null;
      this.success = null;
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_INVITE_MUTATION,
          variables: {
            id: this.invite.id,
            email: this.newEmail,
            products: this.invite.products,
          },
          update(store, { data: { updateInvite } }) {
            const data = store.readQuery(this.getInvitesQuery);
            const index = data.getInvites.invites.findIndex(
              (invite) => invite.id === this.invite.id
            );
            if (index !== -1) {
              data.getInvites.invites.splice(index, 1, updateInvite);
              store.writeQuery({
                ...this.getInvitesQuery,
                data,
              });
            }
          },
        });
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'invite_updated',
          props: {
            inviteId: this.invite.id,
          },
        });
        this.success = this.$t('components.sentInvite.emailUpdated');
      } catch (error) {
        this.error = formatError(error.message);
      }
      this.newEmail = null;
      this.loading = false;
    },
    async archiveInvite() {
      this.error = null;
      this.alertMessage = null;
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: ARCHIVE_INVITE_MUTATION,
          variables: {
            id: this.invite.id,
          },
          update: (store, { data: { archiveInvite } }) => {
            if (archiveInvite) {
              const data = store.readQuery(this.getInvitesQuery);
              const index = data.getInvites.invites.findIndex(
                (invite) => invite.id === this.invite.id
              );
              if (index !== -1) {
                data.getInvites.invites.splice(index, 1);
                store.writeQuery({
                  ...this.getInvitesQuery,
                  data,
                });
              }
            }
          },
        });

        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'invite_archived',
          props: {
            inviteId: this.invite.id,
          },
        });
      } catch (error) {
        this.error = formatError(error.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &sent-invite {
    padding-bottom: var(--base-margin);

    &__sent-products {
      display: flex;
      margin-bottom: var(--base-margin-small);
      color: --rgba(rock);
    }

    &__sent-product {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      color: --rgba(basalt);
      margin-left: var(--base-margin);
    }

    &__redeemed {
      color: --rgba(theme);
    }

    &__detail {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &__status {
      display: flex;
      flex-direction: row;
      color: --rgba(grass);
      margin-bottom: var(--base-margin);

      svg {
        margin-right: var(--base-margin-small);
      }
    }

    &__resend {
      margin-left: auto;
    }

    &__cancel-gifts {
      text-align: center;
    }
  }
}
</style>
