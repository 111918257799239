<template>
  <div
    ref="containerContent"
    class="ru:iframe-reader__container"
    @scroll="onScroll"
  >
    <div v-if="srcDoc" class="ru:iframe-reader__content">
      <iframe
        class="ru:iframe-reader__content-iframe"
        :srcdoc="srcDoc"
        :title="title"
      />
      <!-- eslint-disable vue/no-v-html -->
      <div class="ru:iframe-reader__content-div" v-html="srcDoc" />
      <!--eslint-enable-->
    </div>
    <div
      v-else-if="getUrlFailed"
      class="ru:iframe-reader__loading ru:iframe-reader__loading--failed"
    >
      {{ $t('components.iframeReader.failed') }}
    </div>
    <div v-else class="ru:iframe-reader__loading">
      {{ $t('components.iframeReader.loading') }}
    </div>
  </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
export default {
  name: 'ComponentsOrganismsIframeReader',
  components: {},
  props: {
    readContent: {
      default: false,
      type: Boolean,
    },
    url: {
      required: true,
      type: String,
    },
    maxAttempts: {
      default: 30,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      srcDoc: null,
      loadAttempts: 0,
      getUrlFailed: false,
    };
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    fetchContent() {
      const getUrlFailed = () => {
        this.getUrlFailed = true;
        this.$emit('getUrlFailed');
        clearInterval(fetchInterval);
      };
      const fetchInterval = setInterval(() => {
        this.loadAttempts++;
        if (this.loadAttempts < this.maxAttempts) {
          fetch(this.url)
            .then((response) => {
              if (response.ok) {
                clearInterval(fetchInterval);
                return response.text();
              }
            })
            .then((html) => {
              this.srcDoc = html;
            })
            .catch(() => {
              getUrlFailed();
              throw new Error(
                this.$t('components.iframeReader.fetchContentError')
              );
            });
        } else {
          getUrlFailed();
        }
      }, 1000);
    },
    onScroll: debounce(100, function () {
      if (!this.readContent) {
        const scrollTop = this.$refs.containerContent.scrollTop;
        const clientHeight = this.$refs.containerContent.clientHeight;
        const scrollHeight = this.$refs.containerContent.scrollHeight;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          this.$emit('input', true);
        }
      }
    }),
  },
};
</script>
<style lang="scss">
#{$ru} {
  &iframe-reader {
    &__container {
      margin-bottom: var(--base-margin);
      border-radius: 0.5rem;
      background: --rgba(white);
      border: 1px solid --rgba(silver);
      height: 30rem;
      padding: 1px;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
    }

    &__loading {
      text-align: center;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: 50% #{calc(50% - 2.5rem)};
      padding: 2.5rem 1rem 1rem;
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100"><circle cx="50" cy="50" r="35" fill="none" stroke="#fff" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="20"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle><circle cx="50" cy="50" r="35" fill="none" stroke="#00DABF" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="10"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg>'
      );

      &--failed {
        background: unset;
      }
    }

    &__content {
      margin: 1.25rem;
      position: relative;

      &-iframe {
        border: 0;
        position: absolute;
        height: 100%;
        width: 100%;
      }

      &-div {
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
}
</style>
