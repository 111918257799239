<template functional>
  <component
    :is="`h${props.level}`"
    class="ru:headline"
    :class="[
      data.staticClass,
      props.centered ? '--centered' : null,
      props.flush ? '--flush' : null,
      props.row ? '--row' : null,
      props.big ? '--big' : null,
    ]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ComponentsAtomsHeadline',
  props: {
    level: {
      default: 6,
      type: Number,
    },
    big: {
      default: false,
      type: Boolean,
    },
    row: {
      default: false,
      type: Boolean,
    },
    centered: {
      default: false,
      type: Boolean,
    },
    flush: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &headline {
    small {
      display: block;
      font-size: 0.5em;
      line-height: 1;
      color: --rgba(gravel);
      margin-bottom: var(--base-margin-small);
    }
    &#{$mf}big small {
      font-size: 0.625em;
      margin-top: 0.25rem;
    }

    &#{$mf}centered {
      text-align: center;
    }

    &#{$mf}flush {
      margin-bottom: 0 !important;
      line-height: 1;
    }

    &#{$mf}row {
      display: flex;
      align-items: center;
      width: 100%;
    }

    #{$ru}icon,
    .icon {
      display: inline;
      vertical-align: bottom;
      position: relative;
      margin-right: 0.5rem;
    }
  }
}
</style>
