<template>
  <div class="ru:container ru:container--tight">
    <Checkout
      ref="checkoutComponent"
      :checkout-items="checkoutItems"
      @orderCreated="ordersCreationSuccessfulHandler"
    >
      <template #PAYMENT>
        <Headline :level="1">
          {{ $t('pages.checkout.addPowersOfAttorney', { firstName }) }}
        </Headline>
        <p>
          {{ $t('pages.checkout.financialMedicalAttorney') }}
        </p>
      </template>
    </Checkout>
  </div>
</template>

<script>
import Checkout from '@/components/templates/Checkout';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

const checkoutItems = [
  {
    product: 'WILL',
    type: 'GIFTED_ONLY',
  },
  {
    product: 'POA',
    type: 'DEFAULT',
  },
  {
    product: 'SUBSCRIPTION',
    type: 'DEFAULT',
  },
];

export default {
  name: 'PagesAffiliateCheckoutPoaIndex',
  components: {
    Checkout,
    Headline,
  },
  mixins: [user, will],
  middleware: ['authenticated'],
  data() {
    return {
      checkoutItems,
    };
  },
  computed: {
    firstName() {
      return this.willMeta.name_first;
    },
  },
  methods: {
    ordersCreationSuccessfulHandler() {
      this.$router.push({
        path: this.localePath(
          `/checkout/poa/${
            this.$ff.isShowingGiftsInCheckout() ? 'thank-you' : 'thanks'
          }`
        ),
      });
    },
  },
};
</script>
