<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.postalAddress') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.cremationServiceReturnAddress') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('final')">
      <Headline :level="3">{{ $t('texts.sentences.yourAddress') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="postalAddressLine1"
            v-model="formData.postalAddressLine1"
            :required="false"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.addressLine1')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="postalAddressLine2"
            v-model="formData.postalAddressLine2"
            :required="false"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.addressLine2')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="postalAddressSuburb"
            v-model="formData.postalAddressSuburb"
            :required="false"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.suburb')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="postalAddressState"
            v-model="formData.postalAddressState"
            :required="false"
            :disabled="loading"
            type="select"
            :text="$t('forms.labels.australianState')"
            :options="australianStatesOptions"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="postalAddressPostcode"
            v-model="formData.postalAddressPostcode"
            :required="false"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.4digitPostcode')"
            inputmode="decimal"
            min="4"
            max="4"
            pattern="[0-9]{4}"
          />
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="goNext">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="goNext">
          {{ $t('forms.labels.ok') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'PagesAffiliateCremationsAtNeedAddressId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      formData: {
        postalAddressLine1: null,
        postalAddressLine2: null,
        postalAddressSuburb: null,
        postalAddressState: null,
        postalAddressPostcode: null,
      },
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/documentation/final/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/children/${this.cremationRequestId}`
      );
    },
  },
};
</script>
