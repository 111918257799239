<template>
  <ModuleStep :index="3" prev-step="/will/estate/beneficiaries">
    <AdiForm
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit="submit"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.estate.beneficiaryProportionsQuestion') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.estate.beneficiaryProportionsNote') }}
        </Tip>
        <CheckboxInput
          id="isPrimaryEstateSplitEvenly"
          v-model="isPrimaryEstateSplitEvenly"
          class="w-full mb-8"
        >
          {{ $t('pages.will.estate.splitEvenlyCheckbox') }}
        </CheckboxInput>
        <BeneficiaryInputs
          v-model="distributions"
          :beneficiaries="beneficiaries"
          :is-split-evenly="isPrimaryEstateSplitEvenly"
          @valid="checkValid"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import BeneficiaryInputs from '@/components/BeneficiaryInputs';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import CheckboxInput from '@/components/CheckboxInput';

import { will, beneficiaries, user } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillEstateDistribution',
  components: {
    CheckboxInput,
    AdiForm,
    FormSection,
    ModuleStep,
    BeneficiaryInputs,
    Tip,
    Headline,
  },
  mixins: [will, beneficiaries, user],
  data() {
    return {
      distributions: [],
      valid: false,
      isPrimaryEstateSplitEvenly: false,
    };
  },
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    isComplete() {
      return !!this.valid;
    },
  },
  watch: {
    will(newWillValue) {
      this.isPrimaryEstateSplitEvenly = newWillValue.isPrimaryEstateSplitEvenly;
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await this.updateWillIsPrimaryEstateSplitEvenly(
        this.isPrimaryEstateSplitEvenly
      );
      await Promise.all(
        this.beneficiaries.map((beneficiary, i) => {
          return this.updateBeneficiaryDistribution(
            beneficiary,
            this.isPrimaryEstateSplitEvenly ? null : this.distributions[i]
          );
        })
      );

      const hasPeople = this.beneficiaries.find((b) => b.person !== null);

      if (hasPeople) {
        this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });
        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage ? '/will/charity-consent' : '/will'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>
