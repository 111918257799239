var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/will"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_gifts,"submit-icon":"arrow-right"},on:{"done":_vm.done}},[_c('FormSection',{staticClass:"step-1"},[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.gifts.leaveGift'))+"\n        "),_c('GlossaryTooltip',{attrs:{"term":"gift"}},[_vm._v("\n          "+_vm._s(_vm.$t('texts.words.gift'))+"\n        ")]),_vm._v("\n        ? 🎁\n      ")],1),_vm._v(" "),_c('p',{staticClass:"mb-8 sm:mb-12 text-2xl"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.gifts.giftInfo'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.gifts.specificGifts'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_gifts","options":[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_gifts),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_gifts", $$v)},expression:"willMeta.has_gifts"}})],1),_vm._v(" "),(_vm.willMeta.has_gifts)?_c('FormSection',{staticClass:"step-2"},[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.gifts.giftType'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.gifts.proportionalGifts'))+"\n      ")]),_vm._v(" "),_vm._l((_vm.gifts),function(gift,index){return _c('MetaSlot',{key:index,attrs:{"gift-meta":gift.meta,"person-meta":gift.person && gift.person.meta,"charity-meta":gift.charity && gift.charity.meta},scopedSlots:_vm._u([{key:"default",fn:function({ giftMeta, personMeta, charityMeta }){return [_c('DataCard',{attrs:{"title":giftMeta.type === 'money'
              ? `$${giftMeta.amount}`
              : giftMeta.description,"description":personMeta.full_name ||
            charityMeta.displayName ||
            charityMeta.name,"icon":giftMeta.type === 'money' ? 'wallet' : 'gift',"edit":"","remove":"","type":"gift"},on:{"edit":function($event){return _vm.editGift(gift)},"remove":function($event){return _vm.removeGift(gift)}}},[(giftMeta.note)?_c('Box',{attrs:{"shadow":""}},[_c('Headline',{attrs:{"flush":"","level":3}},[_c('em',[_vm._v(_vm._s(_vm.$t('texts.sentences.youWrote')))])]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(giftMeta.note)+"\n            ")])],1):_vm._e()],1)]}}],null,true)})}),_vm._v(" "),_c('Buttons',{attrs:{"layout":"stretch"}},[_c('RuButton',{attrs:{"size":"large","level":"secondary"},on:{"click":_vm.addGift}},[_vm._v("\n          + "+_vm._s(_vm.$t('forms.labels.addAGift'))+"\n        ")])],1),_vm._v(" "),(_vm.showGiftWizard)?_c('GiftWizard'):_vm._e()],2):_vm._e()],1),_vm._v(" "),_c('GlossaryPanel')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }