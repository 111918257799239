<template>
  <div ref="radioButtons" class="ru:radio-buttons">
    <Buttons :layout="layout">
      <RuButton
        v-for="(option, index) in options"
        :id="`${id}__${option.value}`"
        :key="index"
        :size="size"
        level="secondary"
        :active="option.value === value"
        @click="$emit('input', option.value)"
      >
        {{ option.label }}
      </RuButton>
    </Buttons>
  </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsLargeRadioButtons',
  components: {
    Buttons,
    RuButton,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    layout: {
      default: 'stretch',
      type: String,
    },
    id: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [Boolean, String],
    },
  },
  data() {
    return {
      size: 'large',
    };
  },
  mounted() {
    this.measureAndAdjust();
    window.addEventListener('resize', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    measureAndAdjust: debounce(100, async function () {
      this.size = 'large';
      await this.$nextTick();
      if (
        this.$refs.radioButtons.scrollWidth >
        this.$refs.radioButtons.offsetWidth
      ) {
        this.size = null;
      }
    }),
  },
};
</script>

<style lang="scss">
#{$ru} {
  &radio-buttons {
    margin-bottom: var(--base-margin);
    width: 100%;
  }
}
</style>
