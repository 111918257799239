<template>
  <ProductGateway
    :title="$t('components.vaultGateway.title')"
    icon="end-of-life"
    :primary-cta-list="[cta]"
    :items-cta="cta"
    :items="items"
    :step="3"
    :items-description="itemsDescription"
    :progress="progress"
    :completed="progress === 100"
  >
    <RuImage :src="require('@/assets/svg/gateway__vault.svg')" alt="" />
    <p>
      {{ $t('components.vaultGateway.description') }}
    </p>
  </ProductGateway>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductGateway from '@/components/molecules/ProductGateway';
import RuImage from '@/components/atoms/Image';

export default {
  name: 'ComponentsTemplatesVaultGateway',
  components: {
    ProductGateway,
    RuImage,
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('end-of-life-categories', [
      'endOfLifeCategoryItemCounts',
      'endOfLifeCategoryTree',
    ]),
    totalVaultItems() {
      return Object.values(this.endOfLifeCategoryItemCounts).reduce(
        (acc, val) => acc + val,
        0
      );
    },
    cta() {
      if (this.totalVaultItems > 0) {
        return {
          link: '/end-of-life',
          text: this.$t('components.vaultGateway.continue'),
        };
      }
      return {
        link: '/onboarding/end-of-life',
        text: this.$t('components.vaultGateway.start'),
      };
    },
    itemsDescription() {
      return `${this.totalVaultItems} ${this.$t(
        'components.vaultGateway.addedItems'
      )}`;
    },
    endOfLifeCategoryTreeWithTotalItems() {
      return this.endOfLifeCategoryTree?.map((category) => {
        let hasItems = this.endOfLifeCategoryItemCounts[category.id] > 0;
        if (!hasItems && category.subcategories) {
          hasItems = category.subcategories.some(
            (subcategory) =>
              this.endOfLifeCategoryItemCounts[subcategory.id] > 0 ||
              subcategory.subcategories.some(
                (subsubcategory) =>
                  this.endOfLifeCategoryItemCounts[subsubcategory.id] > 0
              )
          );
        }
        return {
          ...category,
          hasItems,
        };
      });
    },
    items() {
      return this.endOfLifeCategoryTreeWithTotalItems?.map((category) => ({
        title: category.name,
        status: category.hasItems > 0 ? 'in-progress' : 'not-started',
        cta: `/end-of-life/${category.slug}`,
      }));
    },
    progress() {
      if (!this.endOfLifeCategoryItemCounts) {
        return 0;
      }
      const categoryHasItems = Object.values(
        this.endOfLifeCategoryItemCounts
      ).filter((count) => count > 0).length;

      // 95% is the max progress
      return (
        (categoryHasItems /
          Object.keys(this.endOfLifeCategoryItemCounts).length) *
        95
      );
    },
  },
};
</script>
