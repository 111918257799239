<template>
  <div class="w-full">
    <label v-if="label" class="input-label" :for="day"> {{ label }} </label>
    <div class="flex">
      <BaseInput
        id="day"
        ref="day"
        v-model="day"
        class="mr-4 w-32"
        :compact="compact"
        :disabled="disabled"
        name="day"
        placeholder="DD"
        type="number"
        :min="1"
        :max="31"
        :classes="classes"
        @input="onInput('day', $event)"
        @blur="onBlur('day', $event)"
      />
      <BaseInput
        id="month"
        ref="month"
        v-model="month"
        class="mr-4 w-32"
        :compact="compact"
        :disabled="disabled"
        name="month"
        placeholder="MM"
        type="number"
        :min="1"
        :max="12"
        :classes="classes"
        @input="onInput('month', $event)"
        @blur="onBlur('month', $event)"
      />
      <BaseInput
        id="year"
        ref="year"
        v-model="year"
        class="mr-4 w-40"
        :compact="compact"
        :disabled="disabled"
        name="year"
        placeholder="YYYY"
        :min="1900"
        :max="9999"
        type="number"
        :classes="classes"
        @input="onInput('year', $event)"
        @blur="onBlur('year', $event)"
        @keydown="onKeydown('year', $event)"
      />
    </div>
    <ValidationProvider
      v-slot="{ errors }"
      ref="provider"
      :disabled="!rules"
      :mode="customInteraction"
      :rules="rules"
      tag="div"
    >
      <input
        :id="id"
        v-model="modelValue"
        class="hidden"
        :compact="compact"
        :disabled="disabled"
        :rules="rules"
        name="date"
      />
      <p
        v-for="error in errors"
        :key="error"
        class="mt-1 text-red-300 truncate"
      >
        {{ getErrorMessage(error) }}
      </p>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import BaseInput from '@/components/BaseInput';

export default {
  name: 'ComponentsDateInput',
  components: {
    BaseInput,
    ValidationProvider,
  },
  props: {
    compact: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
    type: {
      default: 'text',
      type: String,
    },
    mask: {
      default: false,
      type: [Boolean, String],
    },
    value: {
      default: '',
      type: String,
    },
    classes: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      modelValue: this.value,
      day: null,
      month: null,
      year: null,
      error: null,
    };
  },
  computed: {
    output() {
      return `${this.day}/${this.month}/${this.year}`;
    },
  },
  mounted() {
    const parts = this.value?.split('/');
    if (parts?.length) {
      this.day = parts[0];
      this.month = parts[1];
      this.year = parts[2];
    }
  },
  methods: {
    onBlur(field, event) {
      const val = event.target.value;
      switch (field) {
        case 'day':
          this.day = val.length === 1 ? `0${val}` : val;
          this.modelValue = this.output;
          break;
        case 'month':
          this.month = val.length === 1 ? `0${val}` : val;
          this.modelValue = this.output;
          break;
        default:
          this.modelValue = this.output;
          break;
      }
      this.$emit('input', this.modelValue);
    },
    onInput(field, val) {
      const monthEl = this.$refs.month.$el.querySelector('input');
      const yearEl = this.$refs.year.$el.querySelector('input');

      switch (field) {
        case 'day':
          if (val.length >= 2) {
            val = val.substring(0, 2);
            monthEl.focus();
          }
          break;
        case 'month':
          if (val.length >= 2) {
            val = val.substring(0, 2);
            yearEl.focus();
          }
          break;
        case 'year':
          if (val.length >= 4) {
            val = val.substring(0, 4);
          }
          break;
      }
    },
    onKeydown(field, event) {
      if (field === 'year') {
        if (event.which === 10 || event.which === 13) {
          this.year = event.target.value;
          this.modelValue = this.output;
          this.$emit('input', this.modelValue);
          this.$refs.provider.validate();
        }
      }
    },
    customInteraction({ errors }) {
      if (errors.length) {
        return {
          on: ['input'],
        };
      }

      return {
        on: ['blur'],
      };
    },
    getErrorMessage(error) {
      if (error.split(':').length > 1) {
        const [key, args] = error.split(':');
        return this.$t(key, { args });
      } else {
        return this.$t(error);
      }
    },
  },
};
</script>
