<template>
  <div class="ru:admin-back">
    <Buttons layout="start">
      <RuButton level="secondary" size="small" :href="to">
        <Icon id="arrow-left" />
        <slot />
      </RuButton>
    </Buttons>
  </div>
</template>

<script>
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ComponentsAdminBackLink',
  components: {
    Buttons,
    RuButton,
    Icon,
  },
  props: {
    to: {
      default: null,
      type: String,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-back {
    margin-bottom: var(--base-margin-big);
  }
}
</style>
