<template>
  <Box shadow background="white" class="ru:stripe-payment">
    <Headline :level="3">
      {{ $t('components.stripePayment.secureTransaction') }}
    </Headline>
    <p>
      <BaseIcon id="lock" size="small" classes="inline mb-1 ml-1 mr-2" />
      {{ $t('components.stripePayment.protectedTransaction') }}
    </p>
    <FormWrapper>
      <Headline :level="3">
        {{ $t('components.stripePayment.cardDetail') }}
      </Headline>
      <div class="grid ru:form__fieldset flush">
        <div class="cell cell__12/12 cell__6/12--lg">
          <div class="ru:form__control">
            <div id="card-number" ref="cardNumber" />
            <label class="ru:form__label">{{
              $t('components.stripePayment.cardNumber')
            }}</label>
          </div>
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <div class="ru:form__control">
            <div id="card-expiry" ref="cardExpiry" />
            <label class="ru:form__label">{{
              $t('components.stripePayment.expiry')
            }}</label>
          </div>
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <div class="ru:form__control">
            <div id="card-cvc" ref="cardCvc" />
            <label class="ru:form__label">{{
              $t('components.stripePayment.cvc')
            }}</label>
          </div>
        </div>
      </div>
    </FormWrapper>
    <Alert v-if="errorMessage" theme="error" level="major">
      {{ errorMessage }}
    </Alert>
    <div v-if="paymentIsProcessing" class="ru:stripe-payment__processing">
      {{ $t('components.stripePayment.processing') }}
    </div>
  </Box>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseIcon from '@/components/BaseIcon';
import Alert from '@/components/molecules/Alert';
import FormWrapper from '@/components/organisms/Form';
import Headline from '@/components/atoms/Headline';
import Box from '@/components/atoms/Box';

import CREDIT_CARD_CHECKOUT_MUTATION from '@/graphql/mutations/CreditCardCheckout';
import UPDATE_CREDIT_CARD_DETAILS from '@/graphql/mutations/UpdateCreditCardDetails';

import { subscription, user, will } from '@/mixins/apollo';
import { formatError } from '@/utilities';

export default {
  name: 'ComponentsOrganismsStripePayment',
  components: {
    Headline,
    BaseIcon,
    Alert,
    FormWrapper,
    Box,
  },
  mixins: [subscription, user, will],
  props: {
    items: {
      required: true,
      type: Array,
    },
    discountCode: {
      default: null,
      type: String,
    },
    productsWithCustomPricesToken: {
      type: String,
      default: null,
    },
    actionType: {
      type: String,
      default: 'checkout',
    },
    expectedCostInCents: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cardCvc: null,
      cardNumber: null,
      cardExpiry: null,
      elements: null,
      stripe: null,
      stripeClasses: {
        base: 'ru:form__input-text',
        focus: 'input-focus',
        invalid: 'input-error',
      },
      stripeStyles: {
        base: {
          fontFamily: 'Larsseit, Helvetica, Arial',
          fontSize: '16px',
          fontWeight: '400',
          fontSmoothing: 'antialiased',
          lineHeight: '1.25',
          '::-ms-clear': {
            display: 'none',
          },
        },
      },
      errorMessage: null,
      paymentIsProcessing: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
  },
  watch: {
    paymentIsProcessing(value) {
      this.$emit('paymentProcessingChanged', value);
    },
  },
  async mounted() {
    if (typeof window !== 'undefined') {
      this.stripe = await this.$stripe.import();
      this.createStripeElements();
    }
  },
  methods: {
    createStripeElements() {
      this.elements = this.stripe.elements({
        fonts: [
          {
            family: 'Larsseit',
            src: `url('${
              window.location.origin
            }${require('~/static/fonts/Larsseit/Regular.ttf')}') format('truetype')`,
            weight: '400',
          },
        ],
      });
      this.cardNumber = this.elements.create('cardNumber', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
        placeholder: '0000 0000 0000 0000',
        showIcon: true,
      });
      this.cardNumber.mount('#card-number');
      this.$emit('change', this.cardNumber);
      this.cardExpiry = this.elements.create('cardExpiry', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc = this.elements.create('cardCvc', {
        classes: this.stripeClasses,
        style: this.stripeStyles,
      });
      this.cardCvc.mount('#card-cvc');
    },
    disabledStripeElements(disabled) {
      if (this.cardNumber) {
        this.cardNumber.update({ disabled });
      }
      if (this.cardExpiry) {
        this.cardExpiry.update({ disabled });
      }
      if (this.cardCvc) {
        this.cardCvc.update({ disabled });
      }
    },
    async submitPayment() {
      try {
        this.paymentIsProcessing = true;
        this.disabledStripeElements(true);
        const { token, error } = await this.stripe.createToken(this.cardNumber);
        if (error) {
          throw error;
        } else if (token) {
          await this.processOrder(token);
          this.errorMessage = null;
          this.$emit('paymentSucceeded');
        }
      } catch (error) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ Payment Failed',
          props: {
            error: error.message,
          },
        });
        this.errorMessage = formatError(error.message);
      } finally {
        this.paymentIsProcessing = false;
        this.disabledStripeElements(false);
      }
    },
    async processOrder(token) {
      if (this.actionType === 'checkout') {
        const {
          data: {
            creditCardCheckout: { success, message },
          },
        } = await this.$apollo.mutate({
          mutation: CREDIT_CARD_CHECKOUT_MUTATION,
          variables: {
            userId: this.userId,
            products:
              this.productsWithCustomPricesToken === null
                ? this.items.map(({ product }) => product)
                : [],
            expectedCostInCents: this.expectedCostInCents,
            cardToken: token.id,
            discountCode: this.discountCode,
            productsWithCustomPricesToken: this.productsWithCustomPricesToken,
          },
        });
        if (!success) {
          throw new Error(message);
        }
      } else {
        await this.$apollo.mutate({
          mutation: UPDATE_CREDIT_CARD_DETAILS,
          variables: {
            userId: this.userId,
            stripeCreditCardToken: token.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &stripe-payment {
    position: relative;
    overflow: hidden;

    &__processing {
      backdrop-filter: blur(0.75rem);
      text-align: center;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: 50% #{calc(50% - 2.5rem)};
      padding-top: 2.5rem;
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100"><circle cx="50" cy="50" r="35" fill="none" stroke="#fff" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="20"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle><circle cx="50" cy="50" r="35" fill="none" stroke="#00DABF" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="10"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg>'
      );
    }
  }
}
</style>
