<template>
  <div class="ru:container">
    <Headline :level="1">
      <DecoratedText decoration="circle">
        {{ $upperFirst($t('texts.words.your')) }}
      </DecoratedText>
      {{ $t('pages.cremations.prepaidCremation') }}
    </Headline>
    <p v-if="completeData">
      {{ $t('pages.cremations.makeSureYourCorrectDetails') }}
    </p>
    <p v-else>
      {{ $t('pages.cremations.moreDetailsToPrepareYourContract') }}
    </p>
    <FormWrapper @submit="submit">
      <Headline :level="3">{{ $t('texts.sentences.yourName') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="firstName"
            v-model="formData.firstName"
            type="text"
            :text="$t('forms.labels.firstName')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="lastName"
            v-model="formData.lastName"
            type="text"
            :text="$t('forms.labels.lastName')"
          />
        </div>
      </div>
      <Headline :level="3">
        {{ $t('texts.sentences.contactDetails') }}
      </Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="primaryPhone"
            v-model="formData.primaryPhone"
            type="text"
            :text="$t('forms.labels.primaryPhoneNumber')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="secondaryPhone"
            v-model="formData.secondaryPhone"
            type="text"
            :text="$t('forms.labels.secondaryPhoneNumber')"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="email"
            v-model="formData.email"
            type="email"
            :text="$t('forms.labels.emailAddress')"
          />
        </div>
      </div>
      <Headline :level="3">{{
        $upperFirst($t('texts.words.address'))
      }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="addressLine1"
            v-model="formData.addressLine1"
            type="text"
            :text="$t('forms.labels.addressLine1')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="addressLine2"
            v-model="formData.addressLine2"
            type="text"
            :required="false"
            :text="$t('forms.labels.addressLine2')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="australianState"
            v-model="formData.australianState"
            type="text"
            :text="$t('forms.labels.australianState')"
            :disabled="true"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="postcode"
            v-model="formData.postcode"
            type="text"
            :text="$t('forms.labels.4digitPostcode')"
            inputmode="decimal"
            min="4"
            max="4"
            pattern="[0-9]{4}"
            :disabled="true"
          />
        </div>
      </div>
      <Headline :level="3">{{ $t('texts.sentences.personalInfo') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="placeOfBirth"
            v-model="formData.placeOfBirth"
            type="text"
            :text="$t('forms.labels.placeOfBirth')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="dateOfBirth"
            v-model="formData.dateOfBirth"
            :min="maximumAge"
            :max="minimumAge"
            type="date"
            :text="$t('forms.labels.dateOfBirth')"
          />
        </div>
      </div>
      <RuButton
        v-if="$ff.prePaidContractFlowEnabled()"
        aria-label="Submit"
        button-type="submit"
      >
        <span>{{ forms.labels.next }}</span>
      </RuButton>
      <RuButton v-else aria-label="Submit" button-type="submit">
        <span>{{ $t('forms.labels.finish') }}</span>
        <span class="icon">
          <BaseIcon id="arrow-right" />
        </span>
      </RuButton>
    </FormWrapper>
  </div>
</template>

<script>
import RuButton from '@/components/atoms/Button';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import DecoratedText from '@/components/atoms/DecoratedText';
import Headline from '@/components/atoms/Headline';
import BaseIcon from '@/components/BaseIcon';

import { decodePayloadFromUrl, encodePayloadForUrl } from '@/utilities';

export default {
  name: 'PagesAffiliateCremationsCompleteQuoteIndex',
  components: {
    FormWrapper,
    FormControl,
    RuButton,
    DecoratedText,
    Headline,
    BaseIcon,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        primaryPhone: '',
        addressLine1: '',
        addressLine2: '',
        australianState: '',
        postcode: '',
        secondaryPhone: '',
        dateOfBirth: '',
        placeOfBirth: '',
        transportFee: '',
        professionalFee: '',
        totalQuote: '',
      },
    };
  },
  computed: {
    minimumAge() {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 18))
        .toISOString()
        .split('T')[0];
    },
    maximumAge() {
      return new Date(1900, 0, 0).toISOString().split('T')[0];
    },
  },
  mounted() {
    this.$nuxt.$emit('sendTrackingEvent', {
      event: 'Pre-paid cremation Contract Requested',
    });
  },
  created() {
    try {
      const leadData = {
        ...decodePayloadFromUrl(this.$route.query.leadData),
      };
      Object.keys(leadData).forEach((key) => {
        this.formData[key] = leadData[key];
      });
      this.completeData = Object.keys(this.formData).every((key) => {
        return this.formData[key] || this.optionalFormData.includes(key);
      });
    } catch (error) {
      this.completeData = false;
    }
  },
  methods: {
    submit() {
      const newBase64 = encodePayloadForUrl(this.formData);
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'Pre-paid cremation Contract Details Submitted',
      });
      this.$router.push({
        path: this.localePath('/cremations/complete-quote/contract-preview'),
        query: {
          leadData: newBase64,
        },
      });
    },
  },
};
</script>
