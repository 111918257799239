<template>
  <div class="w-full relative">
    <label v-if="label" class="input-label" :class="labelClass" :for="id">
      {{ label }}
    </label>
    <div class="relative">
      <BaseInput
        :id="id"
        :classes="compact ? 'pr-10' : 'pr-16'"
        :compact="compact"
        :disabled="disabled"
        :name="id"
        :placeholder="placeholder"
        rules="required"
        :type="type"
        :value="value"
        @blur="blur"
        @focus="focus"
        @input="$emit('input', $event)"
      />
      <div
        class="absolute top-0 right-0 flex items-center w-8"
        :class="classes.helper"
      >
        <Tooltip :content="tooltipContent">
          <button
            class="btn btn-sm text-slate-100"
            type="button"
            @click="togglePasswordVisibility"
          >
            <BaseIcon :id="icon" />
          </button>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import BaseIcon from '@/components/BaseIcon';
import Tooltip from '@/components/Tooltip';

export default {
  name: 'ComponentsPasswordInput',
  components: {
    BaseInput,
    BaseIcon,
    Tooltip,
  },
  props: {
    compact: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    fancy: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showPassword: false,
      focused: false,
    };
  },
  computed: {
    classes() {
      return {
        helper: {
          'h-12 mr-3': this.compact,
          'h-18 md:h-20 mr-5': !this.compact,
        },
      };
    },
    icon() {
      return this.showPassword ? 'eye-alt' : 'eye';
    },
    tooltipContent() {
      return `${this.showPassword ? 'Hide' : 'Show'} password`;
    },
    type() {
      return this.showPassword ? 'text' : 'password';
    },
    started() {
      return !!this.value;
    },
    labelClass() {
      return {
        'fancy-label': this.fancy,
        'fancy-label--active': this.fancy && (this.focused || this.started),
      };
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    focus() {
      this.focused = true;
    },
    blur($event) {
      this.focused = false;
      this.$emit('blur', $event);
    },
  },
};
</script>
