<template>
  <Portal to="stack">
    <transition appear mode="out-in" name="ru:wiggle-vertical">
      <div class="ru:container ru:container--snug ru:wizard">
        <div class="ru:wizard__header">
          <Headline flush :level="2">
            {{ headline }}
          </Headline>
          <RuButton level="plain" @click="close">
            <Icon id="close" />
          </RuButton>
        </div>
        <slot />
      </div>
    </transition>
  </Portal>
</template>

<script>
import { Portal } from 'portal-vue';

import Icon from '@/components/atoms/Icon';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'ComponentsBaseWizard',
  components: {
    Icon,
    RuButton,
    Headline,
    Portal,
  },
  props: {
    headline: {
      type: String,
      required: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &wizard {
    border-radius: var(--border-radius);
    background: --rgba(water);
    position: relative;
    padding: var(--gutter-width);
    margin: var(--gutter-width) auto;
    max-height: #{calc(100% - var(--gutter-width) * 2)};
    overflow-y: auto;
    box-shadow: var(--base-box-shadow);

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--base-margin);
    }
  }
}
</style>
