<template>
  <div class="w-full">
    <label v-if="label" class="input-label" :for="id">
      {{ label }}
    </label>
    <div class="relative">
      <textarea
        :id="id"
        class="input h-auto pt-6 pb-10 resize-none"
        :class="classes"
        :rows="compact ? 1 : 4"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        v-text="value"
      />
      <div
        v-if="maxlength && charactersRemaining <= maxlengthInt / 2"
        class="absolute right-0 bottom-0 m-5 text-base text-slate-100 pointer-events-none"
      >
        {{
          `${charactersRemaining} ${$t(
            'components.textArea.charactersRemaining'
          )}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsTextArea',
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      default: '',
      type: String,
    },
    maxlength: {
      default: null,
      type: String,
      validator(val) {
        const int = parseInt(val, 10);
        return Number.isInteger(int) && int > 0;
      },
    },
    placeholder: {
      default: null,
      type: String,
    },
    value: {
      default: '',
      type: String,
    },
    compact: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      filled: false,
    };
  },
  computed: {
    charactersRemaining() {
      return this.value ? this.maxlengthInt - this.value.length : Infinity;
    },
    classes() {
      return {
        'input-compact': this.compact,
        'input-disabled': this.disabled,
        'input-error': this.error,
      };
    },
    maxlengthInt() {
      return this.maxlength ? parseInt(this.maxlength, 10) : 0;
    },
  },
};
</script>
