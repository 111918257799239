<template>
  <Box shadow background="white" class="ru:afterpay-payment">
    <Headline :level="3">
      <img
        :src="require('~/assets/img/payment-gateways/afterpay-logo.svg')"
        style="height: 24px"
        alt="Afterpay"
        class="inline"
      />
    </Headline>
    <p>
      <BaseIcon
        id="info-alt"
        size="small"
        classes="inline align-middle mb-1 ml-1 mr-2"
      />
      <i18n path="components.afterpayPayment.payForYourOrder">
        <strong> ${{ fortnightlyPaymentsAmount }} </strong>
      </i18n>
    </p>
    <FormWrapper>
      <Headline :level="4">
        {{ $t('components.afterpayPayment.yourAddress') }}
      </Headline>
      <div class="grid ru:form__fieldset flush">
        <div class="cell cell__12/12">
          <FormControl
            id="full_name"
            v-model="formData.name"
            type="text"
            text="Full name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="address_street"
            v-model="formData.street"
            type="text"
            text="Street address"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="address_suburb"
            v-model="formData.suburb"
            type="text"
            text="Suburb"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="address_state"
            v-model="formData.state"
            type="select"
            text="State"
            :options="australianStatesOptions"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="address_postcode"
            v-model="formData.postcode"
            type="text"
            inputmode="decimal"
            min="4"
            max="4"
            pattern="[0-9]{4}"
            text="Postcode"
          />
        </div>
      </div>
    </FormWrapper>
    <p :class="!errorMessage ? 'flush' : null">
      {{ $t('components.afterpayPayment.afterpayMessage') }}
    </p>
    <Alert v-if="errorMessage" theme="error" level="major">
      {{ errorMessage }}
    </Alert>
    <div
      class="flex fixed inset-0 z-50 p-2 md:p-10 justify-center items-center modal-backdrop"
      :class="showAfterpayLightbox ? '' : 'hidden'"
    >
      <div class="relative bg-white max-width-700">
        <button
          class="absolute text-3xl py-2 px-6 top-0 right-0"
          type="button"
          @click="hideAfterpayLightbox"
        >
          x
        </button>
        <a
          href="https://www.afterpay.com/terms/"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="
              require('~/assets/img/payment-gateways/afterpay-lightbox.jpg')
            "
            alt="Shop now. Pay later. Always interest free."
            class="max-w-full"
          />
        </a>
      </div>
    </div>
    <div v-if="paymentIsProcessing" class="ru:stripe-payment__processing">
      {{ $t('components.afterpayPayment.processingPayment') }}
    </div>
  </Box>
</template>

<script>
import { mapGetters } from 'vuex';
import { stateCodeToStateName } from '@/modules/stateCodeToStateName';

import AFTERPAY_CHECKOUT from '@/graphql/mutations/AfterpayCheckout';
import Alert from '@/components/molecules/Alert';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import Headline from '@/components/atoms/Headline';
import BaseIcon from '@/components/BaseIcon';
import Box from '@/components/atoms/Box';

import { subscription, user, will } from '@/mixins/apollo';
import { getUserFullName, formatError } from '@/utilities';

export default {
  name: 'ComponentsOrganismsAfterpayPayment',
  components: {
    Alert,
    FormWrapper,
    FormControl,
    Headline,
    BaseIcon,
    Box,
  },
  mixins: [subscription, user, will],
  props: {
    items: {
      required: true,
      type: Array,
    },
    discountCode: {
      default: null,
      type: String,
    },
    productsWithCustomPricesToken: {
      type: String,
      default: null,
    },
    billingAddress: {
      type: Object,
      default: null,
    },
    expectedCostInCents: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showAfterpayLightbox: false,
      stripe: null,
      errorMessage: null,
      formData: {
        street: null,
        suburb: null,
        state: null,
        postcode: null,
        name: null,
      },
      paymentIsProcessing: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('ui', ['australianStates']),
    australianStatesOptions() {
      const options = [{ text: '', value: '' }];
      this.australianStates.forEach((australianState) => {
        options.push({
          text: stateCodeToStateName(australianState),
          value: australianState,
        });
      });
      return options;
    },
    fortnightlyPaymentsAmount() {
      return (this.expectedCostInCents / 400).toFixed(2);
    },
    userFullName() {
      return getUserFullName(this.willMeta);
    },
  },
  watch: {
    billingAddress(value) {
      this.validateAndSetCheckoutInformationInput({
        ...value,
        name: this.userFullName,
      });
    },
    paymentIsProcessing(value) {
      this.$emit('paymentProcessingChanged', value);
    },
  },
  async mounted() {
    this.stripe = await this.$stripe.import();
    const paymentIntentClientSecret =
      this.$route.query.payment_intent_client_secret;
    if (paymentIntentClientSecret) {
      const { paymentIntent } = await this.stripe.retrievePaymentIntent(
        paymentIntentClientSecret
      );
      this.processPaymentIntent(paymentIntent);
    } else {
      this.validateAndSetCheckoutInformationInput({
        ...this.billingAddress,
        name: this.userFullName,
      });
    }
  },
  methods: {
    resetError() {
      this.errorMessage = null;
    },
    displayAfterpayLightbox() {
      this.showAfterpayLightbox = true;
    },
    hideAfterpayLightbox() {
      this.showAfterpayLightbox = false;
    },
    validateAndSetCheckoutInformationInput(value) {
      if (typeof value === 'object' && value !== null) {
        this.formData = {
          street: value.street,
          suburb: value.suburb,
          state: value.state,
          postcode: value.postcode,
          name: value.name,
        };
      }
    },
    validateAddress(address) {
      const isAddressInputValid = Object.keys(address).every((key) => {
        return !!address[key];
      });
      if (!isAddressInputValid) {
        return this.$t('components.afterpayPayment.addressInvalidMessage');
      }
      if (!/\b\d{4}\b/g.test(address.postcode)) {
        return this.$t('components.afterpayPayment.postcodeInvalidMessage');
      }
      return null;
    },
    async submitPayment() {
      try {
        const addressError = this.validateAddress(this.formData);
        if (addressError) {
          throw new Error(addressError);
        }
        this.paymentIsProcessing = true;
        const {
          data: {
            afterpayCheckout: { paymentIntentClientSecret, success, message },
          },
        } = await this.$apollo.mutate({
          mutation: AFTERPAY_CHECKOUT,
          variables: {
            userId: this.userId,
            products:
              this.productsWithCustomPricesToken === null
                ? this.items.map(({ product }) => product)
                : [],
            expectedCostInCents: this.expectedCostInCents,
            discountCode: this.discountCode,
            productsWithCustomPricesToken: this.productsWithCustomPricesToken,
          },
        });
        if (!success) {
          throw new Error(message);
        }
        const name = this.formData.name;
        const address = {
          line1: this.formData.street,
          city: this.formData.suburb,
          state: this.formData.state,
          country: 'AU',
          postal_code: this.formData.postcode,
        };
        const returnUrl = new URL(window.location.href);
        const { error } = await this.stripe.confirmAfterpayClearpayPayment(
          paymentIntentClientSecret,
          {
            shipping: {
              name,
              address,
            },
            payment_method: {
              billing_details: {
                email: this.$store.state.email,
                name,
                address,
              },
            },
            return_url: returnUrl,
          }
        );
        if (error) {
          this.errorMessage = this.$t(
            'components.afterpayPayment.paymentFailed'
          );
          throw error;
        }
      } catch (error) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '❌ Payment Failed',
          props: {
            error: 'Afterpay payment failed',
          },
        });
        this.errorMessage = formatError(error.message);
      } finally {
        this.paymentIsProcessing = false;
      }
    },
    cleanCheckoutUrl() {
      const query = Object.assign({}, this.$route.query);
      this.$router.replace({
        query: Object.keys(query).reduce((object, key) => {
          if (
            !['payment_intent_client_secret', 'payment_intent'].includes(key)
          ) {
            object[key] = query[key];
          }
          return object;
        }, {}),
      });
    },
    processPaymentIntent({ status }) {
      this.cleanCheckoutUrl();
      try {
        switch (status) {
          case 'succeeded':
            this.$emit('paymentSucceeded');
            break;
          case 'requires_action':
            this.$nuxt.$emit('sendTrackingEvent', {
              event: '❌ Payment Failed',
              props: {
                error: 'Afterpay payment cancelled by customer',
              },
            });
            throw new Error('Your payment was cancelled.');
          case 'requires_payment_method':
            this.$nuxt.$emit('sendTrackingEvent', {
              event: '❌ Payment Failed',
              props: {
                error: 'Afterpay payment declined',
              },
            });
            throw new Error(
              this.$t('components.afterpayPayment.paymentDeclined')
            );
          default:
            throw new Error(
              this.$t('components.afterpayPayment.errorsOccurred')
            );
        }
      } catch (error) {
        this.errorMessage = formatError(error.message);
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &afterpay-payment {
    position: relative;
    overflow: hidden;

    &__processing {
      backdrop-filter: blur(0.75rem);
      text-align: center;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: 50% #{calc(50% - 2.5rem)};
      padding-top: 2.5rem;
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100"><circle cx="50" cy="50" r="35" fill="none" stroke="#fff" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="20"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle><circle cx="50" cy="50" r="35" fill="none" stroke="#00DABF" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="10"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg>'
      );
    }
  }
}
</style>
