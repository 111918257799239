<template>
  <div class="ru:user">
    <template v-if="willId">
      <Icon v-if="!userName" id="user" size="large" shadow />
      <template v-else>
        <Badge v-bind="$attrs">
          {{ userInitial }}
        </Badge>
        <div class="ru:user__name">
          {{ userName }}
        </div>
      </template>
    </template>
    <template v-else>
      <Buttons layout="inline">
        {{ $t('components.user.alreadyStartedWill') }}
        <RuButton level="primary" :href="localePath('/log-in')" size="small">
          {{ $t('components.user.logIn') }}
        </RuButton>
      </Buttons>
    </template>
  </div>
</template>

<script>
import Badge from '@/components/atoms/Badge';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';

import { will } from '@/mixins/apollo';

export default {
  name: 'ComponentsMoleculesUser',
  components: {
    Badge,
    Buttons,
    RuButton,
    Icon,
  },
  mixins: [will],
  props: {
    letters: {
      default: 1,
      type: Number,
    },
  },
  computed: {
    userName() {
      const firstName = this.willMeta.name_first;
      const lastName = this.willMeta.name_last;
      const name = `${firstName || ''} ${lastName || ''}`;
      return name;
    },
    userInitial() {
      const firstName = this.willMeta.name_first;
      const lastName = this.willMeta.name_last;
      const initials =
        (firstName ? firstName.charAt(0) : '') +
        (lastName ? lastName.charAt(0) : '');
      return initials.toUpperCase().substring(0, this.letters);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &user {
    display: flex;
    align-items: center;
    gap: 1rem;
    @include mq('min-lg') {
      flex-direction: row-reverse;
    }

    &__name {
      font-size: var(--base-font-size);
    }
  }
}
</style>
