var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.prevStep}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-icon":"arrow-right"},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',{staticClass:"step-1"},[(_vm.willMeta.executors_option === 'professional')?[_c('Headline',{attrs:{"level":1}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.executors.backup.appointBackup.professional'))+"\n        ")]),_vm._v(" "),_c('Tip',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.executors.backup.tip.professional'))+"\n        ")])]:[_c('Headline',{attrs:{"level":1}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.executors.backup.appointBackup.other'))+"\n        ")]),_vm._v(" "),_c('Tip',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.executors.backup.tip.other'))+"\n        ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasBackupExecutor","options":[
            {
              label: _vm.$t('forms.labels.yes'),
              value: true,
            },
            {
              label: _vm.$t('forms.labels.no'),
              value: false,
            },
          ]},model:{value:(_vm.willMeta.has_backup_executor),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_backup_executor", $$v)},expression:"willMeta.has_backup_executor"}})]],2),_vm._v(" "),(_vm.willMeta.has_backup_executor)?_c('FormSection',{staticClass:"step-2",attrs:{"tip":_vm.$t('pages.will.peopleSelectionInstructions')}},[(_vm.willMeta.executors_option !== 'professional')?_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.executors.backup.whoBackupExecutor'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":2,"people":_vm.filteredPeople,"type":"executor"},model:{value:(_vm.selectedBackupExecutors),callback:function ($$v) {_vm.selectedBackupExecutors=$$v},expression:"selectedBackupExecutors"}})],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }