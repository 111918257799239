<template>
  <div>
    <Headline :level="1"> {{ $t('components.ohDear.ohDear') }} </Headline>
    <p>
      {{ $t('components.ohDear.someThingWentWrong') }}
      <a v-if="retry" class="underline hover:no-underline" :href="retry">
        {{ $t('components.ohDear.tryAgain') }}
      </a>
      <span v-if="retry">{{ $t('components.ohDear.or') }}</span>
      <button
        type="button"
        class="underline hover:no-underline"
        @click="showChat"
      >
        {{ $t('components.ohDear.chatToTeam') }}
      </button>
      {{ $t('components.ohDear.forAssistance') }}
    </p>
    <Buttons theme="wills" layout="start">
      <RuButton level="primary" :href="localePath('/')">
        <span>{{ $t('components.ohDear.goToDashboard') }}</span>
        <BaseIcon id="arrow-right" classes="icon" />
      </RuButton>
    </Buttons>
  </div>
</template>

<script>
import { showChat } from '@/modules/chat';
import Headline from '@/components/atoms/Headline';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import BaseIcon from '@/components/BaseIcon';
export default {
  name: 'ComponentsTemplatesOhDear',
  components: {
    Headline,
    BaseIcon,
    RuButton,
    Buttons,
  },
  props: {
    retry: {
      type: String,
      default: null,
    },
  },
  methods: {
    showChat() {
      showChat();
    },
  },
};
</script>
