<template>
  <BaseModal v-if="showSubmitHelperModal" @close="close">
    <Headline row :level="2">
      <Icon id="info" size="large" shadow />
      {{ $t('components.submitHelperModal.title') }}
    </Headline>
    <p>
      {{ $t('components.submitHelperModal.description') }}
    </p>
    <Tip> {{ $t('components.submitHelperModal.tip') }} </Tip>
    <Buttons layout="start">
      <RuButton level="primary" @click="close">
        {{ $t('components.submitHelperModal.cta') }}

        <Icon id="arrow-right" size="small" />
      </RuButton>
    </Buttons>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Icon from '@/components/atoms/Icon';
import BaseModal from '@/components/BaseModal';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsSubmitHelperModal',
  components: {
    Icon,
    BaseModal,
    Tip,
    Headline,
    Buttons,
    RuButton,
  },
  computed: {
    ...mapGetters('ui', ['showSubmitHelperModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions('ui', ['setShowSubmitHelperModal']),
    close() {
      this.setShowSubmitHelperModal(false);
    },
  },
};
</script>
