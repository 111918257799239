<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.aboutYourself') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.checkAboutYourselfDetails') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('initial')">
      <Headline :level="3">{{ $t('texts.sentences.yourName') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantFirstName"
            v-model="formData.informantFirstName"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.firstName')"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantOtherGivenNames"
            v-model="formData.informantOtherGivenNames"
            :required="false"
            :optional="true"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.otherGivenNames')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantLastName"
            v-model="formData.informantLastName"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.lastName')"
          />
        </div>
      </div>
      <Headline :level="3">Your Contact Details</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantPhone"
            v-model="formData.informantPhone"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.primaryPhoneNumber')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantSecondaryPhone"
            v-model="formData.informantSecondaryPhone"
            :required="false"
            :optional="true"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.secondaryPhoneNumber')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantEmail"
            v-model="formData.informantEmail"
            :disabled="true"
            type="text"
            :text="$t('forms.labels.emailAddress')"
          />
        </div>
      </div>
      <Headline :level="3">{{ $t('texts.sentences.yourAddress') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="informantAddressLine1"
            v-model="formData.informantAddressLine1"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.addressLine1')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="informantAddressLine2"
            v-model="formData.informantAddressLine2"
            :required="false"
            :optional="true"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.addressLine2')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantAddressSuburb"
            v-model="formData.informantAddressSuburb"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.suburb')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantAddressState"
            v-model="formData.informantAddressState"
            :disabled="loading"
            type="select"
            :text="$t('forms.labels.australianState')"
            :options="australianStatesOptions"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="informantAddressPostcode"
            v-model="formData.informantAddressPostcode"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.4digitPostcode')"
            inputmode="decimal"
            min="4"
            max="4"
            pattern="[0-9]{4}"
          />
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="goNext">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="goNext">
          {{ $t('forms.labels.ok') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedInformantId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      formData: {
        informantFirstName: null,
        informantOtherGivenNames: null,
        informantLastName: null,
        informantPhone: null,
        informantSecondaryPhone: null,
        informantEmail: null,
        informantAddressLine1: null,
        informantAddressLine2: null,
        informantAddressSuburb: null,
        informantAddressState: null,
        informantAddressPostcode: null,
      },
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/instructions/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
  },
};
</script>
