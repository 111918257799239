<template>
  <div class="ru:charity-selector">
    <Headline v-if="label" :level="2">
      {{ $t('components.charitySelector.title') }}
    </Headline>
    <div v-if="charities.length" class="ru:charity-selector__list">
      <MetaSlot
        v-for="(charity, index) in charities"
        :key="index"
        v-slot="{ meta }"
        :meta="charity.meta"
      >
        <LocalScope v-slot="{ id, selected }" :selected="isSelected[index]">
          <Selectable
            :id="id"
            :is-selected="selected"
            :data="charity"
            @selectionChange="selectionChange"
          >
            <template #content>
              <img
                v-if="meta.logo"
                class="ru:charity-selector__logo"
                :src="meta.logo"
                :alt="meta.displayName || meta.name"
              />
              <Headline :level="3" big :flush="!!meta.logo">
                {{ meta.displayName || meta.name }}
              </Headline>
              <p>
                {{ meta.description || meta.address }}
              </p>
            </template>
            <template #actions>
              <div class="d-flex-end">
                <Badge
                  :color="selected ? 'white' : 'silver'"
                  :background="selected ? 'theme' : 'iron'"
                  size="large"
                >
                  <Icon id="check" size="large" />
                </Badge>
              </div>
            </template>
          </Selectable>
        </LocalScope>
      </MetaSlot>
    </div>
  </div>
</template>

<script>
import { LocalScope } from 'vue-local-scope';
import { mapActions } from 'vuex';
import { metaArrayToObject } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';

import MetaSlot from '@/components/MetaSlot';
import Selectable from '@/components/molecules/Selectable';
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge';

import { charities, user } from '@/mixins/apollo';

export default {
  name: 'ComponentsCharitySelector',
  components: {
    LocalScope,
    MetaSlot,
    Selectable,
    Headline,
    Icon,
    Badge,
  },
  mixins: [charities, user],
  props: {
    label: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: Infinity,
      type: Number,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    isSelected() {
      return this.charities.map((charity) => {
        return this.selected.includes(charity.id);
      });
    },
  },
  watch: {
    value(newValue) {
      if (!this.loading) {
        this.selected = newValue;
      }
    },
  },
  mounted() {
    this.$nuxt.$on('addCharities', (addedCharities) => {
      this.charities.forEach((charity) => {
        addedCharities.forEach((addedCharity) => {
          if (isSameCharity(metaArrayToObject(charity.meta), addedCharity)) {
            this.selectionChange(true, charity);
          }
        });
      });
    });
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    selectionChange(checked, charity) {
      const index = this.selected.indexOf(charity.id);
      if (!checked && index > -1) {
        this.selected.splice(index, 1);
      } else if (this.selected.length < this.max) {
        this.selected.push(charity.id);
      } else if (this.max === 1) {
        this.selected = [charity.id];
      }
      this.$emit('input', this.selected);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &charity-selector {
    &__list {
      --margin-y: var(--base-margin);
    }

    &__logo {
      background: --rgba(white);
      margin-bottom: var(--base-margin-big);
      max-height: 4rem;
      max-width: 100%;
      outline: 0.5rem solid --rgba(white);
      box-shadow: 0.5rem 0 0 0.5rem --rgba(white);
      border-radius: var(--border-radius-small);
    }
  }
}
</style>
