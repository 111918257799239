<template>
  <div class="ru:send-gifts">
    <Box background="white">
      <p v-if="!remainingGifts" class="flex items-center flush">
        <LoadingRing :size="24" class="mr-4" />
        {{ $t('components.sendGifts.loading') }}
      </p>
      <template v-else-if="hasPurchasedGifts">
        <Chip background="darker">{{
          $t('components.sendGifts.inviteYourFamily')
        }}</Chip>
        <Headline :level="2">{{
          $t('components.sendGifts.sendGift')
        }}</Headline>
        <FormWrapper>
          <Headline :level="3" flush>{{
            $t('components.sendGifts.ILikeToSend')
          }}</Headline>
          <div class="grid grid--flush ru:form__fieldset">
            <div class="cell cell__12/12">
              <FormControl
                id="WILL"
                v-model="formData.WILL"
                value="WILL"
                type="checkbox"
                text="Will"
                format="boolean"
                :disabled="!remainingWillGifts"
                :required="false"
              />
              <FormControl
                id="POA"
                v-model="formData.POA"
                value="POA"
                type="checkbox"
                text="Powers of Attorney"
                format="boolean"
                :disabled="!remainingPoaGifts"
                :required="false"
              />
            </div>
          </div>
          <Headline :level="3" flush>{{
            $t('components.sendGifts.to')
          }}</Headline>
          <SendInvite
            type="PAID"
            :products="selectedProducts"
            :invalid="!selectedProducts.length || !hasRemainingGifts"
            @complete="clearFields"
          />
        </FormWrapper>
        <hr />
        <div v-if="hasRemainingGifts" class="ru:send-gifts__remaining">
          <Headline :level="3">
            {{ $t('components.sendGifts.youHave') }}
          </Headline>
          <p>
            <span class="ru:send-gifts__remaining-gifts">
              <span class="ru:send-gifts__remaining-gift">
                <strong class="ru:send-gifts__remaining-gift-number">
                  {{ remainingWillGifts }}
                </strong>
                {{ $t('components.sendGifts.giftWills') }}
              </span>
              <span class="ru:send-gifts__remaining-gift">
                <strong class="ru:send-gifts__remaining-gift-number">
                  {{ remainingPoaGifts }}
                </strong>
                {{ $t('components.sendGifts.giftPoa') }}
              </span>
            </span>
            {{ $t('components.sendGifts.sendToFriendAndFamily') }}
          </p>
        </div>
        <div v-else class="ru:send-gifts__sales">
          <Icon id="gift" size="huge" />
          <Headline :level="3">
            {{ $t('components.sendGifts.sentAllGifts') }}
          </Headline>
          <p>{{ $t('components.sendGifts.buyMore') }}</p>
          <Buttons layout="stretch">
            <RuButton
              level="primary"
              aria-label="Send"
              :href="localePath('/checkout/gifts')"
            >
              {{ $t('components.sendGifts.buyGifts') }}
              <Icon id="arrow-right" />
            </RuButton>
          </Buttons>
        </div>
      </template>
      <div v-else class="ru:send-gifts__sales">
        <Icon id="gift" size="huge" />
        <Headline :level="3">{{
          $t('components.sendGifts.haveNotBoughtGiftsYet')
        }}</Headline>
        <p>{{ $t('components.sendGifts.buyNow') }}</p>
        <Buttons layout="stretch">
          <RuButton
            level="primary"
            aria-label="Send"
            :href="localePath('/checkout/gifts')"
          >
            {{ $t('components.sendGifts.buyGifts') }}
            <Icon id="arrow-right" />
          </RuButton>
        </Buttons>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/atoms/Box';
import Chip from '@/components/atoms/Chip';
import Headline from '@/components/atoms/Headline';
import FormControl from '@/components/molecules/FormControl';
import FormWrapper from '@/components/organisms/Form';
import SendInvite from '@/components/organisms/SendInvite';
import LoadingRing from '@/components/LoadingRing';
import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { invites, user } from '@/mixins/apollo';

import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

export default {
  name: 'ComponentsOrganismsSendGifts',
  components: {
    Box,
    Chip,
    Headline,
    FormWrapper,
    FormControl,
    SendInvite,
    LoadingRing,
    Icon,
    Buttons,
    RuButton,
  },
  mixins: [invites, user],
  apollo: {
    purchasedGifts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update(data) {
        const products = data.getPurchasedProducts.products || [];
        return {
          WILL: products.filter(
            (product) =>
              product.includes('WILL_GIFT') || product.includes('PARTNER_WILL')
          ).length,
          POA: products.filter(
            (product) =>
              product.includes('POA_GIFT') || product.includes('PARTNER_POA')
          ).length,
          LEGAL_ADVICE: products.filter((product) =>
            product.includes('LEGAL_ADVICE_GIFT')
          ).length,
        };
      },
      skip() {
        return !this.userId;
      },
    },
  },
  data() {
    return {
      formData: {
        email: null,
        WILL: false,
        POA: false,
        LEGAL_ADVICE: false,
      },
    };
  },
  computed: {
    hasPurchasedGifts() {
      return Object.keys(this.purchasedGifts).length;
    },
    sentGifts() {
      const sentInvites = this.invites
        ?.filter(({ type }) => {
          return type === 'PAID';
        })
        .flatMap(({ products }) => products);
      const sentGifts = {};
      sentInvites.forEach((product1) => {
        sentGifts[product1] = sentInvites.filter(
          (product2) => product1 === product2
        ).length;
      });
      return sentGifts;
    },
    selectedProducts() {
      const gifts = [];
      if (this.formData.WILL) {
        gifts.push('WILL');
      }
      if (this.formData.POA) {
        gifts.push('POA');
      }
      return gifts;
    },
    remainingGifts() {
      if (!this.purchasedGifts || !this.invites) {
        return null;
      }
      const remainingGifts = {};

      Object.keys(this.purchasedGifts).forEach((gift) => {
        const sentGifts = (this.sentGifts[gift] ||= 0);
        remainingGifts[gift] = this.purchasedGifts[gift] - sentGifts;
      });
      return remainingGifts;
    },
    remainingWillGifts() {
      return this.remainingGifts.WILL || 0;
    },
    remainingPoaGifts() {
      return this.remainingGifts.POA || 0;
    },
    remainingLegalGifts() {
      return this.remainingGifts.LEGAL_ADVICE || 0;
    },
    hasRemainingGifts() {
      return (
        this.remainingGifts.WILL +
          this.remainingGifts.POA +
          this.remainingGifts.LEGAL_ADVICE >
        0
      );
    },
  },
  methods: {
    clearFields() {
      this.formData.WILL = false;
      this.formData.POA = false;
      this.formData.LEGAL_ADVICE = false;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &send-gifts {
    &__remaining {
      &-gifts {
        display: block;
        margin-bottom: var(--base-margin-small);
      }

      &-gift {
        white-space: nowrap;

        &:not(:last-child) {
          border-right: 1px solid --rgba(gravel);
          padding-right: var(--base-margin);
          margin-right: var(--base-margin);
        }

        &-number {
          margin-right: var(--base-margin-small);
        }
      }
    }

    &__sales {
      text-align: center;

      > #{$ru}icon {
        margin: 0 auto;
      }
    }
  }
}
</style>
