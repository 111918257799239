<template>
  <ValidationObserver ref="observer" tag="form" @submit.prevent="submit">
    <slot :loading="loading" />
    <FormRow v-if="!hideSubmit" :inline="submitInline">
      <div class="ru:adi-form">
        <p v-if="error" class="form-error mt-2 text-red-300">
          {{ customError || error }}
        </p>
        <p v-if="$nuxt.isOffline" class="form-error mt-2 text-red-300">
          {{ $t('components.form.networkError') }}
        </p>
        <Buttons layout="start">
          <RuButton
            :disabled="disabled || loading || $nuxt.isOffline"
            button-type="submit"
            level="primary"
            size="large"
            align="between"
            width="33%"
          >
            {{ submitLabel }}
            <Loading v-if="loading" />
            <Icon v-else :id="submitIcon" />
          </RuButton>
        </Buttons>
      </div>
    </FormRow>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { formatError } from '@/utilities';
import FormRow from '@/components/FormRow';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import Loading from '@/components/atoms/Loading';

export default {
  name: 'ComponentsForm',
  components: {
    FormRow,
    ValidationObserver,
    Buttons,
    RuButton,
    Icon,
    Loading,
  },
  props: {
    autoFocus: {
      default: false,
      type: Boolean,
    },
    customError: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hideSubmit: {
      default: false,
      type: Boolean,
    },
    autoSubmit: {
      default: false,
      type: Boolean,
    },
    mutation: {
      default: null,
      type: Object,
    },
    submitIcon: {
      default: null,
      type: String,
    },
    submitCompact: {
      default: false,
      type: Boolean,
    },
    submitClasses: {
      default: '',
      type: String,
    },
    submitInline: {
      default: false,
      type: Boolean,
    },
    submitLabel: {
      default: 'Next',
      type: String,
    },
    submitWide: {
      default: false,
      type: Boolean,
    },
    variables: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      isDone: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    apollo() {
      return this.mutation && this.variables;
    },
    loading() {
      return this.isDone || this.isLoading;
    },
  },
  watch: {
    hideSubmit(hideSubmit) {
      if (this.autoSubmit && !hideSubmit) {
        this.submit();
      }
    },
  },
  mounted() {
    this.$nuxt.$on('unlockForm', () => {
      this.isDone = false;
      this.isLoading = false;
    });

    this.$nuxt.$on('clearErrors', () => {
      this.$refs.observer && this.$refs.observer.reset();
    });

    if (this.autoFocus) {
      const first = this.$el.querySelector(
        'input[type=email],input[type=number],input[type=password],input[type=text]'
      );

      if (first) {
        first.focus();
      }
    }
  },
  methods: {
    async submit($event) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());

      if (isValid) {
        this.isLoading = true;
        this.error = null;

        if (this.apollo) {
          this.$emit('requestStarted');
          await this.$apollo
            .mutate({
              mutation: this.mutation,
              variables: this.variables,
              update: (store, res) => {
                this.$emit('update', store, res);
                this.isDone = true;
                this.$emit('done', res);
              },
            })
            .then(({ errors }) => {
              if (errors) {
                this.isLoading = false;
                this.error = formatError(errors[0].message);
              }
            })
            .catch((e) => {
              this.isLoading = false;
              this.error = formatError(e.message);
            });
        } else {
          this.$emit('submit', $event);
          this.isLoading = true;
        }
      } else {
        this.error = this.$t('components.form.missingRequiredFieldsError');

        // Scroll to the first field with an error
        const parent = this.$refs.observer.$el.parentElement;
        const errorElement = parent.querySelector('.input-error');
        const parentIsScrollable = parent.classList.contains('overflow-y-auto');
        const scrollableElement = parentIsScrollable ? parent : window;

        if (errorElement && errorElement.closest('.text-base')) {
          const position = errorElement.closest('.text-base').offsetTop;
          const offsetPosition = parentIsScrollable ? parent.offsetTop : 0;

          scrollableElement.scrollTo({
            top: position - offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &adi-form {
    margin-bottom: var(--base-margin);
  }
}
</style>
