<template>
  <div>
    <button
      class="border-2 p-1 rounded-full flex justify-start border-white items-center cursor-pointer w-15 focus:outline-none focus:border-teal-100 transition-switch"
      :class="buttonStyles"
      @click.stop="onChange"
      @keydown.enter="onChange"
    >
      <span
        class="rounded-full border-2 w-6 h-6 shadow-inner border-white bg-white shadow border-4 transition-all transition-duration-300 ease-in-out"
        :class="coreStyles"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ComponentsBaseSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonStyles() {
      return {
        'bg-teal-200': this.value,
        'bg-grey-200': !this.value,
      };
    },
    coreStyles() {
      return {
        'translate-switch': this.value,
        'translate-x-0': !this.value,
      };
    },
  },
  methods: {
    onChange() {
      const val = !this.value;
      this.$emit('input', val);
      this.$emit('change', val);
    },
  },
};
</script>
