<template>
  <div>
    <AdminCardRow
      v-bind="$attrs"
      :value="modelValue"
      :type="type"
      :disabled="disabledInput"
      :options="optionsWithUnknown"
      @input="intercept"
    />
    <div v-if="unknownIsAllowed" class="flex items-center justify-end px-2">
      <input
        :id="`${identifier}IsUnknown`"
        v-model="unknownIsChecked"
        type="checkbox"
        :value="unknownIsChecked"
        @change="toggleUnknown"
      />
      <label :for="`${identifier}IsUnknown`" class="ru:form__label">
        {{ $t('components.adminCardRowWithUnknown.unknown') }}
      </label>
    </div>
  </div>
</template>

<script>
import {
  UNKNOWN_DATE,
  UNKNOWN_NUMBER,
  UNKNOWN_TEXT,
  UNKNOWN_YEAR,
} from '@/utilities/cremations/form-constants';

import AdminCardRow from '@/components/admin/CardRow';

export default {
  name: 'ComponentsMoleculesAdminCardRowWithUnknown',
  components: { AdminCardRow },
  props: {
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    optionsWithUnknown() {
      if (this.options) {
        if (typeof this.options[0] === 'string') {
          return [...this.options, UNKNOWN_TEXT];
        }
        return [
          ...this.options,
          {
            value: UNKNOWN_TEXT,
            text: UNKNOWN_TEXT.charAt(0) + UNKNOWN_TEXT.slice(1).toLowerCase(),
          },
        ];
      }
      return null;
    },
    disabledInput() {
      return this.unknownIsAllowed && (this.disabled || this.unknownIsChecked);
    },
    unknownIsChecked() {
      const dateIsUnknown = this.type === 'date' && this.value === UNKNOWN_DATE;
      const numberIsUnknown =
        this.type === 'number' && this.value === UNKNOWN_NUMBER;
      const textIsUnknown =
        this.value === UNKNOWN_TEXT || this.value === `${UNKNOWN_YEAR}-01-01`;

      return dateIsUnknown || numberIsUnknown || textIsUnknown;
    },
    unknownIsAllowed() {
      return !['select', 'radio', 'checkbox'].includes(this.type);
    },
  },
  created() {
    this.identifier = this.id + '__' + Math.random().toString(36).slice(2);
    if (this.unknownIsChecked && this.unknownIsAllowed) {
      this.modelValue = null;
    }
  },
  methods: {
    toggleUnknown($event) {
      if ($event.target.checked) {
        switch (this.type) {
          case 'number':
            this.modelValue = UNKNOWN_NUMBER;
            break;
          case 'date':
            this.modelValue = UNKNOWN_DATE;
            break;
          default:
            this.modelValue = UNKNOWN_TEXT;
            break;
        }
      }
      this.$emit('input', this.modelValue);
      this.modelValue = null;
    },
    intercept(value) {
      this.$emit('input', value);
    },
  },
};
</script>
