<template>
  <AuthPanel>
    <template #heading>{{
      $t('texts.sentences.twoFactorAuthentication')
    }}</template>
    <template #form>
      <p class="mb-2">
        {{ $t('pages.2FaSetup.twoFactorMessage') }}
      </p>
      <ol>
        <i18n path="pages.2FaSetup.installApp" tag="li" class="pb-2">
          <template #authy>
            <strong>{{ $t('pages.2FaSetup.authy') }}</strong>
          </template>
          <template #google>
            <strong>{{ $t('pages.2FaSetup.googleAuthenticator') }}</strong>
          </template>
        </i18n>
        <i18n path="pages.2FaSetup.addToken" tag="li" class="pb-2">
          <strong>{{ $t('pages.2FaSetup.token') }}</strong>
        </i18n>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="w-56 h-56 bg-grey-300 my-4 mx-auto" v-html="image" />
        <li class="pb-2">
          {{ $t('pages.2FaSetup.enterNumber') }}
        </li>
      </ol>
      <AdiForm
        v-slot="{ loading }"
        :mutation="ACTIVATE_2FA"
        :variables="variables"
        :custom-error="$t('pages.2FaSetup.errorMessage')"
        submit-icon="arrow-right"
        :submit-label="$t('forms.labels.save')"
        submit-wide
        @done="done"
      >
        <FormRow>
          <TextInput
            id="twoFactorCode"
            v-model="twoFactorCode"
            :disabled="loading"
            placeholder="000000"
            rules="required"
            type="text"
          />
        </FormRow>
      </AdiForm>
    </template>
  </AuthPanel>
</template>

<script>
import { mapActions } from 'vuex';

import GENERATE_2FA from '@/graphql/mutations/GenerateTwoFactorSecret';
import ACTIVATE_2FA from '@/graphql/mutations/ActivateTwoFactor';
import ACTIVATE_2FA_LEGACY from '@/graphql/mutations/ActivateTwoFactorCode';

import AuthPanel from '@/components/AuthPanel';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import TextInput from '@/components/TextInput';

import { parseJwtTokenAndReturnPayload } from '@/utilities';

export default {
  name: 'PagesAffiliateLogIn2faSetup',
  components: {
    AuthPanel,
    AdiForm,
    FormRow,
    TextInput,
  },
  middleware: ['notAuthenticated'],
  data() {
    return {
      ACTIVATE_2FA,
      ACTIVATE_2FA_LEGACY,
      twoFactorCode: '',
      image: '',
    };
  },
  computed: {
    variables() {
      return {
        twoFactorCode: this.twoFactorCode,
        sessionToken: this.$route.query.sessionToken,
        state: this.$route.query.state,
      };
    },
  },
  async mounted() {
    this.$analytics.reset();

    if (!this.$route.query.sessionToken) {
      this.$router.push({
        path: this.localePath('/log-in'),
      });
    }
    const { data } = await this.$apollo.mutate({
      mutation: GENERATE_2FA,
      variables: {
        sessionToken: this.$route.query.sessionToken,
      },
    });
    this.image = data.generateTwoFactorSecret.image;
  },
  methods: {
    ...mapActions(['setTwoFactorEnabled']),
    done({ data }) {
      if (data.activateTwoFactor?.success) {
        const { sessionToken: modifiedSessionToken } = data.activateTwoFactor;
        try {
          const { continue_uri: continueUri } =
            parseJwtTokenAndReturnPayload(modifiedSessionToken);
          const redirectUrl =
            continueUri +
            '?' +
            new URLSearchParams({
              sessionToken: modifiedSessionToken,
              state: this.$route.query.state,
            }).toString();
          window.location.href = redirectUrl;
        } catch (error) {
          throw new Error('Invalid token passed');
        }
      }
    },
  },
};
</script>
