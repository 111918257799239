<template>
  <ProductGateway
    :title="$t('components.poaGateway.title')"
    icon="poa"
    :primary-cta-list="primaryCtaList"
    :items-cta="itemsCta"
    :step="2"
    :progress="poaProgressAsPercentage"
    :items="items"
    :items-description="itemsDescription"
    :completed="poaProgressAsPercentage === 100"
  >
    <RuImage :src="require('@/assets/svg/gateway__poa.svg')" alt="" />
    <p>
      {{ description }}
    </p>
  </ProductGateway>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductGateway from '@/components/molecules/ProductGateway';
import poaProgress from '@/mixins/poa-progress';
import RuImage from '@/components/atoms/Image';

import { queries } from '@/modules/apollo-queries/poa-status';
import { PRICE_DEFAULTS } from '@/store/checkout';

export default {
  name: 'ComponentsTemplatesPoaGateway',
  components: {
    ProductGateway,
    RuImage,
  },
  mixins: [poaProgress],
  apollo: {
    poaStatus: {
      ...queries.getPoaStatus(),
    },
  },
  computed: {
    ...mapGetters(['userId']),
    primaryCtaList() {
      if (this.poaStatus === 'APPROVED') {
        return [
          {
            link: '/poa',
            text: this.$t('components.poaGateway.downloadMyPoa'),
            icon: 'download',
          },
          {
            link: '/poa',
            text: this.$t('components.poaGateway.updateMyPoa'),
            icon: 'pencil',
            level: 'plain',
          },
        ];
      }
      if (!this.poaStatus) {
        return [
          {
            link: '/poa',
            text: this.ctaText,
          },
          {
            link: '/poa',
            text: this.$t('components.poaGateway.learnMore'),
            level: 'plain',
            icon: null,
          },
        ];
      }
      return [
        {
          link: '/poa',
          text: this.ctaText,
        },
      ];
    },
    itemsCta() {
      return {
        link: '/poa',
        text: this.ctaText,
      };
    },
    description() {
      if (this.poaStatus === 'APPROVED') {
        return this.$t('components.poaGateway.approved');
      }
      return this.$t('components.poaGateway.incomplete');
    },
    ctaText() {
      if (this.poaProgressAsPercentage > 0) {
        if (this.poaProgressAsPercentage === 100) {
          return this.$t('components.poaGateway.downloadMyPoa');
        } else if (this.poaProgressAsPercentage === 80) {
          return this.$t('components.poaGateway.submitPoa');
        }
        return this.$t('components.poaGateway.update');
      }
      return this.$t('components.poaGateway.purchase', {
        cost: PRICE_DEFAULTS.DEFAULT_POA_PRICE,
      });
    },
    aboutYourselfStatus() {
      if (this.poaStatus === 'APPROVED') return 'approved';
      if (this.hasCompletedAboutYourself) return 'completed';
      if (this.hasStartedAboutYourself) return 'in-progress';
      return 'not-started';
    },
    financialStatus() {
      if (this.poaStatus === 'APPROVED') return 'approved';
      if (this.hasCompletedFinancial) return 'completed';
      if (this.hasStartedFinancial) return 'in-progress';
      return 'not-started';
    },
    medicalStatus() {
      if (this.poaStatus === 'APPROVED') return 'approved';
      if (this.hasCompletedMedical) return 'completed';
      if (this.hasStartedMedical) return 'in-progress';
      return 'not-started';
    },
    itemsDescription() {
      const toComplete = [
        this.hasCompletedAboutYourself,
        this.hasCompletedMedical,
        this.hasCompletedFinancial,
      ];
      const hasCompleted = toComplete.reduce(
        (hasCompleted, current) => (current ? hasCompleted + 1 : hasCompleted),
        0
      );
      return `${hasCompleted} ${this.$t('components.poaGateway.outOf')} ${
        toComplete.length
      } ${this.$t('components.poaGateway.areComplete')}`;
    },
    items() {
      return [
        {
          title: this.$t('components.poaGateway.aboutYourself'),
          status: this.aboutYourselfStatus,
          cta: '/poa/about-yourself',
          disabled: !this.poaStatus || this.poaStatus === 'APPROVED',
        },
        {
          title: this.$t('components.poaGateway.financial'),
          status: this.financialStatus,
          cta: '/poa/financial',
          disabled: !this.poaStatus || this.poaStatus === 'APPROVED',
        },
        {
          title: this.$t('components.poaGateway.medical'),
          status: this.medicalStatus,
          cta: '/poa/medical',
          disabled: !this.poaStatus || this.poaStatus === 'APPROVED',
        },
      ];
    },
  },
};
</script>
