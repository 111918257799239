<template>
  <span>
    <template v-if="!['APPROVED', 'FLAGGED'].includes(willStatus)">
      is
    </template>
    <Tooltip
      :class="{ '--theme-color --error': willStatus === 'FLAGGED' }"
      :content="tooltip"
    >
      <DecoratedText>
        {{ text }}
      </DecoratedText>
    </Tooltip>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import Tooltip from '@/components/Tooltip';
import DecoratedText from '@/components/atoms/DecoratedText';

export default {
  name: 'ComponentsOrganismsWillStatus',
  components: {
    Tooltip,
    DecoratedText,
  },
  props: {
    ready: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['willStatus']),
    dashed() {
      return this.willStatus === 'FLAGGED';
    },
    text() {
      let text = '';

      switch (this.willStatus) {
        case 'IN_PROGRESS':
          text = this.ready
            ? this.$t('components.statusText.statusReadyToSubmit')
            : this.$t('components.statusText.statusInProgress');
          break;
        case 'AWAITING_APPROVAL':
          text = 'being reviewed';
          break;
        case 'FLAGGED':
          text = 'needs attention';
          break;
        case 'APPROVED':
          text = 'is complete';
          break;
        default:
          break;
      }

      return text;
    },
    tooltip() {
      let tooltip = '';
      switch (this.willStatus) {
        case 'IN_PROGRESS':
          tooltip = this.ready
            ? this.$t('components.statusText.inProgress.isReady')
            : this.$t('components.statusText.inProgress.notReady');
          break;
        case 'AWAITING_APPROVAL':
          tooltip = this.$t('components.statusText.awaitingApproval');
          break;
        case 'FLAGGED':
          tooltip = this.$t('components.statusText.flagged');
          break;
        case 'APPROVED':
          tooltip = this.$t('components.statusText.approved');
          break;
        default:
          break;
      }
      return tooltip;
    },
  },
};
</script>
