<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.will.aboutYourSelf.letsStartToWrite') }}
        <DecoratedText>
          {{ $t('pages.will.aboutYourSelf.yourWill') }}
        </DecoratedText>
      </Headline>
      <FormSection class="step-1">
        <Headline :level="2">
          {{ $t('pages.will.aboutYourSelf.questions.fullName') }}
        </Headline>
        <FormRow>
          <TextInput
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourFirstName')"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourMiddleName')"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourLastName')"
            rules="required"
          />
        </FormRow>
      </FormSection>
      <FormSection class="step-2">
        <Headline :level="2">
          {{ $t('pages.will.aboutYourSelf.questions.knownByAnotherName') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.aboutYourSelf.egAltName') }}
        </Tip>
        <LargeRadioButtons
          id="has_alt_name"
          v-model="willMeta.has_alt_name"
          :options="[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_alt_name" class="step-3">
        <Headline :level="3">
          {{ $t('pages.will.aboutYourSelf.questions.otherName') }}
        </Headline>
        <FormRow>
          <TextInput
            id="alt_name_first"
            v-model="willMeta.alt_name_first"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourFirstName')"
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="alt_name_middle"
            v-model="willMeta.alt_name_middle"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourMiddleName')"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="alt_name_last"
            v-model="willMeta.alt_name_last"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourLastName')"
            rules="required"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import TextInput from '@/components/TextInput';
import DecoratedText from '@/components/atoms/DecoratedText';
import Tip from '@/components/molecules/Tip';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfName',
  components: {
    AdiForm,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
    DecoratedText,
    Tip,
    LargeRadioButtons,
    Headline,
  },
  mixins: [user, will],
  computed: {
    isComplete() {
      return !!(
        this.willMeta.name_first &&
        this.willMeta.name_last &&
        (this.willMeta.has_alt_name === false ||
          (this.willMeta.alt_name_first && this.willMeta.alt_name_last))
      );
    },
  },
  methods: {
    onDone() {
      const willMeta = this.willMeta;

      this.$nuxt.$emit('sendTrackingAttributes', {
        name: `${willMeta.name_first} ${willMeta.name_middle} ${willMeta.name_last}`,
      });

      if (willMeta.has_alt_name) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          alternative_name: `${willMeta.alt_name_first} ${willMeta.alt_name_middle} ${willMeta.alt_name_last}`,
        });
      }

      this.$router.push({
        path: this.localePath('/will/about-yourself/date-of-birth'),
      });
    },
  },
};
</script>
