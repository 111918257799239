<template>
  <AdminCard tight>
    <AdminDataTable
      :columns="columns"
      :loading="$apollo.loading"
      :min-rows="purchasedProductsReportData.length"
      :row-count="purchasedProductsReportData.length"
    >
      <template #rows>
        <AdminDataTableRow
          v-for="(product, index) in purchasedProductsReportData"
          :key="index"
          class="group"
        >
          <AdminDataTableCell>
            {{
              productToDisplayNameMapping[product.product] || product.product
            }}
          </AdminDataTableCell>
          <AdminDataTableCell>
            {{ product.quantity }}
          </AdminDataTableCell>
          <AdminDataTableCell>
            ${{ (product.totalValueInCents / 100).toFixed(2) }}
          </AdminDataTableCell>
        </AdminDataTableRow>
      </template>
    </AdminDataTable>
  </AdminCard>
</template>

<script>
import GET_PURCHASED_PRODUCTS_REPORT_QUERY from '@/graphql/queries/GetPurchasedProductsReportData';

import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';

import { productToDisplayNameMapping } from '@/utilities';

export default {
  name: 'ComponentsPurchasedProductsReportTable',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
  },
  props: {
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    purchasedProductsReportData: {
      query: GET_PURCHASED_PRODUCTS_REPORT_QUERY,
      variables() {
        return {
          startDate: this.dateFrom,
          endDate: this.dateTo,
        };
      },
      update: ({ getPurchasedProductsReportData }) => {
        // sort by revenue
        getPurchasedProductsReportData.sort(
          (a, b) => b.totalValueInCents - a.totalValueInCents
        );
        return getPurchasedProductsReportData;
      },
    },
  },
  data() {
    return {
      columns: ['Product', 'Quantity', 'Revenue'],
      pageSize: 10,
      purchasedProductsReportData: [],
      productToDisplayNameMapping,
    };
  },
};
</script>
