<template>
  <ModuleStep :index="5" :prev-step="previous">
    <BaseAlert v-if="errorMessage" compact type="error">
      <p class="font-normal">{{ errorMessage }}</p>
    </BaseAlert>
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{
            $t('pages.poa.substituteDecisionMaker.whoShouldBeYour', {
              substitute: canHaveMultipleSubstitutes
                ? 'substitutes'
                : 'substitute',
            })
          }}
        </Headline>
        <p v-if="canHaveMultipleSubstitutes" class="mb-4">
          {{
            $t('pages.poa.substituteDecisionMaker.maxSubstitutes', {
              substitute: substitutesAllowed[userState],
            })
          }}
        </p>
        <p v-else class="mb-4">
          {{ $t('pages.poa.substituteDecisionMaker.oneSubstitute') }}
        </p>
        <PersonSelector
          v-model="selectedSecondaryAttorneyPersonIDs"
          :loading="loading"
          :people="filteredPeople"
          :max="substitutesAllowed[userState]"
          :will-beneficiary-selector="false"
          :identifier-requirements="identifierRequirements"
          type="attorney"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import * as peopleQueries from '@/modules/apollo-queries/people';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormSection from '@/components/FormSection';
import PersonSelector from '@/components/PersonSelector';
import BaseAlert from '@/components/BaseAlert';
import Headline from '@/components/atoms/Headline';

import SET_ATTORNEYS from '@/graphql/mutations/SetAttorneys';

import { will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliatePoaFinancialSubstituteDecisionMakers',
  components: {
    AdiForm,
    ModuleStep,
    FormSection,
    PersonSelector,
    BaseAlert,
    Headline,
  },
  mixins: [will],
  apollo: {
    attorneys: {
      ...queries.getAttorneys({ power: 'FINANCIAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    people: {
      ...peopleQueries.queries.getPeopleOfAccount(),
      variables() {
        return {
          userId: this.userId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedSecondaryAttorneyPersonIDs: [],
      substitutesAllowed: {
        act: 4,
        nsw: 1,
        nt: 1,
        qld: 1,
        vic: 1,
        wa: 1,
      },
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    canHaveMultipleSubstitutes() {
      return this.substitutesAllowed[this.userState] > 1;
    },
    isComplete() {
      return this.selectedSecondaryAttorneyPersonIDs.length > 0;
    },
    filteredPeople() {
      return (
        this.people &&
        this.people.filter((person) => {
          const meta = metaArrayToObject(person.meta);
          if (!this.primaryAttorneyPeopleIDs) return false;
          const personIsAlreadySubstituteFinancialAttorney =
            this.selectedSecondaryAttorneyPersonIDs.includes(person.id);
          return (
            personIsAlreadySubstituteFinancialAttorney ||
            (meta.is_over_18 &&
              meta.identifier === 'address' &&
              !this.primaryAttorneyPeopleIDs?.includes(person.id))
          );
        })
      );
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    identifierRequirements() {
      return ['address', ...(this.userState === 'vic' ? ['dob'] : [])];
    },
    primaryAttorneyPeopleIDs() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'PRIMARY')
        ?.map((attorney) => attorney.person.id);
    },
    oldSecondaryAttorneys() {
      return this.attorneys?.filter(
        (attorney) => attorney.role === 'SECONDARY'
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length && !this.selectedSecondaryAttorneyPersonIDs.length) {
        this.selectedSecondaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'SECONDARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    async onSubmit() {
      this.errorMessage = '';
      try {
        await this.$apollo.mutate({
          mutation: SET_ATTORNEYS,
          variables: {
            personIds: this.selectedSecondaryAttorneyPersonIDs,
            power: 'FINANCIAL',
            role: 'SECONDARY',
            poaId: this.poaId,
          },
        });
        await this.$apollo.queries.attorneys.refetch();
      } catch (error) {
        await this.$apollo.queries.people.refetch();
        await this.$apollo.queries.attorneys.refetch();
        this.selectedSecondaryAttorneyPersonIDs = [];
        this.$nuxt.$emit('unlockForm');
        this.errorMessage = this.$t('texts.sentences.duplicateAttorneyError');
        return;
      }

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
