<template>
  <div>
    <AdminCard
      v-for="(order, index) in orders"
      :key="order.id"
      :title="'Order: ' + (index + 1)"
      tight
    >
      <AdminSimpleTable description="Orders" :rows="buildOrderRows(order)" />
      <AdminSimpleTable
        v-for="(item, index) in order.orderItems"
        :key="item.id"
        description="Items"
        :rows="buildItemsRows(item, index)"
      />
    </AdminCard>
  </div>
</template>

<script>
import AdminCard from '@/components/admin/Card';
import AdminSimpleTable from '@/components/admin/SimpleTable';

export default {
  name: 'ComponentsOrdersDetails',
  components: {
    AdminCard,
    AdminSimpleTable,
  },
  props: {
    orders: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  methods: {
    centsToDollars(cents) {
      return cents / 100;
    },
    buildItemsRows(item, index) {
      const rows = [];

      rows.push([`${this.$t('components.ordersDetails.item')} ${index + 1}`]);
      rows.push([this.$t('components.ordersDetails.product'), item.product]);
      rows.push([
        this.$t('components.ordersDetails.value'),
        '$' + this.centsToDollars(item.valueInCents),
      ]);
      rows.push([
        this.$t('components.ordersDetails.createdAt'),
        this.$formatDate(item.createdAt),
      ]);
      rows.push([
        this.$t('components.ordersDetails.cancelled'),
        item.cancelled,
      ]);
      return rows;
    },
    buildOrderRows(order) {
      return [
        [this.$t('components.ordersDetails.id'), order.id],
        [
          this.$t('components.ordersDetails.value'),
          '$' + this.centsToDollars(order.valueInCents),
        ],
        [
          this.$t('components.ordersDetails.createdAt'),
          this.$formatDate(order.createdAt),
        ],
        [
          this.$t('components.ordersDetails.paymentMethod'),
          order.paymentMethod,
        ],
        [
          this.$t('components.ordersDetails.externalPaymentId'),
          order.externalPaymentId,
        ],
        [this.$t('components.ordersDetails.discount'), order.discountId],
      ];
    },
  },
};
</script>
