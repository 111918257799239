var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/will"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_assets,"submit-icon":"arrow-right"},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.asset.title'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.asset.desc'))+"\n      ")]),_vm._v(" "),_c('Tip',[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.assets.asset.tip.assetsGuide'))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.assets.asset.tip.superannuationInsurance'))+"\n        ")]),_vm._v(" "),_c('Buttons',{attrs:{"layout":"start"}},[_c('RuButton',{attrs:{"theme":"secondary","size":"small","href":"https://safewill.com/blog/posts/what-happens-to-your-superannuation-when-you-die"}},[_c('Icon',{attrs:{"id":"info"}}),_vm._v("\n            "+_vm._s(_vm.$t('pages.will.assets.asset.superDeathExplanation'))+"\n          ")],1)],1)],1),_vm._v(" "),_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.asset.question'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasAssets","options":[
          {
            label: _vm.$t('forms.labels.yes'),
            value: true,
          },
          {
            label: _vm.$t('forms.labels.no'),
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_assets),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_assets", $$v)},expression:"willMeta.has_assets"}})],1),_vm._v(" "),(_vm.willMeta.has_assets)?_c('FormSection',[_c('Headline',{attrs:{"level":2}},[_vm._v(" "+_vm._s(_vm.$t('texts.sentences.yourAssets'))+" ")]),_vm._v(" "),(!_vm.selectedAssets.length)?_c('EmptyState',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.asset.noAssets'))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.selectedAssets),function(asset,index){return _c('AssetInputs',{key:index,attrs:{"id":`asset_${asset.id || index}`,"disabled":loading,"description":asset.meta.description,"type":asset.meta.type},on:{"description":function($event){asset.meta.description = $event},"type":function($event){asset.meta.type = $event},"add":_vm.addAssetInput,"remove":function($event){return _vm.removeAssetInput(asset)}}})}),_vm._v(" "),_c('Buttons',{attrs:{"layout":"end"}},[_c('RuButton',{attrs:{"size":"large","level":"secondary"},on:{"click":_vm.addAssetInput}},[_vm._v("\n          + "+_vm._s(_vm.$t('forms.labels.add'))+"\n        ")])],1)],2):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }