<template>
  <Alert layout="center" level="medium" theme="info">
    {{ $t('components.masqueradeAlert.message') }} {{ email }}
    <Buttons theme="default" layout="inline">
      <RuButton level="plain" @click="exit">
        {{ $t('components.masqueradeAlert.exit') }}
      </RuButton>
    </Buttons>
  </Alert>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import Alert from '@/components/molecules/Alert';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';

export default {
  name: 'ComponentsSnowflakesMasqueradeAlert',
  components: {
    Alert,
    Buttons,
    RuButton,
  },
  computed: {
    ...mapGetters(['email']),
  },
  methods: {
    ...mapMutations('poa', ['setPoaId']),
    async exit() {
      try {
        await this.$apollo.query({
          query: CURRENT_USER_QUERY,
        });
        this.setPoaId(null);
        this.$router.push({ path: this.localePath('/admin') });
      } catch (e) {
        console.error(e.message);
      }
    },
  },
};
</script>
