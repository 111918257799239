var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":3,"prev-step":_vm.prevStep}},[_c('AdiForm',{attrs:{"hide-submit":!(
        _vm.willMeta.professional_executor_terms_check &&
        _vm.willMeta.professional_executor_payment_check &&
        _vm.willMeta.professional_executor_court_check
      ),"submit-icon":"arrow-right"},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.executors.professionalTerms.headline'))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.executors.professionalTerms.tip'))+"\n      ")]),_vm._v(" "),_c('div',{ref:"terms",staticClass:"h-84 px-8 py-6 border-2 border-grey-200 rounded overflow-y-scroll bg-white professional-terms",on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"text-charcoal-400",domProps:{"innerHTML":_vm._s(
            _vm.$t('pages.will.executors.professionalTerms.termsConditions')
          )}})]),_vm._v(" "),_c('FormRow',[_c('Tooltip',{attrs:{"disabled":_vm.hasScrolledTerms,"content":"You'll need to read the terms before you tick this box."}},[_c('CheckboxInput',{staticClass:"mt-4 md:mt-6",attrs:{"id":"professional_executor_terms","disabled":!_vm.hasScrolledTerms,"required":""},model:{value:(_vm.willMeta.professional_executor_terms_check),callback:function ($$v) {_vm.$set(_vm.willMeta, "professional_executor_terms_check", $$v)},expression:"willMeta.professional_executor_terms_check"}},[_vm._v("\n            "+_vm._s(_vm.$t('pages.will.executors.professionalTerms.label.terms'))+"\n          ")])],1)],1),_vm._v(" "),_c('FormRow',[_c('CheckboxInput',{staticClass:"mt-4 md:mt-6",attrs:{"id":"professional_executor_payment","required":""},model:{value:(_vm.willMeta.professional_executor_payment_check),callback:function ($$v) {_vm.$set(_vm.willMeta, "professional_executor_payment_check", $$v)},expression:"willMeta.professional_executor_payment_check"}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.executors.professionalTerms.label.payment'))+"\n        ")])],1),_vm._v(" "),_c('FormRow',[_c('CheckboxInput',{staticClass:"mt-4 md:mt-6",attrs:{"id":"professional_executor_court","required":""},model:{value:(_vm.willMeta.professional_executor_court_check),callback:function ($$v) {_vm.$set(_vm.willMeta, "professional_executor_court_check", $$v)},expression:"willMeta.professional_executor_court_check"}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.executors.professionalTerms.label.court'))+"\n        ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }