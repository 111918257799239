<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.registerDeath') }}
    </Headline>
    <CremationsFormInstructions />
    <FormWrapper @submit="submitHandler">
      <Headline :level="3">
        {{ $t('forms.labels.placeOfBirth') }}
      </Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestPlaceOfBirthSuburb"
            v-model="formData.guestPlaceOfBirthSuburb"
            :required="false"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.suburb')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            v-if="showGuestPlaceOfBirthRegion"
            id="guestPlaceOfBirthState"
            v-model="formData.guestPlaceOfBirthState"
            :required="false"
            :disabled="loading"
            type="text"
            :text="$t('forms.labels.region')"
          />
          <FormControlWithUnknown
            v-else
            id="guestPlaceOfBirthState"
            v-model="formData.guestPlaceOfBirthState"
            :required="false"
            :disabled="loading"
            type="select"
            :text="$t('forms.labels.australianState')"
            :options="australianStatesOptions"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControlWithUnknown
            id="guestPlaceOfBirthCountry"
            v-model="formData.guestPlaceOfBirthCountry"
            :required="false"
            :disabled="loading"
            type="select"
            :text="$t('forms.labels.country')"
            :options="countries"
          />
        </div>
        <div
          v-if="showGuestDateOfArrival"
          class="cell cell__12/12 cell__4/12--lg"
        >
          <FormControlWithUnknown
            id="guestDateOfArrival"
            v-model="guestYearOfArrival"
            type="text"
            inputmode="decimal"
            min="4"
            max="4"
            pattern="[0-9]{4}"
            :text="$t('forms.labels.yearOfArrival')"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          {{ $t('pages.cremations.aboriginalOrTorresStraitIslandOrigin') }}
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestIndigenousOrigin"
            v-model="formData.guestIndigenousOrigin"
            :required="false"
            :disabled="loading"
            type="select"
            :text="$t('forms.labels.indigenousOrigin')"
            :options="INDIGENOUS_ORIGIN_OPTIONS"
          />
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="goNext">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="goNext">
          {{ $t('forms.labels.ok') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import FormControlWithUnknown from '@/components/molecules/FormControlWithUnknown';
import CremationsFormInstructions from '@/components/snowflakes/CremationsFormInstructions';

export default {
  name: 'PagesAffiliateCremationsAtNeedLifeId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
    FormControlWithUnknown,
    CremationsFormInstructions,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      formData: {
        guestPlaceOfBirthSuburb: null,
        guestPlaceOfBirthState: null,
        guestPlaceOfBirthCountry: null,
        guestDateOfArrival: null,
        guestIndigenousOrigin: null,
      },
      guestYearOfArrival: null,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/parents/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
  },
  watch: {
    loading() {
      if (this.formData.guestDateOfArrival && !this.guestYearOfArrival) {
        const year = new Date(
          Number(this.formData.guestDateOfArrival)
        ).getFullYear();
        this.guestYearOfArrival =
          year === this.UNKNOWN_YEAR ? this.UNKNOWN_TEXT : year;
      }
    },
  },
  methods: {
    submitHandler() {
      if (this.showGuestDateOfArrival && this.guestYearOfArrival) {
        this.formData.guestDateOfArrival = this.guestYearOfArrival = `${
          this.guestYearOfArrival === this.UNKNOWN_TEXT
            ? this.UNKNOWN_YEAR
            : this.guestYearOfArrival
        }-01-01`;
      } else {
        this.formData.guestDateOfArrival = null;
      }
      return this.saveUpdatedDetails('final');
    },
  },
};
</script>
