<template>
  <BaseModal :is-dismissable="false">
    <Badge color="basalt" background="sky" size="large">
      <Icon id="charity" size="large" />
    </Badge>
    <hr class="divider" />
    <template v-if="showOptions">
      <Headline :level="2">
        {{ $t('components.charitiesConsentModal.title') }}
      </Headline>
      <p>
        {{ $t('components.charitiesConsentModal.message1') }}
      </p>
      <p>
        {{ $t('components.charitiesConsentModal.message2') }}
      </p>
      <RadioOptions
        id="notify_charities"
        :value="notify_charities"
        :options="options"
        @change="optionSelected"
      />
    </template>
    <template v-else-if="notify_charities === false">
      <p>{{ $t('components.charitiesConsentModal.thankYouTitle') }}</p>
      <p>
        {{ $t('components.charitiesConsentModal.thankYouMessage') }}
      </p>
      <Buttons layout="end">
        <RuButton level="secondary" round @click="reset">
          {{ $t('components.charitiesConsentModal.changedMyMind') }}
        </RuButton>
        <RuButton level="primary" round @click="savePreference(false)">
          {{ $t('components.charitiesConsentModal.finish') }}
        </RuButton>
      </Buttons>
    </template>
  </BaseModal>
</template>

<script>
import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '@/graphql/mutations/UpdateNotifyCharityOfWill';

import BaseModal from '@/components/BaseModal';
import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';
import RadioOptions from '@/components/molecules/RadioOptions';
import Badge from '@/components/atoms/Badge';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { will } from '@/mixins/apollo';

export default {
  name: 'ComponentsOrganismsCharitiesConsentModal',
  components: {
    BaseModal,
    Icon,
    Headline,
    RadioOptions,
    Badge,
    Buttons,
    RuButton,
  },
  mixins: [will],
  data() {
    return {
      options: [
        {
          label: this.$t('components.charitiesConsentModal.no'),
          value: false,
          level: 'neutral',
          theme: 'light',
        },
        {
          label: this.$t('components.charitiesConsentModal.yes'),
          value: true,
          level: 'secondary',
          theme: 'wills',
        },
      ],
      notify_charities: true,
      isDirty: false,
    };
  },
  computed: {
    showOptions() {
      return !this.isDirty || this.notify_charities === true;
    },
  },
  methods: {
    async savePreference(preference) {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'consent_requested',
        props: {
          action: 'Share contact details with charities',
          granted: preference,
        },
      });
      await this.$apollo.mutate({
        mutation: UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
        variables: {
          userId: this.userId,
          value: preference,
        },
      });
      await this.refetchWill();
      await this.$updateShouldShowCharityConsent();
    },
    optionSelected(preference) {
      this.notify_charities = preference;
      this.isDirty = true;
      if (this.notify_charities === true) {
        this.savePreference(preference);
      }
    },
    reset() {
      this.isDirty = false;
      this.notify_charities = true;
    },
  },
};
</script>
