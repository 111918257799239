<template>
  <ModuleStep :index="3" prev-step="/will/funeral/type">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-icon="arrow-right"
      :submit-label="submitLabel"
      @done="onDone"
    >
      <FormSection>
        <Headline :level="1">
          {{ getHeadingText }}
        </Headline>
        <Tip>
          {{ $t('pages.will.funeral.executorLocation') }}
        </Tip>
        <TextInput
          id="funeral_location"
          v-model="willMeta.funeral_location"
          :disabled="loading"
          :placeholder="$t('forms.placeholders.enterLocation')"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import TextInput from '@/components/TextInput';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralLocation',
  components: {
    AdiForm,
    FormSection,
    ModuleStep,
    TextInput,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.funeral_location
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
    getHeadingText() {
      return this.willMeta.funeral_type === 'cremation'
        ? this.$t('pages.will.funeral.chooseAshesLocation')
        : this.$t('pages.will.funeral.chooseBurialLocation');
    },
  },
  methods: {
    onDone() {
      this.$router.push({
        path: this.localePath('/will/funeral/other-requests'),
      });
    },
  },
};
</script>
