<template>
  <div :style="styles" class="ru:progress">
    <nuxt-link
      v-if="features.willEnabled"
      to="/will"
      class="ru:progress__product"
    >
      <div class="ru:progress__product-title --will">
        <Icon id="will" />
        <span>{{ $t('components.progress.will') }}</span>
      </div>
      <ProgressBar :progress="willProgress" />
    </nuxt-link>
    <nuxt-link
      v-if="features.powerOfAttorneyEnabled"
      to="/poa"
      class="ru:progress__product"
    >
      <div class="ru:progress__product-title --poa">
        <Icon id="poa" />
        <span>{{ $t('components.progress.poa') }}</span>
      </div>
      <ProgressBar :progress="poaProgressAsPercentage" />
    </nuxt-link>
    <nuxt-link
      v-if="features.endOfLifeEnabled"
      :to="endOfLifePlaningCta"
      class="ru:progress__product"
    >
      <div class="ru:progress__product-title --vault">
        <Icon id="end-of-life" />
        <span>{{ $t('components.progress.endOfLife') }}</span>
      </div>
      <ProgressBar :progress="vaultProgress" />
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Icon from '@/components/atoms/Icon';
import ProgressBar from '@/components/atoms/ProgressBar';

import { purchasedProducts } from '@/mixins/apollo';
import poaProgress from '@/mixins/poa-progress';
import willProgress from '@/mixins/will-progress';

export default {
  name: 'ComponentsOrganismsProgress',
  components: {
    Icon,
    ProgressBar,
  },
  mixins: [purchasedProducts, poaProgress, willProgress],
  computed: {
    ...mapGetters('modules', ['modules']),
    ...mapGetters('affiliate', ['features']),
    ...mapGetters([
      'willEnabled',
      'powerOfAttorneyEnabled',
      'endOfLifeEnabled',
    ]),
    ...mapGetters('end-of-life-categories', ['endOfLifeCategoryItemCounts']),
    willProgress() {
      let willProgress = this.completedWillStepsAsPercentage * 0.9;
      if (this.isWillPurchased) {
        willProgress += 10;
      }
      return willProgress;
    },
    vaultProgress() {
      const totalCategories = Object.keys(
        this.endOfLifeCategoryItemCounts
      ).length;
      if (!totalCategories) return 1;
      const categoriesHaveItems = Object.values(
        this.endOfLifeCategoryItemCounts
      ).reduce((total, count) => total + count, 0);
      return Math.min((categoriesHaveItems / totalCategories) * 100, 95);
    },
    endOfLifePlaningCta() {
      return this.endOfLifePlaningProgress > 0
        ? '/end-of-life'
        : '/onboarding/end-of-life';
    },
    styles() {
      return {
        '--will-color':
          this.willProgress > 0 ? 'var(--turquoise)' : 'var(--rock)',
        '--poa-color':
          this.poaProgressAsPercentage > 0 ? 'var(--turquoise)' : 'var(--rock)',
        '--vault-color':
          this.vaultProgress > 0 ? 'var(--turquoise)' : 'var(--rock)',
      };
    },
  },
  created() {
    this.getEndOfLifeCategoryItemCounts();
  },
  methods: {
    ...mapActions('end-of-life-categories', ['getEndOfLifeCategoryItemCounts']),
  },
};
</script>

<style lang="scss">
#{$ru} {
  &progress {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: var(--cell-spacing);

    &__product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: var(--base-margin-big);
      @include mq('min-md') {
        margin-bottom: 0;
      }

      &-title {
        display: flex;
        flex-direction: row;

        svg {
          margin-right: var(--base-margin-small);
          margin-bottom: var(--base-margin-small);
        }

        &.--will {
          color: --rgba(will-color);
        }

        &.--poa {
          color: --rgba(poa-color);
        }

        &.--vault {
          color: --rgba(vault-color);
        }
      }
    }

    @include mq('min-md') {
      flex-direction: row;
      gap: 1rem;

      &__product {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: 33.3333%;
      }
    }
  }
}
</style>
