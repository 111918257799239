<template>
  <BaseModal
    v-if="willMeta && willMeta.show_welcome_modal"
    :is-dismissable="false"
  >
    <div class="flex mb-6">
      <div
        v-for="i in totalSteps"
        :key="i"
        class="w-2 h-2 rounded-full mr-2 bg-grey-300 cursor-pointer"
        :class="{ 'bg-teal-200': i - 1 === step }"
        @click="step = i - 1"
      />
    </div>
    <div ref="container" class="welcome-modal">
      <transition-group
        tag="div"
        class="pb-8 relative"
        name="ru:wiggle-horizontal"
        mode="out-in"
      >
        <div v-if="step === 0" key="step0">
          <Headline :level="1">
            {{ $t('components.welcomeModal.step1.title') }}
          </Headline>
          <p>
            {{ $t('components.welcomeModal.step1.message') }}
          </p>
        </div>
        <div v-else-if="step === 1" key="step1">
          <Headline row :level="2">
            <Icon id="check" size="large" shadow />
            {{ $t('components.welcomeModal.step2.title') }}
          </Headline>
          <p>
            {{ $t('components.welcomeModal.step2.message') }}
          </p>
        </div>
        <div v-else-if="step === 2" key="step2">
          <Headline row :level="2">
            <Icon id="pencil" size="large" shadow />
            {{ $t('components.welcomeModal.step3.title') }}
          </Headline>
          <p>
            {{ $t('components.welcomeModal.step3.message') }}
          </p>
        </div>
        <div v-else-if="step === 3" key="step3">
          <Headline row :level="2">
            <Icon id="chat" size="large" shadow />
            {{ $t('components.welcomeModal.step4.title') }}
          </Headline>
          <p>
            {{ $t('components.welcomeModal.step4.message') }}

            <button
              type="button"
              class="link link-dark whitespace-no-wrap"
              @click="showChat"
            >
              {{ $t('components.welcomeModal.viaChat') }}
            </button>
            , {{ $t('components.welcomeModal.orCallUsOn') }}
            <a href="tel:1800103310" class="link link-dark whitespace-no-wrap">
              1800 10 33 10
            </a>
            - {{ $t('components.welcomeModal.open7days') }}
          </p>
        </div>
      </transition-group>
    </div>
    <Buttons layout="stretch">
      <RuButton level="secondary" @click="prevStep">
        {{ prevText }}
      </RuButton>
      <RuButton level="primary" @click="nextStep">
        {{ nextText }}
        <Icon id="arrow-right" size="small" />
      </RuButton>
    </Buttons>
  </BaseModal>
</template>

<script>
import { showChat } from '@/modules/chat';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import BaseModal from '@/components/BaseModal';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'ComponentsWelcomeModal',
  components: {
    Buttons,
    RuButton,
    Icon,
    BaseModal,
    Headline,
  },
  mixins: [user, will],
  data() {
    return {
      step: 0,
      totalSteps: 4,
    };
  },
  computed: {
    nextText() {
      if (this.step === 0) {
        return this.$t('components.welcomeModal.howItWork');
      } else if (this.step === this.totalSteps - 1) {
        return this.$t('components.welcomeModal.okay');
      }
      return this.$t('components.welcomeModal.next');
    },
    prevText() {
      return this.step === 0
        ? this.$t('components.welcomeModal.skip')
        : this.$t('components.welcomeModal.back');
    },
  },
  methods: {
    close() {
      this.willMeta.show_welcome_modal = false;
      this.updateWillMeta();
    },
    nextStep() {
      this.$refs.container.style.minHeight = `${this.$refs.container.offsetHeight}px`;
      if (this.step < this.totalSteps - 1) {
        this.step++;
      } else {
        this.close();
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.close();
      } else {
        this.step--;
      }
    },
    showChat() {
      showChat();
    },
  },
};
</script>
