<template>
  <div class="ru:affiliate-logo">
    <img v-if="logo" :src="logo" class="ru:affiliate-logo__image" alt="" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ComponentsAtomsAffiliateLogo',
  computed: {
    ...mapGetters('affiliate', ['branding']),
    logo() {
      return this.branding.logo;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &affiliate-logo {
    border-left: 1px solid --rgba(concrete);
    margin-left: var(--base-margin);
    padding-left: var(--base-margin);

    &__image {
      max-height: var(--app-logo-height);
      max-width: 10rem;
      display: block;
      @include mq('min-sm') {
        max-width: none;
      }
    }
  }
}
</style>
