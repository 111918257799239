<template>
  <div />
</template>

<script>
export default {
  name: 'PagesAffiliateAdminIndex',
  layout: 'default',
  middleware: ['adminNotAuthenticated'],
};
</script>
