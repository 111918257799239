<template>
  <div class="ru:container">
    <div class="ru:end-of-life">
      <div v-if="showIntro" class="grid grid--big">
        <div class="cell cell__12/12 cell__4/12--md">
          <img
            class="ru:end-of-life__cover"
            :src="cover"
            alt="End of life dashboard cover"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:end-of-life__content">
            <div class="ru:end-of-life__header">
              <Headline :level="1">
                {{ $t('pages.onboarding.endOfLife.header') }}
                <DecoratedText decoration="circle">
                  {{ $t('pages.onboarding.endOfLife.subHeader') }}
                </DecoratedText>
              </Headline>
            </div>
            <p>
              {{ $t('pages.onboarding.endOfLife.mainDescription') }}
            </p>
            <div class="ru:end-of-life__checklist">
              <ul>
                <li>
                  <Icon id="check" />
                  {{ $t('pages.onboarding.endOfLife.checklistTitle1') }}
                </li>
                <li>
                  <Icon id="check" />
                  {{ $t('pages.onboarding.endOfLife.checklistTitle2') }}
                </li>
                <li>
                  <Icon id="check" />
                  {{ $t('pages.onboarding.endOfLife.checklistTitle3') }}
                </li>
                <li>
                  <Icon id="check" />
                  {{ $t('pages.onboarding.endOfLife.checklistTitle4') }}
                </li>
              </ul>
            </div>
            <AccordionPanel>
              <template #title>{{
                $t('pages.onboarding.endOfLife.accordionTitle1')
              }}</template>
              <template #content>
                <p>
                  {{ $t('pages.onboarding.endOfLife.accordionText1') }}
                </p>
              </template>
            </AccordionPanel>
            <AccordionPanel>
              <template #title>{{
                $t('pages.onboarding.endOfLife.accordionTitle2')
              }}</template>
              <template #content>
                <p>
                  {{ $t('pages.onboarding.endOfLife.accordionText2') }}
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://safewill.com/security"
                  >
                    https://safewill.com/security
                  </a>
                </p>
              </template>
            </AccordionPanel>
            <Buttons layout="end">
              <RuButton level="primary" :href="localePath('/end-of-life')">
                {{ $t('forms.labels.getStarted') }}
              </RuButton>
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';
import DecoratedText from '@/components/atoms/DecoratedText';
import Icon from '@/components/atoms/Icon';
import AccordionPanel from '@/components/molecules/AccordionPanel';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'PagesAffiliateOnboardingEndOfLifeIndex',
  components: {
    Headline,
    DecoratedText,
    Icon,
    AccordionPanel,
    Buttons,
    RuButton,
  },
  layout: 'ruach',
  data() {
    return {
      cover: require('@/assets/img/end-of-life__cover.png'),
      showIntro: true,
    };
  },
  methods: {
    hideIntro() {
      this.showIntro = false;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &end-of-life {
    display: flex;
    flex-direction: column;

    &__cover {
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: var(--base-margin);
      color: var(--basalt);
    }

    &__header {
      margin-bottom: var(--base-margin);
    }

    &__checklist {
      margin-bottom: var(--base-margin);

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
      }

      svg {
        margin-right: var(--base-margin);
        color: --rgba(theme);
      }
    }
  }
}
</style>
