<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex';
import { removeLegacyCookie } from '@/modules/cookies';

export default {
  name: 'PagesAffiliateLogOut',
  layout: 'minimal',
  async beforeMount() {
    if (!this.$auth.loggedIn && !this.$apolloHelpers.getToken()) {
      await this.$router.push(this.localePath('/'));
      return;
    }

    removeLegacyCookie();
    await this.$apolloHelpers.onLogout();
    await this.resetUserState();
    await this.resetCheckoutState();
    await this.resetUI();
    await this.$analytics.reset();

    await this.$auth.logout();
  },
  methods: {
    ...mapActions(['resetUserState']),
    ...mapActions('ruach', ['resetUI']),
    ...mapActions('checkout', ['resetCheckoutState']),
  },
};
</script>
