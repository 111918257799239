<template>
  <div class="min-w-2xl">
    <div
      v-if="$slots.filters"
      class="flex items-end px-4 pt-6 border-b border-grey-300"
    >
      <slot name="filters" />
    </div>
    <div class="w-full overflow-y-auto">
      <table ref="table" class="w-full text-left">
        <caption v-if="description">
          {{
            description
          }}
        </caption>
        <thead ref="thead" :class="classes">
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index"
              class="max-w-2xs pt-8 pb-5 font-medium first-child:pl-8 last-child:pr-8"
            >
              <AdminDataTableSortableHeader
                v-if="sortableColumns.includes(column)"
                :column="column"
                :current-sorting-column="currentSortingColumn"
                @click="changeSortDirection"
              >
              </AdminDataTableSortableHeader>
              <span v-else>{{ column }}</span>
            </th>
          </tr>
        </thead>
        <tbody
          class="transition-opacity transition-fade-blur transition-100"
          :class="{ 'opacity-50 filter-blur': loading }"
        >
          <slot name="rows" />
          <AdminDataTableRow
            v-for="i in minRows - rowCount"
            :key="minRows - i"
            class="pointer-events-none"
          >
            <AdminDataTableCell
              v-for="(column, index) in columns"
              :key="index"
            />
          </AdminDataTableRow>
        </tbody>
      </table>
      <div
        v-if="loading"
        class="absolute inset-0 flex justify-center items-center pt-20 text-teal-200"
      >
        <LoadingDots animation="slide" />
      </div>
    </div>
    <div
      v-if="$slots.pagination"
      class="flex justify-end p-8 border-t border-grey-300"
    >
      <slot name="pagination" />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableSortableHeader from '@/components/admin/DataTableSortableHeader';
import LoadingDots from '@/components/LoadingDots';

export default {
  name: 'ComponentsAdminDataTable',
  components: {
    AdminDataTableCell,
    AdminDataTableRow,
    AdminDataTableSortableHeader,
    LoadingDots,
  },
  props: {
    currentSortingColumn: {
      type: Array,
      required: false,
      default: () => [],
    },
    sortableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    minRows: {
      type: Number,
      required: true,
    },
    rowCount: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      shadow: false,
    };
  },
  computed: {
    classes() {
      return {
        'shadow-sm': this.shadow,
      };
    },
  },
  watch: {
    loading() {
      const rect = this.$refs.table.getBoundingClientRect();

      if (rect.top < 0) {
        const scrollElement =
          window.document.scrollingElement ||
          window.document.body ||
          window.document.documentElement;
        const scrollTop =
          window.scrollY ||
          (document.documentElement.clientHeight
            ? document.documentElement.scrollTop
            : document.body.scrollTop) ||
          0;

        anime({
          targets: scrollElement,
          scrollTop: scrollTop + rect.top,
          duration: Math.max(500, Math.min(2000, -rect.top)),
          easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        });
      }
    },
  },
  methods: {
    changeSortDirection(name) {
      this.$emit('sortColumnClicked', name);
    },
  },
};
</script>
