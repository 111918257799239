<template>
  <ModuleStep :index="2" prev-step="/will/funeral">
    <AdiForm
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      :auto-submit="isComplete"
      @done="$router.push({ path: nextStep })"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.funeral.funeralType') }}
        </Headline>
        <p>
          {{ $t('pages.will.funeral.executorInstructions') }}
        </p>
        <Tip>
          {{ $t('pages.will.funeral.funeralDescription') }}
        </Tip>
        <LargeRadioButtons
          id="funeral_type"
          v-model="willMeta.funeral_type"
          layout="column"
          :options="options"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralType',
  components: {
    AdiForm,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
  },
  mixins: [user, will],
  data() {
    return {
      options: [
        {
          label: this.$t('titles.cremation'),
          value: 'cremation',
        },
        {
          label: this.$t('titles.burial'),
          value: 'burial',
        },
        {
          label: this.$t('pages.will.funeral.bodyDonation'),
          value: 'donate',
        },
        {
          label: this.$t('pages.will.funeral.executorDecision'),
          value: 'executor',
        },
      ],
    };
  },
  computed: {
    isComplete() {
      return !!this.willMeta.funeral_type;
    },
    nextStep() {
      return this.localePath(
        ['cremation', 'burial'].includes(this.willMeta.funeral_type)
          ? '/will/funeral/location'
          : '/will/funeral/other-requests'
      );
    },
  },
  mounted() {
    this.$apollo.queries.will.refetch();
  },
};
</script>
