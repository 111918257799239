var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.prevStep}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"auto-submit":!_vm.willMeta.has_pets,"submit-icon":"arrow-right"},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',{staticClass:"step-1"},[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.doYouHaveAnyPets'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPets","options":[
          {
            label: _vm.$t('forms.labels.yes'),
            value: true,
          },
          {
            label: _vm.$t('forms.labels.no'),
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_pets),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_pets", $$v)},expression:"willMeta.has_pets"}})],1),_vm._v(" "),(_vm.willMeta.has_pets)?_c('FormSection',{staticClass:"step-2"},[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.myPetsAre'))+"\n      ")]),_vm._v(" "),_vm._l((_vm.pets),function(pet,index){return _c('MetaSlot',{key:index,attrs:{"meta":pet.meta},scopedSlots:_vm._u([{key:"default",fn:function({ meta }){return [_c('Box',{attrs:{"background":"white","border":"white"}},[_c('div',{staticClass:"grid grid--flush grid--middle grid--small"},[_c('div',{staticClass:"cell cell__9/12"},[_c('Headline',{attrs:{"big":"","level":3}},[_vm._v("\n                "+_vm._s(meta.name)+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"--big flush"},[_vm._v(_vm._s(meta.type))])],1),_vm._v(" "),_c('div',{staticClass:"cell cell__3/12"},[_c('Buttons',{attrs:{"layout":"column"}},[_c('RuButton',{attrs:{"level":"secondary"},on:{"click":function($event){return _vm.removePet(pet)}}},[_vm._v("\n                  "+_vm._s(_vm.$t('forms.labels.remove'))+"\n                ")]),_vm._v(" "),_c('RuButton',{attrs:{"level":"secondary"},on:{"click":function($event){return _vm.editPet(pet)}}},[_vm._v("\n                  "+_vm._s(_vm.$t('forms.labels.edit'))+"\n                ")])],1)],1)])])]}}],null,true)})}),_vm._v(" "),_c('Buttons',{attrs:{"layout":"stretch"}},[_c('RuButton',{attrs:{"size":"large","level":"secondary"},on:{"click":_vm.addPet}},[_vm._v("\n          + "+_vm._s(_vm.$t('forms.labels.addAPet'))+"\n        ")])],1)],2):_vm._e(),_vm._v(" "),_c('PetPanel')]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }