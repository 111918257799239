var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":_vm.previous}},[(_vm.errorMessage)?_c('BaseAlert',{attrs:{"compact":"","type":"error"}},[_c('p',{staticClass:"font-normal"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_vm._v(" "),_c('AdiForm',{attrs:{"hide-submit":!_vm.isComplete,"submit-icon":"arrow-right"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisionMakers.headline'))+"\n      ")]),_vm._v(" "),(['nsw', 'qld', 'act', 'sa', 'nt'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisionMakers.youCanAppointUpTo', {
              count: _vm.attorneysAllowed[_vm.userState],
            }))+"\n        ")])]:(['vic'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.poa.medical.primaryDecisionMakers.youCanAppointOnePerson'
            ))+"\n        ")])]:(['wa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.poa.medical.primaryDecisionMakers.youCanAppointTwoPeople'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisionMakers.ifYouSelectTwoPeople'))+"\n        ")])]:(['tas'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.poa.medical.primaryDecisionMakers.youCanAppointFourPeople'
            ))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.poa.medical.primaryDecisionMakers.ifYouSelectMoreThanOnePerson'
            ))+"\n        ")])]:_vm._e(),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"people":_vm.filteredPeople,"max":_vm.attorneysAllowed[_vm.userState],"will-beneficiary-selector":false,"identifier-requirements":_vm.identifierRequirements,"type":"attorney"},model:{value:(_vm.selectedPrimaryAttorneyPersonIDs),callback:function ($$v) {_vm.selectedPrimaryAttorneyPersonIDs=$$v},expression:"selectedPrimaryAttorneyPersonIDs"}})],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }