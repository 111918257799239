<template>
  <div v-if="visible" class="my-1">
    <div class="flex flex-col">
      <div
        v-for="(beneficiary, index) in beneficiaries"
        :key="index"
        class="flex mb-4"
      >
        <label
          :for="beneficiary.id"
          class="flex flex-grow items-center min-w-0 px-6 md:px-8 rounded-tl rounded-bl bg-white text-2xl text-charcoal-200 cursor-pointer"
        >
          <div class="w-full truncate">
            {{ labelText(beneficiary) }}
          </div>
        </label>
        <div v-if="!isSplitEvenly" class="relative w-28 flex-none">
          <BaseInput
            :id="beneficiary.id"
            v-model="values[index]"
            classes="pl-2 pr-6 rounded-tl-none rounded-bl-none text-right"
            :name="beneficiary.id"
            type="number"
            rules="required|beneficiarySplit"
            :min="0.1"
            :max="beneficiaries.length > 1 ? 99.9 : 100"
            :step="0.01"
            :show-error="false"
            @input="onInput"
          />
          <div
            class="absolute top-0 right-0 bottom-0 flex justify-start items-center w-6 opacity-30 text-xl pointer-events-none"
          >
            %
          </div>
        </div>
        <div v-else class="relative w-28 flex-none">
          <BaseInput
            :id="beneficiary.id"
            v-model="values[index]"
            classes="rounded-tl-none rounded-bl-none text-center"
            :name="beneficiary.id"
            type="text"
            disabled
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isSplitEvenly"
      class="flex justify-between pl-8 pr-6 text-2xl text-charcoal-200"
      :class="{ 'text-red-300': !valid }"
    >
      <span>{{ $t('components.benediciaryInputs.total') }}</span>
      <span>
        {{ `${total}%` }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import {
  isBeneficiarySplitValid,
  calculateTotalDistribution,
} from '@/utilities';

export default {
  name: 'ComponentsBeneficiaryInputs',
  components: {
    BaseInput,
  },
  props: {
    isSplitEvenly: {
      type: Boolean,
      required: false,
    },
    beneficiaries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      values: this.beneficiaries.map((beneficiary) =>
        this.isSplitEvenly
          ? `1/${this.beneficiaries.length}`
          : beneficiary.distribution
      ),
    };
  },
  computed: {
    visible() {
      return this.beneficiaries && this.beneficiaries.length;
    },
    valid() {
      return (
        this.isSplitEvenly ||
        (this.total === 100 &&
          this.values.every((value) => isBeneficiarySplitValid(value)))
      );
    },
    total() {
      const total = calculateTotalDistribution(this.values);

      return total;
    },
  },
  watch: {
    beneficiaries(beneficiaries) {
      this.values = beneficiaries.map((beneficiary) =>
        this.isSplitEvenly
          ? `1/${this.beneficiaries.length}`
          : beneficiary.distribution
      );
      this.onInput();
    },
    isSplitEvenly(isSplitEvenly) {
      this.values = this.beneficiaries.map((beneficiary) =>
        isSplitEvenly
          ? `1/${this.beneficiaries.length}`
          : beneficiary.distribution
      );
      this.onInput();
    },
  },
  mounted() {
    this.onInput();
  },
  methods: {
    onInput() {
      this.$emit('input', this.values);
      this.$emit('valid', this.valid);
    },
    labelText(beneficiary) {
      if (beneficiary.charity) {
        return (
          beneficiary.charity.meta.displayName || beneficiary.charity.meta.name
        );
      } else if (beneficiary.person) {
        return beneficiary.person.meta.full_name;
      }

      return '';
    },
  },
};
</script>
