<template>
  <Portal to="stack">
    <div
      ref="containerContent"
      v-on-dismiss="{
        callback: close,
      }"
      class="ru:document-reader__container"
      @scroll="onScroll"
    >
      <button
        v-if="!signable"
        type="button"
        class="ru:document-reader__close"
        @click="close"
      >
        <Badge color="white" background="aluminium">
          <BaseIcon id="close" />
        </Badge>
      </button>
      <div class="ru:document-reader__content">
        <div v-if="images.length">
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            class="ru:document-reader__image"
            alt=""
          />
        </div>
        <div v-else class="ru:document-reader__loading">
          {{ $t('components.documentReader.loading') }}
        </div>
      </div>
      <Box v-if="signable || $slots['bottom']" background="lilac" tight square>
        <div v-if="signable">
          <p :class="readContentClass">
            {{ readContentText }}
          </p>
          <div class="grid grid--between grid--bottom grid--flush">
            <div class="cell cell__8/12 cell__6/12--lg">
              <signature-pad
                :disabled="!readContent"
                @signature-changed="signDocument"
              />
            </div>
            <div class="cell cell__4/12 cell__3/12--lg text-right">
              <RuButton
                v-if="!isSigned"
                aria-label="Cancel"
                button-type="button"
                background="iron"
                border="silver"
                color="gravel"
                size="small"
                @click="close"
              >
                <span>{{ $t('components.documentReader.cancel') }}</span>
              </RuButton>
              <RuButton
                v-else
                aria-label="Next"
                button-type="button"
                background="wine"
                color="white"
                @click="close"
              >
                <span>{{ $t('components.documentReader.next') }}</span>
              </RuButton>
            </div>
          </div>
        </div>
        <div v-if="$slots['bottom']">
          <slot name="bottom" />
        </div>
      </Box>
    </div>
  </Portal>
</template>

<script>
import VDismiss from 'vue-dismiss';
import { Portal } from 'portal-vue';

import { debounce } from 'throttle-debounce';
import BaseIcon from '@/components/BaseIcon';
import Badge from '@/components/atoms/Badge';
import Box from '@/components/atoms/Box';
import RuButton from '@/components/atoms/Button';
import SignaturePad from '@/components/atoms/SignaturePad';

export default {
  name: 'ComponentsOrganismsDocumentReader',
  components: {
    BaseIcon,
    Badge,
    RuButton,
    Box,
    Portal,
    SignaturePad,
  },
  mixins: [VDismiss],
  props: {
    name: {
      required: true,
      type: String,
    },
    signable: {
      default: null,
      type: Boolean,
    },
    images: {
      required: true,
      type: Array,
    },
    maxAttempts: {
      default: 30,
      type: Number,
    },
  },
  data() {
    return {
      readContent: false,
      signature: null,
    };
  },
  computed: {
    isSigned() {
      return !!this.signature;
    },
    readContentText() {
      if (this.readContent) {
        return this.$t('components.documentReader.signOnTheBox');
      }
      return this.$t('components.documentReader.mustReadEntireDocument');
    },
    readContentClass() {
      if (this.readContent) {
        return 'text-success';
      }
      return 'text-warning';
    },
  },
  methods: {
    signDocument(value) {
      this.signature = value;
      this.$emit('input', value);
    },
    close() {
      this.$emit('close');
    },
    onScroll: debounce(100, function () {
      if (!this.readContent) {
        const scrollTop = this.$refs.containerContent.scrollTop;
        const clientHeight = this.$refs.containerContent.clientHeight;
        const scrollHeight = this.$refs.containerContent.scrollHeight;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          this.readContent = true;
        }
      }
    }),
  },
};
</script>
<style lang="scss">
#{$ru} {
  &--document-reader-open {
    overflow: hidden;
  }

  &document-reader {
    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1;
      border-radius: 100%;
      border: 1px solid --rgba(white);
      box-shadow: 0 0 10px --rgba(black, 0.25);
    }

    &__container {
      display: flex;
      flex-direction: column;
      text-align: left;
      background: #fff;
      box-shadow: var(--base-box-shadow);
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      position: fixed;
      bottom: 0;
      height: #{calc(100vh - var(--app-height) - var(--base-margin) * 2)};
      left: 50%;
      transform: translateX(-50%);
      width: min(
        #{calc(100% - var(--gutter-width))},
        var(--container-max-width)
      );
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 1;
      transition: transform calc(var(--transition-duration-n) * 2ms)
        var(--transition-timing-function);
    }

    &__content {
      padding: var(--cell-spacing);
      margin-bottom: auto;

      & + #{$ru}box {
        position: sticky;
        bottom: 0;

        p {
          margin-bottom: var(--base-margin-small);
        }
      }
    }

    &__image {
      margin-bottom: var(--base-margin-small);
      border-radius: var(--border-radius-small);
    }

    &__loading {
      text-align: center;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: 50% #{calc(50% - 2.5rem)};
      padding: 2.5rem 1rem 1rem;
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100"><circle cx="50" cy="50" r="35" fill="none" stroke="#fff" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="20"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle><circle cx="50" cy="50" r="35" fill="none" stroke="#00DABF" stroke-dasharray="164.93361431346415 56.97787143782138" stroke-width="10"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg>'
      );

      &--failed {
        background: unset;
      }
    }
  }
}
</style>
