<template>
  <div class="w-full min-h-screen overflow-hidden">
    <div class="container gutters py-8 md:py-20 mx-auto">
      <nuxt />
    </div>
  </div>
</template>

<script>
import tracking from '@/mixins/tracking';

export default {
  name: 'DefaultLayout',
  components: {},
  mixins: [tracking],
};
</script>
