<template>
  <div class="ru:document-cover">
    <div
      :style="styles"
      class="ru:document-cover__image"
      @click="$emit('click')"
    >
      <img :src="cover" alt="" />
      <div class="ru:document-cover__overlay">
        <div v-if="overlay" class="ru:document-cover__overlay-text">
          {{ overlay }}
        </div>
        <div class="ru:document-cover__overlay-tick">
          <BaseIcon id="tick" />
        </div>
      </div>
    </div>
    <div v-if="title" class="ru:document-cover__title">{{ title }}</div>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon';

export default {
  name: 'ComponentsAtomsDocumentCover',
  components: {
    BaseIcon,
  },
  props: {
    signed: {
      default: false,
      type: Boolean,
    },
    cover: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    overlay: {
      default: null,
      type: String,
    },
  },
  computed: {
    styles() {
      return {
        '--is-signed': this.signed ? 1 : 0,
      };
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &document-cover {
    &__image {
      margin-bottom: var(--base-margin-small);
      position: relative;
      border-radius: 0.5rem;
      overflow: hidden;
      box-shadow: 0 4px 10px --rgba(black, 0.15);
      cursor: pointer;
      transform: scale(0.975);
      transition: transform calc(var(--transition-duration-n) * 0.5ms)
        var(--transition-timing-function);

      &:hover {
        transform: scale(1);
        box-shadow: 0 4px 10px --rgba(black, 0.3);
      }

      img {
        filter: #{'grayscale(calc(var(--is-signed) / 4 * 3))'};
      }
    }

    &__overlay {
      position: absolute;
      content: '';
      inset: 0;
      background: linear-gradient(
        -225deg,
        --rgba(azure, #{calc(var(--is-signed) * 0.2)}),
        --rgba(white, #{calc(var(--is-signed) * 0.6)}),
        --rgba(white, #{calc(var(--is-signed) * 0.4)}),
        --rgba(azure, #{calc(var(--is-signed) * 0.4)})
      );
      transition: opacity calc(var(--transition-duration-n) * 1ms)
        var(--transition-timing-function);

      &-text {
        background: --rgba(white, 0.9);
        color: --rgba(orange);
        box-shadow: 0 0 10px --rgba(black, 0.3);
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding: 1rem;
      }

      &-tick {
        opacity: var(--is-signed);
        background: #fff;
        padding: 0.5rem;
        border-radius: 50%;
        color: --rgba(jade);
        transform: scale(1.25);
        transform-origin: bottom right;
        box-shadow: 0 4px 10px --rgba(black, 0.3);
        position: absolute;
        right: 1rem;
        bottom: 1rem;
      }
    }

    &__title {
      margin-bottom: var(--base-margin);
    }
  }
}
</style>
