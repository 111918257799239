<template>
  <div class="ru:checkout-summary">
    <Headline :level="2">{{ $t('components.checkoutSummary.title') }}</Headline>
    <div v-if="useQuantityLayout" class="grid ru:checkout-summary__columns">
      <div class="cell cell__2/12">
        {{ $t('components.checkoutSummary.quantity') }}
      </div>
      <div class="cell cell__5/12">
        {{ $t('components.checkoutSummary.item') }}
      </div>
      <div class="cell cell__2/12 ru:checkout-summary__pricing">
        {{ $t('components.checkoutSummary.unitPrice') }}
      </div>
      <div class="cell cell__3/12 ru:checkout-summary__pricing">
        {{ $t('components.checkoutSummary.total') }}
      </div>
    </div>
    <div
      v-for="(item, i) in collatedItems"
      :key="`line-item-${i}`"
      class="grid grid--gutterless ru:checkout-summary__line-item"
    >
      <template v-if="itemIsFirstYearSubscription(item)">
        <div class="cell cell__7/12 d-flex">
          {{ $t('components.checkoutSummary.1stYearSubscription') }}
          <Tooltip
            :tooltip="$t('components.checkoutSummary.offerUnlimitedUpdates')"
          >
            <Icon id="question" size="small" />
          </Tooltip>
        </div>
        <div class="cell cell__5/12 ru:checkout-summary__pricing">
          <span class="--strike">
            {{ `$${PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE}` }}
          </span>
          <span class="--dark">
            {{ formatPrice(item.finalPrice) }}
          </span>
        </div>
      </template>
      <template v-else>
        <div v-if="useQuantityLayout" class="cell cell__2/12">
          {{ item.count }}
        </div>
        <div
          class="cell"
          :class="useQuantityLayout ? 'cell__5/12' : 'cell__7/12'"
        >
          {{ productToDisplayNameMapping[item.product] || item.product }}
        </div>
        <div
          v-if="useQuantityLayout"
          class="cell cell__2/12 ru:checkout-summary__pricing"
        >
          <span
            :class="{
              '--strike': discountHasBeenAppliedToProduct(
                item.basePrice,
                item.finalPrice
              ),
            }"
          >
            {{ formatPrice(item.basePrice) }}
          </span>
        </div>
        <div
          class="cell cell__3/12 ru:checkout-summary__pricing"
          :class="useQuantityLayout ? 'cell__3/12' : 'cell__5/12'"
        >
          <span class="--dark">
            {{ formatPrice(item.finalPrice * item.count) }}
          </span>
        </div>
      </template>
      <template
        v-if="discountHasBeenAppliedToProduct(item.basePrice, item.finalPrice)"
      >
        <div v-if="useQuantityLayout" class="cell cell__2/12" />
        <div
          class="cell ru:checkout-summary__discount-details"
          :class="useQuantityLayout ? 'cell__5/12' : 'cell__7/12'"
        >
          <Buttons layout="inline">
            <RuButton level="plain" @click="removeCoupon">
              <BaseIcon id="cross" size="tiny" />
            </RuButton>
          </Buttons>
          <strong>{{ discountData.code }}</strong>
          {{ $t('components.checkoutSummary.codeApplied') }}
        </div>
        <div class="cell cell__5/12 ru:checkout-summary__pricing">
          <span class="--dark">
            {{ discountDataText }}
          </span>
        </div>
      </template>
    </div>
    <div
      v-if="!isUpdateCardDetails"
      class="grid grid--gutterless ru:checkout-summary__line-item"
    >
      <div class="cell cell__8/12 --dark">
        {{ $t('components.checkoutSummary.total') }}
      </div>
      <div class="cell cell__4/12 ru:checkout-summary__pricing">
        AUD
        <span
          v-if="
            discountHasBeenAppliedToProduct(totalBasePrice, totalFinalPrice)
          "
          class="--strike"
        >
          {{ formatPrice(totalBasePrice) }}
        </span>
        <span class="--dark">
          {{ formatPrice(totalFinalPrice) }}
        </span>
      </div>
    </div>
    <div v-if="isFree">
      <Alert theme="info" level="medium">
        {{ $t('components.checkoutSummary.validCreditCardRequired') }}
      </Alert>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';
import Alert from '@/components/molecules/Alert';
import Tooltip from '@/components/atoms/Tooltip';
import Icon from '@/components/atoms/Icon';

import { PRICE_DEFAULTS } from '@/store/checkout';

import { productToDisplayNameMapping } from '@/utilities';

export default {
  name: 'ComponentsOrganismsCheckoutSummary',
  components: {
    BaseIcon,
    Buttons,
    RuButton,
    Headline,
    Alert,
    Tooltip,
    Icon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    discountData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      productToDisplayNameMapping,
      PRICE_DEFAULTS,
    };
  },
  computed: {
    isFree() {
      return this.items.length && this.totalFinalPrice === 0;
    },
    totalBasePrice() {
      return this.items.reduce((total, current) => {
        return total + current.basePrice;
      }, 0);
    },
    totalFinalPrice() {
      return this.items.reduce((total, current) => {
        return total + current.finalPrice;
      }, 0);
    },
    isUpdateCardDetails() {
      // Bad code, refactor this later
      return this.items.some(
        (item) => item.product === 'UPDATE_CREDIT_CARD_DETAILS'
      );
    },
    discountDataText() {
      if (this.discountData.type === 'FIXED') {
        return `-$${this.discountData.factor}`;
      }
      return `-${this.discountData.factor}%`;
    },
    useQuantityLayout() {
      return this.collatedItems.some((item) => item.count > 1);
    },
    collatedItems() {
      const products = [];
      const collatedItems = [];
      this.items.forEach((item) => {
        const product = item.product;
        const productIndex = products.indexOf(product);
        if (productIndex > -1) {
          item.count = collatedItems[productIndex].count + 1;
        } else {
          products.push(product);
          item.count = 1;
          collatedItems.push(item);
        }
      });
      return collatedItems;
    },
  },
  methods: {
    itemIsFirstYearSubscription(item) {
      return (
        item.product === 'SUBSCRIPTION' &&
        item.finalPrice < PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE
      );
    },
    removeCoupon() {
      this.$emit('removeCoupon', null);
    },
    discountHasBeenAppliedToProduct(basePrice, finalPrice) {
      return this.discountData && basePrice > finalPrice;
    },
    formatPrice(price) {
      const dollars = price / 100;
      return Number.isInteger(dollars)
        ? dollars.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: dollars === Math.round(dollars) ? 0 : 2,
          })
        : '-';
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &checkout-summary {
    margin-bottom: var(--base-margin);

    &__pricing {
      text-align: right;
    }

    &__columns,
    &__line-item {
      color: --rgba(gravel);
      border-bottom: 2px solid --rgba(concrete);

      &,
      .cell {
        margin-bottom: var(--base-margin-small);
        align-items: center;
      }
    }

    &__discount-details {
      text-transform: uppercase;
    }

    #{$ru}icon {
      margin-left: 0.5rem;
    }

    &__columns,
    #{$mf}dark {
      color: --rgba(basalt);
    }

    #{$mf}strike {
      text-decoration: line-through;
    }
  }
}
</style>
