<template>
  <div class="ru:sent-gifts">
    <Box background="white">
      <p v-if="!paidInvites" class="flex items-center flush">
        <LoadingRing :size="24" class="mr-4" />
        {{ $t('components.sentGifts.loading') }}
      </p>
      <template v-else-if="paidInvites.length">
        <Headline :level="2">{{
          $t('components.sentGifts.giftsSent')
        }}</Headline>
        <div
          v-for="invite in paidInvites"
          :key="invite.id"
          class="ru:sent-gifts__gift"
        >
          <SentInvite
            :invite="invite"
            :products-to-show="['WILL', 'POA', 'LEGAL']"
          />
        </div>
      </template>
      <div v-else class="ru:send-gifts__sales">
        <Icon id="gift" size="huge" />
        <Headline :level="3">{{
          $t('components.sentGifts.youHaveNotSentAnyGiftsYet')
        }}</Headline>
        <p>
          {{ $t('components.sentGifts.goToThe') }}
          <strong>{{ $t('components.sentGifts.sendGifts') }}</strong>
          {{ $t('components.sentGifts.tabAndsendThemNow') }}
        </p>
        <Buttons layout="stretch">
          <RuButton level="primary" :href="localePath('/checkout/gifts')">
            {{ $t('components.sentGifts.buyGifts') }}
            <Icon id="arrow-right" />
          </RuButton>
        </Buttons>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/atoms/Box';
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import SentInvite from '@/components/organisms/SentInvite';

import { will, invites } from '@/mixins/apollo';

export default {
  name: 'ComponentsOrganismsSentGifts',
  components: {
    Box,
    Headline,
    Icon,
    Buttons,
    RuButton,
    SentInvite,
  },
  mixins: [will, invites],
  computed: {
    paidInvites() {
      return this.invites.filter((invite) => invite.type === 'PAID').reverse();
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &sent-gifts {
    > #{$ru}box > #{$ru}icon {
      margin: 0 auto;
    }

    &__gift {
      margin-bottom: var(--base-margin);

      &:not(:last-of-type) {
        border-bottom: 2px solid --rgba(iron);
      }

      &:is(:first-of-type) {
        border-top: 2px solid --rgba(iron);
        padding-top: var(--base-margin);
      }
    }

    &__sales {
      text-align: center;
    }
  }
}
</style>
