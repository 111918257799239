<template>
  <div class="ru:container ru:skyflow">
    <div class="grid grid--big">
      <div class="cell cell__12/12 cell__8/12--md">
        <p>
          Create an entry in the app, create an entry in the vault, update the
          entry in the app
        </p>
        <FormControl
          id="formDataCategoryId"
          v-model="formData.categoryId"
          :required="false"
          type="text"
          text="App category ID"
        />
        <FormControl
          id="formDataName"
          v-model="formData.appData.name"
          :required="false"
          type="text"
          text="appData Name"
        />
        <FormControl
          id="formDataNotes"
          v-model="formData.appData.notes"
          :required="false"
          type="text"
          text="appData Notes"
        />
        <FormControl
          id="formDataAccount"
          v-model="formData.privacyVaultData.account"
          :required="false"
          type="text"
          text="privacyVaultData Account"
        />
        <FormControl
          id="formDataSecret"
          v-model="formData.privacyVaultData.secret"
          :required="false"
          type="text"
          text="privacyVaultData Secret"
        />
        <FormControl
          id="formDataItemId"
          v-model="formData.itemId"
          :required="false"
          type="text"
          text="App item ID"
        />
        <FormControl
          id="formDataPrivacyVaultId"
          v-model="formData.privacyVaultId"
          :required="false"
          type="text"
          text="Privacy vault item ID"
        />
        <FormControl
          id="formDataAccount"
          v-model="formData.tokens.account"
          :required="false"
          type="text"
          text="Account token"
        />
        <FormControl
          id="formDataSecret"
          v-model="formData.tokens.secret"
          :required="false"
          type="text"
          text="Secret token"
        />
        <Buttons layout="start">
          <RuButton level="primary" @click="upsertEndOfLifeItem">
            App ⇢ Vault ⇢ App
          </RuButton>
        </Buttons>

        <template v-if="output">
          <p>App output:</p>
          <pre>{{ output }}</pre>
          <hr />
        </template>
        <hr />
        <p>
          Privacy vault output:<br />
          (this won't work until we save
          <strong>privacy_vault_target_id</strong> &
          <strong>privacy_vault_target_collection</strong> on our side)
        </p>
        <FormControl
          id="appTargetCollection"
          v-model="appTarget.targetCollection"
          :required="false"
          type="text"
          placeholder="appTargetCollection"
        />
        <FormControl
          id="appTargetId"
          v-model="appTarget.targetId"
          :required="false"
          type="text"
          placeholder="appTargetId"
        />
        <RadioOptions
          id="redaction_type"
          layout="start"
          :value="redactionType"
          :options="redactionTypes"
          @change="redactionTypeSelected"
        />
        <Buttons layout="start">
          <RuButton level="primary" @click="getPrivacyVaultRecords">
            Get privacy vault records
          </RuButton>
        </Buttons>
        <LoadingRing v-if="loading" :size="24" class="mt-4" />
        <template v-else-if="privacyVaultRecords.length">
          <p>Privacy vault records:</p>
          <pre v-for="(record, i) in privacyVaultRecords" :key="`record${i}`">
            {{ record }}
          </pre>
        </template>
      </div>
      <div class="cell cell__12/12 cell__4/12--md">
        <p>User ID:</p>
        <pre>{{ userId }}</pre>
        <p>Auth0 token:</p>
        <pre>{{ auth0Token }}</pre>
        <p>Skyflow ID:</p>
        <pre>{{ SKYFLOW_ID }}</pre>
        <p>Skyflow URL:</p>
        <pre>{{ SKYFLOW_URL }}</pre>

        <hr />

        <Buttons layout="start">
          <RuButton level="primary" @click="getAccessToken">
            Get a privacy vault access token
          </RuButton>
        </Buttons>

        <p>Privacy vault access token:</p>
        <pre>{{ privacyVaultAccessToken || '...' }}</pre>

        <hr />

        <Buttons layout="start">
          <RuButton level="primary" @click="getTarget">
            Get target ID & collection
          </RuButton>
        </Buttons>

        <p>Privacy vault target:</p>
        <pre>{{ privacyVaultTarget.targetId || '...' }}</pre>
        <pre>{{ privacyVaultTarget.targetCollection || '...' }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from 'js-cookie';
import { mapGetters } from 'vuex';

import CREATE_END_OF_LIFE_ITEM from '@/graphql/mutations/CreateEndOfLifeItem';
import UPDATE_END_OF_LIFE_ITEM from '@/graphql/mutations/UpdateEndOfLifeItem';
import UPDATE_END_OF_LIFE_ITEM_WITH_PRIVACY_VAULT_CONFIG from '@/graphql/mutations/UpdateEndOfLifeItemWithPrivacyVaultConfig';

import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import FormControl from '@/components/molecules/FormControl';
import RadioOptions from '@/components/molecules/RadioOptions';
import LoadingRing from '@/components/LoadingRing';

export default {
  name: 'PagesAffiliateSkyflow',
  components: {
    Buttons,
    RuButton,
    FormControl,
    RadioOptions,
    LoadingRing,
  },
  layout: 'ruach',
  middleware: ['authenticated'],
  data() {
    return {
      loading: false,
      privacyVaultAccessToken: null,
      formData: {
        categoryId: '10f34b42-dcd2-4fee-8649-074d1fdf4527',
        itemId: null,
        privacyVaultId: null,
        appData: {
          name: 'Name that goes into app',
          notes: 'Notes that goes into app',
        },
        privacyVaultData: {
          account: 'Account that goes into privacy vault',
          secret: 'Secret that goes into privacy vault',
        },
        tokens: {
          account: null,
          secret: null,
        },
      },
      appTarget: {
        targetId: null,
        targetCollection: 'END_OF_LIFE_ITEMS',
      },
      privacyVaultTarget: {
        targetId: null,
        targetCollection: null,
      },
      redactionType: 'DEFAULT',
      redactionTypes: [
        {
          label: 'DEFAULT',
          value: 'DEFAULT',
          level: 'secondary',
          theme: 'light',
        },
        {
          label: 'REDACTED',
          value: 'REDACTED',
          level: 'secondary',
          theme: 'light',
        },
        {
          label: 'MASKED',
          value: 'MASKED',
          level: 'secondary',
          theme: 'light',
        },
        {
          label: 'PLAIN_TEXT',
          value: 'PLAIN_TEXT',
          level: 'secondary',
          theme: 'light',
        },
      ],
      privacyVaultRecords: [],
      output: null,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    SKYFLOW_ID() {
      return process.env.SKYFLOW_ID;
    },
    SKYFLOW_URL() {
      return process.env.SKYFLOW_URL;
    },
    auth0Token() {
      return cookies.get('auth._token.auth0').split(' ')[1];
    },
  },
  methods: {
    redactionTypeSelected(value) {
      this.redactionType = value;
    },

    updateEndOfLifeItem: async function (endOfLifeItem) {
      if (
        endOfLifeItem.privacyVaultTargetId &&
        endOfLifeItem.privacyVaultCollection
      ) {
        return await this.$apollo.mutate({
          mutation: UPDATE_END_OF_LIFE_ITEM_WITH_PRIVACY_VAULT_CONFIG,
          variables: endOfLifeItem,
        });
      } else {
        return await this.$apollo.mutate({
          mutation: UPDATE_END_OF_LIFE_ITEM,
          variables: endOfLifeItem,
        });
      }
    },

    /** How this works:
     * use endpoint to create the end of life item, no secret stuff sent
     * insert secrets into skyflow
     * add tokens from skyflow into EoL item
     * Send EoL item with tokens, and targetId/Collection with special UPDATE_END_OF_LIFE_ITEM_WITH_PRIVACY_VAULT_CONFIG mutation
     * @param itemData - should not contain any secrets here.
     * @param secrets - secrets to be tokenised should be put here as a map
     */
    async createEndOfLifeItemWithSecrets({
      userId,
      itemData,
      categoryId,
      secrets,
    }) {
      const createItemArgs = {
        userId,
        data: itemData,
        categoryId,
      };
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_END_OF_LIFE_ITEM,
        variables: createItemArgs,
      });
      const itemId = data.createEndOfLifeItem.endOfLifeItem.id;

      const privacyVaultRecord = await this.$privacyVaultService.save({
        privacyVaultTargetCollection: this.appTarget.targetCollection,
        ownerId: userId,
        resourceTargetId: itemId,
        record: secrets,
      });

      await this.updateEndOfLifeItem({
        ...createItemArgs,
        data: {
          ...itemData,
          ...privacyVaultRecord.tokens,
        },
        id: itemId,
        privacyVaultTargetId: privacyVaultRecord.privacyVaultTarget.targetId,
        privacyVaultCollection: this.appTarget.targetCollection,
      });

      return {
        itemId,
        privacyVaultTargetId: privacyVaultRecord.privacyVaultTarget.targetId,
        tokens: privacyVaultRecord.tokens,
      };
    },

    /** How this works:
     * if any dirty secrets, upsert them in skyflow
     * merge new secret tokens into existing secrets
     * add targetId and collection into EoL item if necessary
     * Update EoL item with any PII data tokenised
     */
    async updateEndOfLifeItemWithSecrets({ userId, item, dirtySecrets = {} }) {
      if (Object.keys(dirtySecrets).length) {
        const privacyVaultRecord = await this.$privacyVaultService.save({
          privacyVaultTargetCollection: this.appTarget.targetCollection,
          ownerId: userId,
          resourceTargetId: item.id,
          record: dirtySecrets,
        });
        Object.assign(item.data, privacyVaultRecord.tokens);
        const privacyVaultTargetId =
          privacyVaultRecord.privacyVaultTarget.targetId;
        const existingPrivacyVaultTarget =
          await this.$privacyVaultService.getPrivacyVaultTargetByResourceTarget(
            item.id,
            this.appTarget.targetCollection
          );
        if (
          existingPrivacyVaultTarget === null ||
          !existingPrivacyVaultTarget.targetId
        ) {
          // no existing privacy vault ID - must be updated with it
          await this.updateEndOfLifeItem({
            ...item,
            privacyVaultTargetId,
            privacyVaultCollection: this.appTarget.targetCollection,
          });
          return;
        }
      }
      // secrets have been updated in skyflow, and
      // item has a privacy vault id already set
      await this.updateEndOfLifeItem(item);
    },
    async upsertEndOfLifeItem() {
      const isUpdate = this.formData.itemId;
      if (!isUpdate) {
        const { itemId, privacyVaultTargetId, tokens } =
          await this.createEndOfLifeItemWithSecrets({
            userId: this.userId,
            itemData: this.formData.appData,
            categoryId: this.formData.categoryId,
            secrets: this.formData.privacyVaultData,
          });
        this.formData.itemId = itemId;
        this.formData.privacyVaultId = privacyVaultTargetId;
        this.formData.tokens.account = tokens.account;
        this.formData.tokens.secret = tokens.secret;
      } else {
        await this.updateEndOfLifeItemWithSecrets({
          userId: this.userId,
          item: {
            id: this.formData.itemId,
            data: {
              ...this.formData.appData,
              ...this.formData.privacyVaultData,
            },
            userId: this.userId,
            categoryId: this.formData.categoryId,
          },
          dirtySecrets: {},
        });
      }
    },
    async getAccessToken() {
      this.privacyVaultAccessToken =
        await this.$privacyVaultService.getAccessToken();
    },
    async getTarget() {
      this.privacyVaultTarget = await this.$privacyVaultService.getTarget(
        this.appTarget
      );
    },
    async getPrivacyVaultRecords() {
      this.loading = true;
      this.privacyVaultRecords = await this.$privacyVaultService.getRecords({
        ...(await this.$privacyVaultService.getTarget(this.appTarget)),
        redactionType: this.redactionType,
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &skyflow {
    --base-margin: 0.25rem;

    .ru\:radio-options {
      margin-bottom: 0.5rem;
    }

    .ru\:form__control {
      margin-bottom: var(--base-margin);
    }

    .ru\:form__label {
      margin-left: 0.5rem;
      font-size: 0.875rem;
    }

    input,
    p,
    pre {
      font-size: 0.875rem;
      margin-bottom: 0.25rem !important;
      padding: 0.25rem 0.5rem !important;
      border-radius: 0.375rem;
    }

    input {
      border-color: --rgba(dust);
      border-width: 1px;
    }

    hr {
      margin: 1rem 0 !important;
    }

    pre {
      background: --rgba(lighter);
      overflow: hidden;
    }
  }
}
</style>
