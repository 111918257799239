<template>
  <div>
    <FormControl
      v-bind="$attrs"
      :value="modelValue"
      :type="type"
      :disabled="disabledInput"
      :options="optionsWithUnknown"
      @input="intercept"
    />
    <div
      v-if="unknownIsAllowed"
      class="ru:form__control ru:form__control--inline"
    >
      <FormControl
        :id="`${identifier}IsUnknown`"
        type="checkbox"
        format="boolean"
        :value="unknownIsChecked"
        :required="false"
        :text="$t('components.formControlWithUnknown.unknown')"
        @input="toggleUnknown"
      />
    </div>
  </div>
</template>

<script>
import {
  UNKNOWN_DATE,
  UNKNOWN_NUMBER,
  UNKNOWN_TEXT,
} from '@/utilities/cremations/form-constants';

import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'ComponentsMoleculesFormControlWithUnknown',
  components: { FormControl },
  props: {
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    optionsWithUnknown() {
      if (this.options) {
        if (typeof this.options[0] === 'string') {
          return [...this.options, UNKNOWN_TEXT];
        }
        return [
          ...this.options,
          {
            value: UNKNOWN_TEXT,
            text: UNKNOWN_TEXT.charAt(0) + UNKNOWN_TEXT.slice(1).toLowerCase(),
          },
        ];
      }
      return null;
    },
    disabledInput() {
      return this.disabled || this.unknownIsChecked;
    },
    unknownIsChecked() {
      const dateIsUnknown = this.type === 'date' && this.value === UNKNOWN_DATE;
      const numberIsUnknown =
        this.type === 'number' && this.value === UNKNOWN_NUMBER;
      const textIsUnknown = this.value === UNKNOWN_TEXT;

      return dateIsUnknown || numberIsUnknown || textIsUnknown;
    },
    unknownIsAllowed() {
      return !['select', 'radio', 'checkbox'].includes(this.type);
    },
  },
  created() {
    this.identifier = this.id + '__' + Math.random().toString(36).slice(2);
    if (this.unknownIsChecked && this.unknownIsAllowed) {
      this.modelValue = null;
    }
  },
  methods: {
    toggleUnknown(checked) {
      if (checked) {
        switch (this.type) {
          case 'number':
            this.modelValue = UNKNOWN_NUMBER;
            break;
          case 'date':
            this.modelValue = UNKNOWN_DATE;
            break;
          default:
            this.modelValue = UNKNOWN_TEXT;
            break;
        }
      }
      this.$emit('input', this.modelValue);
      this.modelValue = null;
    },
    intercept(value) {
      this.$emit('input', value);
    },
  },
};
</script>
