<template>
  <Alert layout="center" level="medium" theme="warning">
    {{ $t('components.offlineAlert.message') }}
  </Alert>
</template>

<script>
import Alert from '@/components/molecules/Alert';

export default {
  name: 'ComponentsSnowflakesOfflineAlert',
  components: {
    Alert,
  },
};
</script>
