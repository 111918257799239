<template>
  <div class="ru:link-invite">
    <div class="grid">
      <div class="cell cell__12/12 cell__9/12--md">
        <FormControl
          id="referralLink"
          :type="'text'"
          :value="link"
          :readonly="true"
        />
      </div>
      <div class="cell cell__12/12 cell__3/12--md">
        <Buttons layout="stretch">
          <RuButton level="secondary" @click="copyLink">
            <Icon id="copy" />
            {{ $t('components.inviteLink.copy') }}
          </RuButton>
        </Buttons>
      </div>
    </div>
    <div class="grid">
      <div class="cell cell__12/12 cell__6/12--md">
        <Buttons theme="facebook" layout="stretch">
          <RuButton level="primary" @click="shareFacebook">
            <Icon id="facebook" />
            {{ $t('components.inviteLink.shareOnFacebook') }}
          </RuButton>
        </Buttons>
      </div>
      <div class="cell cell__12/12 cell__6/12--md">
        <Buttons theme="whatsapp" layout="stretch">
          <RuButton level="primary" @click="shareWhatsApp">
            <Icon id="whatsapp" />
            {{ $t('components.inviteLink.shareOnWhatsApp') }}
          </RuButton>
        </Buttons>
      </div>
    </div>
    <Snackbar
      v-if="snackbarIsVisible"
      type="success"
      :text="$t('components.inviteLink.copied')"
      @closeSnackbar="closeSnackbar"
    />
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';

import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import FormControl from '@/components/molecules/FormControl';
import Snackbar from '@/components/molecules/Snackbar';

export default {
  name: 'ComponentsOrganismsInviteLink',
  components: {
    Icon,
    Buttons,
    RuButton,
    FormControl,
    Snackbar,
  },
  props: {
    hidden: {
      default: false,
      type: Boolean,
    },
    link: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      copied: false,
      disabled: true,
      snackbarIsVisible: false,
    };
  },
  methods: {
    closeSnackbar() {
      this.snackbarIsVisible = false;
    },
    copyLink() {
      this.sendTracking('link');
      copy(this.link);
      this.snackbarIsVisible = true;
    },
    popup(url, title, w, h) {
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
      return window.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
    },
    sendTracking(medium) {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'invite_link_shared',
        props: {
          medium,
        },
      });
    },
    shareWhatsApp() {
      this.sendTracking('whatsapp');
      this.popup(
        `https://api.whatsapp.com/send?text=${this.link.toLowerCase()}`,
        this.$t('components.inviteLink.shareOnWhatsApp'),
        600,
        600
      );
    },
    shareFacebook() {
      this.sendTracking('facebook');
      this.popup(
        `https://www.facebook.com/sharer/sharer.php?u=${this.link.toLowerCase()}`,
        this.$tt('components.inviteLink.shareOnFacebook'),
        600,
        600
      );
    },
  },
};
</script>

<style lang="scss">
#{$mf} {
  &facebook {
    --secondary: 24, 119, 242;
    --primary: 66, 103, 178;
  }

  &whatsapp {
    --secondary: 89, 206, 114;
    --primary: 33, 92, 84;
  }
}
</style>
