<template>
  <form class="ru:form" @submit.prevent="$emit('submit')">
    <slot />
  </form>
</template>

<script>
export default {
  name: 'ComponentsOrganismsForm',
};
</script>

<style lang="scss">
#{$ru} {
  &form {
    margin-bottom: var(--base-margin);

    & > &__control {
      margin-bottom: var(--base-margin);
    }

    & > &__control--inline + &__control--inline {
      margin-top: #{calc(var(--base-margin-small) * -1)};
    }
  }
}
</style>
