<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      submit-icon="arrow-right"
      @submit="$router.push({ path: localePath(`/will/executors/options`) })"
    >
      <FormSection>
        <Headline :level="1">
          {{ $upperFirst($t('texts.words.executors')) }}
        </Headline>
        <Tip>
          <i18n path="pages.will.executors.index.carryingOutYourWishes">
            <GlossaryTooltip term="estateAdministration">
              {{ $t('texts.sentences.estateAdministration') }}
            </GlossaryTooltip>
          </i18n>
        </Tip>
        <i18n
          path="pages.will.executors.index.instruction"
          tag="p"
          class="mb-5"
        >
          <GlossaryTooltip term="executor">
            {{ $t('texts.words.executor') }}
          </GlossaryTooltip>
        </i18n>
        <ul>
          <li>{{ $t('pages.will.executors.index.assetsDebts') }}</li>
          <li>{{ $t('pages.will.executors.index.distribute') }}</li>
          <li>{{ $t('pages.will.executors.index.accounting') }}</li>
          <li>{{ $t('pages.will.executors.index.paperwork') }}</li>
          <li>{{ $t('pages.will.executors.index.informed') }}</li>
        </ul>
      </FormSection>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import GlossaryPanel from '@/components/GlossaryPanel';

export default {
  name: 'PagesAffiliateWillExecutorsIndex',
  components: {
    AdiForm,
    FormSection,
    GlossaryTooltip,
    ModuleStep,
    Tip,
    Headline,
    GlossaryPanel,
  },
};
</script>
