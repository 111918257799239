<template>
  <div>
    <nuxt :nuxt-child-key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: 'PagesAffiliateWillEstate',
  layout: 'ruach',
  middleware: ['authenticated'],
};
</script>
