<template>
  <div class="ru:tip">
    <Chip background="theme">{{ $t('components.tip.title') }}</Chip>
    <slot />
  </div>
</template>

<script>
import Chip from '@/components/atoms/Chip';

export default {
  name: 'ComponentsMoleculesTip',
  components: {
    Chip,
  },
};
</script>

<style lang="scss">
#{$ru} {
  &tip {
    border-left: 3px solid --rgba(iron);
    padding: 0 var(--base-margin);
    margin-bottom: var(--base-margin);
    color: --rgba(rock);

    &,
    & p {
      font-size: 0.875rem;
    }

    p {
      margin-bottom: var(--base-margin-small);
    }

    #{$ru} & > :last-child:not(:first-child) {
      margin-bottom: 0;
    }
  }
}
</style>
