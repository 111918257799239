<template>
  <div class="ru:fieldset">
    <FormWrapper v-if="!isQuestionLayout">
      <FormControl
        id="type"
        v-model="formData.data.name"
        :required="false"
        text="Name"
        type="text"
      />
    </FormWrapper>
    <template v-if="isFieldsetVisible('LICENCE')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="text"
        />
        <FormControl
          id="licenceNumber"
          v-model="formData.data.licenceNumber"
          :required="false"
          text="Licence number"
          type="text"
        />
        <FormControl
          id="issueCountry"
          v-model="formData.data.issueCountry"
          :required="false"
          text="Country of issue"
          type="select"
          :options="countries"
        />
        <FormControl
          v-if="formData.data.issueCountry !== 'Australia'"
          id="issueState"
          v-model="formData.data.issueLocation"
          :required="false"
          text="Location of issue"
          type="text"
        />
        <FormControl
          v-else
          id="addressState"
          v-model="formData.data.issueLocation"
          :required="false"
          text="State of issue"
          type="select"
          :options="australianStates"
        />
        <FormControl
          id="expirationDate"
          v-model="formData.data.expirationDate"
          :required="false"
          text="Expiration date"
          type="date"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('PASSPORT')">
      <FormWrapper>
        <FormControl
          id="passportNumber"
          v-model="formData.data.passportNumber"
          :required="false"
          text="Passport number"
          type="text"
        />
        <FormControl
          id="issueCountry"
          v-model="formData.data.issueCountry"
          :required="false"
          text="Country of issue"
          type="select"
          :options="countries"
        />
        <FormControl
          id="expirationDate"
          v-model="formData.data.expirationDate"
          :required="false"
          text="Expiration date"
          type="date"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('BIRTH_CERTIFICATE')">
      <FormWrapper>
        <FormControl
          id="registrationNumber"
          v-model="formData.data.registrationNumber"
          :required="false"
          text="Registration number"
          type="text"
        />
        <FormControl
          id="registrationDate"
          v-model="formData.data.registrationDate"
          :required="false"
          text="Registration date"
          type="date"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('MEDICARE')">
      <FormWrapper>
        <FormControl
          id="cardNumber"
          v-model="formData.data.cardNumber"
          :required="false"
          text="Card number"
          type="text"
        />
        <FormControl
          id="individualReferenceNumber"
          v-model="formData.data.individualReferenceNumber"
          :required="false"
          text="Individual reference number"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('TAXATION')">
      <FormWrapper>
        <FormControl
          id="tfn"
          v-model="formData.data.tfn"
          :required="false"
          text="Tax file number"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('RESIDENCE')">
      <FormWrapper>
        <FormControl
          id="country"
          v-model="formData.data.country"
          :required="false"
          text="Country"
          type="select"
          :options="countries"
        />
        <FormControl
          id="identifier"
          v-model="formData.data.identifier"
          :required="false"
          text="Identifier"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('GOVERNMENT_CONCESSION')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="GOVERNMENT_CONCESSION_OPTIONS"
        />
        <FormControl
          id="identifier"
          v-model="formData.data.identifier"
          :required="false"
          text="Identifier"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('RELATIONSHIP')">
      <FormWrapper>
        <FormControl
          id="relationshipType"
          v-model="formData.data.relationshipType"
          :required="false"
          text="Relationship type"
          type="select"
          :options="RELATIONSHIP_OPTIONS"
        />
        <FormControl
          id="partnerLegalName"
          v-model="formData.data.partnerLegalName"
          :required="false"
          text="Legal name of partner"
          type="text"
        />
        <FormControl
          id="identifier"
          v-model="formData.data.identifier"
          :required="false"
          text="Identifier"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('WILL')">
      <FormWrapper>
        <FormControl
          id="provider"
          v-model="formData.data.provider"
          :required="false"
          text="Provider"
          type="text"
        />
        <FormControl
          id="locationOfDocument"
          v-model="formData.data.locationOfDocument"
          :required="false"
          text="Location of physical document"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('POWER_OF_ATTORNEY')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="POA_OPTIONS"
        />
        <FormControl
          id="provider"
          v-model="formData.data.provider"
          :required="false"
          text="Provider"
          type="text"
        />
        <FormControl
          id="locationOfDocument"
          v-model="formData.data.locationOfDocument"
          :required="false"
          text="Location of physical document"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('GUARDIANSHIP')" />
    <template v-else-if="isFieldsetVisible('BANK_ACCOUNT')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="BANK_ACCOUNT_OPTIONS"
        />
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
        <ValidationProvider v-if="$ff.privacyVaultEnabled()" ref="account">
          <FormControl
            id="account"
            v-model="formData.data.account"
            :required="false"
            text="Account"
            type="text"
          />
        </ValidationProvider>
        <ValidationProvider v-if="$ff.privacyVaultEnabled()" ref="secret">
          <FormControl
            id="secret"
            v-model="formData.data.secret"
            :required="false"
            text="Secret"
            type="text"
          />
        </ValidationProvider>
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('PROPERTY')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="PROPERTY_OPTIONS"
        />
        <FormControl
          id="addressLine1"
          v-model="formData.data.addressLine1"
          :required="false"
          text="Address line 1"
          type="text"
        />
        <FormControl
          id="addressLine2"
          v-model="formData.data.addressLine2"
          :required="false"
          text="Address line 2"
          type="text"
        />
        <FormControl
          id="addressSuburb"
          v-model="formData.data.addressSuburb"
          :required="false"
          text="Suburb"
          type="text"
        />
        <FormControl
          id="addressState"
          v-model="formData.data.addressState"
          :required="false"
          text="State"
          type="select"
          :options="australianStates"
        />
        <FormControl
          id="addressPostcode"
          v-model="formData.data.addressPostcode"
          :required="false"
          text="Postcode"
          type="text"
        />
        <FormControl
          id="addressCountry"
          v-model="formData.data.addressCountry"
          :required="false"
          text="Country"
          type="select"
          :options="countries"
        />
        <FormControl
          id="ownershipType"
          v-model="formData.data.ownershipType"
          :required="false"
          text="Ownership type"
          type="select"
          :options="PROPERTY_OWNERSHIP_OPTIONS"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('BUSINESS')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="BUSINESS_OPTIONS"
        />
        <FormControl
          id="businessName"
          v-model="formData.data.businessName"
          :required="false"
          text="Business name"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('TRUST')">
      <FormWrapper>
        <FormControl
          id="trustName"
          v-model="formData.data.trustName"
          :required="false"
          text="Trust name"
          type="text"
        />
        <FormControl
          id="identifier"
          v-model="formData.data.identifier"
          :required="false"
          text="Identifier"
          type="text"
        />
        <FormControl
          id="role"
          v-model="formData.data.role"
          :required="false"
          text="Are you a Trustee or Director of a Trustee?"
          type="select"
          :options="ROLE_IN_TRUST_OPTIONS"
        />
        <div>
          {{ $t('components.endOfLifeFieldset.isBeneficiaryQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isBeneficiary"
              v-model="formData.data.isBeneficiary"
              :required="false"
              text="Yes"
              type="radio"
              format="boolean"
              :option="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isBeneficiary"
              v-model="formData.data.isBeneficiary"
              :required="false"
              text="No"
              type="radio"
              format="boolean"
              :option="false"
            />
          </div>
        </div>
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('FINANCIAL_ASSETS')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="FINANCIAL_ASSET_OPTIONS"
        />
        <FormControl
          id="identifier"
          v-model="formData.data.identifier"
          :required="false"
          text="Identifier"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('SUPERANNUATION')">
      <FormWrapper>
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
        <FormControl
          id="memberNumber"
          v-model="formData.data.memberNumber"
          :required="false"
          text="Member number"
          type="text"
        />
        <div>
          {{
            $t(
              'components.endOfLifeFieldset.hasMadeBindingDeathNominationQuestion'
            )
          }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasMadeBindingDeathNomination"
              v-model="formData.data.hasMadeBindingDeathNomination"
              :required="false"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasMadeBindingDeathNomination"
              v-model="formData.data.hasMadeBindingDeathNomination"
              :required="false"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('INSURANCE')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="INSURANCE_OPTIONS"
        />
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
        <FormControl
          id="policyNumber"
          v-model="formData.data.policyNumber"
          :required="false"
          text="Policy number"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('VEHICLE')">
      <FormWrapper>
        <FormControl
          id="makeOrModel"
          v-model="formData.data.makeOrModel"
          :required="false"
          text="Make or model"
          type="text"
        />
        <FormControl
          id="numberPlate"
          v-model="formData.data.numberPlate"
          :required="false"
          text="Number plate"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('CREDIT_CARD')">
      <FormWrapper>
        <FormControl
          id="accountName"
          v-model="formData.data.accountName"
          :required="false"
          text="Account name"
          type="text"
        />
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('LOAN')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="LOAN_OPTIONS"
        />
        <FormControl
          id="accountName"
          v-model="formData.data.accountName"
          :required="false"
          text="Account name"
          type="text"
        />
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('UTILITY')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="UTILITY_OPTIONS"
          @input="updateCurrentInstitution"
        />
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
        <FormControl
          id="amount"
          v-model="formData.data.amount"
          :required="false"
          text="Amount"
          type="number"
        />
        <FormControl
          id="frequency"
          v-model="formData.data.frequency"
          :required="false"
          text="Frequency"
          type="select"
          :options="FREQUENCY_OPTIONS"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('SUBSCRIPTION')">
      <FormWrapper>
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Provider name"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
        <FormControl
          id="amount"
          v-model="formData.data.amount"
          :required="false"
          text="Amount"
          type="number"
        />
        <FormControl
          id="frequency"
          v-model="formData.data.frequency"
          :required="false"
          text="Frequency"
          type="select"
          :options="FREQUENCY_OPTIONS"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('DONATION')">
      <FormWrapper>
        <FormControl
          id="institution"
          v-model="formData.data.institutionId"
          :required="false"
          text="Charity/Institution name"
          type="text"
        />
        <FormControl
          id="amount"
          v-model="formData.data.amount"
          :required="false"
          text="Amount"
          type="number"
        />
        <FormControl
          id="frequency"
          v-model="formData.data.frequency"
          :required="false"
          text="Frequency"
          type="select"
          :options="FREQUENCY_OPTIONS"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('GOVERNMENT_BENEFIT')">
      <FormWrapper>
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type of benefit"
          type="select"
          :options="GOVERNMENT_BENEFIT_OPTIONS"
        />
        <FormControl
          id="institution"
          v-model="institution"
          :required="false"
          text="Institution"
          type="select"
          :options="institutionOptions"
          :allow-other="true"
        />
        <FormControl
          v-if="institution === 'OTHER'"
          id="name"
          v-model="formData.data.otherInstitutionName"
          text="Other institution"
          type="text"
        />
        <FormControl
          id="amount"
          v-model="formData.data.amount"
          :required="false"
          text="Amount"
          type="number"
        />
        <FormControl
          id="frequency"
          v-model="formData.data.frequency"
          :required="false"
          text="Frequency"
          type="select"
          :options="FREQUENCY_OPTIONS"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('EMPLOYMENT')">
      <FormWrapper>
        <FormControl
          id="employer"
          v-model="formData.data.employer"
          :required="false"
          text="Employer"
          type="text"
        />
        <FormControl
          id="type"
          v-model="formData.data.type"
          :required="false"
          text="Type"
          type="select"
          :options="EMPLOYMENT_TYPE_OPTIONS"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('ORGAN_DONATION')">
      <FormWrapper>
        <div>
          {{ $t('components.endOfLifeFieldset.isOrganDonorQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isOrganDonor"
              v-model="formData.data.isOrganDonor"
              :required="false"
              text="Yes"
              type="radio"
              format="boolean"
              :option="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isOrganDonor"
              v-model="formData.data.isOrganDonor"
              :required="false"
              text="No"
              type="radio"
              format="boolean"
              :option="false"
            />
          </div>
        </div>
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('MEDICAL_DEVICE')">
      <FormWrapper>
        <div>
          {{ $t('components.endOfLifeFieldset.hasPacemakerQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasPacemaker"
              v-model="formData.data.hasPacemaker"
              :required="false"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasPacemaker"
              v-model="formData.data.hasPacemaker"
              :required="false"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
        <div>
          {{ $t('components.endOfLifeFieldset.hasDefibrillatorQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillator"
              v-model="formData.data.hasDefibrillator"
              :required="false"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillator"
              v-model="formData.data.hasDefibrillator"
              :required="false"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
        <div>
          {{
            $t(
              'components.endOfLifeFieldset.hasDefibrillatorDrugInfusionQuestion'
            )
          }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillatorDrugInfusion"
              v-model="formData.data.hasDefibrillatorDrugInfusion"
              :required="false"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillatorDrugInfusion"
              v-model="formData.data.hasDefibrillatorDrugInfusion"
              :required="false"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
        <FormControl
          id="hasOtherEquipment"
          v-model="formData.data.hasOtherEquipment"
          :required="false"
          text="Do you have any other equipment/devices?"
          type="textarea"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isQuestionLayout">
      <FormWrapper>
        <FormControl
          v-show="false"
          id="type"
          v-model="formData.data.name"
          :value="question.name"
          text="Name"
          type="text"
        />
        <FormControl
          v-show="false"
          id="key"
          v-model="formData.data.key"
          :value="question.key"
          text="Name"
          type="text"
        />
        <FormControl
          id="value"
          v-model="formData.data.value"
          :text="question.name"
          :required="false"
          :type="question.type"
          :options="question.options"
          @change="$emit('change', formData.data.key, $event)"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('EMOTIONAL_MESSAGE')">
      <FormWrapper>
        <FormControl
          id="recipientName"
          v-model="formData.data.recipientName"
          :required="false"
          text="Recipient name"
          type="text"
        />
        <FormControl
          id="recipientEmail"
          v-model="formData.data.recipientEmail"
          :required="false"
          text="Email"
          type="text"
        />
        <FormControl
          id="phoneNumber"
          v-model="formData.data.phoneNumber"
          :required="false"
          text="Phone number"
          type="text"
        />
        <FormControl
          id="modeOfDelivery"
          v-model="formData.data.modeOfDelivery"
          :required="false"
          text="How do you want your message delivered to the recipient?"
          type="select"
          :options="EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS"
        />
        <FormControl
          id="message"
          v-model="formData.data.message"
          :required="false"
          text="Message"
          type="textarea"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('EMOTIONAL_STORY')">
      <FormWrapper>
        <FormControl
          id="recipientName"
          v-model="formData.data.recipientName"
          :required="false"
          text="Recipient name"
          type="text"
        />
        <FormControl
          id="recipientEmail"
          v-model="formData.data.recipientEmail"
          :required="false"
          text="Email"
          type="text"
        />
        <FormControl
          id="phoneNumber"
          v-model="formData.data.phoneNumber"
          :required="false"
          text="Phone number"
          type="text"
        />
        <FormControl
          id="modeOfDelivery"
          v-model="formData.data.modeOfDelivery"
          :required="false"
          text="How do you want your story delivered to the recipient?"
          type="select"
          :options="EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS"
        />
        <FormControl
          id="story"
          v-model="formData.data.story"
          :required="false"
          text="Story"
          type="textarea"
        />
      </FormWrapper>
    </template>
    <template v-else-if="isFieldsetVisible('CONTACT')">
      <FormWrapper>
        <FormControl
          id="contact"
          v-model="formData.data.contact"
          :required="false"
          text="Contact details"
          type="text"
        />
      </FormWrapper>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import FormControl from '@/components/molecules/FormControl';
import FormWrapper from '@/components/organisms/Form';
import institutions from '@/mixins/institutions';
import {
  GOVERNMENT_CONCESSION_OPTIONS,
  RELATIONSHIP_OPTIONS,
  POA_OPTIONS,
  BANK_ACCOUNT_OPTIONS,
  PROPERTY_OPTIONS,
  PROPERTY_OWNERSHIP_OPTIONS,
  BUSINESS_OPTIONS,
  FINANCIAL_ASSET_OPTIONS,
  INSURANCE_OPTIONS,
  LOAN_OPTIONS,
  UTILITY_OPTIONS,
  FREQUENCY_OPTIONS,
  GOVERNMENT_BENEFIT_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  FUNERAL_TYPE_OPTIONS,
  FUNERAL_DIRECTOR_OPTIONS,
  FUNERAL_PREPAID_TYPE_OPTIONS,
  EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS,
  ROLE_IN_TRUST_OPTIONS,
} from '@/utilities/end-of-life/form-constants';

export default {
  name: 'ComponentsTemplatesEndOfLifeFieldset',
  components: {
    FormControl,
    FormWrapper,
    ValidationProvider,
  },
  mixins: [institutions],
  props: {
    layout: {
      type: String,
      default: 'DEFAULT',
    },
    institutionType: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentInstitutionType: null,
      institution: this.value.data.otherInstitutionName
        ? 'OTHER'
        : this.value.data.institutionId,
      institutionOptions: [],
      formData: this.value,
      GOVERNMENT_CONCESSION_OPTIONS,
      RELATIONSHIP_OPTIONS,
      POA_OPTIONS,
      BANK_ACCOUNT_OPTIONS,
      PROPERTY_OPTIONS,
      PROPERTY_OWNERSHIP_OPTIONS,
      BUSINESS_OPTIONS,
      FINANCIAL_ASSET_OPTIONS,
      INSURANCE_OPTIONS,
      LOAN_OPTIONS,
      UTILITY_OPTIONS,
      FREQUENCY_OPTIONS,
      GOVERNMENT_BENEFIT_OPTIONS,
      EMPLOYMENT_TYPE_OPTIONS,
      FUNERAL_TYPE_OPTIONS,
      FUNERAL_DIRECTOR_OPTIONS,
      FUNERAL_PREPAID_TYPE_OPTIONS,
      EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS,
      ROLE_IN_TRUST_OPTIONS,
    };
  },
  computed: {
    ...mapGetters('ui', ['australianStates', 'countries']),
    isQuestionLayout() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.layout
      );
    },
  },
  watch: {
    currentInstitutionType() {
      this.getAndSetInstitutionOptions();
    },
    institution(value) {
      if (value === 'OTHER') {
        this.formData.data.institutionId = null;
      } else {
        this.formData.data.institutionId = value;
        this.formData.data.otherInstitutionName = null;
      }
    },
    formData: {
      handler(newData) {
        this.$emit('input', newData);
      },
      deep: true,
    },
    value: {
      handler(newData) {
        this.formData = newData;
      },
      deep: true,
    },
  },
  mounted() {
    this.updateCurrentInstitution();
  },
  created() {
    if (this.isQuestionLayout) {
      this.formData.data.name = this.question.name;
      this.formData.data.key = this.question.key;
    }
  },
  methods: {
    updateCurrentInstitution() {
      if (this.isFieldsetVisible('UTILITY')) {
        this.currentInstitutionType = this.getCurrentInstitutionForSubscription(
          this.formData.data.type
        );
      } else {
        this.currentInstitutionType = this.institutionType;
      }
    },
    getCurrentInstitutionForSubscription(current) {
      switch (current) {
        case 'Mobile':
          return 'Mobile';
        case 'Internet':
          return 'Internet';
        default:
          return 'Utilities';
      }
    },
    getCurrentInstitutionForLoan(current) {
      if (current === 'Mortgage') {
        return 'Mortgage';
      }
      return 'Personal Loan';
    },
    async getAndSetInstitutionOptions() {
      const institutions = await this.getInstitutionsByType(
        this.currentInstitutionType
      );

      this.institutionOptions = institutions.map(({ id, name }) => ({
        text: name,
        value: id,
      }));
    },
    isFieldsetVisible(fieldset) {
      return fieldset === this.layout;
    },
  },
};
</script>
