<template>
  <div class="ru:files-list">
    <template v-for="(file, index) in files">
      <div
        :key="`files${index}`"
        class="grid grid--flush grid--middle"
        :title="file.fileName ? file.fileName : file.meta.fileName"
      >
        <div class="cell">
          <Icon
            v-if="fileIsDeleted(file.fileStatus)"
            id="warning"
            theme="warning"
          />
          <Icon
            v-else
            :id="fileIcon(file.fileName ? file.fileName : file.meta.fileName)"
          />
        </div>
        <div class="cell cell__fill">
          <div
            class="ru:files-list__filename"
            :class="fileIsDeleted(file.fileStatus) ? '--deleted' : ''"
          >
            {{ file.fileName || file.meta.title || file.meta.fileName }}
          </div>
        </div>
        <div class="cell">
          <Buttons>
            <RuButton
              v-if="!fileIsDeleted(file.fileStatus)"
              icon
              level="plain"
              @click="$emit('downloadFile', file.id ? file.id : file)"
            >
              <Icon id="download" />
            </RuButton>
            <RuButton
              v-if="file.mutable"
              icon
              theme="error"
              level="plain"
              @click="$emit('deleteFile', file.id ? file.id : file)"
            >
              <Icon id="delete" />
            </RuButton>
            <RuButton
              v-if="file.unlinkable"
              class="ru:files-list__unlink"
              icon
              level="plain"
              @click="unlinkFile(file.id ? file.id : null)"
            >
              <Icon id="unlink" />
            </RuButton>
          </Buttons>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dialog from '@/mixins/message-box';

import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';

import { fileIcon } from '@/utilities';

export default {
  name: 'ComponentsMoleculesFilesList',
  components: {
    Buttons,
    RuButton,
    Icon,
  },
  mixins: [dialog],
  props: {
    files: {
      type: Array,
      required: true,
    },
    confirmUnlink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    fileIcon,
    fileIsDeleted(fileStatus) {
      return fileStatus === 'DELETED';
    },
    unlinkFile(fileId) {
      if (this.confirmUnlink) {
        this.$confirm(
          {
            title: this.$t('components.fileList.unlinkFile'),
            message: this.$t('components.fileList.unlinkFileMessage'),
          },
          (action) => {
            if (action) {
              this.$emit('unlinkFile', fileId);
            }
          }
        );
      } else {
        this.$emit('unlinkFile', fileId);
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &files-list {
    &:not(:last-child) {
      margin-bottom: var(--base-margin);
    }

    .grid {
      &:not(:last-child) {
        border-bottom: 1px solid --rgba(iron);
        padding-bottom: var(--base-margin);
        margin-bottom: var(--base-margin);
      }
    }
    #{$ru}icon {
      display: inline;
      vertical-align: bottom;
    }

    &__filename {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &#{$mf}deleted {
        text-decoration: line-through;
      }
    }

    &__unlink {
      color: --rgba(gravel);
    }
  }
}
</style>
