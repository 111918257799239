<template>
  <div class="ru:container ru:container--tight">
    <Checkout
      v-if="loaded"
      ref="checkoutComponent"
      :checkout-items="checkoutItems"
      :breadcrumbs="{
        start: $t('pages.checkout.breadcrumbs.will'),
        finish: $t('pages.checkout.breadcrumbs.printAndSign'),
      }"
      @orderCreated="submitWill"
    >
      <template #PRODUCTS_SUGGESTED>
        <Headline :level="1">
          {{ $t('pages.checkout.firstNameOpportunity', { firstName }) }}
        </Headline>
        <Tip>
          {{ $t('pages.checkout.completeEstatePlan') }}
        </Tip>
      </template>
      <template #PRODUCTS_PARTNER>
        <Headline big :level="2">
          {{ $t('pages.checkout.partnerEstatePlan', { partnerName }) }}
        </Headline>
        <Tip>
          {{ $t('pages.checkout.bundleWillPartner') }}
        </Tip>
      </template>
      <template #PAYMENT>
        <Headline :level="1">
          {{ $t('pages.checkout.finishWill', { firstName }) }}
        </Headline>
        <p>
          {{ $t('pages.checkout.willCheck') }}
        </p>
      </template>
    </Checkout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Checkout from '@/components/templates/Checkout';
import Headline from '@/components/atoms/Headline';
import Tip from '@/components/molecules/Tip';

import { user, will, people } from '@/mixins/apollo';
import { metaArrayToObject, toggleObjectInArray } from '@/utilities';

import SUBMIT_WILL_MUTATION from '@/graphql/mutations/SubmitWill';

export default {
  name: 'PagesAffiliateCheckoutWillIndex',
  components: {
    Checkout,
    Headline,
    Tip,
  },
  mixins: [user, will, people],
  middleware: ['authenticated'],
  data() {
    return {
      loaded: false,
      firstName: null,
      partnerName: this.$t('texts.sentences.yourPartner'),
      checkoutItems: [
        {
          product: 'WILL',
          type: 'DEFAULT',
        },
        {
          product: 'SUBSCRIPTION',
          type: 'DEFAULT',
        },
        {
          product: 'POA',
          type: 'SUGGESTED',
          description: this.$t('pages.checkout.appointSomeone'),
        },
        {
          product: 'LEGAL_ADVICE',
          type: 'SUGGESTED',
          description: this.$t('pages.checkout.specialistLegalAdvice'),
        },
        {
          product: 'PARTNER_WILL',
          type: 'PARTNER',
          description: this.$t('pages.checkout.bundleWill'),
        },
        {
          product: 'PARTNER_POA',
          type: 'PARTNER',
          description: this.$t('pages.checkout.partnerLivingWishes'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('affiliate', ['features']),
  },
  watch: {
    willMeta(willMeta) {
      this.firstName = this.willMeta.name_first;
      if (!willMeta.has_partner) {
        this.removePartnerItemsFromCheckoutItems();
      }
      this.loaded = true;
    },
    partners(partners) {
      this.partnerName =
        partners[0] &&
        metaArrayToObject(partners[0].meta).full_name.split(' ')[0];
    },
  },
  mounted() {
    if (!this.$ff.isShowingGiftsInCheckout()) {
      this.checkoutItems = toggleObjectInArray(
        { product: 'PARTNER_POA' },
        [...this.checkoutItems],
        'product'
      );
    }

    if (this.features.checkoutLegalAdviceEnabled === false) {
      this.checkoutItems = toggleObjectInArray(
        { product: 'LEGAL_ADVICE' },
        [...this.checkoutItems],
        'product'
      );
    }

    if (this.isFreeWillWeek) {
      this.checkoutItems = toggleObjectInArray(
        { product: 'PARTNER_WILL' },
        [...this.checkoutItems],
        'product'
      );
    }
  },
  methods: {
    ...mapActions('affiliate', ['resetAffiliate']),
    removePartnerItemsFromCheckoutItems() {
      this.checkoutItems = this.checkoutItems.filter(
        (checkoutItem) => checkoutItem.type !== 'PARTNER'
      );
    },
    async submitWill() {
      if (this.willStatus === 'IN_PROGRESS') {
        try {
          await this.$apollo.mutate({
            mutation: SUBMIT_WILL_MUTATION,
            variables: {
              id: this.willId,
            },
          });
          this.willMeta.notify_charities = null;
          await this.updateWillMeta();
          await this.refetchWill();
          this.goToThanks();
        } catch (error) {
          console.error('Order was successful but will submission failed.');
        }
      } else {
        this.goToThanks();
      }
    },
    goToThanks() {
      this.resetAffiliate();
      this.$router.push({
        path: this.localePath(
          `/checkout/will/${
            this.$ff.isShowingGiftsInCheckout() ? 'thank-you' : 'thanks'
          }`
        ),
      });
    },
  },
};
</script>
