<template>
  <div class="w-full max-w-3xl mx-auto">
    <div class="px-6 py-8 sm:px-15 sm:py-15 bg-white">
      <nuxt-link to="/" class="inline-block h-8 mb-12">
        <Logo />
      </nuxt-link>
      <h2 class="mb-8 text-6xl sm:text-8xl leading-none">
        <slot name="heading" />
      </h2>
      <slot name="form" />
      <div v-if="showCta" class="mt-8 text-center text-xl">
        <p>{{ $t('components.authPanel.doNotHaveAnAccount') }}</p>
        <nuxt-link
          :to="{ name: localePath('get-started'), query: $route.query }"
          class="link-alt"
        >
          {{ $t('components.authPanel.getStarted') }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/atoms/Logo';

export default {
  name: 'ComponentsAuthPanel',
  components: {
    Logo,
  },
  props: {
    showCta: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
