<template>
  <div>
    <AdminDataTable
      :columns="columns"
      :loading="loading"
      :min-rows="wills.length"
      :row-count="wills.length"
    >
      <template #rows>
        <AdminDataTableRow
          v-for="(will, index) in wills"
          :key="index"
          :to="localePath(`/admin/submissions/${will.id}`)"
        >
          <AdminDataTableCell>
            <span
              class="admin-id px-2 py-1 border rounded font-mono font-medium text-sm leading-none"
            >
              {{ will.hashId.toUpperCase() }}
            </span>
          </AdminDataTableCell>
          <AdminDataTableCell>
            {{ $formatDate(will.updatedAt) }}
          </AdminDataTableCell>
          <AdminDataTableCell full>
            <div class="flex items-center">
              <AdminStatusChip :status="will.status" />
              <nuxt-link
                :to="localePath(`/admin/submissions/${will.id}`)"
                class="inline-flex justify-center items-center w-4 h-4 rounded-full ml-4 bg-slate-100 opacity-0 group-hover:opacity-100 text-white fill-current transition-opacity transition-100"
              >
                <svg width="6" height="8" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 7.4L.6 6l2-2-2-2L2 .6 5.4 4z" />
                </svg>
              </nuxt-link>
            </div>
          </AdminDataTableCell>
        </AdminDataTableRow>
      </template>
    </AdminDataTable>
  </div>
</template>

<script>
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminStatusChip from '@/components/admin/StatusChip';

export default {
  name: 'ComponentsUserHistoricalWills',
  components: {
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminStatusChip,
  },
  props: {
    wills: {
      type: Array,
      require: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      columns: ['ID', 'Date', 'Status'],
    };
  },
};
</script>
