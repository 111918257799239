<template>
  <div>
    <Headline :level="1"> {{ $t('components.oneMoment.message') }} </Headline>
    <p class="flex items-center">
      <LoadingRing :size="24" class="mr-4" />
      {{ text }}
    </p>
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';
import LoadingRing from '@/components/LoadingRing';
export default {
  name: 'ComponentsTemplatesOneMoment',
  components: {
    Headline,
    LoadingRing,
  },
  props: {
    text: {
      default: 'We’re getting your order details…',
      type: String,
    },
  },
};
</script>
