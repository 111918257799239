var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":5,"prev-step":"/will/guardians/your-pets"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"hide-submit":!_vm.isComplete,"submit-icon":"arrow-right"},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',{staticClass:"step-1"},[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.appointPetGuardian'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPetGuardians","options":[
          {
            label: _vm.$t('forms.labels.yes'),
            value: true,
          },
          {
            label: _vm.$t('forms.labels.no'),
            value: false,
          },
        ]},model:{value:(_vm.willMeta.has_pet_guardians),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_pet_guardians", $$v)},expression:"willMeta.has_pet_guardians"}})],1),_vm._v(" "),_vm._l((_vm.pets),function(pet,index){return _c('MetaSlot',{key:index,attrs:{"meta":pet.meta},scopedSlots:_vm._u([{key:"default",fn:function({ meta }){return [(_vm.showForm(index))?_c('FormSection',{staticClass:"step-2",attrs:{"tip":_vm.$t('pages.will.peopleSelectionInstructions')}},[_c('Headline',{attrs:{"level":2}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.guardians.appointAGuardianFor', {
              name: meta.name,
            }))+"\n        ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"id":pet.id,"loading":loading,"max":1,"people":_vm.filteredPeople[index],"type":"guardian"},model:{value:(_vm.selectedPetGuardians[index]),callback:function ($$v) {_vm.$set(_vm.selectedPetGuardians, index, $$v)},expression:"selectedPetGuardians[index]"}})],1):_vm._e()]}}],null,true)})})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }