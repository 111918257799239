<template>
  <div class="flex">
    <div class="flex-grow">
      <slot name="main" />
    </div>
    <div class="flex-shrink-0 w-84 ml-8">
      <div ref="side">
        <slot name="side" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsAdminMainSideLayout',
  data() {
    return {
      transform: 0,
    };
  },
};
</script>
