<template>
  <Box
    :background="isSelected ? 'lighter' : 'white'"
    :border="isSelected ? 'theme' : 'sand'"
  >
    <label :for="id" class="ru:selectable">
      <div class="grid grid--flush grid--start grid--small">
        <div class="cell cell__9/12">
          <slot name="content" />
        </div>
        <div class="cell cell__3/12">
          <slot name="actions" />
        </div>
      </div>
      <div class="d-none">
        <input
          :id="id"
          :checked="isSelected"
          :name="id"
          type="checkbox"
          @change="$emit('selectionChange', !isSelected, data)"
        />
      </div>
    </label>
  </Box>
</template>

<script>
import Box from '@/components/atoms/Box';

export default {
  name: 'ComponentsMoleculesSelectable',
  components: {
    Box,
  },
  props: {
    isSelected: {
      default: false,
      type: Boolean,
    },
    isSelectable: {
      default: true,
      type: Boolean,
    },
    data: {
      default: null,
      type: Object,
    },
    id: {
      default: null,
      type: String,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &selectable {
    &#{$mf}disabled {
      color: --rgba(dust);
      pointer-events: none;
      filter: grayscale(1);
    }
  }
}
</style>
