<template>
  <AuthPanel>
    <template #heading>
      {{ $t('texts.sentences.twoFactorAuthentication') }}
    </template>
    <template #form>
      <FormWrapper
        ref="formWrapper"
        v-slot="{ loading }"
        @submit="verify2faCode"
      >
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12">
            <FormControl
              id="2faCode"
              v-model="twoFactorCode"
              :required="true"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.code')"
              placeholder="000000"
            />
          </div>
          <div class="cell cell__12/12">
            <Buttons layout="stretch">
              <RuButton level="primary" button-type="submit">
                {{ $t('forms.labels.logIn') }}
              </RuButton>
            </Buttons>
          </div>
          <div class="cell cell__12/12">
            <Alert v-if="errorMessage" theme="error" level="major">
              {{ errorMessage }}
            </Alert>
          </div>
        </div>
      </FormWrapper>
    </template>
  </AuthPanel>
</template>

<script>
import LOGIN_MUTATION from '@/graphql/mutations/Login';
import AuthPanel from '@/components/AuthPanel';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Alert from '@/components/molecules/Alert';

import VERIFY_TWO_FACTOR_CODE_QUERY from '@/graphql/queries/VerifyTwoFactorCode';
import IS_TWO_FACTOR_ENABLED_QUERY from '@/graphql/queries/IsTwoFactorEnabled';

import { parseJwtTokenAndReturnPayload, formatError } from '@/utilities';

export default {
  name: 'PagesAffiliateLogIn2faIndex',
  components: {
    AuthPanel,
    FormWrapper,
    FormControl,
    Buttons,
    RuButton,
    Alert,
  },
  middleware: ['notAuthenticated'],
  data() {
    return {
      LOGIN_MUTATION,
      twoFactorCode: '',
      errorMessage: null,
      loading: false,
    };
  },
  async mounted() {
    this.$analytics.reset();
    this.loading = true;
    const { data } = await this.$apollo.query({
      query: IS_TWO_FACTOR_ENABLED_QUERY,
      variables: {
        sessionToken: this.$route.query.sessionToken,
      },
    });
    if (!data.isTwoFactorEnabled) {
      this.$router.replace({
        path: '/log-in/2fa/setup',
        query: this.$route.query,
      });
    }
    this.loading = false;
  },
  methods: {
    async verify2faCode() {
      this.errorMessage = null;
      this.loading = true;
      const state = this.$route.query.state;
      try {
        const { data } = await this.$apollo.query({
          query: VERIFY_TWO_FACTOR_CODE_QUERY,
          variables: {
            sessionToken: this.$route.query.sessionToken,
            twoFactorCode: this.twoFactorCode,
            state,
          },
        });
        const { sessionToken: modifiedSessionToken } = data.verifyTwoFactorCode;

        try {
          const { continue_uri: continueUri } =
            parseJwtTokenAndReturnPayload(modifiedSessionToken);
          const redirectUrl =
            continueUri +
            '?' +
            new URLSearchParams({
              sessionToken: modifiedSessionToken,
              state,
            }).toString();
          window.location.href = redirectUrl;
        } catch (error) {
          throw new Error('Invalid token passed');
        }
      } catch (error) {
        this.errorMessage = formatError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
