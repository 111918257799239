<template>
  <ModuleStep :index="3" prev-step="/will">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_liabilities"
      submit-icon="arrow-right"
      @done="done"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.assets.liabilities.question') }}
        </Headline>
        <p :class="{ 'mb-10': isFreeWillWeek }">
          {{ $t('pages.will.assets.liabilities.desc') }}
        </p>
        <Tip v-if="!isFreeWillWeek || willMeta.asset_note">
          {{ $t('pages.will.assets.liabilities.youCanAddNotes') }}
        </Tip>
        <LargeRadioButtons
          id="hasLiabilities"
          v-model="willMeta.has_liabilities"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_liabilities">
        <Headline :level="2">
          {{ $t('texts.sentences.yourLiabilities') }}
        </Headline>
        <EmptyState v-if="!selectedLiabilities.length">
          {{ $t('pages.will.assets.liabilities.noLiabilities') }}
        </EmptyState>
        <LiabilityInputs
          v-for="(liability, index) in selectedLiabilities"
          :id="`liability_${liability.id || index}`"
          :key="index"
          :disabled="loading"
          :description="liability.meta.description"
          :type="liability.meta.type"
          @description="liability.meta.description = $event"
          @type="liability.meta.type = $event"
          @add="addLiabilityInput"
          @remove="removeLiabilityInput(liability)"
        />
        <Buttons layout="end">
          <RuButton size="large" level="secondary" @click="addLiabilityInput">
            + {{ $t('forms.labels.add') }}
          </RuButton>
        </Buttons>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import LiabilityInputs from '@/components/LiabilityInputs';
import EmptyState from '@/components/EmptyState';
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { liabilities, user, will } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';

export default {
  name: 'PagesAffiliateWillAssetsLiabilities',
  components: {
    LiabilityInputs,
    EmptyState,
    AdiForm,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
    Buttons,
    RuButton,
  },
  mixins: [liabilities, user, will],
  data() {
    return {
      defaultLiability: {},
      selectedLiabilities: [],
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    isComplete() {
      return !!(
        this.willMeta.has_liabilities === false ||
        this.selectedLiabilities.length
      );
    },
  },
  watch: {
    liabilities(liabilities) {
      if (liabilities.length && !this.selectedLiabilities.length) {
        this.selectedLiabilities = liabilities.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addLiabilityInput() {
      this.selectedLiabilities.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeLiabilityInput(liability) {
      if (this.isLiability(liability)) {
        await this.removeLiability(liability);
      }

      this.selectedLiabilities.splice(
        this.selectedLiabilities.indexOf(liability),
        1
      );
    },
    async done() {
      for (const liability of this.selectedLiabilities) {
        const isLiability = this.isLiability(liability);
        const meta = objectToMetaArray(liability.meta);

        if (this.willMeta.has_liabilities) {
          if (isLiability) {
            await this.updateLiability(liability, meta);
          } else {
            await this.addLiability(meta);
          }
        } else if (isLiability) {
          await this.removeLiability(liability);
        }
      }

      if (
        (this.willMeta.has_liabilities || this.willMeta.has_assets) &&
        (!this.isFreeWillWeek || this.willMeta.asset_note)
      ) {
        this.$router.push({
          path: this.localePath('/will/assets/notes'),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 6 (Assets)',
        });
        this.$router.push({
          path: this.localePath('/will'),
        });
      }
    },
  },
};
</script>
