<template>
  <div>
    <div class="flex items-center justify-between h-12 mb-8">
      <Headline :level="1"> Reporting </Headline>
    </div>
    <AdminCard tight>
      <div class="grid">
        <div class="cell cell__4/12">
          <FormControl
            id="reporting-date-from"
            v-model="reportType"
            text="Report Type"
            type="select"
            :options="reportTypes"
            :disabled="$apollo.loading"
          />
        </div>
        <div class="cell cell__4/12">
          <FormControl
            id="reporting-date-from"
            v-model="dateFrom"
            text="Date From"
            type="date"
            format="date"
            :disabled="$apollo.loading"
          />
        </div>
        <div class="cell cell__4/12">
          <FormControl
            id="reporting-date-to"
            v-model="dateTo"
            text="Date To"
            type="date"
            format="date"
            :disabled="$apollo.loading"
          />
        </div>
      </div>
    </AdminCard>
    <template v-if="showTable">
      <PurchasedProductsReportTable
        v-if="reportType === 'PURCHASED_PRODUCTS'"
        :date-from="dateFrom"
        :date-to="dateTo"
        :loading="$apollo.loading"
      />
      <CremationRequestsStatusReportTable
        v-if="reportType === 'CREMATION_REQUESTS_BY_STATUS'"
        :date-from="dateFrom"
        :date-to="dateTo"
        :loading="$apollo.loading"
      />
      <PartnershipsReportTable
        v-if="reportType === 'PARTNERSHIPS_REPORT'"
        :date-from="dateFrom"
        :date-to="dateTo"
        :loading="$apollo.loading"
      />
    </template>
    <Toast />
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters } from 'vuex';
import {
  isSuperAdmin,
  isCremationsAdminOrHigher,
  isPartnershipsAdminOrHigher,
} from '@/utilities';

import PurchasedProductsReportTable from '@/components/PurchasedProductsReportTable';
import CremationRequestsStatusReportTable from '@/components/CremationRequestsStatusReportTable';
import PartnershipsReportTable from '@/components/PartnershipsReportTable';
import Toast from '@/components/Toast';
import FormControl from '@/components/molecules/FormControl';
import AdminCard from '@/components/admin/Card';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliateAdminReporting',
  components: {
    FormControl,
    Toast,
    PurchasedProductsReportTable,
    CremationRequestsStatusReportTable,
    PartnershipsReportTable,
    AdminCard,
    Headline,
  },
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  data() {
    const dateTo = format(new Date(), 'yyyy-MM-dd');
    const dateFrom = format(
      new Date(new Date().setDate(new Date().getDate() - 30)),
      'yyyy-MM-dd'
    );
    return {
      dateFrom,
      dateTo,
      reportType: null,
    };
  },
  computed: {
    ...mapGetters(['email', 'role']),
    showTable() {
      return this.dateFrom <= this.dateTo;
    },
    reportTypes() {
      return [
        ...(isSuperAdmin(this.role)
          ? [
              {
                value: 'PURCHASED_PRODUCTS',
                text: 'forms.labels.purchasedProducts',
              },
            ]
          : []),
        ...(isPartnershipsAdminOrHigher(this.role)
          ? [
              {
                value: 'PARTNERSHIPS_REPORT',
                text: 'forms.labels.partnershipBequests',
              },
            ]
          : []),
        ...(isCremationsAdminOrHigher(this.role)
          ? [
              {
                value: 'CREMATION_REQUESTS_BY_STATUS',
                text: 'forms.labels.cremationRequestsByStatus',
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    dateFrom(dateFrom) {
      this.validateDates(dateFrom, this.dateTo);
    },
    dateTo(dateTo) {
      this.validateDates(this.dateFrom, dateTo);
    },
  },
  methods: {
    validateDates(from, to) {
      if (from > to) {
        this.$nuxt.$emit('toast', {
          type: 'error',
          message: 'Start date should be before end date',
        });
      }
    },
  },
};
</script>
