<template>
  <BaseWizard
    :hide-submit="!complete"
    :headline="$t('components.giftWizard.title')"
    @close="close"
  >
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="!complete"
      :submit-label="$t('components.giftWizard.saveGift')"
      @submit.prevent="submit"
    >
      <FormSection class="step-1">
        <Headline :level="3">
          {{ $t('components.giftWizard.whatTypeOfGift') }}
        </Headline>
        <Buttons layout="stretch">
          <RuButton
            data-test="gift-type-possession"
            v-bind="tabAttributes('possession')"
            level="secondary"
            @click="setGiftType('possession')"
          >
            <BaseIcon id="gift" />
            {{ $t('components.giftWizard.aPossession') }}
          </RuButton>
          <RuButton
            data-test="gift-type-money"
            v-bind="tabAttributes('money')"
            level="secondary"
            @click="setGiftType('money')"
          >
            <BaseIcon id="wallet" />
            {{ $t('components.giftWizard.someMoney') }}
          </RuButton>
        </Buttons>
      </FormSection>
      <FormSection v-if="type === 'possession'" class="step-2">
        <Headline :level="3">
          {{ $t('components.giftWizard.describeGift') }}
        </Headline>
        <Tip>
          {{ $t('components.giftWizard.describeGiftTooltip') }}
        </Tip>
        <TextInput
          id="gift_description"
          v-model="description"
          placeholder="record collection"
        />
      </FormSection>
      <FormSection v-else-if="type === 'money'" class="step-2">
        <Headline :level="3">
          {{ $t('components.giftWizard.howMuchMoney') }}
        </Headline>
        <Tip>
          {{ $t('components.giftWizard.howMuchMoneyTooltip') }}
        </Tip>
        <MoneyInput id="gift_amount" v-model="amount" :max="9999999999" />
      </FormSection>
      <FormSection v-if="type" class="step-3">
        <Headline :level="2">
          {{ $t('components.giftWizard.whoAreYouGivingTo') }}
        </Headline>
        <Tip v-if="type === 'possession'">
          {{
            $t('components.giftWizard.whoAreYouGivingToTooltipPossessionType')
          }}
        </Tip>
        <Tip v-else-if="type === 'money'">
          {{ $t('components.giftWizard.whoAreYouGivingToTooltipMoneyType') }}
        </Tip>
        <Headline :level="3"> People </Headline>
        <PersonSelector
          v-model="recipients"
          label
          :loading="loading"
          :max="1"
          :people="people"
          type="beneficiary"
        />
        <Headline :level="3">
          {{ $t('components.giftWizard.charity') }}
        </Headline>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="recipients"
          :loading="loading"
          :selected-suggested-charities="recipients"
          :max="1"
        />
        <CharitySelector v-model="recipients" :loading="loading" :max="1" />
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addCharity">
            + {{ $t('components.giftWizard.addCharity') }}
          </RuButton>
        </Buttons>
        <CharityPanel />
      </FormSection>
      <FormSection v-if="type && (!isFreeWillWeek || note)" class="step-4">
        <Headline :level="2"> {{ $t('components.giftWizard.note') }} </Headline>
        <Tip> {{ $t('components.giftWizard.noteTooltip') }} </Tip>
        <TextArea
          id="gift_note"
          v-model="note"
          placeholder="Optional"
          maxlength="2500"
        />
      </FormSection>
    </AdiForm>
  </BaseWizard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import BaseIcon from '@/components/BaseIcon';
import BaseWizard from '@/components/BaseWizard';
import CharitySelector from '@/components/CharitySelector';
import CharitySuggester from '@/components/CharitySuggester';
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import PersonSelector from '@/components/PersonSelector';
import MoneyInput from '@/components/MoneyInput';
import TextInput from '@/components/TextInput';
import TextArea from '@/components/TextArea';
import Tip from '@/components/molecules/Tip';
import CharityPanel from '@/components/CharityPanel';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { charities, gifts, people, user, will } from '@/mixins/apollo';
import { objectToMetaArray, metaArrayToObject } from '@/utilities';

export default {
  name: 'ComponentsGiftWizard',
  components: {
    BaseIcon,
    BaseWizard,
    CharitySelector,
    CharitySuggester,
    AdiForm,
    FormSection,
    MoneyInput,
    PersonSelector,
    TextInput,
    TextArea,
    Tip,
    CharityPanel,
    Headline,
    Buttons,
    RuButton,
  },
  mixins: [charities, gifts, people, user, will],
  data() {
    return {
      recipients: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    ...mapFields('gift', [
      'fields.type',
      'fields.description',
      'fields.amount',
      'fields.note',
    ]),
    ...mapGetters('gift', ['id', 'fields', 'person', 'charity']),
    ...mapGetters(['isFreeWillWeek']),
    mutation() {
      return this.id ? this.UPDATE_GIFT_MUTATION : this.ADD_GIFT_MUTATION;
    },
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        person: null,
        charity: null,
        willId: this.willId,
      };

      if (this.id && !this.note) {
        variables.meta.push({ key: 'note', value: null });
      }

      if (this.id) {
        variables.id = this.id;
      } else {
        variables.willId = this.willId;
      }

      const recipient = this.recipients[0];

      if (recipient) {
        if (this.isPerson(recipient)) {
          variables.person = recipient;
        } else if (this.isCharity(recipient)) {
          variables.charity = recipient;
        }
      }

      return variables;
    },
    complete() {
      return (
        this.type &&
        ((this.type === 'money' && this.amount) ||
          (this.type === 'possession' && this.description)) &&
        this.recipients.length
      );
    },
  },
  mounted() {
    if (this.person) {
      this.recipients = [this.person.id];
    } else if (this.charity) {
      this.recipients = [this.charity.id];
    }
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    ...mapActions('gift', ['setShowGiftWizard']),
    ...mapActions('person', ['addPerson']),
    setGiftType(giftType) {
      this.type = giftType;
    },
    tabAttributes(giftType) {
      return {
        active: giftType === this.type,
      };
    },
    async submit() {
      const noRecipientID = !this.variables.person && !this.variables.charity;
      if (noRecipientID) {
        const newRecipientABN = this.recipients[0].abn;
        await this.addPartnerCharity(this.recipients[0]);
        await this.refetchCharities();
        const newRecipient = this.charities.find((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          return charityMeta.abn === newRecipientABN;
        });
        this.recipients = [newRecipient.id];
      }
      await this.$apollo.mutate({
        mutation: this.mutation,
        variables: this.variables,
        update: (store, res) => {
          this.update(store, res);
          this.close();
        },
      });
    },
    close() {
      this.setShowGiftWizard(false);
    },
    update(store, { data: { addGift } }) {
      if (addGift) {
        const data = store.readQuery(this.getGiftsQuery);
        data.getGifts.gifts.push(addGift.gift);

        store.writeQuery({
          ...this.getGiftsQuery,
          data,
        });
      }
    },
  },
};
</script>
