<template>
  <div :style="styles" class="ru:box">
    <slot />
  </div>
</template>

<script>
import { hexToRgb } from '@/utilities';
export default {
  name: 'ComponentsAtomsBox',
  props: {
    fit: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
    loose: {
      type: Boolean,
      default: false,
    },
    tight: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: null,
    },
    border: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        if (this.background.includes('#')) {
          return hexToRgb(this.background);
        }
        return `var(--${this.background})`;
      }
      return null;
    },
    shadowStyle() {
      if (this.shadow) {
        return '4px 4px 50px rgba(0, 0, 0, 0.1)';
      }
      return null;
    },
    borderStyle() {
      if (this.border) {
        if (this.border.includes('#')) {
          return hexToRgb(this.border);
        }
        return `1px solid rgb(var(--${this.border}))`;
      }
      return null;
    },
    radiusStyle() {
      if (this.square) {
        return '0';
      }
      return null;
    },
    stretchStyle() {
      if (this.stretch) {
        return 'block';
      }
      return null;
    },
    marginYStyle() {
      if (this.tight) {
        return '0';
      }
      return null;
    },
    marginXStyle() {
      if (this.stretch || this.loose) {
        return 'calc(var(--gutter-width) * -0.5)';
      }
      return null;
    },
    fitStyle() {
      if (this.fit) {
        return '100%';
      }
      return null;
    },
    styles() {
      return {
        '--box-shadow': this.shadowStyle,
        '--border-radius': this.radiusStyle,
        '--border-style': this.borderStyle,
        '--background': this.backgroundStyle,
        '--stretch-display': this.stretchStyle,
        '--margin-x': this.marginXStyle,
        '--margin-y': this.marginYStyle,
        '--height': this.fitStyle,
      };
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &box {
    --margin-x: 0;
    --stretch-display: none;
    box-shadow: var(--box-shadow, none);
    border: var(--border-style, none);
    border-radius: var(--border-radius, 0.375rem);
    background-color: --rgba(background);
    padding: var(--base-margin-big);
    margin: 0 var(--margin-x) var(--margin-y, var(--base-margin))
      var(--margin-x);
    position: relative;
    height: var(--height, auto);
    transition: --in();

    & + & {
      margin-top: var(--margin-y, #{calc(var(--base-margin) * 2)});
    }

    &::before {
      background-color: inherit;
      display: var(--stretch-display);
      content: '';
      position: absolute;
      inset: 0 50%;
      margin: 0 -50vw;
      width: 100vw;
      z-index: -1;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
