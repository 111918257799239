<template>
  <div class="ru:file-thumbnail">
    <button
      class="ru:file-thumbnail__content"
      @click="$emit('selectFile', fileId)"
    >
      <Icon :id="fileIcon(fileName)" size="large" />
      <span class="ru:file-thumbnail__filename">
        {{ shortFileName(fileName) }}
      </span>
    </button>
    <Buttons v-if="unlinkable" layout="strech">
      <RuButton
        theme="light"
        level="plain"
        @click="$emit('unlinkFile', fileId)"
      >
        {{ $t('components.fileThumbnail.unlink') }}
      </RuButton>
    </Buttons>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import { fileIcon, shortFileName } from '@/utilities';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsAtomsFileThumbnail',
  components: {
    Icon,
    Buttons,
    RuButton,
  },
  props: {
    fileId: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    unlinkable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    shortFileName,
    fileIcon,
  },
};
</script>

<style lang="scss">
#{$ru} {
  &file-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    cursor: pointer;
    gap: var(--base-margin-small);
    border-radius: var(--border-radius-small);

    &__filename {
      color: --rgba(basalt);
      overflow-wrap: break-word;
      max-width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: var(--base-margin-small);
    }
  }
}
</style>
