<template>
  <Tooltip
    class="inline-block w-full md:w-auto"
    :content="tooltip"
    :disabled="!isDisabled"
  >
    <Buttons layout="stretch">
      <RuButton
        level="primary"
        size="large"
        :disabled="isLoading || isDisabled || $nuxt.isOffline"
        @click="submitWill"
      >
        {{ $t('components.willSubmitButton.submit') }}
        <Loading v-if="isLoading" />
        <Icon v-else id="arrow-right" />
      </RuButton>
    </Buttons>
  </Tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import SUBMIT_WILL_MUTATION from '@/graphql/mutations/SubmitWill';
import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

import Tooltip from '@/components/Tooltip';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import Loading from '@/components/atoms/Loading';

import { subscription, will } from '@/mixins/apollo';

export default {
  name: 'ComponentsWillSubmitButton',
  components: {
    Tooltip,
    Buttons,
    RuButton,
    Icon,
    Loading,
  },
  mixins: [subscription, will],
  props: {
    ready: {
      default: false,
      type: Boolean,
    },
    hasSubscriptionExpired: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  apollo: {
    hasPurchasedWill: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return { userId: this.userId };
      },
      update(data) {
        const purchasedProducts = data.getPurchasedProducts.products || [];
        return (
          purchasedProducts.includes('WILL') ||
          purchasedProducts.includes('WILL_X2')
        );
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    ...mapGetters(['verified', 'willStatus']),
    ...mapGetters(['userId']),
    ...mapFields('signup', ['meta.has_partner']),
    isDisabled() {
      return (
        this.hasSubscriptionExpired ||
        this.willStatus === 'FLAGGED' ||
        !this.ready
      );
    },
    tooltip() {
      if (this.hasSubscriptionExpired) {
        return this.$t(
          'components.willSubmitButton.tooltip.subscriptionExpired'
        );
      } else if (this.willStatus === 'FLAGGED') {
        return this.$t('components.willSubmitButton.tooltip.willFlagged');
      } else {
        return this.$t('components.willSubmitButton.tooltip.default');
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    ...mapActions(['setWillStatus']),
    async resubmitWill() {
      if (this.willMeta.notify_charities === false) {
        this.willMeta.notify_charities = null;
        await this.updateWillMeta();
      }
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SUBMIT_WILL_MUTATION,
          variables: {
            id: this.willId,
          },
        });
        if (data.submitWill) {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: 'Resubmit Will',
            props: {},
            integrations: {
              Amplitude: false,
              Intercom: false,
              'Facebook Pixel': false,
              'Facebook Pixel Server Side': false,
              'Personas Facebook Custom Audiences': false,
            },
          });
          await this.$updateShouldShowCharityConsent();
          this.setWillStatus(data.submitWill.status);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async purchaseWill() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'Open Checkout',
      });
      await this.refetchWill();
      this.$router.push({
        path: this.localePath(`/checkout/will`),
      });
    },
    async submitWill() {
      this.isLoading = true;
      if (this.hasValidSubscription && this.hasPurchasedWill) {
        await this.resubmitWill();
      } else {
        await this.purchaseWill();
      }
    },
  },
};
</script>
