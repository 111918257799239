<template>
  <div class="ru:container">
    <div class="flex items-center mb-12">
      <div class="flex justify-center items-center">
        <NameBadge size="huge" />
      </div>
      <div class="ml-6">
        <Headline :level="1" flush>
          <small>{{ fullName }}</small>
          {{ headline }}
        </Headline>
      </div>
    </div>
    <CremationsGateway
      v-for="(cremationRequest, i) in cremationsRequested"
      :key="i"
      :href="`/cremations/at-need/${cremationRequest.id}`"
      app="cremations"
      :data="cremationRequest"
    />
  </div>
</template>

<script>
import GET_AT_NEED_CREMATION_REQUESTS_BY_INFORMANT_USER_ID_QUERY from '@/graphql/queries/GetAtNeedCremationRequestsByInformantUserId';

import Headline from '@/components/atoms/Headline';
import NameBadge from '@/components/molecules/NameBadge';
import CremationsGateway from '@/components/templates/CremationsGateway';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateCremationsAtNeedIndex',
  components: {
    Headline,
    NameBadge,
    CremationsGateway,
  },
  mixins: [will],
  middleware: ['authenticated'],
  apollo: {
    cremationsRequested: {
      query: GET_AT_NEED_CREMATION_REQUESTS_BY_INFORMANT_USER_ID_QUERY,
      variables() {
        return {
          informantUserId: this.userId,
        };
      },
      skip() {
        return !this.userId;
      },
      update: (data) => data.getAtNeedCremationRequestsByInformantUserId,
    },
  },
  data() {
    return {
      cremationsRequested: [],
    };
  },
  computed: {
    headline() {
      return (
        this.$t('pages.cremations.cremationService') +
        (this.cremationsRequested.length > 1 ? 's' : '')
      );
    },
    fullName() {
      let name = '';
      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();
        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }
        name = `${name} ${this.willMeta.name_last.trim()}`;
      }
      return name;
    },
  },
};
</script>
