<template>
  <Badge v-if="userInitial" v-bind="$attrs">
    {{ userInitial }}
  </Badge>
</template>

<script>
import Badge from '@/components/atoms/Badge';

import { will } from '@/mixins/apollo';

export default {
  name: 'ComponentsMoleculesNameBadge',
  components: {
    Badge,
  },
  mixins: [will],
  props: {
    letters: {
      default: 1,
      type: Number,
    },
  },
  computed: {
    userInitial() {
      const firstName = this.willMeta.name_first;
      const lastName = this.willMeta.name_last;
      const initials =
        (firstName ? firstName.charAt(0) : '') +
        (lastName ? lastName.charAt(0) : '');
      return initials.toUpperCase().substring(0, this.letters);
    },
  },
};
</script>
