<template>
  <ModuleStep :index="1">
    <AdiForm
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      :auto-submit="false"
      @submit="submit"
    >
      <FormSection class="ru:charity-consent">
        <Headline :level="1">
          {{ $t('pages.will.charity.supportCausesHeadline') }}
        </Headline>
        <Box background="white">
          <Badge color="basalt" background="sky" size="large">
            <Icon id="charity" size="large" />
          </Badge>
          <template v-if="showOptions">
            <p>
              {{ $t('pages.will.charity.charityOptionsDescription1') }}
            </p>
            <p>
              {{ $t('pages.will.charity.charityOptionsDescription2') }}
            </p>
            <RadioOptions
              id="notify_charities"
              :value="willMeta.notify_charities"
              :options="options"
              @change="optionSelected"
            />
          </template>
          <template v-else-if="willMeta.notify_charities === false">
            <p>
              {{ $t('pages.will.charity.thankYou') }}
            </p>
            <p>
              {{ $t('pages.will.charity.consentDetails') }}
            </p>
            <Buttons layout="end">
              <RuButton level="secondary" round @click="reset">
                {{ $t('pages.will.charity.changedMind') }}
              </RuButton>
            </Buttons>
          </template>
        </Box>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import RadioOptions from '@/components/molecules/RadioOptions';
import ModuleStep from '@/components/templates/ModuleStep';
import Headline from '@/components/atoms/Headline';
import Box from '@/components/atoms/Box';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillCharityConsentIndex',
  components: {
    AdiForm,
    FormSection,
    RadioOptions,
    ModuleStep,
    Headline,
    Box,
    Icon,
    Badge,
    Buttons,
    RuButton,
  },
  mixins: [user, will],
  data() {
    return {
      options: [
        {
          label: 'No thanks',
          value: false,
          level: 'neutral',
          theme: 'light',
        },
        {
          label: 'Yes',
          value: true,
          level: 'secondary',
          theme: 'wills',
        },
      ],
      isDirty: false,
    };
  },
  computed: {
    isComplete() {
      return this.willMeta.notify_charities !== null;
    },
    showOptions() {
      return !this.isDirty || this.willMeta.notify_charities === true;
    },
    showReset() {
      return this.isDirty && this.willMeta.notify_charities === false;
    },
  },
  methods: {
    reset() {
      this.isDirty = false;
      this.willMeta.notify_charities = null;
    },
    optionSelected(value) {
      this.willMeta.notify_charities = value;
      this.isDirty = true;
      if (this.willMeta.notify_charities === true) {
        this.submit();
      }
    },
    async submit() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'consent_requested',
        props: {
          action: 'Share contact details with charities',
          granted: this.willMeta.notify_charities,
        },
      });
      await this.updateWillMeta();
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &charity-consent {
    #{$ru}badge {
      margin-bottom: var(--base-margin);
    }
  }
}
</style>
