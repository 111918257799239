<template>
  <div class="ru:container">
    <Headline :level="1"> {{ $t('texts.sentences.thankYou') }} </Headline>
    <p>
      {{ $t('pages.checkout.thanksForSharing') }}
    </p>
    <ThankYou primary-tab-name="GIFT" />
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';
import ThankYou from '@/components/templates/ThankYou';

export default {
  name: 'PagesAffiliateCheckoutGiftsThankYou',
  components: {
    Headline,
    ThankYou,
  },
  layout: 'ruach',
};
</script>
