<template>
  <div class="ru:container">
    <OneMoment v-if="showLoading" text="" />
    <div v-else-if="loadError">
      <Headline :level="2">{{ $t('texts.sentences.ohDear') }}</Headline>
      <p>{{ $t('texts.sentences.somethingWentWrong') }}</p>
    </div>
    <FormWrapper v-else @submit="submit">
      <div class="ru:container">
        <Headline :level="2">
          <DecoratedText decoration="circle">
            {{ $upperFirst($t('texts.words.your')) }}
          </DecoratedText>
          {{ $t('titles.prepaidFuneralPlanContract') }}
        </Headline>
        <p>{{ $t('texts.sentences.letsDotAndCross') }}</p>
        <IframeReader
          v-if="$ff.prePaidContractFlowEnabled()"
          v-model="readContract"
          :url="contractUrl('.html')"
          :read-content="readContract"
          :title="$t('titles.prepaidFuneralPlanContract')"
          @getUrlFailed="getUrlFailed"
        />
        <Headline :level="3">
          <i18n path="texts.templates.lookGood">
            <DecoratedText decoration="underline">
              {{ $upperFirst($t('texts.words.good')) }}
            </DecoratedText>
          </i18n>
        </Headline>
        <p>
          <i18n path="pages.cremations.anyChangesToMake">
            <nuxt-link :to="previousPage" class="underline hover:no-underline">
              {{ $t('pages.cremations.goBackAndUpdateYourDetails') }}
            </nuxt-link>
          </i18n>
          .
          <br />
          <i18n path="pages.cremations.needMoreTime">
            <nuxt-link :to="previousPage" class="underline hover:no-underline">
              {{ $t('pages.cremations.downloadAndReadPDF') }}
            </nuxt-link>
          </i18n>
          .
          <br />
          {{ $t('texts.sentences.anyQuestions') }}
          <button
            type="button"
            class="underline hover:no-underline"
            @click="showChat"
          >
            {{ $t('texts.sentences.ourCustomerCareTeamAreHereToHelp') }}
          </button>
          !
        </p>
        <p :class="readContractClass">{{ readContractText }}</p>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12">
            <FormControl
              id="agreeContract"
              v-model="formData.agreeContract"
              value="agreeContract"
              type="checkbox"
              format="boolean"
              :disabled="!readContract"
              :text="$t('pages.cremations.contractAgreement')"
            />
          </div>
          <div class="cell cell__12/12">
            <i18n
              path="pages.cremations.downloadStatement"
              tag="p"
              :class="downloadedPDSClass"
            >
              <a
                href="https://safewill-misc.s3.ap-southeast-2.amazonaws.com/cremations/SafewillCremations-Prepaid-Product-Disclosure-Statement.pdf"
                class="underline hover:no-underline"
                target="_blank"
                rel="noopener"
                @click.once="downloadPDS"
              >
                {{ $t('titles.funeralPlanProductDisclosureStatement') }}
              </a>
            </i18n>
            <FormControl
              id="agreePDS"
              v-model="formData.agreePDS"
              value="agreePDS"
              type="checkbox"
              format="boolean"
              :text="$t('pages.cremations.productStatementAgreement')"
            />
          </div>
        </div>
        <Headline :level="3">
          <DecoratedText decoration="underline">
            {{ $t('texts.sentences.happy') }}?
          </DecoratedText>
        </Headline>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12">
            <Signature
              v-model="formData.agreeSign"
              :name="formData.firstName + ' ' + formData.lastName"
              :signed="formData.agreeSign"
              :label="$t('pages.cremations.clickBoxToSignContract')"
            />
          </div>
        </div>
        <RuButton
          aria-label="Submit"
          button-type="submit"
          :disabled="!formCompleted"
        >
          <span>{{ $t('forms.labels.finish') }}</span>
          <span class="icon">
            <BaseIcon id="arrow-right" />
          </span>
        </RuButton>
      </div>
    </FormWrapper>
  </div>
</template>

<script>
import { showChat } from '@/modules/chat';
import Headline from '@/components/atoms/Headline';
import Signature from '@/components/atoms/Signature';
import FormControl from '@/components/molecules/FormControl';
import FormWrapper from '@/components/organisms/Form';
import DecoratedText from '@/components/atoms/DecoratedText';
import RuButton from '@/components/atoms/Button';
import BaseIcon from '@/components/BaseIcon';
import OneMoment from '@/components/templates/OneMoment';
import IframeReader from '@/components/organisms/IframeReader';

import { decodePayloadFromUrl, encodePayloadForUrl } from '@/utilities';

const S3_QUOTE_URL = 'https://safewill-quotes.s3.ap-southeast-2.amazonaws.com/';

export default {
  name: 'PagesAffiliateCremationsCompleteQuoteContractPreview',
  components: {
    FormWrapper,
    FormControl,
    Headline,
    Signature,
    DecoratedText,
    RuButton,
    BaseIcon,
    OneMoment,
    IframeReader,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        primaryPhone: '',
        addressLine1: '',
        addressLine2: '',
        australianState: '',
        postcode: '',
        secondaryPhone: '',
        dateOfBirth: '',
        placeOfBirth: '',
        agreePDS: false,
        agreeContract: false,
        agreeSign: false,
        transportFee: '',
        professionalFee: '',
        totalQuote: '',
      },
      optionalOnLoad: [
        'secondaryPhone',
        'addressLine2',
        'agreePDS',
        'agreeContract',
        'agreeSign',
      ],
      readContract: false,
      downloadedPDS: false,
      leadData: {},
      documentFilename: null,
      loadAttempts: 0,
      maxAttempts: 30,
      loadError: false,
      showLoading: true,
    };
  },
  computed: {
    formCompleted() {
      return (
        this.formData.agreePDS &&
        this.formData.agreeContract &&
        this.formData.agreeSign
      );
    },
    readContractText() {
      if (this.readContract) {
        return this.$t('pages.cremations.thankYouForReadContract');
      }
      return this.$t('pages.cremations.readEntireContractMessage');
    },
    readContractClass() {
      if (this.readContract) {
        return 'text-success';
      }
      return 'text-warning';
    },
    downloadedPDSClass() {
      if (this.downloadedPDS) {
        return 'text-success';
      }
      return 'text-warning';
    },
    previousPage() {
      return (
        '/cremations/complete-quote?leadData=' + this.$route.query.leadData
      );
    },
  },
  created() {
    try {
      const leadData = {
        ...decodePayloadFromUrl(this.$route.query.leadData),
      };
      this.leadData = leadData;
      Object.keys(this.formData).forEach((key) => {
        if (leadData[key]) {
          this.formData[key] = leadData[key];
        }
      });
      this.updateUrl();
      this.loadError = !Object.keys(this.formData).every((key) => {
        return leadData[key] || this.optionalOnLoad.includes(key);
      });
    } catch (error) {
      this.loadError = true;
    }
  },
  methods: {
    contractUrl(extension) {
      return S3_QUOTE_URL + this.documentFilename + extension;
    },
    getUrlFailed() {
      this.loadError = true;
    },
    downloadPDS() {
      this.downloadedPDS = true;
    },
    async updateUrl() {
      this.documentFilename = await this.generateContract(this.leadData, false);
      if (!this.$ff.prePaidContractFlowEnabled()) {
        this.$router.replace({
          path: '/cremations/complete-quote/arrange-payment',
        });
      }
    },
    async submit() {
      await this.generateContract(
        this.leadData,
        this.formData.agreeSign,
        this.formData.agreePDS,
        this.formData.agreeContract
      );
      const newBase64 = encodePayloadForUrl(this.formData);
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'Pre-paid cremation Contract Signed',
      });
      this.$router.push({
        path: this.localePath('/cremations/complete-quote/arrange-payment'),
        query: {
          leadData: newBase64,
        },
      });
    },
    goBack() {
      this.$router.push({
        path: this.localePath('/cremations/complete-quote'),
        query: this.$route.query,
      });
    },
    showChat() {
      showChat();
    },
    async generateContract(leadData, signed, agreePDS, agreeContract) {
      const fullAddress = [
        leadData.addressLine1,
        leadData.addressLine2,
        leadData.australianState,
        leadData.postcode,
      ]
        .filter((val) => {
          return val;
        })
        .join(', ');
      try {
        const response = await fetch(
          `${process.env.SAFEWILL_BACKEND_API}/generate-document/prepaid-cremation-quote`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              payload: {
                firstName: leadData.firstName,
                lastName: leadData.lastName,
                fullName: [
                  leadData.firstName.trim(),
                  leadData.lastName.trim(),
                ].join(' '),
                postcode: leadData.postcode,
                state: leadData.australianState,
                email: leadData.email,
                addressLine1: leadData.addressLine1,
                addressLine2: leadData.addressLine2,
                fullAddress,
                secondaryPhone: leadData.secondaryPhone,
                primaryPhone: leadData.primaryPhone,
                dateOfBirth: leadData.dateOfBirth,
                placeOfBirth: leadData.placeOfBirth,
                transportFee: `${leadData.transportFee}`,
                establishmentFee: '500',
                paymentMethod: 'TBC',
                totalQuote: `${leadData.totalQuote}`,
                signed,
                agreePDS,
                agreeContract,
              },
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          return data.documentFilename;
        }
      } catch {
        throw new Error(
          'Something went wrong while trying to generateContract.'
        );
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
