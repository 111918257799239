<template>
  <div class="ru:container ru:container--snug ru:invites">
    <Headline :level="1">{{ $t('pages.invites.headline') }}</Headline>
    <p>
      {{ $t('pages.invites.description') }}
    </p>
    <Tip>
      {{ $t('pages.invites.tipText') }}
    </Tip>
    <Tabs
      :tabs="[
        {
          name: 'gifts',
          title: $t('pages.invites.giftsTab'),
        },
        {
          name: 'sent',
          title: $t('pages.invites.sentTab'),
        },
        {
          name: 'history',
          title: $t('pages.invites.historyTab'),
        },
        {
          name: 'referrals',
          title: $t('pages.invites.referralsTab'),
        },
      ]"
      variant="pill"
    >
      <template #gifts>
        <SendGifts />
      </template>
      <template #sent>
        <SentGifts />
      </template>
      <template #history>
        <Box background="white">
          <Headline :level="2">{{
            $t('pages.invites.orderHistoryHeadline')
          }}</Headline>
          <Orders />
        </Box>
      </template>
      <template #referrals>
        <Box background="white">
          <Headline :level="2">{{
            $t('pages.invites.referralsHeadline')
          }}</Headline>
          <p>
            {{ $t('pages.invites.referralsText') }}
          </p>
        </Box>
        <Invite />
      </template>
    </Tabs>
  </div>
</template>

<script>
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Tabs from '@/components/molecules/Tabs';
import Box from '@/components/atoms/Box';
import Orders from '@/components/organisms/Orders';
import Invite from '@/components/organisms/Invite';
import SendGifts from '@/components/organisms/SendGifts';
import SentGifts from '@/components/organisms/SentGifts';

export default {
  name: 'PagesAffiliateDashboardInvites',
  components: {
    Tip,
    Headline,
    Tabs,
    Box,
    Orders,
    Invite,
    SendGifts,
    SentGifts,
  },
};
</script>
