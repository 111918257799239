<template>
  <label
    class="inline-flex items-center p-3 border border-grey-300 hover:border-grey-400 rounded leading-none select-none relative"
    :class="classes.label"
    @click="open = !open"
  >
    <div
      class="mr-1"
      :class="disabled && !flagged ? 'text-grey-500' : 'text-red-300'"
    >
      <BaseGlyph id="flag" />
    </div>
    <div class="whitespace-no-wrap">
      {{ value || 'Flag Section' }}
    </div>
    <div
      class="relative w-6 h-6 border border-grey-300 group-hover:border-grey-400 rounded ml-2"
      :class="classes.checkbox"
    >
      <div
        class="flex justify-center items-center w-full h-full text-white group-hover:text-grey-400"
        :class="classes.icon"
      >
        <BaseGlyph id="tick-large" />
      </div>
    </div>

    <div
      :class="open && !value && !disabled ? 'block' : 'hidden'"
      class="origin-top-right absolute right-0 top-0 mt-12 w-96 bg-white border border-grey-300 hover:border-grey-400 rounded z-10 shadow"
    >
      <div class="rounded bg-white shadow-xs">
        <div class="py-1">
          <div
            v-for="(reason, i) in reasons[moduleName]"
            :key="i"
            class="reason block px-4 py-2 leading-normal text-gray-700 hover:bg-grey-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click="handleChange(reason)"
          >
            {{ reason }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="open && value && !disabled ? 'block' : 'hidden'"
      class="origin-top-right absolute right-0 top-0 mt-12 w-96 bg-white border border-grey-300 hover:border-grey-400 rounded z-10 shadow"
    >
      <div class="rounded bg-white shadow-xs">
        <div class="py-1">
          <div
            class="block px-4 py-2 leading-normal text-gray-700 hover:bg-grey-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click="handleChange(false)"
          >
            {{ $t('components.flagCheckbox.unflagSection') }}
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import BaseGlyph from '@/components/BaseGlyph';

export default {
  name: 'ComponentsAdminFlagCheckbox',
  components: {
    BaseGlyph,
  },
  model: {
    event: 'change',
  },
  props: {
    willId: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: [String, Boolean],
    },
  },
  data() {
    return {
      open: false,
      flagged: this.value,
      reasons: this.$t('components.flagCheckbox.reasons'),
    };
  },
  computed: {
    classes() {
      return {
        checkbox: {
          'border-red-300': this.flagged,
        },
        icon: {
          'text-red-300 group-hover:text-red-300': this.flagged,
        },
        label: {
          'border-red-300 hover:border-red-300 bg-red-100': this.flagged,
          'cursor-not-allowed opacity-50': this.disabled,
          'group cursor-pointer': !this.disabled,
        },
      };
    },
  },
  watch: {
    value(newValue) {
      this.flagged = newValue;
    },
  },
  methods: {
    handleChange(reason) {
      this.$emit('change', reason);
    },
  },
};
</script>
