<template>
  <div v-if="authenticated">
    <NavigationAdmin />
    <div class="ru: ru:admin max-w-admin min-w-admin ml-60">
      <div class="gutters py-6">
        <nuxt />
      </div>
    </div>
    <PortalTarget ref="stack" class="ru:stack" name="stack" multiple />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';

import NavigationAdmin from '@/components/organisms/NavigationAdmin';

import { isModeratorOrHigher } from '@/utilities';

export default {
  name: 'AdminLayout',
  components: {
    NavigationAdmin,
    PortalTarget,
  },
  computed: {
    ...mapGetters(['role', 'token']),
    authenticated() {
      return this.token && isModeratorOrHigher(this.role);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin {
    td {
      padding: var(--base-margin-small);
      font-size: 1rem;
    }
  }
}
</style>
