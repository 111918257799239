<template>
  <div class="ru:form__control" :class="classes" :style="styles">
    <select
      v-if="type === 'select'"
      :id="identifier"
      ref="formControl"
      v-model="modelValue"
      :required="required"
      class="ru:form__select"
      @input="change"
      @blur.once="touch"
    >
      <option
        v-for="(option, index) in selectOptions"
        :key="index"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
    <input
      v-else-if="type === 'checkbox'"
      :id="identifier"
      ref="formControl"
      :required="required"
      :type="type"
      :checked="value === true"
      :disabled="disabled"
      class="ru:form__input-checkbox"
      @input="change"
      @blur.once="touch"
    />
    <input
      v-else-if="type === 'radio'"
      :id="identifier"
      ref="formControl"
      :type="type"
      :name="id"
      :value="option"
      :checked="modelValue === option"
      class="ru:form__input-radio"
      @change="change"
      @blur.once="touch"
    />
    <textarea
      v-else-if="type === 'textarea'"
      :id="identifier"
      ref="formControl"
      v-model="modelValue"
      :name="id"
      rows="4"
      class="ru:form__input-text"
      :placeholder="placeholder"
      :readonly="readonly"
      @change="change"
      @blur.once="touch"
    />
    <input
      v-else
      :id="identifier"
      ref="formControl"
      v-model="modelValue"
      :required="required"
      :type="type"
      :placeholder="placeholder"
      :inputmode="inputmode"
      :min="min"
      :max="max"
      :pattern="pattern"
      :disabled="disabled"
      :readonly="readonly"
      :title="title"
      class="ru:form__input-text"
      @input="change"
      @blur.once="touch"
    />
    <label v-if="text" :for="identifier" class="ru:form__label">
      {{ text }}
    </label>
  </div>
</template>

<script>
import { format } from 'date-fns';

import { isValidDateString } from '@/utilities';

export default {
  name: 'ComponentsMoleculesFormControl',
  props: {
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    option: {
      type: [String, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    allowOther: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        if (this.type === 'date') {
          return this.value ? this.getDateValue(this.value) : this.value;
        }
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectOptions() {
      let options = [];
      if (typeof this.options[0] === 'string') {
        options = this.options.map((option) => {
          return {
            value: option,
            text: option,
          };
        });
      } else {
        options = this.options.map((option) => {
          return {
            value: option.value,
            text: this.$t(option.text),
          };
        });
      }
      if (!this.required && options[0] && options[0].value) {
        options.unshift({
          value: null,
          text: null,
        });
      }
      if (this.allowOther) {
        options.push({
          value: 'OTHER',
          text: this.$t('components.formControl.other'),
        });
      }
      return options;
    },
    hintText() {
      if (this.optional) {
        return ` (${this.$t('components.formControl.optional')})`;
      } else if (this.required) {
        return ' *';
      }
      return '';
    },
    styles() {
      return {
        '--hint-content': ` '${this.hintText}'`,
      };
    },
  },
  created() {
    this.classes = ['radio', 'checkbox'].includes(this.type)
      ? 'ru:form__control--inline'
      : null;
    this.identifier = this.id + '__' + Math.random().toString(36).slice(2);
  },
  methods: {
    getDateValue(value) {
      if (isValidDateString(value)) {
        return value;
      }
      return format(new Date(parseInt(value)), 'yyyy-MM-dd');
    },
    change($event) {
      switch (this.format) {
        case 'number':
          this.$emit('input', parseInt($event.target.value));
          break;
        case 'boolean':
          if (this.type === 'radio') {
            this.$emit('input', $event.target.value === 'true');
            break;
          }
          this.$emit('input', $event.target.checked);
          break;
        case 'date':
          this.$emit('input', $event.target.value || null);
          break;
        default:
          this.$emit('input', $event.target.value.trimRight());
      }
    },
    touch($event) {
      $event.target.classList.add('ru:form__field--touched');
    },
    reset() {
      this.$refs.formControl.classList.remove('ru:form__field--touched');
      this.modelValue = '';
    },
  },
};
</script>

<style lang="scss">
$this: '.ru\\:form';
#{$ru} {
  &form {
    --square: var(--base-line-height);

    &__fieldset,
    &__buttons {
      margin-bottom: var(--base-margin);
    }

    &__buttons {
      text-align: right;
    }

    &__control {
      --input-background: var(--sand);
      --input-background-focus: var(--white);
      --input-border-width: 2px;
      --input-border: var(--sand);
      --input-border-hover: var(--dust);
      --input-border-focus: var(--teal);
      --input-padding-y: 0.875rem;
      --input-padding-x: 1rem;
      --input-radius: 0.375rem;
      --input-padding: var(--input-padding-y) var(--input-padding-x);
      position: relative;
      display: flex;
      flex-direction: column;
      transition: --out();

      &:hover,
      &:focus-within {
        transition: --in();
      }

      &:hover {
        --input-border: var(--input-border-hover);
      }

      &:focus-within {
        --input-border: var(--input-border-focus);
        --input-background: var(--input-background-focus);
      }

      &--inline {
        display: flex;
        align-items: center;
        flex-direction: row;

        input {
          display: inline-block;
          flex-shrink: 0;
          margin-right: #{calc(var(--square) / 2)};
          height: #{calc(var(--square) + 5px)};
          width: var(--square);
          opacity: 0;
        }
      }
    }

    &__field--touched:invalid {
      --input-border: var(--red);
      --input-background: var(--pink);

      & + #{$this}__label {
        color: --rgba(red);
      }
    }

    &__select,
    &__input-text {
      outline: 0;
      font-size: var(--base-font-size);
      line-height: var(--base-line-height);
      display: block;
      background: --rgba(input-background);
      border: var(--input-border-width) solid --rgba(input-border);
      padding: var(--input-padding);
      border-radius: var(--input-radius);
      width: 100%;
      transition: inherit;
    }

    &__select {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      height: #{calc(
          var(--base-line-height) + (var(--input-padding-y) * 2) +
            (var(--input-border-width) * 2)
        )};
    }

    &__input-text {
      &[disabled] {
        border-color: --rgba(silver);
        background: --rgba(concrete);
        color: --rgba(rock);
        cursor: not-allowed;
      }
    }

    &__label {
      &::after {
        color: --rgba(ebony, 0.25);
        content: var(--hint-content);
      }

      @at-root [required] + &::after {
        color: --rgba(red);
      }

      @at-root [disabled] {
        + &::after {
          color: --rgba(red);
        }
      }

      @at-root :is(#{$this}__select, #{$this}__input-text) + & {
        display: block;
        margin-bottom: 0.5rem;
        color: --rgba(basalt);
        font-size: var(--base-font-size);
        padding: 0 var(--outline-offset);
        line-height: var(--base-line-height);
        order: -1;
      }

      @at-root :is(#{$this}__input-text) + & {
        cursor: text;
      }

      @at-root :is(
            :focus:not(#{$this}__input-radio, #{$this}__input-checkbox),
            #{$this}__input-text:not(:placeholder-shown),
            #{$this}__select:valid
          )
          + & {
        cursor: pointer;
        line-height: var(--base-line-height);
      }
    }

    &__input-radio {
      + #{$this}__label::before,
      + #{$this}__label::after {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: var(--square);
        height: var(--square);
        border-radius: 999px;
        border: var(--input-border-width) solid --rgba(checkbox-border);
      }

      + #{$this}__label::after {
        border-color: --rgba(checkbox-border-focus);
        background-color: --rgba(input-background);
        opacity: 0;
        transition: var(--transition);
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 9.086 4 6.586 2.586 8 6.5 11.914 13.414 5 12 3.586z" fill="#123"/></svg>'
        );
      }

      &:checked + #{$this}__label::after {
        opacity: 1;
      }
    }

    &__input-checkbox {
      &[disabled] + label {
        opacity: 0.5;
        cursor: not-allowed;
      }

      & + #{$this}__label {
        padding-top: var(--input-border-width);
      }

      + #{$this}__label::before,
      + #{$this}__label::after {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: var(--square);
        height: var(--square);
        border-radius: var(--input-radius);
        border: var(--input-border-width) solid --rgba(checkbox-border);
      }

      + #{$this}__label::before {
        background-color: --rgba(input-background);
      }

      + #{$this}__label::after {
        border-color: --rgba(checkbox-border-focus);
        background-color: --rgba(input-background);
        opacity: 0;
        transition: var(--transition);
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 9.086 4 6.586 2.586 8 6.5 11.914 13.414 5 12 3.586z" fill="#123"/></svg>'
        );
      }

      &:checked + #{$this}__label::after {
        opacity: 1;
      }
    }
  }
}
</style>
