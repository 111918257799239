<template>
  <div>
    <AdminBackLink to="/admin/cremations">Cremations</AdminBackLink>
    <AdminMainSideLayout v-if="cremationRequestData">
      <template #main>
        <AdminCard title="Cremation request" tight>
          <AdminSimpleTable
            description="Cremation request"
            :rows="[
              [
                'Informant',
                informantIdentifier,
                `/admin/users/${formData.informantUserId}`,
              ],
              ['Deceased', guestIdentifier],
            ]"
          />
        </AdminCard>
        <div class="jump-links">
          <a href="#jumpInformant"> ⇣ Informant </a>
          <a href="#jumpWill"> ⇣ Will </a>
          <a href="#jumpAbout"> ⇣ Deceased </a>
          <a href="#jumpPlaceOfDeath"> ⇣ Place of death </a>
          <a href="#jumpMortuaryCare"> ⇣ Mortuary care </a>
          <a href="#jumpPlaceOfBirth"> ⇣ Place of birth </a>
          <a href="#jumpOccupation"> ⇣ Occupation </a>
          <a href="#jumpGuestParents"> ⇣ Parents of deceased </a>
          <a href="#jumpMarritalStatus"> ⇣ Marriage or relationship </a>
          <a href="#jumpGuestChildren"> ⇣ Children of deceased </a>
          <a href="#jumpPostalAddress"> ⇣ Postal Address </a>
        </div>
        <div id="jumpInformant" />
        <AdminCard title="Informant">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="informantFirstName"
            v-model="formData.informantFirstName"
            type="text"
            label="Informant first name"
            :disabled="loading"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
          <AdminCardRow
            id="informantOtherGivenNames"
            v-model="formData.informantOtherGivenNames"
            type="text"
            label="Informant other given name(s)"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantLastName"
            v-model="formData.informantLastName"
            type="text"
            label="Informant last name"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantPhone"
            v-model="formData.informantPhone"
            type="text"
            label="Informant phone"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantSecondaryPhone"
            v-model="formData.informantSecondaryPhone"
            type="text"
            label="Informant secondary phone"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantAddressLine1"
            v-model="formData.informantAddressLine1"
            type="text"
            label="Informant address line 1"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantAddressLine2"
            v-model="formData.informantAddressLine2"
            type="text"
            label="Informant address line 2"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantAddressSuburb"
            v-model="formData.informantAddressSuburb"
            type="text"
            label="Informant address suburb"
            :disabled="loading"
          />
          <AdminCardRow
            id="informantAddressState"
            v-model="formData.informantAddressState"
            type="select"
            label="Informant address state"
            :disabled="loading"
            :options="australianStates"
          />
          <AdminCardRow
            id="informantAddressPostcode"
            v-model="formData.informantAddressPostcode"
            type="text"
            label="Informant address postcode"
            :disabled="loading"
          />
        </AdminCard>
        <div id="jumpWill" />
        <AdminCard title="Will">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="informantRelationship"
            v-model="formData.informantRelationship"
            type="select"
            label="Informant relationship"
            :disabled="loading"
            :options="translateOptions(RELATIVE_OPTIONS)"
          />
          <AdminCardRow
            id="guestHasWill"
            v-model="formData.guestHasWill"
            type="radio"
            label="Deceased has will"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <div v-if="showInformantIsWillExecutor">
            <AdminCardRow
              id="informantIsWillExecutor"
              v-model="formData.informantIsWillExecutor"
              type="radio"
              label="Informant is will executor"
              :options="translateOptions(YES_NO_OPTIONS)"
            />
          </div>
          <div v-if="showInformantIsNextOfKin">
            <AdminCardRow
              id="informantIsNextOfKin"
              v-model="formData.informantIsNextOfKin"
              type="radio"
              label="Informant is next of kin"
              :options="translateOptions(YES_NO_OPTIONS)"
            />
          </div>
          <div v-if="showExecutorOrKin">
            <AdminCardRow
              id="executorOrKinFirstName"
              v-model="formData.executorOrKinFirstName"
              type="text"
              label="Executor or kin first name"
              :disabled="loading"
              :pattern="FIRST_NAME_PATTERN.regex"
              :title="$t(FIRST_NAME_PATTERN.description)"
            />
            <AdminCardRow
              id="executorOrKinOtherGivenNames"
              v-model="formData.executorOrKinOtherGivenNames"
              type="text"
              label="Executor or kin other given name(s)"
              :disabled="loading"
            />
            <AdminCardRow
              id="executorOrKinLastName"
              v-model="formData.executorOrKinLastName"
              type="text"
              label="Executor or kin last name"
              :disabled="loading"
            />
            <AdminCardRow
              id="executorOrKinRelationship"
              v-model="formData.executorOrKinRelationship"
              type="select"
              label="Executor or kin relationship"
              :disabled="loading"
              :options="translateOptions(RELATIVE_OPTIONS)"
            />
            <AdminCardRow
              id="executorOrKinPhone"
              v-model="formData.executorOrKinPhone"
              type="text"
              label="Executor or kin phone"
              :disabled="loading"
            />
            <AdminCardRow
              id="executorOrKinEmail"
              v-model="formData.executorOrKinEmail"
              type="text"
              label="Executor or kin email"
              :disabled="loading"
            />
            <AdminCardRow
              id="executorOrKinAddressLine1"
              v-model="formData.executorOrKinAddressLine1"
              type="text"
              label="Executor or kin address line 1"
              :disabled="loading"
            />
            <AdminCardRow
              id="executorOrKinAddressLine2"
              v-model="formData.executorOrKinAddressLine2"
              type="text"
              label="Executor or kin address line 2"
              :disabled="loading"
            />
            <AdminCardRow
              id="executorOrKinAddressSuburb"
              v-model="formData.executorOrKinAddressSuburb"
              type="text"
              label="Executor or kin address suburb"
              :disabled="loading"
            />
            <AdminCardRow
              v-if="showExecutorOrKinRegion"
              id="executorOrKinAddressState"
              v-model="formData.executorOrKinAddressState"
              :disabled="loading"
              type="text"
              label="Executor or kin address region"
            />
            <AdminCardRow
              v-else
              id="executorOrKinAddressState"
              v-model="formData.executorOrKinAddressState"
              :disabled="loading"
              type="select"
              label="Executor or kin address state"
              :options="australianStatesOptions"
            />
            <AdminCardRow
              id="executorOrKinAddressPostcode"
              v-model="formData.executorOrKinAddressPostcode"
              type="text"
              label="Executor or kin address postcode"
              :disabled="loading"
              inputmode="decimal"
              min="4"
              max="4"
              :pattern="showExecutorOrKinRegion ? null : `[0-9]{4}`"
            />
            <AdminCardRow
              id="executorOrKinAddressCountry"
              v-model="formData.executorOrKinAddressCountry"
              type="select"
              label="Executor or kin address country"
              :disabled="loading"
              :options="countries"
            />
          </div>
        </AdminCard>
        <div id="jumpAbout" />
        <AdminCard title="Deceased">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="guestFirstName"
            v-model="formData.guestFirstName"
            type="text"
            label="Deceased first name"
            :disabled="loading"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
          <AdminCardRow
            id="guestOtherGivenNames"
            v-model="formData.guestOtherGivenNames"
            type="text"
            label="Deceased other given name(s)"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestLastName"
            v-model="formData.guestLastName"
            type="text"
            label="Deceased last name"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestLastNameAtBirth"
            v-model="formData.guestLastNameAtBirth"
            type="text"
            label="Deceased last name at birth"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestDateOfBirth"
            v-model="formData.guestDateOfBirth"
            type="date"
            label="Deceased date of birth"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestDateOfDeath"
            v-model="formData.guestDateOfDeath"
            type="date"
            label="Deceased date of death"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestSex"
            v-model="formData.guestSex"
            type="select"
            label="Deceased sex"
            :disabled="loading"
            :options="translateOptions(SEX_OPTIONS)"
          />
          <AdminCardRow
            id="guestAddressLine1"
            v-model="formData.guestAddressLine1"
            type="text"
            label="Deceased address line 1"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestAddressLine2"
            v-model="formData.guestAddressLine2"
            type="text"
            label="Deceased address line 2"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestAddressSuburb"
            v-model="formData.guestAddressSuburb"
            type="text"
            label="Deceased address suburb"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestAddressState"
            v-model="formData.guestAddressState"
            type="select"
            label="Deceased address state"
            :disabled="loading"
            :options="australianStates"
          />
          <AdminCardRow
            id="guestAddressPostcode"
            v-model="formData.guestAddressPostcode"
            type="text"
            label="Deceased address postcode"
            :disabled="loading"
          />
        </AdminCard>
        <div id="jumpPlaceOfDeath" />
        <AdminCard title="Place of death">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="placeOfDeathAddressState"
            v-model="formData.placeOfDeathAddressState"
            type="select"
            label="Place of death address state"
            :disabled="loading"
            :options="australianStates"
          />
          <AdminCardRow
            v-if="stateSelected && !isSupportedState"
            id="placeOfDeathUnsupportedState"
            type="info"
            label="We do not currently accept cremations from outside NSW/ACT."
            :disabled="loading"
          />
          <template v-if="stateSelected && isSupportedState">
            <AdminCardRow
              id="placeOfDeathLocation"
              v-model="formData.placeOfDeathLocation"
              type="select"
              label="Place of death location"
              :disabled="loading"
              :options="translateOptions(DEATH_LOCATION_OPTIONS)"
            />
            <AdminCardRow
              v-if="showPlaceOfDeathFacilityName"
              id="placeOfDeathFacilityName"
              v-model="formData.placeOfDeathFacilityName"
              :type="
                stateIsNsw && showPlaceOfDeathFacilityName ? 'select' : 'text'
              "
              :label="
                showPlaceOfDeathFacilityName ? 'Facility name' : 'Location'
              "
              :disabled="loading"
              :options="
                stateIsNsw && showPlaceOfDeathFacilityName
                  ? [...NSW_HOSPITAL_NAMES, 'OTHER']
                  : null
              "
            />
            <AdminCardRow
              v-if="placeOfDeathFacilityIsOther"
              id="placeOfDeathFacilityName"
              v-model="formData.placeOfDeathFacilityNameOther"
              type="text"
              label="Place of death facility name"
              :disabled="loading"
            />
            <AdminCardRow
              id="placeOfDeathAddressLine1"
              v-model="formData.placeOfDeathAddressLine1"
              type="text"
              label="Place of death address line 1"
              :disabled="loading"
            />
            <AdminCardRow
              id="placeOfDeathAddressLine2"
              v-model="formData.placeOfDeathAddressLine2"
              type="text"
              label="Place of death address line 2"
              :disabled="loading"
            />
            <AdminCardRow
              id="placeOfDeathAddressSuburb"
              v-model="formData.placeOfDeathAddressSuburb"
              :type="stateIsNsw ? 'select' : 'text'"
              label="Place of death address suburb"
              :disabled="loading"
              :options="stateIsNsw ? [...NSW_HOSPITAL_SUBURBS, 'OTHER'] : null"
            />
            <AdminCardRow
              v-if="placeOfDeathSuburbIsOther"
              id="placeOfDeathAddressSuburbOther"
              v-model="formData.placeOfDeathAddressSuburbOther"
              type="text"
              label="Place of death address suburb"
              :disabled="loading"
            />
            <AdminCardRow
              id="placeOfDeathAddressPostcode"
              v-model="formData.placeOfDeathAddressPostcode"
              type="text"
              label="Place of death address postcode"
              :disabled="loading"
            />
          </template>
          <AdminCardRow
            v-if="isSupportedState"
            id="isReportableToCoroner"
            v-model="formData.isReportableToCoroner"
            type="radio"
            label="Is reportable to coroner"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <AdminCardRow
            v-if="showCoronerLocation"
            id="coronerLocation"
            v-model="formData.coronerLocation"
            type="select"
            label="Coroner location"
            :disabled="loading"
            :options="translateOptions(CORONER_LOCATION)"
          />
          <template v-if="showGpDetails">
            <AdminCardRow
              id="gpFirstName"
              v-model="formData.gpFirstName"
              type="text"
              label="GP first name"
              :disabled="loading"
              :pattern="FIRST_NAME_PATTERN.regex"
              :title="$t(FIRST_NAME_PATTERN.description)"
            />
            <AdminCardRow
              id="gpLastName"
              v-model="formData.gpLastName"
              type="text"
              label="GP last name"
              :disabled="loading"
            />
            <AdminCardRow
              id="gpAddressLine1"
              v-model="formData.gpAddressLine1"
              type="text"
              label="GP address line 1"
              :disabled="loading"
            />
            <AdminCardRow
              id="gpAddressLine2"
              v-model="formData.gpAddressLine2"
              type="text"
              label="GP address line 2"
              :disabled="loading"
            />
            <AdminCardRow
              id="gpAddressSuburb"
              v-model="formData.gpAddressSuburb"
              type="text"
              label="GP address suburb"
              :disabled="loading"
            />
            <AdminCardRow
              id="gpAddressState"
              v-model="formData.gpAddressState"
              type="select"
              label="GP address state"
              :disabled="loading"
              :options="australianStates"
            />
            <AdminCardRow
              id="gpAddressPostcode"
              v-model="formData.gpAddressPostcode"
              type="text"
              label="GP address postcode"
              :disabled="loading"
            />
            <AdminCardRow
              id="gpPhone"
              v-model="formData.gpPhone"
              type="text"
              label="GP phone"
              :disabled="loading"
            />
          </template>
        </AdminCard>
        <div id="jumpMortuaryCare" />
        <AdminCard title="Mortuary care">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="guestHasPacemaker"
            v-model="formData.guestHasPacemaker"
            type="radio"
            label="Deceased has pacemaker"
            :options="translateOptions(YES_NO_UNSURE_OPTIONS)"
          />
          <AdminCardRow
            id="guestHasDefibrillator"
            v-model="formData.guestHasDefibrillator"
            type="radio"
            label="Deceased has defibrillator"
            :options="translateOptions(YES_NO_UNSURE_OPTIONS)"
          />
          <AdminCardRow
            id="guestHasDefibrillatorDrugInfusion"
            v-model="formData.guestHasDefibrillatorDrugInfusion"
            type="radio"
            label="Deceased has drug infusion pump"
            :options="translateOptions(YES_NO_UNSURE_OPTIONS)"
          />
          <AdminCardRow
            id="isAuthorizedForPrep"
            v-model="formData.isAuthorizedForPrep"
            type="radio"
            label="Is authorized for prep"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <AdminCardRow
            id="isRelativeInformed"
            v-model="formData.isRelativeInformed"
            type="radio"
            label="Is relative informed"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <AdminCardRow
            id="hasRelativeExpressedObjection"
            v-model="formData.hasRelativeExpressedObjection"
            type="radio"
            label="Has relative expressed objection"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <div v-if="showRelativeReasonForObjection">
            <AdminCardRow
              id="relativeReasonForObjection"
              v-model="formData.relativeReasonForObjection"
              type="text"
              label="Relative reason for objection"
              :disabled="loading"
            />
          </div>
        </AdminCard>
        <div id="jumpPlaceOfBirth" />
        <AdminCard title="Place of birth">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="guestPlaceOfBirthSuburb"
            v-model="formData.guestPlaceOfBirthSuburb"
            type="text"
            label="Deceased place of birth suburb"
            :disabled="loading"
          />
          <AdminCardRow
            v-if="showGuestPlaceOfBirthRegion"
            id="guestPlaceOfBirthRegion"
            v-model="formData.guestPlaceOfBirthState"
            :disabled="loading"
            type="text"
            label="Deceased place of birth region"
          />
          <AdminCardRowWithUnknown
            v-else
            id="guestPlaceOfBirthState"
            v-model="formData.guestPlaceOfBirthState"
            :disabled="loading"
            :required="false"
            type="select"
            label="Deceased place of birth state"
            :options="australianStatesOptions"
          />
          <AdminCardRowWithUnknown
            id="guestPlaceOfBirthCountry"
            v-model="formData.guestPlaceOfBirthCountry"
            type="select"
            label="Deceased place of birth country"
            :disabled="loading"
            :options="countries"
          />
          <div v-if="showGuestDateOfArrival" class="admin-card__row">
            <AdminCardRowWithUnknown
              id="guestYearOfArrival"
              v-model="guestYearOfArrival"
              type="text"
              inputmode="decimal"
              min="4"
              max="4"
              pattern="[0-9]{4}"
              label="Deceased year of arrival"
              :disabled="loading"
            />
          </div>
          <AdminCardRow
            id="guestIndigenousOrigin"
            v-model="formData.guestIndigenousOrigin"
            type="select"
            label="Deceased indigenous origin"
            :disabled="loading"
            :options="translateOptions(INDIGENOUS_ORIGIN_OPTIONS)"
          />
        </AdminCard>
        <div id="jumpOccupation" />
        <AdminCard title="Occupation">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="guestOccupation"
            v-model="formData.guestOccupation"
            type="text"
            label="Deceased occupation"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestIsRetiredAtDeath"
            v-model="formData.guestIsRetiredAtDeath"
            type="radio"
            label="Deceased is retired at death"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <AdminCardRow
            id="guestIsPensionerAtDeath"
            v-model="formData.guestIsPensionerAtDeath"
            type="radio"
            label="Deceased is pensioner at death"
            :options="translateOptions(YES_NO_OPTIONS)"
          />
          <div v-if="showGuestPensionerType">
            <AdminCardRow
              id="guestPensionerType"
              v-model="formData.guestPensionerType"
              type="select"
              label="Deceased pensioner type"
              :options="translateOptions(PENSIONER_TYPE_OPTIONS)"
              :disabled="loading"
            />
          </div>
        </AdminCard>
        <div id="jumpGuestParents" />
        <AdminCard
          v-for="(parent, i) in formData.guestParents"
          :key="i"
          :title="`Parent ${i + 1} of deceased`"
        >
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="guestParentsfirstName"
            v-model="formData.guestParents[i].firstName"
            type="text"
            label="First name"
            :disabled="loading"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
          <AdminCardRow
            id="guestParentsOtherGivenNames"
            v-model="formData.guestParents[i].otherGivenNames"
            type="text"
            label="Other given name(s)"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestParentslastName"
            v-model="formData.guestParents[i].lastName"
            type="text"
            label="Last name"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestParentslastNameAtBirth"
            v-model="formData.guestParents[i].lastNameAtBirth"
            type="text"
            label="Last name at birth"
            :disabled="loading"
          />
          <AdminCardRow
            id="guestParentssex"
            v-model="formData.guestParents[i].sex"
            type="select"
            label="Sex"
            :disabled="loading"
            :options="translateOptions(SEX_OPTIONS)"
          />
          <AdminCardRow
            id="guestParentsrelationship"
            v-model="formData.guestParents[i].relationship"
            type="select"
            label="Relationship"
            :disabled="loading"
            :options="translateOptions(PARENT_OPTIONS)"
          />
          <AdminCardRow
            id="guestParentsoccupation"
            v-model="formData.guestParents[i].occupation"
            type="text"
            label="Occupation"
            :disabled="loading"
          />
        </AdminCard>
        <div id="jumpMarritalStatus" />
        <AdminCard title="Marital status">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="guestMaritalStatusAtDeath"
            v-model="formData.guestMaritalStatusAtDeath"
            type="select"
            label="Deceased marital status at death"
            :disabled="loading"
            :options="translateOptions(FINAL_MARITAL_STATUS_OPTIONS)"
          />
        </AdminCard>
        <AdminCard
          v-if="!formData.guestPartners.length"
          title="Marriage or relationship"
        >
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--add"
              @click="addPartner"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add
              </div>
            </BaseButton>
          </div>
        </AdminCard>
        <AdminCard
          v-for="(partner, i) in formData.guestPartners"
          :key="i"
          :title="`Partner ${i + 1} of deceased`"
        >
          <div class="admin-card__buttons">
            <BaseButton
              v-if="formData.guestPartners.length === i + 1"
              class="admin-card__button admin-card__button--add"
              @click="addPartner"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--remove"
              @click="removePartner(i)"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="minus" size="small" classes="mr-2" />
                Remove
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="maritalStatus"
            v-model="formData.guestPartners[i].maritalStatus"
            type="select"
            label="Marital status"
            :disabled="loading"
            :options="translateOptions(CURRENT_MARITAL_STATUS_OPTIONS)"
          />
          <AdminCardRow
            id="partnerFirstName"
            v-model="formData.guestPartners[i].partnerFirstName"
            type="text"
            label="Partner first name"
            :disabled="loading"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
          <AdminCardRow
            id="partnerOtherGivenNames"
            v-model="formData.guestPartners[i].partnerOtherGivenNames"
            type="text"
            label="Partner other given name(s)"
            :disabled="loading"
          />
          <AdminCardRow
            id="partnerLastName"
            v-model="formData.guestPartners[i].partnerLastName"
            type="text"
            label="Partner last name"
            :disabled="loading"
          />
          <AdminCardRow
            id="partnerSex"
            v-model="formData.guestPartners[i].partnerSex"
            type="select"
            label="Partner sex"
            :disabled="loading"
            :options="translateOptions(SEX_OPTIONS)"
          />
          <div v-if="showMaritalDetails(i)">
            <AdminCardRow
              id="placeOfMarriageSuburb"
              v-model="formData.guestPartners[i].placeOfMarriageSuburb"
              type="text"
              label="Place of marriage suburb"
              :disabled="loading"
            />
            <AdminCardRow
              id="placeOfMarriageState"
              v-model="formData.guestPartners[i].placeOfMarriageState"
              type="text"
              label="Place of marriage state"
              :disabled="loading"
            />
            <AdminCardRow
              id="placeOfMarriageCountry"
              v-model="formData.guestPartners[i].placeOfMarriageCountry"
              type="select"
              label="Place of marriage country"
              :disabled="loading"
              :options="countries"
            />
            <AdminCardRowWithUnknown
              id="guestAgeAtDateOfMarriage"
              v-model="formData.guestPartners[i].guestAgeAtDateOfMarriage"
              type="number"
              label="Deceased age at date of marriage"
              :disabled="loading"
            />
          </div>
        </AdminCard>
        <div id="jumpGuestChildren" />
        <AdminCard
          v-if="!formData.guestChildren.length"
          title="Children of deceased"
        >
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--add"
              @click="addChild"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add
              </div>
            </BaseButton>
          </div>
        </AdminCard>
        <AdminCard
          v-for="(child, i) in formData.guestChildren"
          :key="i"
          :title="`Child ${i + 1} of deceased`"
        >
          <div class="admin-card__buttons">
            <BaseButton
              v-if="formData.guestChildren.length === i + 1"
              class="admin-card__button admin-card__button--add"
              @click="addChild"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--remove"
              @click="removeChild(i)"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="minus" size="small" classes="mr-2" />
                Remove
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="firstName"
            v-model="formData.guestChildren[i].firstName"
            type="text"
            label="First name"
            :disabled="loading"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
          <AdminCardRow
            id="otherGivenNames"
            v-model="formData.guestChildren[i].otherGivenNames"
            type="text"
            label="Other given name(s)"
            :disabled="loading"
          />
          <AdminCardRow
            id="lastName"
            v-model="formData.guestChildren[i].lastName"
            type="text"
            label="Last name"
            :disabled="loading"
          />
          <AdminCardRowWithUnknown
            id="dateOfBirth"
            v-model="formData.guestChildren[i].dateOfBirth"
            type="date"
            label="Date of Birth"
            :disabled="loading"
          />
          <AdminCardRowWithUnknown
            id="age"
            v-model="formData.guestChildren[i].age"
            type="number"
            label="Age"
            :disabled="loading"
          />
          <AdminCardRow
            id="sex"
            v-model="formData.guestChildren[i].sex"
            type="select"
            label="Sex"
            :disabled="loading"
            :options="translateOptions(SEX_OPTIONS)"
          />
          <AdminCardRow
            id="livingStatus"
            v-model="formData.guestChildren[i].livingStatus"
            type="select"
            label="Living status"
            :disabled="loading"
            :options="translateOptions(LIVING_STATUS_OPTIONS)"
          />
        </AdminCard>
        <div id="jumpPostalAddress" />
        <AdminCard title="Postal Address">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updateDetailsAndDisplayResult"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--top"
              to="#top"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="arrow-up" size="small" classes="mr-2" />
                Top
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="postalAddressLine1"
            v-model="formData.postalAddressLine1"
            type="text"
            label="Postal address line 1"
            :disabled="loading"
          />
          <AdminCardRow
            id="postalAddressLine2"
            v-model="formData.postalAddressLine2"
            type="text"
            label="Postal address line 2"
            :disabled="loading"
          />
          <AdminCardRow
            id="postalAddressSuburb"
            v-model="formData.postalAddressSuburb"
            type="text"
            label="Postal address suburb"
            :disabled="loading"
          />
          <AdminCardRow
            id="postalAddressState"
            v-model="formData.postalAddressState"
            type="select"
            label="Postal address state"
            :disabled="loading"
            :options="australianStates"
          />
          <AdminCardRow
            id="postalAddressPostcode"
            v-model="formData.postalAddressPostcode"
            type="text"
            label="Postal address postcode"
            :disabled="loading"
          />
        </AdminCard>
      </template>
      <template #side>
        <AdminCard title="Admin">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--override"
              @click="updateMetadata"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="lock" size="small" classes="mr-2" />
                Update
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--copy"
              @click="copyUserSignIn"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="user" size="small" classes="mr-2" />
                Copy sign-in
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="status"
            v-model="metadata.status"
            type="select"
            label="Status"
            :disabled="loading"
            :options="DEAL_STATUS_OPTIONS"
            vertical
          />
          <AdminCardRow
            id="status"
            v-model="metadata.adminUserId"
            type="select"
            label="Arranger"
            :disabled="loading"
            :options="arrangerOptions"
            vertical
          />
          <AdminCardRow
            id="status"
            v-model="metadata.crematorium"
            type="select"
            label="Crematorium"
            :disabled="loading"
            :options="translateOptions(CREMATORIUM_OPTIONS)"
            vertical
          />
          <AdminCardRow
            id="status"
            v-model="metadata.transportProvider"
            type="select"
            label="Transport Provider"
            :disabled="loading"
            :options="translateOptions(TRANSPORT_PROVIDER_OPTIONS)"
            vertical
          />
          <AdminCardRow
            id="transportFeesPriceOverride"
            v-model="metadata.transportFeesPriceOverride"
            type="number"
            label="Transport and Mortuary Care Fees (incl. GST)"
            vertical
            :disabled="loading"
          />
          <AdminCardRow
            id="transportFeesPriceOverride"
            v-model="metadata.disposedAt"
            type="date"
            label="Date of Disposal"
            vertical
            :disabled="loading"
          />
        </AdminCard>
        <AdminCard title="Lifelink">
          <div class="admin-card__buttons">
            <BaseButton
              v-if="formData.notificationId"
              class="admin-card__button admin-card__button--override"
              :disabled="loading"
              @click="refreshLifelinkStatuses"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="pencil" compact classes="mr-2" />
                Refresh Status
              </div>
            </BaseButton>
            <BaseButton
              v-else
              class="admin-card__button admin-card__button--override"
              :disabled="isDrsLodgementDisabled || loading"
              @click="lodgeDrsWithCertificate"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="upload" compact classes="mr-2" />
                Lodge DRS
              </div>
            </BaseButton>
          </div>
          <Alert
            v-if="isDrsLodgementDisabled && !formData.notificationId"
            theme="warning"
            level="medium"
          >
            DRS request can only be lodged the when deceased state is NSW or
            ACT, date of disposal is submitted, NSW Death Registration Statement
            form is signed, and, if location was not a Private Residence/Other,
            then Place of Death Facility Name and Place of Death Address Suburb
            must not be 'Other'.
          </Alert>
          <template v-if="formData.notificationId">
            <div class="py-5 px-8 break-words">
              <strong>Submitted At: </strong>
              {{
                metadata.submittedToLifelinkAt
                  ? $formatDate(metadata.submittedToLifelinkAt)
                  : 'NA'
              }}
            </div>
            <div class="py-5 px-8 break-words border-t-2 border-grey-300">
              <strong>DRS Lodgement</strong>
              <div>Notification ID: {{ formData.notificationId }}</div>
              <div>Notification Status: {{ formData.notificationStatus }}</div>
            </div>
            <div class="py-5 px-8 break-words border-t-2 border-grey-300">
              <strong> Certificate Request </strong>
              <div>Application ID: {{ formData.applicationId }}</div>
              <div>Application Status: {{ formData.applicationStatus }}</div>
            </div>
          </template>
          <div v-if="lifelinkError" class="py-5 px-8 break-words">
            Submission to Lifelink failed with the error: <br />
            {{ lifelinkError }} <br />
            Please contact the dev team if the error does not make sense.
          </div>
        </AdminCard>
        <AdminCard title="Downloads">
          <AdminCardRow
            v-if="showAuthorityToAct"
            id="AuthorityToAct"
            type="button"
            label="Authority To Act"
            icon="download"
            @click="openPDF('AuthorityToAct')"
          />
          <AdminCardRow
            id="AuthorityToPost"
            type="button"
            label="Authority To Post"
            icon="download"
            @click="openPDF('AuthorityToPost')"
          />
          <AdminCardRow
            id="CremationServiceAgreement"
            type="button"
            label="Cremation Services Agreement"
            icon="download"
            @click="openPDF('CremationServiceAgreement')"
          />
          <AdminCardRow
            id="NSWApplicationForPermissionForCremation"
            type="button"
            label="NSW Application for Permission for Cremation"
            icon="download"
            @click="openPDF('NSWApplicationForPermissionForCremation')"
          />
          <AdminCardRow
            id="NSWDeathRegistrationStatement"
            type="button"
            label="NSW Death Registration Statement"
            icon="download"
            @click="openPDF('NSWDeathRegistrationStatement')"
          />
          <AdminCardRow
            v-if="showNswTransferAuthorityRegionalCoroner"
            id="nswTransferAuthorityRegionalCoroner"
            type="button"
            label="NSW Transfer Authority (Hospital & Regional Coroner)"
            icon="download"
            @click="openPDF('NSWTransferAuthorityHospitalAndRegionalCoroner')"
          />
          <AdminCardRow
            v-if="showNswTransferAuthorityMetroCoroner"
            id="nswTransferAuthorityMetroCoroner"
            type="button"
            label="NSW Transfer Authority (Lidcombe Coroner)"
            icon="download"
            @click="openPDF('NSWTransferAuthorityLidcombeCoroner')"
          />
          <AdminCardRow
            id="ReleaseOfAshes"
            type="button"
            label="Release of Ashes"
            icon="download"
            @click="openPDF('ReleaseOfAshes')"
          />
          <AdminCardRow
            v-if="showTransferAuthorityPrivate"
            id="transferAuthorityPrivate"
            type="button"
            label="Transfer Authority (Private)"
            icon="download"
            @click="openPDF('TransferAuthorityPrivate')"
          />
        </AdminCard>
        <AdminCard title="Initial documents">
          <AdminCardSection>
            <p :class="signatureClass[formData.authorityToActSignature]">
              Authority to act:
              {{ documentSignatureStatus(formData.authorityToActSignature) }}
            </p>
          </AdminCardSection>
          <AdminCardSection>
            <p
              :class="
                signatureClass[
                  formData.applicationForPermissionForCremationSignature
                ]
              "
            >
              Permission for cremation:
              {{
                documentSignatureStatus(
                  formData.applicationForPermissionForCremationSignature
                )
              }}
            </p>
          </AdminCardSection>
          <AdminCardSection>
            <p :class="signatureClass[formData.servicesAgreementSignature]">
              Services agreement:
              {{ documentSignatureStatus(formData.servicesAgreementSignature) }}
            </p>
          </AdminCardSection>
          <AdminCardSection>
            <p :class="signatureClass[formData.transferAuthoritySignature]">
              Transfer authority:
              {{ documentSignatureStatus(formData.transferAuthoritySignature) }}
            </p>
          </AdminCardSection>
        </AdminCard>
        <AdminCard title="Final documents">
          <AdminCardSection>
            <p :class="signatureClass[formData.authorityToPostSignature]">
              Authority to post:
              {{ documentSignatureStatus(formData.authorityToPostSignature) }}
            </p>
          </AdminCardSection>
          <AdminCardSection>
            <p :class="signatureClass[formData.authorityToReleaseSignature]">
              Authority to release:
              {{
                documentSignatureStatus(formData.authorityToReleaseSignature)
              }}
            </p>
          </AdminCardSection>
          <AdminCardSection>
            <p :class="signatureClass[formData.deathRegistrationSignature]">
              Death registration:
              {{ documentSignatureStatus(formData.deathRegistrationSignature) }}
            </p>
          </AdminCardSection>
        </AdminCard>
      </template>
    </AdminMainSideLayout>
    <Toast />
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';
import CremationsAdmins from '@/mixins/cremations-admins';

import dialog from '@/mixins/message-box';
import AdminBackLink from '@/components/admin/BackLink';
import AdminCard from '@/components/admin/Card';
import AdminCardRow from '@/components/admin/CardRow';
import AdminCardSection from '@/components/admin/CardSection';
import AdminMainSideLayout from '@/components/admin/MainSideLayout';
import Toast from '@/components/Toast';
import BaseButton from '@/components/BaseButton';
import BaseIcon from '@/components/BaseIcon';
import AdminCardRowWithUnknown from '@/components/molecules/AdminCardRowWithUnknown';
import AdminSimpleTable from '@/components/admin/SimpleTable';
import Alert from '@/components/molecules/Alert';

import REFRESH_LIFELINK_NOTIFICATION_STATUSES from '@/graphql/mutations/RefreshLifelinkStatuses';
import UPDATE_AT_NEED_CREMATION_REQUEST_METADATA from '@/graphql/mutations/UpdateAtNeedCremationRequestMetadata';
import LODGE_DRS_REQUEST_WITH_CERTIFICATE_LIFELINK from '@/graphql/mutations/LodgeDrsRequestWithCertificateInLifelink';
import { formatError } from '@/utilities';
import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateAdminCremationsId',
  components: {
    AdminBackLink,
    AdminCard,
    AdminCardRow,
    AdminCardSection,
    AdminMainSideLayout,
    Toast,
    BaseButton,
    BaseIcon,
    AdminCardRowWithUnknown,
    AdminSimpleTable,
    Alert,
  },
  mixins: [AtNeedCremations, CremationsAdmins, dialog],
  layout: 'admin',
  data() {
    return {
      signatureClass: {
        OFFLINE: 'text-success',
        UNSIGNED: 'text-warning',
      },
      informantEmail: null,
      formData: {
        notificationId: null,
        applicationId: null,
        notificationStatus: null,
        applicationStatus: null,
        informantUserId: null,
        informantFirstName: null,
        informantOtherGivenNames: null,
        informantLastName: null,
        informantPhone: null,
        informantSecondaryPhone: null,
        informantAddressLine1: null,
        informantAddressLine2: null,
        informantAddressSuburb: null,
        informantAddressState: null,
        informantAddressPostcode: null,
        informantRelationship: null,
        guestHasWill: null,
        informantIsWillExecutor: null,
        informantIsNextOfKin: null,
        executorOrKinFirstName: null,
        executorOrKinOtherGivenNames: null,
        executorOrKinLastName: null,
        executorOrKinRelationship: null,
        executorOrKinPhone: null,
        executorOrKinEmail: null,
        executorOrKinAddressLine1: null,
        executorOrKinAddressLine2: null,
        executorOrKinAddressSuburb: null,
        executorOrKinAddressState: null,
        executorOrKinAddressPostcode: null,
        executorOrKinAddressCountry: null,
        guestFirstName: null,
        guestOtherGivenNames: null,
        guestLastName: null,
        guestLastNameAtBirth: null,
        guestDateOfBirth: null,
        guestDateOfDeath: null,
        guestSex: null,
        guestAddressLine1: null,
        guestAddressLine2: null,
        guestAddressSuburb: null,
        guestAddressState: null,
        guestAddressPostcode: null,
        isReportableToCoroner: null,
        coronerLocation: null,
        placeOfDeathLocation: null,
        placeOfDeathFacilityName: null,
        placeOfDeathFacilityNameOther: null,
        placeOfDeathAddressLine1: null,
        placeOfDeathAddressLine2: null,
        placeOfDeathAddressSuburb: null,
        placeOfDeathAddressSuburbOther: null,
        placeOfDeathAddressState: null,
        placeOfDeathAddressPostcode: null,
        gpFirstName: null,
        gpLastName: null,
        gpAddressLine1: null,
        gpAddressLine2: null,
        gpAddressSuburb: null,
        gpAddressState: null,
        gpAddressPostcode: null,
        gpPhone: null,
        guestHasPacemaker: null,
        guestHasDefibrillator: null,
        guestHasDefibrillatorDrugInfusion: null,
        isAuthorizedForPrep: null,
        isRelativeInformed: null,
        hasRelativeExpressedObjection: null,
        relativeReasonForObjection: null,
        guestPlaceOfBirthSuburb: null,
        guestPlaceOfBirthState: null,
        guestPlaceOfBirthCountry: null,
        guestDateOfArrival: null,
        guestIndigenousOrigin: null,
        guestOccupation: null,
        guestIsRetiredAtDeath: null,
        guestIsPensionerAtDeath: null,
        guestPensionerType: null,
        guestParents: [],
        guestMaritalStatusAtDeath: null,
        guestPartners: [],
        guestChildren: [],
        postalAddressLine1: null,
        postalAddressLine2: null,
        postalAddressSuburb: null,
        postalAddressState: null,
        postalAddressPostcode: null,
        servicesAgreementSignature: null,
        transferAuthoritySignature: null,
        authorityToActSignature: null,
        deathRegistrationSignature: null,
        authorityToReleaseSignature: null,
        authorityToPostSignature: null,
        applicationForPermissionForCremationSignature: null,
      },
      metadata: {
        adminUserId: null,
        status: null,
        crematorium: null,
        transportProvider: null,
        transportFeesPriceOverride: null,
        disposedAt: null,
        submittedToLifelinkAt: null,
      },
      guestYearOfArrival: null,
      lifelinkError: null,
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    informantIdentifier() {
      if (this.formData.informantFirstName && this.formData.informantLastName) {
        return `${this.formData.informantFirstName} ${this.formData.informantLastName}`;
      }
      return this.informantEmail;
    },
    guestIdentifier() {
      if (this.formData.guestFirstName && this.formData.guestLastName) {
        return `${this.formData.guestFirstName} ${this.formData.guestLastName}`;
      }
      return null;
    },
    arrangerOptions() {
      return this.cremationsAdminAndHigher.map((admin) => {
        return {
          value: admin.id,
          text: admin.email,
        };
      });
    },
    isDrsLodgementDisabled() {
      if (this.formData.deathRegistrationSignature === 'UNSIGNED') {
        return true;
      }
      if (this.metadata.disposedAt === null) {
        return true;
      }
      if (!['NSW', 'ACT'].includes(this.formData.placeOfDeathAddressState)) {
        return true;
      }
      if (
        ['NURSING_HOME', 'PRIVATE_HOSPITAL', 'PUBLIC_HOSPITAL'].includes(
          this.formData.placeOfDeathLocation
        )
      ) {
        return (
          this.formData.placeOfDeathFacilityName === 'OTHER' ||
          this.formData.placeOfDeathAddressSuburb === 'OTHER'
        );
      }
      return false;
    },
  },
  watch: {
    loading() {
      if (this.formData.guestDateOfArrival && !this.guestYearOfArrival) {
        const year = String(
          new Date(Number(this.formData.guestDateOfArrival)).getFullYear()
        );
        this.guestYearOfArrival =
          year === String(this.UNKNOWN_YEAR) ? this.UNKNOWN_TEXT : year;
      }
    },
  },
  methods: {
    copyUserSignIn() {
      navigator.clipboard.writeText(this.informantEmail);
    },
    addChild() {
      if (!this.formData.guestChildren) {
        this.formData.guestChildren = [];
      }
      this.formData.guestChildren.push({ ...this.formFields.guestChildren });
    },
    removeChild(child) {
      this.formData.guestChildren.splice(child, 1);
      if (!this.formData.guestChildren.length) {
        this.formData.guestChildren = [];
      }
    },
    addPartner() {
      if (!this.formData.guestPartners) {
        this.formData.guestPartners = [];
      }
      this.formData.guestPartners.push({
        ...this.formFields.guestGuestPartners,
      });
    },
    removePartner(partner) {
      this.formData.guestPartners.splice(partner, 1);
      if (!this.formData.guestPartners.length) {
        this.formData.guestPartners = [];
      }
    },
    showMaritalDetails(partner) {
      return ['MARRIAGE', 'DIVORCE', 'WIDOW', 'WIDOWER'].includes(
        this.formData.guestPartners[partner].maritalStatus
      );
    },
    isDocumentSignedTextLegacy(value) {
      if (value === 'UNSIGNED') {
        return '';
      }
      return this.$t(
        this.DOCUMENT_SIGN_OPTIONS.find((option) => option.value === value)
          ?.text
      );
    },
    documentSignatureStatus(value) {
      if (['OFFLINE', 'UNSIGNED'].includes(value)) {
        return this.$t(
          this.DOCUMENT_SIGN_OPTIONS.find((option) => option.value === value)
            ?.text
        );
      }
      return value ? 'Yes' : 'No';
    },
    updateRequestMetadata(newValues) {
      return this.$apollo.mutate({
        mutation: UPDATE_AT_NEED_CREMATION_REQUEST_METADATA,
        variables: {
          cremationRequestMetadataInput: newValues,
          cremationRequestId: this.cremationRequestId,
        },
      });
    },
    async updateMetadata() {
      this.loading = true;
      try {
        // Input[type=date] returns a Date object
        // while api accepts date string like '2023-01-01' or '1673370000000'
        const disposedAt = Number.isSafeInteger(+this.metadata.disposedAt)
          ? new Date(+this.metadata.disposedAt).toISOString()
          : this.metadata.disposedAt;

        await this.updateRequestMetadata({
          transportFeesPriceOverride: this.metadata.transportFeesPriceOverride,
          crematorium: this.metadata.crematorium,
          transportProvider: this.metadata.transportProvider,
          status: this.metadata.status,
          adminUserId: this.metadata.adminUserId,
          disposedAt,
        });
        this.$nuxt.$emit('toast', {
          type: 'success',
          message: 'Changes saved',
        });
      } catch (error) {
        this.$nuxt.$emit('toast', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateDetailsAndDisplayResult() {
      if (this.showGuestDateOfArrival && this.guestYearOfArrival) {
        if (
          this.guestYearOfArrival === this.UNKNOWN_TEXT ||
          this.guestYearOfArrival === `${this.UNKNOWN_YEAR}-01-01`
        ) {
          this.formData.guestDateOfArrival = `${this.UNKNOWN_YEAR}-01-01`;
        } else {
          this.formData.guestDateOfArrival = `${this.guestYearOfArrival}-01-01`;
        }
      } else {
        this.formData.guestDateOfArrival = null;
      }

      const error = await this.saveUpdatedDetails();
      if (error) {
        this.$nuxt.$emit('toast', {
          type: 'error',
          message: formatError(error.message),
        });
        return;
      }
      this.$nuxt.$emit('toast', {
        type: 'success',
        message: 'Details Updated',
      });
    },
    async refreshLifelinkStatuses() {
      this.loading = true;
      try {
        const {
          data: { refreshLifelinkStatuses },
        } = await this.$apollo.mutate({
          mutation: REFRESH_LIFELINK_NOTIFICATION_STATUSES,
          variables: {
            cremationRequestId: this.cremationRequestId,
          },
        });
        this.formData = {
          ...this.formData,
          applicationStatus: refreshLifelinkStatuses.applicationStatus,
          notificationStatus: refreshLifelinkStatuses.notificationStatus,
        };
      } catch (error) {
        this.$nuxt.$emit('toast', {
          type: 'error',
          message: formatError(error.message),
        });
      }
      this.loading = false;
    },
    async lodgeDrsWithCertificate() {
      this.loading = true;
      this.lifelinkError = null;
      try {
        const {
          data: { lodgeDrsRequestWithCertificateInLifelink },
        } = await this.$apollo.mutate({
          mutation: LODGE_DRS_REQUEST_WITH_CERTIFICATE_LIFELINK,
          variables: {
            cremationRequestId: this.cremationRequestId,
          },
        });
        const {
          notificationId,
          applicationId,
          notificationStatus,
          applicationStatus,
          applicationMessage,
          submittedToLifelinkAt,
        } = lodgeDrsRequestWithCertificateInLifelink;
        this.formData = {
          ...this.formData,
          notificationId,
          applicationId,
          notificationStatus,
          applicationStatus,
        };
        this.metadata = {
          ...this.metadata,
          submittedToLifelinkAt,
        };
        this.$nuxt.$emit('toast', {
          type: 'success',
          message: applicationMessage,
        });
      } catch (error) {
        this.lifelinkError = formatError(error.message);
      }
      this.loading = false;
    },
    translateOptions(options) {
      return options.map((option) => ({
        ...option,
        text: this.$t(option.text),
      }));
    },
  },
};
</script>

<style scoped>
.jump-links {
  @apply flex flex-wrap py-2 sticky top-0 z-10 bg-grey-100 border-b border-grey-300 -mr-1 mb-6;
}

.jump-links a::first-letter {
  @apply font-normal;
}

.jump-links a {
  @apply bg-blue-200 py-1 pl-2 pr-3 rounded leading-tight text-white text-sm font-bold whitespace-no-wrap mr-1 mb-1;
}
</style>
