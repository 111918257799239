<template>
  <div class="ru:container ru:container">
    <div class="grid">
      <div class="cell text-success">
        <Icon id="feather" size="huge" />
      </div>
      <div class="cell cell__fill">
        <Headline :level="1" flush>
          {{ $t('pages.checkout.congratulations') }}
        </Headline>
      </div>
    </div>
    <p>
      {{ $t('pages.checkout.firstStep') }}
    </p>
    <p v-if="hasPurchasedLegalAdvice">
      {{ $t('pages.checkout.legalAdviceLink') }}
    </p>
    <p v-else>
      {{ $t('pages.checkout.willReview') }}
    </p>
    <p>
      {{ $t('pages.checkout.inTheMeantime') }}
    </p>
    <hr />
    <Headline :level="2">
      {{ $t('pages.checkout.nextSteps') }}
    </Headline>
    <ThankYou />
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';
import ThankYou from '@/components/templates/ThankYou';

import { user } from '@/mixins/apollo';

import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

export default {
  name: 'PagesAffiliateCheckoutWillThankYou',
  components: {
    Icon,
    Headline,
    ThankYou,
  },
  mixins: [user],
  middleware: ['hasPurchasedWill'],
  data() {
    return {
      tabs: null,
    };
  },
  apollo: {
    purchasedProducts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update(data) {
        return data.getPurchasedProducts.products || [];
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    hasPurchasedLegalAdvice() {
      return (this.purchasedProducts || []).includes('LEGAL_ADVICE');
    },
  },
  mounted() {
    this.$updateShouldShowCharityConsent();
  },
};
</script>
