<template>
  <div class="ru:notices">
    <AdminMasquerade v-if="masquerading" />
    <OfflineAlert v-if="$nuxt.isOffline" />
    <MaintenanceAlert v-if="showMaintenanceAlert" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminMasquerade from '@/components/snowflakes/MasqueradeAlert';
import MaintenanceAlert from '@/components/snowflakes/MaintenanceAlert';
import OfflineAlert from '@/components/snowflakes/OfflineAlert';

export default {
  name: 'ComponentsOrganismsNotices',
  components: {
    AdminMasquerade,
    MaintenanceAlert,
    OfflineAlert,
  },
  data() {
    return {
      showMaintenanceAlert: false,
    };
  },
  computed: {
    ...mapGetters(['masquerading']),
  },
};
</script>

<style lang="scss">
#{$ru} {
  &notices {
    --base-margin: 0;
    --border-radius-small: 0;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0 -15px 40px 0 --rgba(glacier, 0.125);
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background: --rgba(white);
    gap: 1px;
    padding-top: 1px;
    z-index: $z-notices;
    text-align: center;

    @include mq('min-lg') {
      padding-left: css-max(
        calc(var(--navigation-visible) * var(--navigation-width-collapsed)),
        calc(
          var(--navigation-visible) * (1 - var(--is-desktop-menu-collapsed)) *
            var(--navigation-width)
        )
      );
    }
  }
}
</style>
