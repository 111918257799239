var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.previous}},[_c('AdiForm',{attrs:{"hide-submit":!_vm.isComplete,"submit-icon":"arrow-right"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('FormSection',[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.financial.substitutes.headline', {
            substitute: _vm.canHaveMultipleSubstitutes
              ? _vm.$t('texts.sentences.substituteDecisionMakers')
              : _vm.$t('texts.sentences.aSubstituteDecisionMaker'),
          }))+"\n      ")]),_vm._v(" "),(['nsw'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.poa.financial.substitutes.stateDescriptionNSW'))+"\n        ")])]:(['vic', 'wa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.poa.financial.substitutes.stateDescriptionVICWA'))+"\n        ")]),_vm._v(" "),(['wa'].includes(_vm.userState))?_c('Tip',[_vm._v("\n          "+_vm._s(_vm.$t('pages.poa.financial.substitutes.stateDescriptionWA'))+"\n        ")]):_vm._e()]:(['qld', 'act', 'nt'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.poa.financial.substitutes.stateDescriptionQLDACTNT', {
              marker: _vm.canHaveMultipleSubstitutes ? 'makers' : 'maker',
            }))+"\n        ")])]:_vm._e(),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_substitute","layout":"column","options":[
          {
            label: _vm.$t('pages.poa.financial.substitutes.options.yes', {
              substitute: _vm.canHaveMultipleSubstitutes
                ? _vm.$t('texts.sentences.substituteDecisionMakers')
                : _vm.$t('texts.sentences.aSubstituteDecisionMaker'),
            }),
            value: true,
          },
          {
            label: _vm.$t('pages.poa.financial.substitutes.options.no', {
              substitute: _vm.canHaveMultipleSubstitutes
                ? _vm.$t('texts.sentences.anySubstituteDecisionMakers')
                : _vm.$t('texts.sentences.aSubstituteDecisionMaker'),
            }),
            value: false,
          },
        ]},on:{"input":_vm.onSubmit},model:{value:(_vm.poaFinancialMeta.has_substitute),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_substitute", $$v)},expression:"poaFinancialMeta.has_substitute"}})],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }