<template>
  <div class="ru:signature-pad__wrapper">
    <div class="ru:signature-pad" :style="styles">
      <VueSignaturePad
        id="signature"
        ref="signaturePad"
        width="100%"
        :options="options"
      ></VueSignaturePad>
    </div>
    <button v-if="!disabled" class="ru:signature-pad__clear" @click="clear">
      {{ $t('components.signaturePad.reset') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsSignaturePad',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      options: {
        penColor: '#212121',
        onEnd: this.sendSignatureChangedEvent,
      },
    };
  },
  computed: {
    cursorStyle() {
      if (this.disabled) {
        return 'not-allowed';
      }
      return null;
    },
    styles() {
      return {
        '--cursor': this.cursorStyle,
        '--is-disabled': this.disabled ? 'block' : 'none',
      };
    },
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.$emit('signature-changed', null);
    },
    sendSignatureChangedEvent() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit('signature-changed', data);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &signature-pad {
    position: relative;
    height: #{calc(17rem * var(--size-modifier))};
    border: 1px solid #ccc;
    border: 2px dashed --rgba(rock);
    background: white;
    cursor: var(--cursor, pen);

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &::before {
      display: var(--is-disabled);
      transition: color calc(var(--transition-duration-n) * 2ms)
        var(--transition-timing-function);
      position: absolute;
      content: '';
      inset: 0;
      background-image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 10px,
        --rgba(iron) 10px,
        --rgba(iron) 20px,
        transparent 20px
      );
    }

    &__clear {
      align-self: flex-end;
      text-decoration: underline;
    }
  }
}
</style>
