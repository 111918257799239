<template>
  <ModuleStep :index="2" :prev-step="previous">
    <AdiForm
      submit-icon="arrow-right"
      :hide-submit="!isComplete"
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.poa.financial.primaryDecisions.headline') }}
        </Headline>
        <p class="mb-4">
          {{ $t('pages.poa.financial.primaryDecisions.jointlyDesc') }}
        </p>
        <p class="mb-4">
          {{ $t('pages.poa.financial.primaryDecisions.severallyDesc') }}
        </p>
        <template v-if="['vic', 'qld', 'act'].includes(userState)">
          <p class="mb-4">
            {{
              $t('pages.poa.financial.primaryDecisions.affectOtherAttorneys')
            }}
          </p>
          <Tip>
            <p class="mb-3">
              {{ $t('pages.poa.financial.primaryDecisions.prosCons') }}
            </p>
            <p class="mb-3">
              {{ $t('pages.poa.financial.primaryDecisions.jointlyPros') }}
            </p>
            <p class="mb-3">
              {{ $t('pages.poa.financial.primaryDecisions.severallyPros') }}
            </p>
          </Tip>
        </template>
        <template v-else-if="['tas'].includes(userState)">
          <Tip>
            <p class="mb-3">
              {{ $t('pages.poa.financial.primaryDecisions.prosCons') }}
            </p>
            <p class="mb-3">
              {{ $t('pages.poa.financial.primaryDecisions.jointlyPros') }}
            </p>
            <p class="mb-3">
              {{ $t('pages.poa.financial.primaryDecisions.severallyPros') }}
            </p>
          </Tip>
        </template>
        <template v-else-if="['nsw', 'wa', 'sa', 'nt'].includes(userState)">
          <Tip>
            <p class="mb-3">
              {{
                $t('pages.poa.financial.primaryDecisions.jointlyProsNswWaSaNt')
              }}
            </p>
          </Tip>
        </template>
        <FormRow class="flex-wrap">
          <LargeRadioButtons
            id="financial_attorney_decisions"
            v-model="poaFinancialMeta.attorney_decisions"
            layout="column"
            :options="[
              {
                label: $t(
                  'pages.poa.financial.primaryDecisions.attorneyDecisions.jointly'
                ),
                value: 'jointly',
              },
              {
                label: $t(
                  'pages.poa.financial.primaryDecisions.attorneyDecisions.jointlyAndSeverally'
                ),
                value: 'jointly and severally',
              },
            ]"
          />
        </FormRow>
      </FormSection>
      <FormSection v-if="eligibleForOtherDecision">
        <Headline :level="2">
          {{
            $t(
              'pages.poa.financial.primaryDecisions.jointDMDeathLosesCapacityHeadline'
            )
          }}
        </Headline>
        <Tip>
          {{ $t('pages.poa.financial.primaryDecisions.exampleUnwellOrDies') }}
        </Tip>
        <FormRow class="flex-wrap">
          <LargeRadioButtons
            id="financial_attorney_decisions_other"
            v-model="poaFinancialMeta.attorney_decisions_other"
            layout="column"
            :options="[
              {
                label: $t(
                  'pages.poa.financial.primaryDecisions.financialAttorneyDecisionsOtherOptions.other'
                ),
                value: 'other',
              },
              {
                label: $t(
                  'pages.poa.financial.primaryDecisions.financialAttorneyDecisionsOtherOptions.none'
                ),
                value: 'none',
              },
            ]"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import Tip from '@/components/molecules/Tip';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import FormSection from '@/components/FormSection';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialPrimaryDecisions',
  components: {
    Tip,
    AdiForm,
    FormRow,
    LargeRadioButtons,
    ModuleStep,
    FormSection,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        attorney_decisions: undefined,
        attorney_decisions_other: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      const completedMainQuestion =
        this.poaFinancialMeta && this.poaFinancialMeta.attorney_decisions;
      const completedOtherQuestion =
        !this.eligibleForOtherDecision ||
        this.poaFinancialMeta.attorney_decisions_other;
      return completedMainQuestion && completedOtherQuestion;
    },
    eligibleForOtherDecision() {
      return (
        ['nsw', 'sa', 'nt'].includes(this.userState) &&
        this.poaFinancialMeta.attorney_decisions === 'jointly'
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'attorney_decisions',
            value: this.poaFinancialMeta.attorney_decisions,
            category: 'FINANCIAL',
          },
          {
            key: 'attorney_decisions_other',
            value: this.poaFinancialMeta.attorney_decisions_other,
            category: 'FINANCIAL',
          },
        ])
      );

      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
</script>
