<template>
  <BaseModal v-if="showAddUserModal" @close="close">
    <h2 class="mb-4 text-4xl leading-tight">
      {{ $t('components.adminNewUserModal.title') }}
    </h2>
    <ValidationObserver ref="observer">
      <AdminCardRow
        id="email"
        v-model="user.email"
        type="text"
        :label="$t('components.adminNewUserModal.emailInput.label')"
        rules="required"
        :disabled="isLoading"
      />
      <AdminCardRow
        id="firstName"
        v-model="user.firstName"
        type="text"
        :label="$t('components.adminNewUserModal.firstNameInput.label')"
        rules="required"
        :disabled="isLoading"
      />
      <AdminCardRow
        id="lastName"
        v-model="user.lastName"
        type="text"
        :label="$t('components.adminNewUserModal.lastNameInput.label')"
        rules="required"
        :disabled="isLoading"
      />
      <AdminCardRow
        id="role"
        v-model="user.role"
        type="select"
        :label="$t('components.adminNewUserModal.role.label')"
        rules="required"
        :disabled="isLoading"
        :options="getSubordinateRoleOptionsByRole(role)"
      />
    </ValidationObserver>
    <Buttons layout="stretch">
      <RuButton :disabled="isLoading" @click="submit"> Save </RuButton>
    </Buttons>
    <Alert v-if="error" theme="error" level="medium">
      {{ error }}
    </Alert>
  </BaseModal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { mapGetters } from 'vuex';
import CREATE_USER_MUTATION from '@/graphql/mutations/CreateUser';
import AdminCardRow from '@/components/admin/CardRow';
import RuButton from '@/components/atoms/Button';
import Alert from '@/components/molecules/Alert';
import Buttons from '@/components/atoms/Buttons';

import BaseModal from '@/components/BaseModal';
import { formatError, getSubordinateRoleOptionsByRole } from '@/utilities';

export default {
  name: 'ComponentsAdminUserModal',
  components: {
    BaseModal,
    AdminCardRow,
    ValidationObserver,
    RuButton,
    Alert,
    Buttons,
  },
  props: {
    showAddUserModal: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    user: {
      email: '',
      firstName: '',
      lastName: '',
      role: '',
    },
    isLoading: false,
    error: null,
  }),
  computed: {
    ...mapGetters(['role']),
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    async submit() {
      this.error = null;
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (!isValid) {
        return;
      }
      try {
        this.isLoading = true;
        const response = await this.$apollo.mutate({
          mutation: CREATE_USER_MUTATION,
          variables: {
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            role: this.user.role,
          },
        });

        const createUser = { ...response.data.createUser };
        if (createUser.success) {
          this.$emit('done', createUser.user.id);
        }
      } catch (e) {
        this.error = formatError(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.user = {};
      this.$refs.observer.reset();
      this.$emit('setShowAddUserModal', false);
    },
  },
};
</script>
