<template>
  <Panel v-if="showGlossaryPanel" title="Glossary" @close="close">
    <div
      v-for="(term, index) in sortedTerms"
      :key="index"
      :class="{ 'mt-12': index > 0 }"
    >
      <h4 class="mb-4 text-2xl leading-none">
        {{ $t('glossary')[term].name }}
      </h4>
      <p class="text-charcoal-200 opacity-75">
        {{ $t('glossary')[term].desc }}
      </p>
    </div>
  </Panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Panel from '@/components/organisms/Panel';

export default {
  name: 'ComponentsGlossaryPanel',
  components: {
    Panel,
  },
  computed: {
    ...mapGetters('glossary', ['showGlossaryPanel']),
    sortedTerms() {
      return Object.keys(this.$t('glossary')).sort();
    },
  },
  methods: {
    ...mapActions('glossary', ['setShowGlossaryPanel']),
    close() {
      this.setShowGlossaryPanel(false);
    },
  },
};
</script>
