<template>
  <client-only>
    <div>
      <BaseCard v-if="subscription" primary-text="Unlimited Will Updates">
        <BaseAlert :type="statusType">
          <Chip :label="subscriptionStatus" :type="statusType" />
          <div class="ml-3 font-medium opacity-75">
            <p>{{ statusText }}</p>
          </div>
        </BaseAlert>
        <p v-if="hasValidSubscription" class="py-6 opacity-50 text-black">
          {{ renewalText }}
        </p>
        <div v-if="hasValidSubscription" class="flex">
          <BaseButton
            v-if="!isCancelled"
            class="btn btn-md btn-outline btn-focus mr-3"
            @click="updateCardDetails"
          >
            {{ $t('components.subscriptionStatusCard.updateCreditCard') }}
          </BaseButton>
          <BaseButton
            v-if="isCancelled"
            class="btn btn-md btn-outline btn-focus"
            :loading="$apollo.loading"
            @click="enableAutoRenew"
          >
            {{ $t('components.subscriptionStatusCard.enableAutoRenew') }}
          </BaseButton>
          <BaseButton
            v-else
            class="btn btn-md btn-outline btn-focus"
            :loading="$apollo.loading"
            @click="disableAutoRenew"
          >
            {{ $t('components.subscriptionStatusCard.disableAutoRenew') }}
          </BaseButton>
        </div>
        <div v-else class="py-6">
          <BaseButton
            class="btn btn-md btn-outline btn-focus"
            :loading="$apollo.loading"
            @click="unlockAccount"
          >
            {{ $t('components.subscriptionStatusCard.unlockAccount') }}
          </BaseButton>
        </div>
      </BaseCard>
      <EmptyState v-if="!subscription">
        {{ $t('components.subscriptionStatusCard.emptyStateMessage') }}
      </EmptyState>
    </div>
  </client-only>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import BaseCard from '@/components/BaseCard';
import BaseAlert from '@/components/BaseAlert';
import Chip from '@/components/Chip';
import EmptyState from '@/components/EmptyState';
import SET_SUBSCRIPTION_AUTO_RENEW from '@/graphql/mutations/SetSubscriptionAutoRenew';

import { subscription, user } from '@/mixins/apollo';
import { PRICE_DEFAULTS } from '@/store/checkout';

export default {
  name: 'ComponentsSubscriptionStatusCard',
  components: {
    BaseButton,
    BaseCard,
    BaseAlert,
    Chip,
    EmptyState,
  },
  mixins: [subscription, user],
  computed: {
    isCancelled() {
      return !this.subscription.autoRenew;
    },
    statusType() {
      return this.hasValidSubscription ? 'confirm' : 'error';
    },
    renewalText() {
      return this.subscription.autoRenew
        ? this.$t('components.subscriptionStatusCard.renewText.isAutoRenew', {
            price: PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE,
            renewalDate: this.subscriptionRenewalDate,
          })
        : this.$t(
            'components.subscriptionStatusCard.renewText.isNotAutoRenew',
            {
              price: PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE,
            }
          );
    },
    subscriptionRenewalDate() {
      const date = new Date(Number(this.subscription.expiresAt));

      date.setDate(date.getDate() + 1);

      return this.$formatDate(date, 'dS mmmm yyyy');
    },
    subscriptionStatus() {
      return this.hasValidSubscription ? 'Active' : 'Inactive';
    },
    statusText() {
      return this.hasValidSubscription
        ? this.$t(
            'components.subscriptionStatusCard.statusText.hasValidSubscription',
            {
              date: this.subscriptionRenewalDate,
            }
          )
        : this.$t(
            'components.subscriptionStatusCard.statusText.hasExpiredSubscription'
          );
    },
  },
  methods: {
    updateCardDetails() {
      this.$router.push({ path: this.localePath(`/checkout/update-card`) });
    },
    unlockAccount() {
      this.$router.push({ path: this.localePath(`/checkout/unlock`) });
    },
    enableAutoRenew() {
      if (this.hasValidSubscription && this.hasValidPaymentMethod) {
        this.setAutoRenew(true);
      } else {
        this.updateCardDetails();
      }
    },
    disableAutoRenew() {
      this.setAutoRenew(false);
    },
    async setAutoRenew(autoRenew) {
      await this.$apollo.mutate({
        mutation: SET_SUBSCRIPTION_AUTO_RENEW,
        variables: {
          userId: this.userId,
          autoRenew,
        },
      });

      if (autoRenew) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'enable-subscription-auto-renew-frontend',
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'disable-subscription-auto-renew-frontend',
        });
      }
    },
  },
};
</script>
