<template>
  <BaseModal v-if="showFlaggedWillAttentionModal" :is-dismissable="false">
    <h4 class="mb-4 text-3xl">
      {{ $t('components.flaggedWillAttentionModal.title') }}
    </h4>
    <div class="text-xl text-charcoal-200">
      <p>
        {{ $t('components.flaggedWillAttentionModal.description') }}
      </p>
    </div>
    <div class="flex items-baseline mt-8">
      <BaseButton class="mr-6 btn-xl btn-slate" @click="editMyWill">
        <div class="flex items-center">
          <span class="text-white">{{
            $t('components.flaggedWillAttentionModal.edit')
          }}</span>
        </div>
      </BaseButton>
      <BaseButton class="btn-xl btn-slate" @click="leaveAndDownload">
        <div class="flex items-center">
          <span class="text-white">{{
            $t('components.flaggedWillAttentionModal.download')
          }}</span>
        </div>
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UPDATE_WILL_STATUS_MUTATION from '@/graphql/mutations/UpdateWillStatus';

import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';

import { will } from '@/mixins/apollo';
import { formatError } from '@/utilities';

export default {
  name: 'ComponentsFlaggedWillAttentionModal',
  components: {
    BaseButton,
    BaseModal,
  },
  mixins: [will],
  data() {
    return {
      isWorking: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['showFlaggedWillAttentionModal']),
  },
  watch: {
    will() {
      this.checkConditionsAndDisplayModal();
    },
  },
  methods: {
    ...mapActions('ui', ['setShowFlaggedWillAttentionModal']),
    async setWillStatusToApproved() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_WILL_STATUS_MUTATION,
          variables: {
            id: this.willId,
            status: 'APPROVED',
          },
        })
        .catch((e) => {
          console.error(e);
          this.$nuxt.$emit('toast', {
            type: 'error',
            message: formatError(e.message),
          });
        });
    },
    async leaveAndDownload() {
      if (!this.isWorking) {
        this.isWorking = true;

        await this.setWillStatusToApproved();
        this.setShowFlaggedWillAttentionModal(false);
      }
    },
    editMyWill() {
      this.setShowFlaggedWillAttentionModal(false);
    },
    checkConditionsAndDisplayModal() {
      if (['FLAGGED'].includes(this.will.status)) {
        this.setShowFlaggedWillAttentionModal(true);
      }
    },
  },
};
</script>
