<template>
  <ul class="ru:steps">
    <li v-for="(step, i) in steps" :key="i" class="ru:steps__step">
      <ProgressCircle
        :progress="step.progress"
        class="ru:steps__progress-circle"
      />
      <span class="ru:steps__step-text">
        {{ step.text }}
      </span>
    </li>
  </ul>
</template>

<script>
import ProgressCircle from '@/components/atoms/ProgressCircle';
export default {
  name: 'ComponentsMoleculesSteps',
  components: {
    ProgressCircle,
  },
  props: {
    steps: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  & &steps {
    margin-bottom: 0;
  }

  &steps {
    &__progress-circle {
      width: 2.25rem;
      margin-right: 1rem;
      position: relative;
      flex-shrink: 0;
    }

    &__step {
      display: flex;
      align-items: flex-start;
      font-size: 1.125rem;
      position: relative;

      &:not(:last-of-type) {
        padding-bottom: 1.875rem;

        &::before {
          display: block;
          content: '';
          top: 0;
          bottom: 0;
          left: calc(2.25rem / 2);
          width: 2px;
          position: absolute;
          background-image: linear-gradient(
            to bottom,
            --rgba(aluminium) 4px,
            --rgba(white) 4px
          );
          background-size: 2px 8px;
        }
      }

      &-text {
        padding-top: 0.25rem;
      }
    }
  }
}
</style>
