<template>
  <ModuleStep :index="4" :prev-step="prevStep">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-icon="arrow-right"
      :submit-label="buttonText"
      @done="onDone"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.funeral.otherRequests') }}
        </Headline>
        <p>
          {{ $t('pages.will.funeral.dayWishes') }}
        </p>
        <Tip>
          {{ $t('pages.will.funeral.suggestions') }}
        </Tip>
        <TextArea
          id="funeral_notes"
          v-model="willMeta.funeral_note"
          :disabled="loading"
          maxlength="2500"
          :placeholder="$t('forms.placeholders.iWouldLike')"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import TextArea from '@/components/TextArea';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralOtherRequests',
  components: {
    AdiForm,
    FormSection,
    ModuleStep,
    TextArea,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    buttonText() {
      return this.willMeta.funeral_note
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
    prevStep() {
      return ['cremation', 'burial'].includes(this.willMeta.funeral_type)
        ? '/will/funeral/location'
        : '/will/funeral/type';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 7 (Funeral)',
      });

      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
