<template>
  <BaseModal v-if="show" @close="close">
    <h2 class="mb-4 text-4xl leading-tight">
      {{ modalTitle }}
    </h2>
    <AdiForm
      v-if="!duplicatingWill"
      ref="form"
      submit-label="Duplicate will"
      @submit="onSubmit"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="email"
            v-model="user.email"
            :label="$t('components.adminPartnerModal.emailInput.label')"
            :placeholder="
              $t('components.adminPartnerModal.emailInput.placeholder')
            "
            rules="required"
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormRow>
          <TextInput
            id="first-name"
            v-model="user.firstName"
            :label="$t('components.adminPartnerModal.firstNameInput.label')"
            :placeholder="
              $t('components.adminPartnerModal.firstNameInput.placeholder')
            "
            rules="required"
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="middle-name"
            v-model="user.middleName"
            :placeholder="
              $t('components.adminPartnerModal.middleNameInput.placeholder')
            "
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="last-name"
            v-model="user.lastName"
            :placeholder="
              $t('components.adminPartnerModal.lastNameInput.placeholder')
            "
            rules="required"
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormRow>
          <DateInput
            id="dob"
            v-model="user.dob"
            :label="$t('components.adminPartnerModal.dateOfBirthInput.label')"
            rules="required|date"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
    <Alert v-else-if="errors.length" theme="error" level="major">
      <p v-for="(error, index) in errors" :key="`error-${index}`">
        {{ error }}
      </p>
    </Alert>
    <Alert
      v-else
      :theme="duplicatingSuccessful ? 'success' : 'info'"
      level="major"
    >
      <p>{{ duplicatingStep }}</p>
    </Alert>
  </BaseModal>
</template>

<script>
import { isValidEmail, age, objectToMetaArray } from '@/utilities';

import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import DateInput from '@/components/DateInput';
import TextInput from '@/components/TextInput';
import Alert from '@/components/molecules/Alert';

import ADD_PET_MUTATION from '@/graphql/mutations/AddPet';
import ADD_PET_GUARDIAN_MUTATION from '@/graphql/mutations/AddPetGuardian';
import ADD_GIFT_MUTATION from '@/graphql/mutations/AddGift';
import CREATE_USER_MUTATION from '@/graphql/mutations/CreateUser';
import UPDATE_WILL_MUTATION from '@/graphql/mutations/UpdateWill';
import ADD_ASSET_MUTATION from '@/graphql/mutations/AddAsset';
import ADD_PERSON_MUTATION from '@/graphql/mutations/AddPerson';
import ADD_CHARITY_MUTATION from '@/graphql/mutations/AddCharity';
import ADD_EXECUTOR_MUTATION from '@/graphql/mutations/AddExecutor';
import ADD_GUARDIAN_MUTATION from '@/graphql/mutations/AddGuardian';
import ADD_BENEFICIARY_MUTATION from '@/graphql/mutations/AddBeneficiary';
import UNIQUE_EMAIL_QUERY from '@/graphql/queries/UniqueEmail';
import WILL_BY_EMAIL_QUERY from '@/graphql/queries/GetWillByEmail';

export default {
  name: 'ComponentsAdminPartnerModal',
  components: {
    BaseModal,
    AdiForm,
    FormRow,
    FormSection,
    DateInput,
    TextInput,
    Alert,
  },
  props: {
    showModal: {
      type: Boolean,
    },
    willData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: this.showModal,
      user: {
        email: this.willData.user.email.trim(),
        password: 'Password123',
        firstName: '',
        middleName: null,
        lastName: '',
        dob: '',
      },
      people: [],
      charities: [],
      emailIsUnique: true,
      modalTitle: 'Partner details',
      duplicatingSuccessful: false,
      duplicatingWill: false,
      duplicatingStep: '',
      errors: [],
      errorMessages: {
        addUser: this.$t('components.adminPartnerModal.errorMessages.addUser'),
        addPerson: this.$t(
          'components.adminPartnerModal.errorMessages.addPerson'
        ),
        getWillByEmail: this.$t(
          'components.adminPartnerModal.errorMessages.getWillByEmail'
        ),
      },
    };
  },
  watch: {
    'user.email'(value) {
      if (value && isValidEmail(value)) {
        this.checkEmailIsUnique(value);
      }
    },
    emailIsUnique(isUnique) {
      if (!isUnique) {
        this.$refs.form.$refs.observer.setErrors({
          email: [this.$t('components.adminPartnerModal.emailAlreadyTaken')],
        });
      }
    },
  },
  mounted() {
    this.setName();
    this.checkEmailIsUnique(this.user.email);
  },
  methods: {
    close() {
      this.$emit('close:partnerModal');
    },
    setName() {
      if (this.willData.user && this.willData.user.fullName) {
        const nameList = this.willData.user.fullName.trim().split(' ');
        this.user.firstName = nameList.shift();

        // 1 item left then use it as last name
        // if more than 1 item then get middle name
        if (nameList.length > 1) {
          // get middle name and join rest of items as last name
          [this.user.middleName, ...this.user.lastName] = nameList;
          this.user.lastName = this.user.lastName.join(' ');
        } else {
          this.user.lastName = nameList.pop();
        }
      }
    },
    getWillMetaData() {
      const meta = [];

      // Copy all data and replace partner's data with user's data
      Object.entries(this.willData.willMeta).forEach(([key, value]) => {
        if (value) {
          switch (key) {
            case 'name_first':
              meta.push({ key, value: this.user.firstName });
              break;
            case 'name_middle':
              if (this.user.middleName) {
                meta.push({ key, value: this.user.middleName });
              }
              break;
            case 'name_last':
              meta.push({ key, value: this.user.lastName });
              break;
            case 'date_of_birth':
              meta.push({ key, value: this.user.dob });
              break;
            case 'is_adult':
              meta.push({ key, value: (age(this.user.dob) >= 18).toString() });
              break;
            case 'has_alt_name':
              // remove alt name since we have no access to it
              meta.push({ key, value: 'false' });
              break;
            case 'alt_name_first':
            case 'alt_name_middle':
            case 'alt_name_last':
              // skip adding alt name since we have no access to it
              break;
            default:
              meta.push({ key, value: value.toString() });
          }
        }
      });

      return meta;
    },
    getPartnerMetaData(person) {
      // create partner full name
      let partnerName = this.willData.willMeta.name_first.trim();

      if (this.willData.willMeta.name_middle) {
        partnerName = `${partnerName} ${this.willData.willMeta.name_middle.trim()}`;
      }

      partnerName = `${partnerName} ${this.willData.willMeta.name_last.trim()}`;

      // partner object
      const meta = [
        {
          key: 'full_name',
          value: partnerName,
        },
        {
          key: 'email',
          value: this.willData.will.email,
        },
        {
          key: 'is_over_18',
          value: (age(this.willData.willMeta.date_of_birth) >= 18).toString(),
        },
      ];

      // add data and skip data already in partner object
      person.meta.forEach((item) => {
        if (
          item.key !== 'full_name' &&
          item.key !== 'email' &&
          item.key !== 'is_over_18'
        ) {
          meta.push({
            key: item.key,
            value: item.value,
          });
        }
      });

      return meta;
    },
    getMetaData(metaList) {
      const meta = [];

      metaList.forEach((item) => {
        meta.push({
          key: item.key,
          value: item.value,
        });
      });

      return meta;
    },
    getPeopleData() {
      const people = [];

      this.willData.people.forEach((person) => {
        let meta = [];

        // get metadata
        if (person.category === 'partner') {
          meta = this.getPartnerMetaData(person);
        } else {
          meta = this.getMetaData(person.meta);
        }

        // Add a reference to the person id, so we
        // can use it to copy gifts, executors, etc.
        people.push({
          category: person.category,
          meta,
          refs: {
            prevId: person.id,
          },
        });
      });

      return people;
    },
    getPetsData() {
      const pets = [];

      this.willData.pets.forEach((pet) => {
        if (!pet.person) {
          pets.push({
            meta: this.getMetaData(pet.meta),
          });

          return;
        }

        // find person
        const person = this.people.find(
          (item) => item.refs.prevId === pet.person.id
        );

        pets.push({
          meta: this.getMetaData(pet.meta),
          guardian: { personId: person.id },
        });
      });

      return pets;
    },
    getAssetsData() {
      const assets = [];

      this.willData.assets.forEach((asset) => {
        assets.push({ meta: this.getMetaData(asset.meta) });
      });

      return assets;
    },
    getCharitiesData() {
      const charities = [];

      this.willData.charities.forEach((charity) => {
        charities.push({
          meta: this.getMetaData(charity.meta),
          refs: {
            prevId: charity.id,
          },
        });
      });

      return charities;
    },
    getExecutorsData() {
      const executors = [];

      this.willData.executors.forEach((executor) => {
        // find person
        const person = this.people.find(
          (item) => item.refs.prevId === executor.person.id
        );

        executors.push({
          type: executor.type,
          person: person.id,
          meta: this.getMetaData(executor.meta),
        });
      });

      return executors;
    },
    getBeneficiaryData(beneficiary) {
      const type = beneficiary.type.toLowerCase();
      const data = {
        type: beneficiary.type,
        distribution: beneficiary.distribution,
        meta: this.getMetaData(objectToMetaArray(beneficiary.meta)),
      };

      // add charity or person
      if (beneficiary.charity) {
        const charity = this.charities.find(
          (item) => item.refs.prevId === beneficiary.charity.id
        );
        data.charity = charity.id;
      } else if (beneficiary.person) {
        const person = this.people.find(
          (item) => item.refs.prevId === beneficiary.person.id
        );
        data.person = person.id;
      }

      // add backups
      if (type === 'primary') {
        data.backups = [];
        beneficiary.backup.forEach((backup) => {
          data.backups.push(this.getBeneficiaryData(backup));
        });
      }

      return data;
    },
    getBeneficiariesData() {
      const beneficiaries = [];

      this.willData.beneficiaries.forEach((beneficiary) => {
        beneficiaries.push(this.getBeneficiaryData(beneficiary));
      });

      return beneficiaries;
    },
    getGiftsData() {
      const gifts = [];

      this.willData.gifts.forEach((gift) => {
        const data = { meta: this.getMetaData(gift.meta) };

        // add charity or person
        if (gift.charity) {
          const charity = this.charities.find(
            (item) => item.refs.prevId === gift.charity.id
          );
          data.charity = charity.id;
        } else if (gift.person) {
          const person = this.people.find(
            (item) => item.refs.prevId === gift.person.id
          );
          data.person = person.id;
        }

        // add data
        gifts.push(data);
      });

      return gifts;
    },
    getGuardiansData() {
      const guardians = [];

      this.willData.guardians.forEach((guardian) => {
        // find person
        const person = this.people.find(
          (item) => item.refs.prevId === guardian.person.id
        );

        guardians.push({
          type: guardian.type,
          person: person.id,
          meta: this.getMetaData(guardian.meta),
        });
      });

      return guardians;
    },
    async checkEmailIsUnique(email) {
      try {
        const response = await this.$apollo.query({
          query: UNIQUE_EMAIL_QUERY,
          variables: { email },
        });

        this.emailIsUnique = response.data.uniqueEmail.isUnique;
      } catch (e) {
        console.error(e.message);
      }
    },
    async duplicateData(mutationKey, variables) {
      let data = { success: false };
      const mutations = {
        asset: {
          name: 'addAsset',
          mutation: ADD_ASSET_MUTATION,
        },
        beneficiary: {
          name: 'addBeneficiary',
          mutation: ADD_BENEFICIARY_MUTATION,
        },
        charity: {
          name: 'addCharity',
          mutation: ADD_CHARITY_MUTATION,
        },
        executor: {
          name: 'addExecutor',
          mutation: ADD_EXECUTOR_MUTATION,
        },
        gift: {
          name: 'addGift',
          mutation: ADD_GIFT_MUTATION,
        },
        guardian: {
          name: 'addGuardian',
          mutation: ADD_GUARDIAN_MUTATION,
        },
        person: {
          name: 'addPerson',
          mutation: ADD_PERSON_MUTATION,
        },
        pet: {
          name: 'addPet',
          mutation: ADD_PET_MUTATION,
        },
        petGuardian: {
          name: 'addPetGuardian',
          mutation: ADD_PET_GUARDIAN_MUTATION,
        },
        user: {
          name: 'createUser',
          mutation: CREATE_USER_MUTATION,
        },
        will: {
          name: 'updateWill',
          mutation: UPDATE_WILL_MUTATION,
        },
      };
      const mutation = mutations[mutationKey].mutation;
      const mutationName = mutations[mutationKey].name;

      try {
        const response = await this.$apollo.mutate({ mutation, variables });
        data = response.data[mutationName];

        // show error if no success
        if (!data.success) {
          this.errors.push(data.message);
        }
      } catch (e) {
        this.errors.push(this.errorMessages[mutationName]);
      }

      return data;
    },
    async onSubmit() {
      await this.checkEmailIsUnique(this.user.email);

      const observer = this.$refs.form.$refs.observer;
      const isValid = observer && (await observer.validate());

      if (isValid) {
        let will = null;
        let willId = null;
        this.duplicatingWill = true;
        this.modalTitle = this.$t(
          'components.adminPartnerModal.duplicatingWill'
        );

        // create user account
        this.duplicatingStep = this.$t(
          'components.adminPartnerModal.creatingUser'
        );
        const user = await this.duplicateData('user', {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          role: 'CONSUMER',
        });

        // get user's will
        if (user.success) {
          try {
            const willResponse = await this.$apollo.query({
              query: WILL_BY_EMAIL_QUERY,
              variables: { email: this.user.email },
            });

            // show error if no success else get will id
            if (!willResponse.data.getWillByEmail.will) {
              this.errors.push(willResponse.data.getWillByEmail.message);
            } else {
              will = willResponse.data.getWillByEmail.will;
              willId = will.id;
            }
          } catch (e) {
            this.errors.push(this.errorMessages.getWillByEmail);
          }
        }

        // duplicate will and add all data to user
        if (willId) {
          const willResponse = await this.duplicateData('will', {
            id: willId,
            meta: this.getWillMetaData(),
          });
          will = willResponse.will;

          // add people
          const peoplePromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingPeople'
          );
          const people = this.getPeopleData();

          people.forEach((person) => {
            // Clone person and delete refs from clone
            const personClone = Object.assign({}, person);
            delete personClone.refs;

            const promise = this.duplicateData('person', {
              willId,
              ...personClone,
            });

            promise.then((response) => {
              this.people.push({
                id: response.person.id,
                ...person,
              });
            });

            peoplePromises.push(promise);
          });

          await Promise.all(peoplePromises);

          // add charities
          const charitiesPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingCharities'
          );
          const charities = this.getCharitiesData();

          charities.forEach((charity) => {
            const charityClone = Object.assign({}, charity);
            delete charityClone.refs;

            const promise = this.duplicateData('charity', {
              willId,
              ...charityClone,
            });

            promise.then((response) => {
              this.charities.push({
                id: response.charity.id,
                ...charity,
              });
            });

            charitiesPromises.push(promise);
          });

          await Promise.all(charitiesPromises);

          // add pets
          const petsPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingPets'
          );
          const pets = this.getPetsData();

          pets.forEach(async (pet) => {
            const petClone = Object.assign({}, pet);
            delete petClone.guardian;

            const response = await this.duplicateData('pet', {
              willId,
              ...petClone,
            });

            if (pet.guardian) {
              petsPromises.push(
                this.duplicateData('petGuardian', {
                  petId: response.pet.id,
                  ...pet.guardian,
                })
              );
            }
          });

          await Promise.all(petsPromises);

          // add assets
          const assetsPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingAssets'
          );
          const assets = this.getAssetsData();

          assets.forEach((asset) => {
            assetsPromises.push(
              this.duplicateData('asset', {
                willId,
                ...asset,
              })
            );
          });

          await Promise.all(assetsPromises);

          // add executors
          const executorsPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingExecutors'
          );
          const executors = this.getExecutorsData();

          executors.forEach((executor) => {
            executorsPromises.push(
              this.duplicateData('executor', {
                willId,
                ...executor,
              })
            );
          });

          await Promise.all(executorsPromises);

          // add beneficiaries
          const beneficiariesPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingBeneficiaries'
          );
          const beneficiaries = this.getBeneficiariesData();

          beneficiaries.forEach(async (beneficiary) => {
            const beneficiaryClone = Object.assign({}, beneficiary);
            delete beneficiaryClone.backups;

            const response = await this.duplicateData('beneficiary', {
              willId,
              ...beneficiaryClone,
            });

            beneficiary.backups.forEach((backup) => {
              beneficiariesPromises.push(
                this.duplicateData('beneficiary', {
                  willId,
                  backupId: response.beneficiary.id,
                  ...backup,
                })
              );
            });
          });

          await Promise.all(beneficiariesPromises);

          // add gifts
          const giftsPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingGifts'
          );
          const gifts = this.getGiftsData();

          gifts.forEach((gift) => {
            giftsPromises.push(
              this.duplicateData('gift', {
                willId,
                ...gift,
              })
            );
          });

          await Promise.all(giftsPromises);

          // add guardians
          const guardiansPromises = [];
          this.duplicatingStep = this.$t(
            'components.adminPartnerModal.creatingGuardians'
          );
          const guardians = this.getGuardiansData();

          guardians.forEach((guardian) => {
            guardiansPromises.push(
              this.duplicateData('guardian', {
                willId,
                ...guardian,
              })
            );
          });

          await Promise.all(guardiansPromises);
        }

        // Finish duplicating
        this.duplicatingStep = this.$t(
          'components.adminPartnerModal.willHasBeenCreated'
        );
        this.duplicatingSuccessful = true;

        this.$nuxt.$emit('sendTrackingEvent', {
          event: "💌 Duplicate Partner's Will",
          props: { email: this.user.email },
        });
      }
    },
  },
};
</script>
