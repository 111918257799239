<template>
  <div>
    <div class="flex items-center justify-between h-12 mb-8">
      <Headline flush :level="1"> Cremations </Headline>
      <div class="flex">
        <input
          id="search"
          class="input input-compact mr-8"
          :v-model="searchQuery"
          placeholder="Search..."
          :compact="true"
          :value="searchValue"
          @keydown="search"
        />
        <RuButton level="primary" @click="setShowAddUserModal(true)">
          + Add User
        </RuButton>
      </div>
    </div>
    <AdminCard tight>
      <AdminDataTable
        v-if="cremations"
        :columns="columns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="cremations.length"
        :sortable-columns="sortableColumns"
        :current-sorting-column="currentSortingColumn"
        @sortColumnClicked="changeSortColumnAndResetPageIndex"
      >
        <template #filters>
          <AdminDataTableFilter
            v-for="(filter, index) in filters"
            :key="index"
            :active="filter.value === cremationRequestStatusFilter"
            :label="filter.label"
            @click="setCremationRequestStatusFilter(filter.value)"
          />
        </template>
        <template #rows>
          <AdminDataTableRow
            v-for="(cremation, index) in cremations"
            :key="index"
            class="group"
            :to="localePath(`/admin/cremations/${cremation.id}`)"
          >
            <AdminDataTableCell>
              {{ informantIdentifier(cremation) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ guestIdentifier(cremation) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ cremation.status }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ getAdminEmail(cremation.adminUserId) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ getDateString(cremation.createdAt) }}
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="usersPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="cremations.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>
    <AdminUserModal
      :show-add-user-modal="showAddUserModal"
      @setShowAddUserModal="setShowAddUserModal"
      @done="createAtNeedCremationRequest"
    />
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
import CremationsAdmins from '@/mixins/cremations-admins';

import GET_AT_NEED_CREMATION_REQUESTS_QUERY from '@/graphql/queries/GetAtNeedCremationRequests';
import CREATE_AT_NEED_CREMATION_REQUEST_MUTATION from '@/graphql/mutations/CreateAtNeedCremationRequest';

import AdminCard from '@/components/admin/Card';
import AdminUserModal from '@/components/admin/UserModal';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminDataTableFilter from '@/components/admin/DataTableFilter';
import BaseIcon from '@/components/BaseIcon';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';

import mixins from '@/mixins/sorting';

export default {
  name: 'PagesAffiliateAdminCremationsIndex',
  components: {
    AdminCard,
    AdminUserModal,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminDataTableFilter,
    BaseIcon,
    AdminDataTableButton,
    RuButton,
    Headline,
  },
  mixins: [CremationsAdmins, mixins],
  apollo: {
    cremations: {
      query: GET_AT_NEED_CREMATION_REQUESTS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.usersPageIndex * this.pageSize,
          searchQuery: this.usersPageSearch,
          status: this.cremationRequestStatusFilter,
          customOrder: this.customOrder,
        };
      },
      update: (data) => data.getAtNeedCremationRequests,
    },
  },
  data() {
    return {
      CREATE_AT_NEED_CREMATION_REQUEST_MUTATION,
      searchQuery: '',
      columns: ['Informant', 'Deceased', 'Status', 'Arranger', 'Created At'],
      pageSize: 10,
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Imminent',
          value: 'IMMINENT',
        },
        {
          label: 'Current',
          value: 'CURRENT',
        },
        {
          label: 'Completed',
          value: 'COMPLETED',
        },
        {
          label: 'Lost',
          value: 'LOST',
        },
      ],
      users: [],
      showAddUserModal: false,
      sortableFields: {
        informant: {
          field: 'informantFirstName',
          columnName: 'Informant',
          defaultDirection: 'ASC',
        },
        deceased: {
          field: 'guestFirstName',
          columnName: 'Deceased',
          defaultDirection: 'ASC',
        },
        status: {
          field: 'status',
          columnName: 'Status',
          defaultDirection: 'ASC',
        },
        createdAt: {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
      },
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'usersPageIndex',
      'usersPageSearch',
      'cremationRequestStatusFilter',
    ]),
    searchValue() {
      return this.usersPageSearch;
    },
  },
  watch: {
    cremationRequestStatusFilter() {
      this.resetUsersPageIndex();
    },
  },
  methods: {
    ...mapActions('admin', [
      'nextUsersPage',
      'prevUsersPage',
      'resetUsersPageIndex',
      'setUsersPageSearch',
      'setCremationRequestStatusFilter',
    ]),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextUsersPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevUsersPage();
      }
    },
    informantIdentifier(cremation) {
      if (cremation.informantFirstName && cremation.informantLastName) {
        return `${cremation.informantFirstName} ${cremation.informantLastName}`;
      }
      return cremation.informantEmail;
    },
    guestIdentifier(cremation) {
      if (cremation.guestFirstName && cremation.guestLastName) {
        return `${cremation.guestFirstName} ${cremation.guestLastName}`;
      }
      return null;
    },
    getAdminEmail(adminUserId) {
      return this.getCremationsAdminOrHigherEmail(adminUserId);
    },
    search: debounce(300, function (event) {
      this.resetUsersPageIndex();
      this.setUsersPageSearch(event.target.value.trim());
    }),
    setShowAddUserModal(show) {
      this.showAddUserModal = show;
    },
    getDateString(string) {
      if (!string) return 'N/A';
      return format(parseInt(string), 'dd/MM/yyyy hh:mma');
    },
    async createAtNeedCremationRequest(informantUserId) {
      const cremationRequest = await this.$apollo.mutate({
        mutation: CREATE_AT_NEED_CREMATION_REQUEST_MUTATION,
        variables: {
          informantUserId,
        },
      });
      this.$router.push({
        path: this.localePath(
          `/admin/cremations/${cremationRequest.data.createAtNeedCremationRequest.id}`
        ),
      });
    },
    changeSortColumnAndResetPageIndex(columnName) {
      this.resetUsersPageIndex();
      this.changeSortColumn(columnName);
    },
  },
};
</script>
