<template>
  <ModuleStep :index="2" prev-step="/will/estate">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @done="done"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.estate.leaveEstateCharityQuestion') }}
        </Headline>
        <p>
          {{ $t('pages.will.estate.leaveEstateCharityMessage') }}
        </p>
        <Tip>
          {{ $t('pages.will.estate.leaveMonetaryGiftMessage') }}
        </Tip>
        <LargeRadioButtons
          id="hasPartner"
          v-model="willMeta.charity_in_estate"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
        />
        <div v-if="willMeta.charity_in_estate">
          <Headline :level="3">
            {{ $t('pages.will.estate.charitiesAndNotForProfit') }}
          </Headline>
          <CharitySuggester
            v-if="showSuggestedCharities"
            v-model="selectedSuggestedCharities"
            :loading="loading"
            :selected-suggested-charities="selectedSuggestedCharities"
          />
          <CharitySelector
            v-model="selectedBeneficiaryIDs"
            :loading="loading"
          />
          <Buttons layout="stretch">
            <RuButton size="large" level="secondary" @click="addCharity">
              + {{ $t('forms.labels.addCharity') }}
            </RuButton>
          </Buttons>
          <CharityPanel />
        </div>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';
import { metaArrayToObject } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';

import CharitySelector from '@/components/CharitySelector';
import CharitySuggester from '@/components/CharitySuggester';
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';
import CharityPanel from '@/components/CharityPanel';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { beneficiaries, charities, people, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillEstateIncludeCharity',
  components: {
    CharitySelector,
    CharitySuggester,
    AdiForm,
    FormSection,
    ModuleStep,
    LargeRadioButtons,
    Tip,
    Headline,
    CharityPanel,
    Buttons,
    RuButton,
  },
  mixins: [beneficiaries, charities, people, user, will],
  data() {
    return {
      selectedBeneficiaryIDs: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    isComplete() {
      const madeSelection = this.willMeta.charity_in_estate !== undefined;
      const includeCharity = this.willMeta.charity_in_estate;
      const hasSelectedBeneficiaryIDs = this.selectedBeneficiaryIDs.length;
      const hasSelectedSuggestedBeneficiaries = !!(
        this.showSuggestedCharities && !!this.selectedSuggestedCharities.length
      );
      return (
        madeSelection &&
        (!includeCharity ||
          (includeCharity &&
            (hasSelectedBeneficiaryIDs || hasSelectedSuggestedBeneficiaries)))
      );
    },
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
  },
  watch: {
    beneficiaries() {
      if (
        this.willMeta.estate_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaryIDs.length
      ) {
        const people = this.people
          .filter((person) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.person && beneficiary.person.id === person.id
            );
          })
          .map((person) => person.id);

        const charities = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        this.selectedBeneficiaryIDs = people.concat(charities);
      }
    },
  },
  mounted() {
    this.willMeta.estate_backup_index = 0;
    this.updateWillMeta();
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    async done() {
      if (this.willMeta.charity_in_estate) {
        this.willMeta.estate_split = 'custom';
        await this.updateWillMeta();

        if (this.selectedSuggestedCharities.length) {
          await Promise.all(
            this.selectedSuggestedCharities.map((charity) => {
              return this.addPartnerCharity(charity);
            })
          );
          await this.refetchCharities();
          this.charities.forEach((charity) => {
            const charityMeta = metaArrayToObject(charity.meta);
            const exists = this.selectedSuggestedCharities.find(
              (selectedSuggestedCharity) => {
                return isSameCharity(selectedSuggestedCharity, charityMeta);
              }
            );
            if (exists) {
              this.selectedBeneficiaryIDs.push(charity.id);
            }
          });
        }

        for (const charity of this.charities) {
          const beneficiary = this.getBeneficiaryByCharity(charity);
          const isSelected = this.selectedBeneficiaryIDs.includes(charity.id);

          if (isSelected) {
            if (!beneficiary) {
              await this.addBeneficiary(charity, null);
            }
          } else if (beneficiary) {
            await this.removeBeneficiary(beneficiary);
          }
        }
      }

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift:
          this.willMeta.charity_in_gifts || this.willMeta.charity_in_estate,
      });

      this.sendBeneficiariesAddedEvent();

      const currentPrimaryTotal = this.beneficiaries.reduce(
        (total, beneficiary) => {
          return total + Number(beneficiary.distribution);
        },
        0
      );
      const mustRedistribute =
        this.willMeta.estate_split === 'custom' || currentPrimaryTotal !== 100;

      if (mustRedistribute) {
        await this.$router.push(this.localePath('/will/estate/distribution'));
      } else {
        await this.updateWillIsPrimaryEstateSplitEvenly(false);
        await this.$router.push(
          this.localePath(
            `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`
          )
        );
      }
    },
  },
};
</script>
