<template>
  <ModuleStep :index="2" prev-step="/will/assets">
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      submit-icon="arrow-right"
      :submit-label="buttonText"
      @done="onDone"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.assets.notes.headline') }}
        </Headline>
        <p>
          {{ $t('pages.will.assets.notes.assetsNoteInstruction') }}
        </p>
        <Tip>
          {{ $t('pages.will.assets.notes.assetsNoteOptional') }}
        </Tip>
        <FormControl
          id="asset_notes"
          v-model="willMeta.asset_note"
          :required="false"
          :placeholder="$t('forms.placeholders.optional')"
          type="textarea"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAssetsNotes',
  components: {
    AdiForm,
    FormSection,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    buttonText() {
      return this.willMeta.asset_note ? 'Next' : 'Skip';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 6 (Assets)',
      });
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
