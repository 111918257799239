<template>
  <ModuleStep :index="3" prev-step="/will/executors/options">
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit="submit"
    >
      <FormSection :tip="$t('pages.will.peopleSelectionInstructions')">
        <Headline :level="1">
          {{ $t('pages.will.executors.primary.whoPrimaryExecutor') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.executors.primary.tip') }}
        </Tip>
        <PersonSelector
          v-model="selectedPrimaryExecutors"
          :loading="loading"
          :max="2"
          :people="filteredPeople"
          type="executor"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { executors, people, user } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillExecutorsPrimary',
  components: {
    AdiForm,
    FormSection,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
  },
  mixins: [executors, people, user],
  data() {
    return {
      selectedPrimaryExecutors: [],
    };
  },
  computed: {
    isComplete() {
      return !!this.selectedPrimaryExecutors.length;
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedPrimaryExecutors.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isBackupExecutor(person))
        );
      });
    },
  },
  watch: {
    primaryExecutors(executors) {
      if (executors.length && !this.selectedPrimaryExecutors.length) {
        this.selectedPrimaryExecutors = this.people
          .filter((person) => {
            return executors.find(
              (executor) => person.id === executor.person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    async submit() {
      await Promise.all(
        this.people.map((person) => {
          const isPrimaryExecutor = this.isPrimaryExecutor(person);
          const isSelected = this.selectedPrimaryExecutors.includes(person.id);
          if (isSelected) {
            if (!isPrimaryExecutor) {
              return this.addExecutor(person, 'primary');
            }
          } else if (isPrimaryExecutor) {
            return this.removeExecutor(person);
          }
          return Promise.resolve();
        })
      );

      this.$router.push({
        path: this.localePath('/will/executors/backup'),
      });
    },
  },
};
</script>
