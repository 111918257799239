<template>
  <div class="ru:container">
    <Headline :level="1">{{ $t('texts.sentences.thankYou') }}</Headline>
    <p>
      {{ $t('pages.checkout.estatePlanPart') }}
    </p>
    <ThankYou primary-tab-name="POA" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Headline from '@/components/atoms/Headline';
import ThankYou from '@/components/templates/ThankYou';

import CHECK_POA_PURCHASED_QUERY from '@/graphql/queries/CheckPoaPurchased';

export default {
  name: 'PagesAffiliateCheckoutPoaThankYou',
  components: {
    Headline,
    ThankYou,
  },
  layout: 'ruach',
  apollo: {
    purchasedProducts: {
      query: CHECK_POA_PURCHASED_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.userId,
        };
      },
      result({ data: { poa } }) {
        if (poa) {
          this.setPoaId(poa.id);
        }
      },
    },
  },
  computed: {
    ...mapGetters(['userId']),
  },
  methods: {
    ...mapActions('poa', ['setPoaId']),
  },
};
</script>
