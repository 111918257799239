<template>
  <svg
    class="ru:loading"
    :width="width"
    height="24"
    :viewBox="`0 0 ${width} 24`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g v-if="type === 'dots'">
      <circle cx="8" cy="12" r="8">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; 0.5; 0.5; 0.5; 1"
          begin="-0.3s"
          dur="1.2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="r"
          attributeType="XML"
          values="8; 6; 6; 6; 8"
          begin="0s"
          dur="1.1s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="32" cy="12" r="8">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; 0.5; 0.5; 0.5; 1"
          begin="-1.3s"
          dur="1.2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="r"
          attributeType="XML"
          values="8; 6; 6; 6; 8"
          begin="-1.3s"
          dur="1.1s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="56" cy="12" r="8">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; 0.5; 0.5; 0.5; 1"
          begin="-2.3s"
          dur="1.2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="r"
          attributeType="XML"
          values="8; 6; 6; 6; 8"
          begin="-2.6s"
          dur="1.1s"
          repeatCount="indefinite"
        />
      </circle>
    </g>
    <g v-else>
      <path
        d="M10.854 21.564V24C4.764 23.397 0 18.26 0 12S4.764.603 10.854 0v2.436A9.634 9.634 0 0 0 2.412 12a9.634 9.634 0 0 0 8.442 9.564Z"
        style="transform-origin: center center"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 0 0"
          to="360 0 0"
          dur="0.9s"
          repeatCount="indefinite"
        />
        <animate
          attributeType="XML"
          attributeName="opacity"
          values="1; 0.5; 1"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ComponentsAtomsLoading',
  props: {
    type: {
      default: 'ring',
      type: String,
    },
    theme: {
      default: '',
      type: String,
    },
  },
  computed: {
    width() {
      switch (this.type) {
        case 'dots':
          return 64;
        default:
          return 24;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &loading {
    fill: --rgba(theme);
  }
}
</style>
