<template>
  <Alert layout="center" level="medium" theme="error">
    {{ $t('components.maintenanceAler.message') }}
  </Alert>
</template>

<script>
import Alert from '@/components/molecules/Alert';

export default {
  name: 'ComponentsSnowflakesMaintenanceAlert',
  components: {
    Alert,
  },
};
</script>
