var render = function render(_c,_vm){return _c(`h${_vm.props.level}`,{tag:"component",staticClass:"ru:headline",class:[
    _vm.data.staticClass,
    _vm.props.centered ? '--centered' : null,
    _vm.props.flush ? '--flush' : null,
    _vm.props.row ? '--row' : null,
    _vm.props.big ? '--big' : null,
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }