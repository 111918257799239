<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :hide-submit="!isComplete"
      :auto-submit="!willMeta.has_assets"
      submit-icon="arrow-right"
      @done="done"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.assets.asset.title') }}
        </Headline>
        <p>
          {{ $t('pages.will.assets.asset.desc') }}
        </p>
        <Tip>
          <p>
            {{ $t('pages.will.assets.asset.tip.assetsGuide') }}
          </p>
          <p>
            {{ $t('pages.will.assets.asset.tip.superannuationInsurance') }}
          </p>
          <Buttons layout="start">
            <RuButton
              theme="secondary"
              size="small"
              href="https://safewill.com/blog/posts/what-happens-to-your-superannuation-when-you-die"
            >
              <Icon id="info" />
              {{ $t('pages.will.assets.asset.superDeathExplanation') }}
            </RuButton>
          </Buttons>
        </Tip>
        <Headline :level="2">
          {{ $t('pages.will.assets.asset.question') }}
        </Headline>
        <LargeRadioButtons
          id="hasAssets"
          v-model="willMeta.has_assets"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
        />
      </FormSection>
      <FormSection v-if="willMeta.has_assets">
        <Headline :level="2"> {{ $t('texts.sentences.yourAssets') }} </Headline>
        <EmptyState v-if="!selectedAssets.length">
          {{ $t('pages.will.assets.asset.noAssets') }}
        </EmptyState>
        <AssetInputs
          v-for="(asset, index) in selectedAssets"
          :id="`asset_${asset.id || index}`"
          :key="index"
          :disabled="loading"
          :description="asset.meta.description"
          :type="asset.meta.type"
          @description="asset.meta.description = $event"
          @type="asset.meta.type = $event"
          @add="addAssetInput"
          @remove="removeAssetInput(asset)"
        />
        <Buttons layout="end">
          <RuButton size="large" level="secondary" @click="addAssetInput">
            + {{ $t('forms.labels.add') }}
          </RuButton>
        </Buttons>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AssetInputs from '@/components/AssetInputs';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import EmptyState from '@/components/EmptyState';
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';

import { assets, user, will } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';

export default {
  name: 'PagesAffiliateWillAssetsIndex',
  components: {
    AssetInputs,
    EmptyState,
    AdiForm,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
    Buttons,
    RuButton,
    Icon,
  },
  mixins: [assets, user, will],
  data() {
    return {
      defaultAsset: {},
      selectedAssets: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_assets === false || this.selectedAssets.length
      );
    },
  },
  watch: {
    assets(assets) {
      if (assets.length && !this.selectedAssets.length) {
        this.selectedAssets = assets.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addAssetInput() {
      this.selectedAssets.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeAssetInput(asset) {
      if (this.isAsset(asset)) {
        await this.removeAsset(asset);
      }

      this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1);
    },
    async done() {
      for (const asset of this.selectedAssets) {
        const isAsset = this.isAsset(asset);
        const meta = objectToMetaArray(asset.meta);

        if (this.willMeta.has_assets) {
          if (isAsset) {
            await this.updateAsset(asset, meta);
          } else {
            await this.addAsset(meta);
          }
        } else if (isAsset) {
          await this.removeAsset(asset);
        }
      }

      this.$router.push({
        path: this.localePath('/will/assets/liabilities'),
      });
    },
  },
};
</script>
