<template>
  <div>
    <div class="flex items-center justify-between h-12 mb-8">
      <Headline flush :level="1"> Partnerships </Headline>
      <div class="flex">
        <input
          id="search"
          class="input input-compact mr-8"
          placeholder="Search..."
          :compact="true"
          @keydown="search"
        />
        <BaseButton
          class="btn btn-slate px-4 py-3"
          @click="setShowNewPartnershipModal(true)"
        >
          <div class="flex items-center">
            <span class="text-white whitespace-no-wrap">
              + Add Partnership
            </span>
          </div>
        </BaseButton>
      </div>
    </div>

    <AdminCard tight>
      <AdminDataTable
        v-if="partnerships"
        :columns="columns"
        :loading="$apollo.loading"
        :min-rows="pageSize"
        :row-count="partnerships.length"
        :sortable-columns="sortableColumns"
        :current-sorting-column="currentSortingColumn"
        @sortColumnClicked="changeSortColumnAndResetPageIndex"
      >
        <template #rows>
          <AdminDataTableRow
            v-for="(partnership, index) in partnerships"
            :key="index"
            :to="localePath(`/admin/partnerships/${partnership.id}`)"
            class="group"
          >
            <AdminDataTableCell>
              {{ partnership.name }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(partnership.createdAt) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              {{ $formatDate(partnership.updatedAt) }}
            </AdminDataTableCell>
            <AdminDataTableCell>
              <BaseSwitch
                :value="partnership.active"
                @change="setPartnershipStatus(partnership)"
              />
            </AdminDataTableCell>
          </AdminDataTableRow>
        </template>
        <template #pagination>
          <AdminDataTableButton
            :disabled="partnershipsPageIndex === 0"
            :loading="$apollo.loading"
            @click="prevPartnershipsPage"
          >
            <BaseIcon id="arrow-left" />
          </AdminDataTableButton>
          <AdminDataTableButton
            :disabled="partnerships.length !== pageSize"
            :loading="$apollo.loading"
            @click="nextPartnershipsPage"
          >
            <BaseIcon id="arrow-right" />
          </AdminDataTableButton>
        </template>
      </AdminDataTable>
    </AdminCard>

    <AdminNewPartnershipModal @partnershipAdded="reloadPartnerships" />
    <Toast />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { debounce } from 'throttle-debounce';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import BaseButton from '@/components/BaseButton';
import BaseSwitch from '@/components/BaseSwitch';
import AdminNewPartnershipModal from '@/components/admin/NewPartnershipModal';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import BaseIcon from '@/components/BaseIcon';
import Headline from '@/components/atoms/Headline';
import Toast from '@/components/Toast';

import dialog from '@/mixins/message-box';
import { partnerships } from '@/mixins/apollo';
import sorting from '@/mixins/sorting';

export default {
  name: 'PagesAffiliateAdminPartnershipsIndex',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    BaseButton,
    BaseSwitch,
    AdminNewPartnershipModal,
    Toast,
    AdminDataTableButton,
    BaseIcon,
    Headline,
  },
  mixins: [dialog, partnerships, sorting],
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchQuery: this.partnershipsSearchQuery,
          limit: this.pageSize,
          offset: this.partnershipsPageIndex * this.pageSize,
          customOrder: this.customOrder,
        };
      },
      update: (data) => data && data.getPartnerships,
    },
  },
  data() {
    return {
      columns: ['Name', 'Created At', 'Updated At', 'Active'],
      pageSize: 10,
      sortableFields: {
        name: {
          field: 'name',
          columnName: 'Name',
          defaultDirection: 'ASC',
        },
        createdAt: {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
        updatedAt: {
          field: 'updatedAt',
          columnName: 'Updated At',
          defaultDirection: 'DESC',
        },
      },
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters('admin', [
      'partnershipsPageIndex',
      'partnershipsSearchQuery',
    ]),
  },
  methods: {
    ...mapMutations('partnership', ['setShowNewPartnershipModal']),
    ...mapActions('admin', [
      'nextPartnershipsPage',
      'prevPartnershipsPage',
      'setPartnershipsSearchQuery',
      'resetPartnershipsPageIndex',
    ]),
    reloadPartnerships() {
      this.$apollo.queries.partnerships.refetch();
    },
    setPartnershipStatus(partnership) {
      const missingFields = this.getPartnershipMissingFields(partnership);

      if (missingFields.length) {
        return this.$nuxt.$emit('toast', {
          type: 'error',
          message:
            'The following field(s) are missing: ' + missingFields.join(', '),
        });
      }

      const title = `${!partnership.active ? 'Activate' : 'Deactivate'} ${
        partnership.name
      }?`;
      const activationText = `Are you sure you want to activate ${partnership.name}?
       Activating this partnership will display the partnership on the platform and activate their dashboard.`;

      const deactivationText = `Are you sure you want to deactivate ${partnership.name}?
       Deactivating this partnership will remove the partnership from the platform and deactivate their dashboard.`;

      this.$confirm(
        {
          title,
          message: !partnership.active ? activationText : deactivationText,
        },
        async (action) => {
          if (action) {
            await this.updatePartnershipPartialData(partnership.id, {
              active: !partnership.active,
            });
          }
        }
      );
    },
    changeSortColumnAndResetPageIndex(columnName) {
      this.changeSortColumn(columnName);
      this.resetPartnershipsPageIndex();
    },
    search: debounce(300, function (event) {
      this.resetPartnershipsPageIndex();
      this.setPartnershipsSearchQuery(event.target.value.trim());
    }),
  },
};
</script>
