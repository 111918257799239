<template>
  <Panel
    v-if="showPersonPanel"
    :title="title"
    class="person-panel"
    @close="close"
  >
    <AdiForm
      v-slot="{ loading }"
      auto-focus
      submit-icon="arrow-right"
      :disabled="!!ageError || !isComplete"
      :mutation="mutation"
      :variables="variables"
      :submit-label="buttonText"
      @update="update"
      @done="close"
    >
      <FormSection>
        <FormRow>
          <TextInput
            id="person_full_name"
            v-model="full_name"
            :disabled="loading"
            :label="$t('components.personPanel.fullNameInput.label')"
            :placeholder="
              $t('components.personPanel.fullNameInput.placeholder')
            "
          />
        </FormRow>
        <FormRow v-if="!hasRequirements">
          <div class="invisible md:visible absolute top-0 right-0 mt-4">
            <Tooltip
              inverted
              :tooltip="$t('components.personPanel.identifyRequired')"
            >
              <Badge color="silver" background="white" size="small">
                <Icon id="question" />
              </Badge>
            </Tooltip>
          </div>
          <label class="input-label" :for="identifier">
            {{ $t('components.personPanel.identifyingInformation') }}
          </label>
        </FormRow>
        <FormRow v-if="!hasRequirements">
          <LargeRadioButtons
            id="identifier"
            v-model="identifier"
            horizontal
            :options="[
              {
                label: $t('components.personPanel.identifierOptions.email'),
                value: 'email',
              },
              {
                label: $t(
                  'components.personPanel.identifierOptions.dateOfBirth'
                ),
                value: 'dob',
              },
              {
                label: $t('components.personPanel.identifierOptions.address'),
                value: 'address',
              },
            ]"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('email')">
          <TextInput
            id="person_email"
            v-model="email"
            :disabled="loading"
            :label="$t('components.personPanel.emailInput.label')"
            rules="required|email"
            :placeholder="$t('components.personPanel.emailInput.placeholder')"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('dob')">
          <DateInput
            id="person_date_of_birth"
            v-model="date_of_birth"
            :disabled="loading"
            :label="$t('components.personPanel.dobInput.label')"
            rules="required|date"
          />
        </FormRow>
        <FormRow v-if="ageError && shownIdentifierFields.includes('dob')">
          <p class="mt-1 text-red-300 truncate">{{ ageError }}</p>
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <div
            v-if="showAddressHelper"
            class="absolute top-0 right-0 mt-3 md:mt-4 z-10"
          >
            <BaseButton
              class="text-teal-200 underline use-mine"
              focusable
              @click="prefillAddressFields"
            >
              {{ $t('components.personPanel.useMyAddress') }}
            </BaseButton>
          </div>
          <TextInput
            id="person_address_street"
            v-model="address_street"
            :disabled="loading"
            :label="$t('components.personPanel.addressInput.street.label')"
            :placeholder="
              $t('components.personPanel.addressInput.street.placeholder')
            "
            rules="required"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <TextInput
            id="person_address_suburb"
            v-model="address_suburb"
            :disabled="loading"
            :label="$t('components.personPanel.addressInput.suburb.label')"
            rules="required"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <SelectInput
            v-if="address_country === 'Australia'"
            id="person_address_state"
            v-model="address_state"
            class="md:w-1/2"
            :disabled="loading"
            :label="$t('components.personPanel.addressInput.state.label')"
            :options="australianStates"
            required
            searchable
          />
          <TextInput
            v-else
            id="person_address_state"
            v-model="address_state"
            class="md:w-1/2"
            :disabled="loading"
            :label="$t('components.personPanel.addressInput.state.label')"
            :rules="address_country === 'Australia' ? 'required' : ''"
          />
          <TextInput
            id="person_address_postcode"
            v-model="address_postcode"
            class="md:w-1/2 md:ml-2"
            :disabled="loading"
            :label="$t('components.personPanel.addressInput.postcode.label')"
            :rules="address_country === 'Australia' ? 'required' : ''"
          />
        </FormRow>
        <FormRow v-if="shownIdentifierFields.includes('address')">
          <div class="invisible md:visible absolute top-0 right-0 mt-4">
            <Tooltip inverted :tooltip="countryTooltip">
              <Badge color="silver" background="white" size="small">
                <Icon id="question" />
              </Badge>
            </Tooltip>
          </div>
          <SelectInput
            id="person_address_country"
            v-model="address_country"
            :disabled="loading || countryDisabled"
            :label="$t('components.personPanel.addressInput.country.label')"
            :placeholder="
              $t('components.personPanel.addressInput.country.placeholder')
            "
            :options="countries"
            searchable
          />
        </FormRow>
        <FormRow v-if="!shownIdentifierFields.includes('email')">
          <TextInput
            id="person_email"
            v-model="email"
            :disabled="loading"
            :label="$t('components.personPanel.emailInput.label')"
            :rules="`email${notify ? '|required' : ''}`"
            :placeholder="`${$t(
              'components.personPanel.emailInput.theirEmailAddress'
            )} ${
              !notify
                ? `(${$t('components.personPanel.emailInput.optional')})`
                : ''
            }`"
          />
        </FormRow>
        <FormRow
          v-if="!shownIdentifierFields.includes('dob')"
          :class="{ 'last-child:mb-10': ageError }"
        >
          <BaseRadio
            id="person_is_over_18"
            v-model="is_over_18"
            large
            class="mt-6"
            label="Is this person over 18?"
            :options="[
              {
                text: $t('components.personPanel.under18'),
                value: false,
              },
              {
                text: $t('components.personPanel.over18'),
                value: true,
              },
            ]"
            :error="ageError"
          />
        </FormRow>
      </FormSection>
      <FormRow>
        <div class="invisible md:visible absolute top-0 right-0">
          <Tooltip
            inverted
            :tooltip="$t('components.personPanel.notifyPerson.tooltip')"
          >
            <Badge color="silver" background="white" size="small">
              <Icon id="question" />
            </Badge>
          </Tooltip>
        </div>
        <CheckboxInput
          id="notify_person"
          v-model="notify"
          class="mb-4 mr-8"
          :disabled="!notifyPersonIsEnabled"
        >
          {{ notifyCheckboxText }}
        </CheckboxInput>
      </FormRow>
      <Tip v-if="!is_over_18">
        {{ $t('components.personPanel.notifyPerson.under18Message') }}
      </Tip>
    </AdiForm>
  </Panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { queries } from '@/modules/apollo-queries/people';

import BaseRadio from '@/components/BaseRadio';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import BaseButton from '@/components/BaseButton';
import Panel from '@/components/organisms/Panel';
import DateInput from '@/components/DateInput';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import SelectInput from '@/components/SelectInput';
import TextInput from '@/components/TextInput';
import Tip from '@/components/molecules/Tip';
import CheckboxInput from '@/components/CheckboxInput';
import Tooltip from '@/components/atoms/Tooltip';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge';

import { people, user, will, invites } from '@/mixins/apollo';
import { objectToMetaArray, age, date, is24HoursFromNow } from '@/utilities';

export default {
  name: 'ComponentsPersonPanel',
  components: {
    BaseRadio,
    BaseButton,
    Panel,
    AdiForm,
    FormRow,
    FormSection,
    SelectInput,
    TextInput,
    DateInput,
    Tip,
    Tooltip,
    LargeRadioButtons,
    CheckboxInput,
    Icon,
    Badge,
  },
  mixins: [people, user, will, invites],
  props: {
    willBeneficiary: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      notify: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapFields('person', [
      'fields.full_name',
      'fields.address_street',
      'fields.address_suburb',
      'fields.address_state',
      'fields.address_postcode',
      'fields.address_country',
      'fields.date_of_birth',
      'fields.email',
      'fields.is_over_18',
      'fields.identifier',
    ]),
    ...mapGetters('person', [
      'id',
      'type',
      'identifierRequirements',
      'fields',
      'showPersonPanel',
    ]),
    ...mapGetters('ui', ['australianStates', 'countries']),
    countryTooltip() {
      if (this.type === 'attorney') {
        return this.$t(
          'components.personPanel.countryTooltip.withAttorneyType'
        );
      }

      return this.$t('components.personPanel.countryTooltip.default');
    },
    isComplete() {
      const fulfilsEmailRequirement = this.shownIdentifierFields.includes(
        'email'
      )
        ? !!this.email
        : true;

      const fulfilsAddressRequirement = this.shownIdentifierFields.includes(
        'address'
      )
        ? !!this.address_street
        : true;

      const fulfilsDobRequirement = this.shownIdentifierFields.includes('dob')
        ? !!this.date_of_birth
        : true;

      return (
        this.full_name &&
        fulfilsEmailRequirement &&
        fulfilsAddressRequirement &&
        fulfilsDobRequirement
      );
    },
    ageError() {
      return ['executor', 'guardian', 'attorney'].includes(this.type) &&
        !this.is_over_18
        ? `${this.capitalisedType} ${this.$t(
            'components.personPanel.mustBeOver18'
          )}.`
        : '';
    },
    buttonText() {
      return this.id
        ? this.$t('components.personPanel.updatePerson')
        : `${this.$t('components.personPanel.save')} ${this.capitalisedType}`;
    },
    capitalisedType() {
      return this.$upperFirst(this.type);
    },
    countryDisabled() {
      // return this.type === 'executor';
      return false;
    },
    mutation() {
      return this.id ? this.UPDATE_PERSON_MUTATION : this.ADD_PERSON_MUTATION;
    },
    showAddressHelper() {
      return (
        this.shownIdentifierFields.includes('address') &&
        ['child', 'partner'].includes(this.type)
      );
    },
    title() {
      return this.id
        ? this.$t('components.personPanel.editPerson')
        : `${this.$t('components.personPanel.addANew')} ${this.type}`;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        notify: this.notify,
      };
      if (this.id) {
        variables.id = this.id;
      } else {
        if (this.willBeneficiary) {
          variables.willId = this.willId;
        } else {
          variables.userId = this.userId;
        }
        variables.category = 'none';
      }
      return variables;
    },
    hasRequirements() {
      return this.identifierRequirements !== null;
    },
    shownIdentifierFields() {
      if (this.hasRequirements) {
        return this.identifierRequirements;
      }
      return this.identifier || 'address';
    },
    notifyCheckboxText() {
      return this.$t('components.personPanel.notifyPerson.label', {
        type: this.type,
      });
    },
    notifyPersonIsEnabled() {
      if (this.loading || !this.is_over_18) {
        return false;
      }
      if (this.id && this.email) {
        const invite = this.getInviteByEmail(this.email);
        if (invite) {
          return !is24HoursFromNow(invite.remindedAt);
        }
      }
      return true;
    },
  },
  watch: {
    address_country() {
      this.address_state = '';
    },
    date_of_birth(newValue) {
      if (!date(newValue)) return;
      this.is_over_18 = age(newValue) >= 18;
    },
    is_over_18(newValue) {
      this.notify = newValue;
    },
    hasRequirements() {
      if (this.hasRequirements) {
        this.identifier = this.identifierRequirements[0];
      } else {
        this.identifier = 'address';
      }
    },
    id(newValue) {
      if (!newValue) return;
      const invite = this.getInviteByEmail(this.email);
      if (invite) {
        this.notify = is24HoursFromNow(invite.remindedAt);
      }
    },
  },
  methods: {
    ...mapActions('person', [
      'setShowPersonPanel',
      'setIdentifierRequirements',
    ]),
    close() {
      this.setShowPersonPanel(false);
      this.setIdentifierRequirements(null);
    },
    altText(alt) {
      return alt === 'dob' ? 'date of birth' : alt;
    },
    prefillAddressFields() {
      this.address_street = this.willMeta.address_street;
      this.address_suburb = this.willMeta.address_suburb;
      this.address_state = this.willMeta.address_state;
      this.address_postcode = this.willMeta.address_postcode;
    },
    update(store, { data: { addPerson } }) {
      if (addPerson) {
        if (this.willBeneficiary) {
          const data = store.readQuery(this.getPeopleQuery);
          data.getPeople.people.push(addPerson.person);
          store.writeQuery({
            ...this.getPeopleQuery,
            data,
          });
        } else {
          const getPeopleOfAccountQuery = {
            variables: this.userId && { userId: this.userId },
            ...queries.getPeopleOfAccount(),
          };
          const data = store.readQuery(getPeopleOfAccountQuery);
          data.getPeopleOfAccount.people.push(addPerson.person);
          store.writeQuery({
            ...getPeopleOfAccountQuery,
            data,
          });
        }
        this.$nuxt.$emit('addPerson', addPerson.person);
      }
      if (this.notify) {
        this.$apollo.queries.invites.refetch();
      }
    },
    getInviteByEmail(email) {
      return this.invites.find((invite) => invite.email === email);
    },
  },
};
</script>
