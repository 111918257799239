<template>
  <div class="grid grid--center ru:end-of-life__empty">
    <div class="cell__12/12 cell__8/12--md cell__6/12--lg">
      <RuImage
        :src="require('~/assets/svg/end-of-life__safe.svg')"
        :width="160"
        :height="120"
      />
      <p>
        {{ $t('components.endOfLifeEmptyCategory.message') }}
      </p>
    </div>
  </div>
</template>

<script>
import RuImage from '@/components/atoms/Image';
export default {
  name: 'ComponentsTemplatesEndOfLifeEmptyCategory',
  components: {
    RuImage,
  },
};
</script>

<style lang="scss">
#{$ru} {
  &end-of-life {
    &__empty {
      margin-bottom: var(--base-margin);
    }
  }
}
</style>
