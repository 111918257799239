<template>
  <span>
    <template v-if="!['APPROVED', 'FLAGGED'].includes(status)">
      {{ $t('components.genericStatusText.is') }}
    </template>
    <Tooltip
      :class="{ '--theme-color --error': status === 'FLAGGED' }"
      :content="tooltip"
    >
      <DecoratedText>
        {{ text }}
      </DecoratedText>
    </Tooltip>
  </span>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import DecoratedText from '@/components/atoms/DecoratedText';

export default {
  name: 'ComponentsOrganismsPoaStatus',
  components: {
    Tooltip,
    DecoratedText,
  },
  props: {
    product: {
      default: 'Will',
      type: String,
    },
    ready: {
      default: false,
      type: Boolean,
    },
    status: {
      required: true,
      type: String,
    },
  },
  computed: {
    text() {
      let text = '';
      switch (this.status) {
        case 'IN_PROGRESS':
          text = this.ready
            ? this.$t('components.genericStatusText.readyToSubmit')
            : this.$t('components.genericStatusText.inProgress');
          break;
        case 'AWAITING_APPROVAL':
          text = this.$t('components.genericStatusText.beingReviewed');
          break;
        case 'FLAGGED':
          text = this.$t('components.genericStatusText.needsAttention');
          break;
        case 'APPROVED':
          text = this.$t('components.genericStatusText.isComplete');
          break;
        default:
          break;
      }
      return text;
    },
    tooltip() {
      let tooltip = '';
      switch (this.status) {
        case 'IN_PROGRESS':
          tooltip = this.ready
            ? this.$t(
                'components.genericStatusText.tooltipText.inProgress.isReady',
                { product: this.product }
              )
            : this.$t(
                'components.genericStatusText.tooltipText.inProgress.notReady',
                { product: this.product }
              );
          break;
        case 'AWAITING_APPROVAL':
          tooltip = this.$t(
            'components.genericStatusText.tooltipText.awaitingApproval',
            { product: this.product }
          );
          break;
        case 'FLAGGED':
          tooltip = this.$t('components.genericStatusText.tooltipText.flagged');
          break;
        case 'APPROVED':
          tooltip = this.$t(
            'components.genericStatusText.tooltipText.approved',
            { product: this.product }
          );
          break;
        default:
          break;
      }
      return tooltip;
    },
  },
};
</script>
