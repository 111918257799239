<template>
  <div class="ru:item-selector">
    <Item
      v-for="item in items"
      :key="item.product"
      :item="item"
      :selection-count="itemSelectionCount(item)"
      @removeItem="updateSelection(item, 'remove')"
      @addItem="updateSelection(item, 'add')"
      @toggleItem="updateSelection(item, 'toggle')"
    />
  </div>
</template>

<script>
import Item from '@/components/molecules/Item';
import { toggleObjectInArray } from '@/utilities';

export default {
  name: 'ComponentsOrganismsItemSelector',
  components: {
    Item,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  methods: {
    updateSelection(item, updateType) {
      const product = item.product;
      const products = this.value.map((item) => item.product);
      let value;
      switch (updateType) {
        case 'add':
          value = [...this.value, item];
          break;
        case 'remove':
          value = [...this.value]
            .slice(0, products.indexOf(product))
            .concat([...this.value].slice(products.indexOf(product) + 1));
          break;
        case 'toggle':
        default:
          value = toggleObjectInArray(item, [...this.value], 'product');
      }
      this.$emit('input', value);
    },
    itemSelectionCount(item) {
      return this.value.filter(
        (selectedItem) => selectedItem.product === item.product
      ).length;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &item-selector {
    margin-bottom: var(--base-margin-big);
  }
}
</style>
