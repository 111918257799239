<template>
  <div v-if="!emailRequested" class="ru:change-password">
    <div class="ru:change-password__text">
      {{ $t('components.changePassword.message') }}
    </div>
    <BaseButton
      class="btn btn-xl btn-slate btn-focus w-full md:w-auto text-white"
      :loading="loading"
      @click="requestUpdatePasswordEmail"
    >
      {{ $t('components.changePassword.changePassword') }}
    </BaseButton>
  </div>
  <div v-else>
    <div class="ru:change-password__text">
      <div class="flex">
        <div
          class="flex justify-center items-center w-5 h-5 rounded-full mr-2 bg-teal-200 text-white"
        >
          <BaseGlyph id="tick-small" />
        </div>
        <span class="opacity-75 text-slate-100">
          {{ $t('components.changePassword.emailSent') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/BaseButton';
import BaseGlyph from '@/components/BaseGlyph';

import REQUEST_UPDATE_PASSWORD_EMAIL from '@/graphql/queries/RequestUpdatePasswordEmail';

import { user } from '@/mixins/apollo';

export default {
  name: 'ComponentsOrganismsChangePassword',
  components: {
    BaseButton,
    BaseGlyph,
  },
  mixins: [user],
  data() {
    return {
      emailRequested: false,
      loading: false,
    };
  },
  methods: {
    async requestUpdatePasswordEmail() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: REQUEST_UPDATE_PASSWORD_EMAIL,
          variables: {
            userId: this.userId,
          },
        });
        if (data.requestUpdatePasswordEmail === true) {
          this.emailRequested = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &change-password {
    &__text {
      margin-bottom: var(--base-margin);
    }
  }
}
</style>
