<template>
  <div class="ru:container ru:container--tight">
    <Headline :level="1"> Thank you! </Headline>
    <p v-if="hasPurchasedLegalAdvice">
      {{ $t('pages.checkout.firstStepLegalAdviceEmail') }}
    </p>
    <p v-else>
      {{ $t('pages.checkout.willReviewAndNotify') }}
    </p>
    <div class="grid">
      <div class="cell">
        <Icon id="info-i" />
      </div>
      <div class="cell cell__fill">
        {{ $t('pages.checkout.beInTouch') }}
      </div>
    </div>
    <Invite />
    <Buttons layout="stretch">
      <RuButton :href="localePath('/will')" level="primary">
        {{ $t('texts.sentences.goToDashboard') }}
        <Icon id="arrow-right" />
      </RuButton>
    </Buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Invite from '@/components/organisms/Invite';

import { subscription, user, will, invites, people } from '@/mixins/apollo';

import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';
import { PRICE_DEFAULTS } from '@/store/checkout';

export default {
  name: 'PagesAffiliateCheckoutWillThanks',
  components: {
    Icon,
    Headline,
    Buttons,
    RuButton,
    Invite,
  },
  mixins: [subscription, user, will, invites, people],
  middleware: ['hasPurchasedWill'],
  data() {
    return {
      showError: false,
      loading: true,
      DEFAULT_SUBSCRIPTION_PRICE: PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE,
    };
  },
  apollo: {
    purchasedProducts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update(data) {
        return data.getPurchasedProducts.products || [];
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    ...mapGetters('checkout', ['invitedByPartner']),
    ...mapGetters(['willId', 'willStatus']),
    hasPurchasedLegalAdvice() {
      return (this.purchasedProducts || []).includes('LEGAL_ADVICE');
    },
  },
  mounted() {
    this.$updateShouldShowCharityConsent();
  },
};
</script>
