<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      :auto-submit="isDirty"
      @submit="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('titles.funeral') }}
        </Headline>
        <p>
          {{ $t('pages.will.funeral.funeralNotes') }}
        </p>
        <Tip>
          {{ $t('pages.will.funeral.funeralDescription') }}
        </Tip>
        <LargeRadioButtons
          id="funeral_skip"
          v-model="willMeta.funeral_skip"
          layout="column"
          :options="options"
          @input="isDirty = true"
        />
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralIndex',
  components: {
    AdiForm,
    FormSection,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
  },
  mixins: [user, will],
  data() {
    return {
      options: [
        {
          label: this.$t('pages.will.funeral.addNotes'),
          value: false,
        },
        {
          label: this.$t('pages.will.funeral.skip'),
          value: true,
        },
      ],
      isDirty: false,
    };
  },
  computed: {
    isComplete() {
      return this.willMeta.funeral_skip !== null;
    },
  },
  watch: {
    isDirty() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      this.willMeta.funeral_type = this.willMeta.funeral_skip
        ? 'executor'
        : null;
      this.willMeta.funeral_location = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_location;
      this.willMeta.funeral_note = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_note;

      if (this.willMeta.funeral_skip) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 7 (Funeral)',
        });
      }

      await this.updateWillMeta();
      const path = this.localePath(
        this.willMeta.funeral_skip ? '/will' : '/will/funeral/type'
      );
      this.$router.push({
        path,
      });
    },
  },
};
</script>
