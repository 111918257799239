<template>
  <div class="ru:alert" :class="classes">
    <svg v-if="icon" width="24" height="24" class="ru:alert__icon">
      <use
        v-bind="{
          'xlink:href': `${require('~/assets/svg/ru-icons.svg')}#${icon}`,
        }"
      />
    </svg>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ComponentsMoleculesAlert',
  props: {
    layout: {
      type: String,
      default: null,
    },
    level: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      const theme = this.theme ? `--${this.theme}` : '';
      const level = this.level ? `--${this.level}` : '';
      const layout = this.layout ? `--${this.layout}` : '';
      return [theme, level, layout];
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &alert {
    padding: 1rem;
    border-radius: var(--border-radius-small);
    color: --rgba(white);
    align-items: center;
    margin-bottom: var(--base-margin);

    a {
      text-decoration: underline;
      font-weight: 500;
    }

    &__icon {
      margin-right: 1rem;
      display: inline-block;
      vertical-align: bottom;
    }

    &#{$mf}center {
      justify-content: center;
    }

    &#{$mf}minor {
      color: --rgba(theme);
    }

    &#{$mf}medium {
      background: --rgba(lighter);
      color: --rgba(darker);
    }

    &#{$mf}major {
      background: --rgba(darker);
    }
  }
}
</style>
