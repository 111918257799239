<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 80 80"
    class="ru:progress-circle"
  >
    <circle
      cx="50%"
      cy="50%"
      :r="radius"
      fill="#fff"
      stroke-dasharray="16 8"
      stroke-linejoin="square"
      stroke-width="6"
      class="ru:progress-circle__circle"
    />
    <circle
      cx="50%"
      cy="50%"
      :r="radius"
      stroke-linejoin="round"
      stroke-width="18"
      :stroke-dasharray="`${circumference} ${circumference}`"
      :stroke-dashoffset="strokeDashoffset"
      class="ru:progress-circle__progress"
    />
  </svg>
</template>

<script>
export default {
  name: 'ComponentsAtomsProgressCircle',
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  data() {
    const started = this.progress > 0;
    const radius = 38;
    const circumference = radius * 2 * Math.PI;
    return {
      started,
      radius,
      circumference,
    };
  },
  computed: {
    strokeDashoffset() {
      return Math.max(
        0,
        this.circumference - (this.progress / 100) * this.circumference
      );
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &progress-circle {
    border-radius: 50%;

    &__circle {
      stroke: --rgba(aluminium);
      transform: rotate(-90deg);
      transform-origin: 50%;
    }

    &__progress {
      stroke: --rgba(theme);
      transform: rotate(-90deg);
      transform-origin: 50%;
    }
  }
}
</style>
