<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.yourLovedOne') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.checkAboutYourselfDetails') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('initial')">
      <Headline :level="3">{{
        $t('pages.cremations.locationOfPassing')
      }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="placeOfDeathAddressState"
            v-model="formData.placeOfDeathAddressState"
            type="select"
            :text="$t('forms.labels.australianState')"
            :options="australianStatesOptions"
          />
        </div>
      </div>
      <div
        v-if="stateSelected && !isSupportedState"
        class="cell cell__12/12 flex items-center"
      >
        <Badge color="white" background="orange">
          <BaseIcon id="alert" size="small" />
        </Badge>
        <span class="text-warning ml-2">
          {{ $t('pages.cremations.contactFuneralArranger') }}
        </span>
      </div>
      <template v-if="stateSelected && isSupportedState">
        <Headline :level="3">{{
          $t('pages.cremations.detailsOfPassing')
        }}</Headline>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12">
            {{ $t('pages.cremations.reportableToCoroner') }}
          </div>
          <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="isReportableToCoroner"
              v-model="formData.isReportableToCoroner"
              type="select"
              :text="$t('forms.labels.deathIsReportable')"
              :options="YES_NO_OPTIONS"
            />
          </div>
          <div
            v-if="showCoronerLocation"
            class="cell cell__12/12 cell__6/12--md cell__4/12--lg"
          >
            <FormControl
              id="coronerLocation"
              v-model="formData.coronerLocation"
              type="select"
              :text="$t('forms.labels.coronerLocation')"
              :options="CORONER_LOCATION"
            />
          </div>
          <div v-else class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="guestDateOfDeath"
              v-model="formData.guestDateOfDeath"
              type="date"
              format="date"
              :text="$t('forms.labels.dateOfDeath')"
              :required="false"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="placeOfDeathLocation"
              v-model="formData.placeOfDeathLocation"
              type="select"
              :text="$t('forms.labels.placeOfDeath')"
              :options="DEATH_LOCATION_OPTIONS"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
            <FormControl
              id="placeOfDeathFacilityName"
              v-model="formData.placeOfDeathFacilityName"
              :type="
                stateIsNsw && showPlaceOfDeathFacilityName ? 'select' : 'text'
              "
              :text="
                showPlaceOfDeathFacilityName
                  ? $t('forms.labels.facilityName')
                  : $t('forms.labels.location')
              "
              :options="
                stateIsNsw && showPlaceOfDeathFacilityName
                  ? [...NSW_HOSPITAL_NAMES, 'OTHER']
                  : null
              "
            />
          </div>
          <div
            v-if="showPlaceOfDeathFacilityName && placeOfDeathFacilityIsOther"
            class="cell cell__12/12 cell__6/12--md cell__4/12--lg"
          >
            <FormControl
              id="placeOfDeathFacilityName"
              v-model="formData.placeOfDeathFacilityNameOther"
              type="text"
              :text="
                showPlaceOfDeathFacilityName
                  ? $t('forms.labels.facilityName')
                  : $t('forms.labels.location')
              "
              :required="showPlaceOfDeathFacilityName"
            />
          </div>
        </div>
        <Headline :level="3">
          {{ $t('texts.sentences.addressDetails') }}
        </Headline>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="placeOfDeathAddressLine1"
              v-model="formData.placeOfDeathAddressLine1"
              type="text"
              :text="$t('forms.labels.addressLine1')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="placeOfDeathAddressLine2"
              v-model="formData.placeOfDeathAddressLine2"
              :required="false"
              :optional="true"
              type="text"
              :text="$t('forms.labels.addressLine2')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="placeOfDeathAddressSuburb"
              v-model="formData.placeOfDeathAddressSuburb"
              :type="stateIsNsw ? 'select' : 'text'"
              :text="$t('forms.labels.suburb')"
              :options="stateIsNsw ? [...NSW_HOSPITAL_SUBURBS, 'OTHER'] : null"
            />
          </div>
          <div
            v-if="placeOfDeathSuburbIsOther"
            class="cell cell__12/12 cell__4/12--lg"
          >
            <FormControl
              id="placeOfDeathAddressSuburb"
              v-model="formData.placeOfDeathAddressSuburbOther"
              type="text"
              :text="$t('forms.labels.suburb')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="placeOfDeathAddressPostcode"
              v-model="formData.placeOfDeathAddressPostcode"
              type="text"
              :text="$t('forms.labels.4digitPostcode')"
              inputmode="decimal"
              min="4"
              max="4"
              pattern="[0-9]{4}"
            />
          </div>
        </div>
      </template>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton
          aria-label="Submit"
          button-type="submit"
          level="primary"
          :disabled="!stateSelected || !isSupportedState"
        >
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="goNext">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="goNext">
          {{ $t('forms.labels.ok') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseIcon from '@/components/BaseIcon';
import Badge from '@/components/atoms/Badge';
import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'PagesAffiliateCremationsAtNeedPassingId',
  components: {
    Badge,
    BaseIcon,
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      formData: {
        isReportableToCoroner: null,
        placeOfDeathLocation: null,
        coronerLocation: null,
        guestDateOfDeath: null,
        placeOfDeathFacilityName: null,
        placeOfDeathFacilityNameOther: null,
        placeOfDeathAddressLine1: null,
        placeOfDeathAddressLine2: null,
        placeOfDeathAddressSuburb: null,
        placeOfDeathAddressSuburbOther: null,
        placeOfDeathAddressState: null,
        placeOfDeathAddressPostcode: null,
      },
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/care/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/deceased/${this.cremationRequestId}`
      );
    },
  },
};
</script>
