<template>
  <ModuleStep :index="7" prev-step="/will/about-yourself/partner">
    <AdiForm
      v-if="willMeta"
      v-slot="{ loading }"
      auto-focus
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :submit-label="btnLabel"
      submit-icon="arrow-right"
      @done="onDone"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.will.aboutYourSelf.questions.phone') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.aboutYourSelf.phoneTip') }}
        </Tip>
        <FormRow>
          <TextInput
            id="phone"
            v-model="willMeta.phone_number"
            :disabled="loading"
            :placeholder="$t('forms.placeholders.yourPhoneNumber')"
            type="tel"
            rules="phone"
          />
        </FormRow>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import FormSection from '@/components/FormSection';
import ModuleStep from '@/components/templates/ModuleStep';
import TextInput from '@/components/TextInput';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfPhone',
  components: {
    AdiForm,
    FormRow,
    FormSection,
    ModuleStep,
    TextInput,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    btnLabel() {
      return this.willMeta.phone_number ? 'Next' : 'Skip';
    },
  },
  methods: {
    onDone() {
      if (this.willMeta.phone_number) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          phone: `${this.willMeta.phone_number}`,
        });
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 1 (About Yourself)',
      });

      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
