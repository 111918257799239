<template>
  <div class="relative">
    <div
      class="group flex flex-col items-start w-full md:h-96 p-8 rounded-lg bg-white shadow-2xl transition-card transition-250"
      :class="classes.card"
    >
      <Tooltip class="relative w-12 h-12 mb-4" :content="tooltip">
        <div v-if="issues">
          <div
            class="absolute flex justify-center items-center w-full h-full rounded-full bg-orange-300 text-white"
          >
            <BaseIcon id="alert" />
          </div>
        </div>
        <div v-else>
          <div
            class="absolute flex justify-center items-center w-full h-full rounded-full font-medium text-2xl text-teal-200"
          >
            {{ number }}
          </div>
          <ProgressRing
            class="absolute w-full h-full"
            :progress="adjustedProgress"
          />
        </div>
      </Tooltip>
      <div class="w-full">
        <div class="mb-3 text-2xl md:text-3xl leading-none">
          {{ $t(title) }}
        </div>
        <Buttons v-if="complete && showEdit && !locked" layout="start">
          <RuButton level="plain" @click="edit" @focus.native="$emit('focus')">
            {{ $t('components.moduleCard.edit') }}
          </RuButton>
        </Buttons>
        <p v-else-if="!complete" class="text-grey-600">{{ $t(description) }}</p>
      </div>
      <div
        v-if="locked"
        class="hidden group-hover:flex flex-col flex-grow justify-end align-start w-full text-grey-600"
      >
        {{ lockedMessage }}
      </div>
      <div
        v-else-if="complete || willStatus === 'APPROVED'"
        class="flex flex-col flex-grow justify-end align-start w-full text-charcoal-100"
      >
        <slot v-if="!loading" />
      </div>
      <div v-else class="w-full mt-auto">
        <p class="text-grey-500">{{ duration.join('-') }} mins</p>
        <Buttons layout="stretch">
          <RuButton level="primary" :href="to">
            {{ buttonText }}
          </RuButton>
        </Buttons>
      </div>
    </div>
    <div
      v-if="loading"
      class="absolute z-10 inset-0 flex justify-center items-center text-teal-200"
    >
      <LoadingDots animation="slide" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseIcon from '@/components/BaseIcon';
import LoadingDots from '@/components/LoadingDots';
import ProgressRing from '@/components/ProgressRing';
import Tooltip from '@/components/Tooltip';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsModuleCard',
  components: {
    BaseIcon,
    LoadingDots,
    ProgressRing,
    Tooltip,
    Buttons,
    RuButton,
  },
  props: {
    editable: {
      default: false,
      type: Boolean,
    },
    description: {
      type: String,
      required: true,
    },
    duration: {
      type: Array,
      required: true,
    },
    issues: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    number: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    ready: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    lockedMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['willStatus']),
    adjustedProgress() {
      if (this.willStatus === 'APPROVED') return 1;
      else return this.ready && this.progress === 0 ? 0.05 : this.progress;
    },
    buttonText() {
      return this.continue ? 'Continue' : 'Start';
    },
    classes() {
      return {
        button: {
          hidden: !this.continue,
        },
        card: {
          'opacity-50 scale-98': this.loading,
        },
      };
    },
    complete() {
      return this.progress === 1;
    },
    continue() {
      return this.ready || this.progress;
    },
    showEdit() {
      return (
        ['IN_PROGRESS', 'FLAGGED'].includes(this.willStatus) || this.editable
      );
    },
    tooltip() {
      let tooltip = this.$t('components.moduleCard.tooltip.default');

      switch (this.willStatus) {
        case 'IN_PROGRESS':
          if (this.complete) {
            tooltip = this.$t('components.moduleCard.tooltip.inProgress');
          }
          break;
        case 'AWAITING_APPROVAL':
          tooltip = this.$t('components.moduleCard.tooltip.awaitingApproval');
          break;
        case 'FLAGGED':
          if (this.issues) {
            tooltip = this.$t(
              'components.moduleCard.tooltip.flaggedWithIssues'
            );
          } else {
            tooltip = this.$t(
              'components.moduleCard.tooltip.flaggedWithoutIssues'
            );
          }
          break;
        case 'APPROVED':
          tooltip = this.$t('components.moduleCard.tooltip.approved');
          break;
        default:
          break;
      }

      return tooltip;
    },
  },
  methods: {
    ...mapActions('ui', ['setShowUnlockWillModal']),
    edit() {
      if (this.willStatus === 'FLAGGED') {
        this.setShowUnlockWillModal(true);
      } else {
        this.$router.push({
          path: this.to,
        });
      }
    },
  },
};
</script>
