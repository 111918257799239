<template>
  <div class="ru:breadcrumb__container">
    <template v-for="(crumb, index) in crumbs">
      <div
        :key="`breadcrumbs${index}`"
        :class="`ru:breadcrumb --${crumb.state}`"
      >
        {{ crumb.text }}
        <div class="ru:breadcrumb__icons">
          <Icon
            id="check"
            class="ru:breadcrumb__icon ru:__complete"
            size="small"
          />
          <Icon
            id="arrow-right"
            class="ru:breadcrumb__icon ru:__active"
            size="small"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ComponentsMoleculesBreadcrumbs',
  components: {
    Icon,
  },
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &breadcrumb {
    display: flex;
    align-items: center;
    color: --rgba(gravel);
    transition: --out();

    &:is(#{$mf}complete) {
      transition: --in();
      color: --rgba(theme);
    }

    &:is(#{$mf}active) {
      color: --rgba(theme);
    }

    &__container {
      display: none;
      @include mq('min-md') {
        display: flex;
        justify-content: center;
        padding-bottom: var(--base-margin);
        margin-bottom: var(--base-margin);
        border-bottom: 2px solid --rgba(iron);
        gap: #{calc(var(--base-margin) * 2)};
      }
    }

    &__icons {
      background: --rgba(body-background);
      margin-left: var(--base-margin);
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
      border-radius: var(--border-radius-round);
      transition: --out();
      display: flex;
      align-items: center;
      justify-content: center;

      @at-root #{$mf}complete & {
        background: --rgba(theme);
        color: --rgba(lighter);
      }

      #{$ru}icon {
        position: absolute;

        &:is(#{$el}complete) {
          opacity: 0;
        }

        @at-root #{$mf}complete &:is(#{$el}complete) {
          opacity: 1;
        }

        @at-root #{$mf}complete &:is(#{$el}active) {
          opacity: 0;
        }
      }
    }
  }
}
</style>
