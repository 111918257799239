<template>
  <div>
    <label
      v-for="(option, index) in options"
      :key="index"
      class="group block border-2 border-transparent rounded mb-4 cursor-pointer select-none"
      :class="classes.label[index]"
      :for="`estate_${option.value}`"
    >
      <BaseCard
        :primary-text="option.label"
        :secondary-text="option.description"
      >
        <template #actions>
          <div
            class="relative inline-flex justify-center items-center w-12 h-12 rounded-full cursor-pointer"
            :class="classes.checkbox[index]"
          >
            <input
              :id="`estate_${option.value}`"
              v-model="selected"
              class="btn absolute top-0 left-0 w-full h-full p-0"
              name="estate"
              type="radio"
              :value="option.value"
            />
            <BaseIcon id="tick" />
          </div>
        </template>
        <BaseAlert
          v-if="option.note && isSelected[index]"
          compact
          type="confirm"
        >
          <p class="font-normal">{{ option.note }}</p>
        </BaseAlert>
      </BaseCard>
    </label>
  </div>
</template>

<script>
import BaseAlert from '@/components/BaseAlert';
import BaseCard from '@/components/BaseCard';
import BaseIcon from '@/components/BaseIcon';

import { people, user } from '@/mixins/apollo';

export default {
  name: 'ComponentsEstateSelector',
  components: {
    BaseAlert,
    BaseCard,
    BaseIcon,
  },
  mixins: [people, user],
  props: {
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    classes() {
      return {
        checkbox: (() => {
          return this.isSelected.map((selected) => {
            return {
              'bg-grey-200 group-hover:bg-teal-100 text-grey-400 group-hover:text-teal-300':
                !selected,
              'bg-teal-200 text-black': selected,
            };
          });
        })(),
        label: (() => {
          return this.isSelected.map((selected) => {
            return {
              'border-teal-200': selected,
            };
          });
        })(),
      };
    },
    options() {
      const options = [];

      if (this.partners.length) {
        options.push({
          label: this.$t('components.estateSelector.myPartner.label'),
          description: this.$t(
            'components.estateSelector.myPartner.description'
          ),
          value: 'partner',
        });
      }

      if (this.children.length) {
        options.push({
          label:
            this.children.length > 1
              ? this.$t('components.estateSelector.myChildren.label')
              : this.$t('components.estateSelector.myChild.label'),
          description:
            this.children.length > 1
              ? this.$t('components.estateSelector.myChildren.description')
              : this.$t('components.estateSelector.myChild.description'),
          value: 'children',
        });
      }

      options.push({
        label: this.$t('components.estateSelector.other.label'),
        description: this.$t('components.estateSelector.other.description'),
        value: 'custom',
      });

      return options;
    },
    isSelected() {
      return this.options.map((option) => {
        return option.value === this.selected;
      });
    },
  },
  watch: {
    selected(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    this.selected = this.value;
  },
};
</script>
