<template>
  <div>
    <AdminBackLink to="/admin/users">Users</AdminBackLink>
    <AdminMainSideLayout>
      <template #main>
        <AdminCard v-if="user" title="User details" tight>
          <AdminSimpleTable :rows="contactRows" />
        </AdminCard>
        <AdminCard v-if="subscription" title="Subscription details" tight>
          <AdminSimpleTable :rows="subscriptionRows" />
        </AdminCard>
        <AdminCard
          v-if="historicalWills && isUserConsumer"
          tight
          title="Historical wills"
        >
          <UserHistoricalWills
            :wills="historicalWills"
            :loading="$apollo.loading"
          />
        </AdminCard>
        <AdminCard
          v-if="
            isAdminCremationsAdminOrHigher &&
            atNeedCremationRequests &&
            atNeedCremationRequests.length > 0 &&
            isUserConsumer
          "
          title="Cremation Requests"
          tight
        >
          <AdminDataTable
            :columns="cremationRequestColumns"
            :loading="$apollo.loading"
            :min-rows="atNeedCremationRequests.length"
            :row-count="atNeedCremationRequests.length"
          >
            <template #rows>
              <AdminDataTableRow
                v-for="(cremationRequest, index) in atNeedCremationRequests"
                :key="index"
                :to="localePath(`/admin/cremations/${cremationRequest.id}`)"
              >
                <AdminDataTableCell>
                  <span
                    class="admin-id px-2 py-1 border rounded font-mono font-medium text-sm leading-none"
                  >
                    {{ cremationRequest.id.substring(0, 5).toUpperCase() }}
                  </span>
                </AdminDataTableCell>
                <AdminDataTableCell>
                  {{ getGuestIdentifier(cremationRequest) }}
                </AdminDataTableCell>
                <AdminDataTableCell>
                  {{ getAdminEmail(cremationRequest.adminUserId) }}
                </AdminDataTableCell>
                <AdminDataTableCell>
                  {{ cremationRequest.status }}
                </AdminDataTableCell>
              </AdminDataTableRow>
            </template>
          </AdminDataTable>
        </AdminCard>
        <OrdersDetails v-if="orders" :orders="orders" />
      </template>
      <template #side>
        <AdminCard v-if="showChangeUserRoleCard" title="Status">
          <AdminCardSection>
            <div class="flex">
              <div class="pr-1 flex-none flex items-center">Role</div>
              <div class="flex-auto flex-1">
                <select
                  id="user-role-input"
                  v-model="userRole"
                  class="w-full h-8"
                  @change="changeUserRole"
                >
                  <option
                    v-for="userRole in getSubordinateRoleOptionsByRole(role)"
                    :key="userRole.value"
                    :value="userRole.value"
                  >
                    {{ userRole.text }}
                  </option>
                </select>
              </div>
            </div>
          </AdminCardSection>
        </AdminCard>
        <AdminCard v-if="isUserConsumer" title="Actions">
          <AdminCardSection>
            <AdminActionLink
              v-if="isAdminClassUser"
              class="mb-4 text-blue-200"
              glyph="user"
              @click="masquerade(userId)"
            >
              Masquerade as user
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="circle-tick"
              :disabled="will && will.verified"
              @click="verify"
            >
              Mark email as verified
            </AdminActionLink>
            <AdminActionLink
              v-if="isAdminCremationsAdminOrHigher"
              glyph="document"
              class="mb-4 text-blue-200"
              @click="createCremationRequest"
            >
              Create cremation request
            </AdminActionLink>
            <AdminActionLink
              v-if="isUserSuperAdmin"
              class="mb-4 text-red-500"
              glyph="remove"
              @click="deleteUser"
            >
              Delete user
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
        <AdminCard v-if="isUserConsumer" title="Referrals">
          <AdminCardSection v-if="invites">
            <AdminActionLink
              class="mb-4 text-blue-200"
              glyph="document"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Invites Emailed: <strong>{{ invitesSent.length }}</strong>
              </p>
            </AdminActionLink>
            <AdminActionLink
              class="mb-4 text-green-100"
              glyph="circle-tick"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Invites Complete: <strong>{{ invitesComplete.length }}</strong>
              </p>
            </AdminActionLink>
            <AdminActionLink
              v-if="invitedBy"
              class="mb-4 text-cerise-200"
              glyph="child"
              :underline="false"
              :loading="$apollo.queries.invites.loading"
            >
              <p>
                Referrer: <strong>{{ invitedBy }}</strong>
              </p>
            </AdminActionLink>
          </AdminCardSection>
        </AdminCard>
      </template>
    </AdminMainSideLayout>
    <Toast />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import CremationsAdmins from '@/mixins/cremations-admins';
import {
  formatError,
  getSubordinateRoleOptionsByRole,
  getSubordinateRolesByRole,
  isCremationsAdminOrHigher,
} from '@/utilities';

import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminBackLink from '@/components/admin/BackLink';
import AdminCard from '@/components/admin/Card';
import AdminCardSection from '@/components/admin/CardSection';
import AdminMainSideLayout from '@/components/admin/MainSideLayout';
import AdminSimpleTable from '@/components/admin/SimpleTable';
import dialog from '@/mixins/message-box';
import Toast from '@/components/Toast';

import GET_USER_QUERY from '@/graphql/queries/GetUser';
import UPDATE_USER_ROLE_MUTATION from '@/graphql/mutations/UpdateUserRole';
import GET_WILLS_QUERY from '@/graphql/queries/GetWills';
import GET_WILL_BY_EMAIL from '@/graphql/queries/GetWillByEmail';
import GET_CREMATIONS_BY_INFORMANT_ID from '@/graphql/queries/GetAtNeedCremationRequestsByInformantUserId.gql';
import DELETE_USER from '@/graphql/mutations/DeleteUser';
import CREATE_AT_NEED_CREMATION_REQUEST_MUTATION from '@/graphql/mutations/CreateAtNeedCremationRequest';

import UserHistoricalWills from '@/components/UserHistoricalWills';
import AdminActionLink from '@/components/admin/ActionLink';
import OrdersDetails from '@/components/OrdersDetails';

import adminUserAction from '@/mixins/admin-user-action';
import { subscription } from '@/mixins/apollo';
import orders from '@/mixins/apollo/orders';

export default {
  name: 'PagesAffiliateAdminUsersId',
  components: {
    AdminBackLink,
    AdminCard,
    AdminCardSection,
    AdminMainSideLayout,
    AdminSimpleTable,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    UserHistoricalWills,
    AdminActionLink,
    Toast,
    OrdersDetails,
  },
  mixins: [dialog, adminUserAction, orders, subscription, CremationsAdmins],
  layout: 'admin',
  apollo: {
    user: {
      query: GET_USER_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.userId,
        };
      },
      update: (data) => data.getUser,
      result({ data }) {
        this.userRole = data.getUser.role;
      },
    },
    will: {
      query: GET_WILL_BY_EMAIL,
      fetchPolicy: 'network-only',
      variables() {
        return {
          email: this.userEmail,
        };
      },
      skip() {
        return !this.isUserConsumer || !this.userEmail;
      },
      update: (data) => {
        if (data.getWillByEmail) {
          return data.getWillByEmail.will;
        }
        return null;
      },
    },
    historicalWills: {
      query() {
        return GET_WILLS_QUERY;
      },
      update: (data) => {
        if (data.getWills) {
          return data.getWills.wills;
        }
        return [];
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchQuery: this.userEmail,
        };
      },
    },
    atNeedCremationRequests: {
      query: GET_CREMATIONS_BY_INFORMANT_ID,
      fetchPolicy: 'network-only',
      skip() {
        return !isCremationsAdminOrHigher(this.role) || !this.userId;
      },
      variables() {
        return {
          informantUserId: this.userId,
        };
      },
      update: (data) => data.getAtNeedCremationRequestsByInformantUserId || [],
    },
  },
  data() {
    return {
      userRole: '',
      historicalWills: [],
      cremationRequestColumns: ['ID', 'Guest', 'Arranger', 'Status'],
      atNeedCremationRequests: [],
      will: null,
    };
  },
  computed: {
    ...mapGetters(['role']),
    userId() {
      return this.$route.params.id;
    },
    userEmail() {
      return this.user?.email;
    },
    contactRows() {
      return [
        ['ID', this.user?.id],
        ['Email', this.user?.email],
        ['Role', this.titleCase(this.user?.role)],
        ['Verified', this.user?.verified],
      ];
    },
    subscriptionRows() {
      return [
        ['ID', this.subscription.id],
        ['Stripe Subscription id', this.subscription.stripeSubscriptionId],
        ['Auto renew', this.subscription.autoRenew],
        [
          'Payment method expires at',
          this.formattedSubscriptionPaymentMethodExpiryDate,
        ],
        ['Expires at', this.formattedSubscriptionExpiryDate],
        ['Created at', this.formattedSubscriptionCreatedAtDate],
      ];
    },
    isUserSuperAdmin() {
      return this.role === 'SUPER_ADMIN';
    },
    isUserConsumer() {
      return this.user?.role === 'CONSUMER';
    },
    isAdminCremationsAdminOrHigher() {
      return isCremationsAdminOrHigher(this.role);
    },
    showChangeUserRoleCard() {
      const subordinates = getSubordinateRolesByRole(this.role);
      return subordinates.length > 1 && subordinates.includes(this.user?.role);
    },
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    ...mapActions('admin', ['setUsersPageSearch']),
    titleCase(str) {
      return startCase(toLower(str));
    },
    getAdminEmail(adminUserId) {
      return this.getCremationsAdminOrHigherEmail(adminUserId);
    },
    getGuestIdentifier(cremationRequest) {
      return `${(cremationRequest.guestFirstName || '').trim()} ${(
        cremationRequest.guestLastName || ''
      ).trim()}`;
    },
    changeUserRole({ target: { value: userNewRole } }) {
      const userRoleString = this.titleCase(userNewRole);
      this.$confirm(
        {
          title: `Change user role to ${userRoleString}?`,
          message: `Are you sure you want to assign ${userRoleString} role to ${this.user?.email}?`,
        },
        async (action) => {
          if (action) {
            try {
              await this.$apollo.mutate({
                mutation: UPDATE_USER_ROLE_MUTATION,
                variables: {
                  id: this.user.id,
                  role: userNewRole,
                },
              });
            } catch (error) {
              this.$nuxt.$emit('toast', {
                type: 'error',
                message: formatError(error.message),
              });
            }
          } else {
            this.userRole = this.user?.role;
          }
        }
      );
    },
    deleteUser() {
      this.$confirm(
        {
          title: `Delete user ${this.user?.email}?`,
          message: `All user information will be permanently deleted.`,
        },
        async (action) => {
          if (action) {
            try {
              await this.$apollo.mutate({
                mutation: DELETE_USER,
                variables: {
                  userId: this.user.id,
                },
              });
              this.setUsersPageSearch(null);
              this.$router.push({ path: this.localePath('/admin/users') });
            } catch (error) {
              this.$nuxt.$emit('toast', {
                type: 'error',
                message: formatError(error.message),
              });
            }
          }
        }
      );
    },
    createCremationRequest() {
      this.$confirm(
        {
          title: `Create cremation request?`,
          message: `A cremation request will be created with ${this.user?.email} as informant.`,
        },
        async (action) => {
          if (action) {
            const cremationRequest = await this.$apollo.mutate({
              mutation: CREATE_AT_NEED_CREMATION_REQUEST_MUTATION,
              variables: {
                informantUserId: this.user?.id,
              },
            });
            this.$router.push({
              path: this.localePath(
                `/admin/cremations/${cremationRequest.data.createAtNeedCremationRequest.id}`
              ),
            });
          }
        }
      );
    },
  },
};
</script>
