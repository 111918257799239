<template>
  <div class="ru:container ru:container--tight">
    <Headline :level="1"> {{ $t('texts.sentences.thankYou') }} </Headline>
    <p>
      {{ $t('pages.checkout.orderSuccessful') }}
      <nuxt-link class="link" :to="localePath('/')">
        {{ $t('pages.checkout.proceed') }}
      </nuxt-link>
    </p>
  </div>
</template>

<script>
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliateCheckoutUnlockThanks',
  components: {
    Headline,
  },
  layout: 'ruach',
};
</script>
