<template>
  <ValidationProvider
    v-slot="{ errors }"
    :disabled="!required"
    :rules="required ? 'required' : null"
    class="w-full"
    tag="div"
    @keydown.up.prevent.self=""
    @keydown.down.prevent.self=""
    @keydown.enter.prevent.self=""
  >
    <label v-if="label" :id="id" class="input-label">{{ label }} </label>
    <client-only>
      <div v-if="isTouchDevice" class="relative">
        <select
          :id="id"
          v-model="modelValue"
          class="input"
          :class="[classes.native, { 'input-error': errors.length }]"
          :disabled="disabled"
          :placeholder="placeholder"
          autocomplete="off"
          @change="onChange"
        >
          <option v-if="placeholder" disabled selected value="">
            {{ placeholder }}
          </option>
          <option
            v-for="(option, index) in selectOptions"
            :key="index"
            :value="option.value"
          >
            {{ option.text }}
          </option>
          <option v-if="allowUnknown" value="UNKNOWN">UNKNOWN</option>
        </select>
        <div
          class="absolute top-0 right-0 bottom-0 flex items-center w-10 stroke-current pointer-events-none"
          :class="classes.indicator"
        >
          <SelectInputOpenIndicator />
        </div>
      </div>
      <v-select
        v-else
        v-model="modelValue"
        :class="[classes.custom, { 'vs--has-error': errors.length }]"
        v-bind="settings"
        :required="required"
        :value="value"
        @input="onChange"
      />
    </client-only>
    <div v-if="errors.length" class="mt-1 text-base text-red-300">
      {{ getErrorMessage(errors) }}
    </div>
  </ValidationProvider>
</template>

<script>
import detectIt from 'detect-it';
import { ValidationProvider } from 'vee-validate';

import SelectInputOpenIndicator from '@/components/SelectInputOpenIndicator';

export default {
  name: 'ComponentsSelectInput',
  components: {
    SelectInputOpenIndicator,
    ValidationProvider,
  },
  model: {
    event: 'change',
  },
  props: {
    autoWidth: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      default: null,
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    searchable: {
      default: false,
      type: Boolean,
    },
    compact: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: String,
    },
    allowUnknown: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    selectOptions() {
      if (typeof this.options[0] === 'string') {
        return this.options.map((option) => {
          return {
            value: option,
            text: option,
          };
        });
      }
      return this.options;
    },
    classes() {
      return {
        custom: {
          'vs--auto-width': this.autoWidth,
        },
        native: {
          'text-grey-500': !this.modelValue,
          'input-disabled': this.disabled,
          'h-12 text-base px-4': this.compact,
        },
        indicator: {
          'text-grey-600': this.disabled,
          'text-teal-200': !this.disabled,
        },
      };
    },
    isTouchDevice() {
      return (
        (typeof navigator !== 'undefined' && navigator.webdriver) ||
        true ||
        detectIt.deviceType !== 'mouseOnly' ||
        ['base', 'sm'].includes(this.$mq)
      );
    },
    settings() {
      return {
        clearable: false,
        components: {
          OpenIndicator: SelectInputOpenIndicator,
        },
        disabled: this.disabled,
        inputId: this.id,
        options: this.options,
        placeholder: this.placeholder,
        searchable: this.searchable,
      };
    },
  },
  watch: {
    value(newValue) {
      this.modelValue = newValue;
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.modelValue, this);
    },
    getErrorMessage(errors) {
      const lastErrorKey = errors[errors.length - 1];
      if (lastErrorKey.split(':').length > 1) {
        const [key, args] = lastErrorKey.split(':');
        return this.$t(key, { args });
      } else {
        return this.$t(lastErrorKey);
      }
    },
  },
};
</script>
