<template>
  <div class="w-full">
    <label v-if="label" class="input-label" :for="id">{{ label }} </label>
    <div class="relative">
      <BaseInput
        :id="id"
        classes="pr-12"
        :disabled="disabled"
        :min="0"
        :max="100"
        :name="id"
        :rules="rules"
        inputmode="numeric"
        :value="value"
        :placeholder="placeholder"
        @input="$emit('input', $event)"
      />
      <div
        class="absolute top-0 right-0 pr-6 bottom-0 flex justify-end items-center w-10 opacity-30 text-xl pointer-events-none user-select-none"
      >
        {{ percentage }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';

export default {
  name: 'ComponentsPercentageInput',
  components: {
    BaseInput,
  },
  props: {
    percentage: {
      default: '%',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      default: null,
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number],
    },
    placeholder: {
      default: null,
      type: String,
    },
  },
};
</script>
